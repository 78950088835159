import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import { rewardWinningListRequest, rewardWinningListFail, rewardWinningListSuccess } from "../slices/rewardWinningTecnicianSlice";
export const getAllrewardWinningList = (status, search, filter) => async (dispatch) => {
    const token = localStorage.getItem("token");
    let payload = search ? `search=${search}&` : '';
    payload += filter?.zone_id ? `zone_id=${filter?.zone_id}&` : '';
    payload += filter?.district ? `district=${filter?.district}&` : '';

    try {
        dispatch(rewardWinningListRequest())
        const response = await axios.get(`${BASE_URL}/technician/reward/technician-count?status=${status}&${payload}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        dispatch(rewardWinningListSuccess(response?.data))
    } catch (error) {
        console.log(error);
        dispatch(rewardWinningListFail(error?.response?.data))
    }
}
