import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { getDashboardDistrictById } from "../../redux/actions/adminDashboard";

const DashboardDataTable = ({ isData, handleZoneId, zoneId }) => {
  const role = localStorage.getItem("role");
  const { superAdminDashboardData } = useSelector(
    (state) => state.superAdminDashboardState
  );
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };
  const [districtData, setDistrictData] = useState([]);
  const zoneData = () => {
    return superAdminDashboardData?.data?.map((value) => ({
      zone_id: value?.id,
      zonalAdminId: value?.zone_admin_id,
      zonalAdminName: value?.userValue?.fullname,
      zone: value?.zone_name,
      districtCount: value?.district_ids?.length,
      totalRevenue: value?.SRCount?.totalRevenue,
      totalTechnicians:
        value?.userCount?.technician +
        value?.userCount?.district_admin +
        value?.userCount?.zonal_admin +
        value?.userCount?.super_admin,
    }));
  };
  useEffect(() => {
    const zoneId = superAdminDashboardData?.data[0]?.id;
    if (zoneId) {
      getDistrictData(zoneId);
    }
    // eslint-disable-next-line
  }, [superAdminDashboardData]);
  const getDistrictData = (id) => {
    const payload = {
      startDate: superAdminDashboardData?.start_date,
      endDate: superAdminDashboardData?.end_date,
      zone_id: id,
    };
    getDashboardDistrictById(payload)
      .then((res) => {
        setDistrictData(
          res?.data?.map((value) => ({
            districtAdminId: value?.dist_admin_id,
            districtAdminName: value?.userValue?.fullname,
            district: value?.city_name,
            totalSRs:
              value?.SRCount?.srCountDetails?.accepted +
              value?.SRCount?.srCountDetails?.assigned +
              value?.SRCount?.srCountDetails?.cancelled +
              value?.SRCount?.srCountDetails?.closed +
              value?.SRCount?.srCountDetails?.notified +
              value?.SRCount?.srCountDetails?.reopened +
              value?.SRCount?.srCountDetails?.unassigned,
            ClosedSRs: value?.SRCount?.srCountDetails?.closed,
            totalRevenue: value?.SRCount?.totalRevenue,
            totalTechnicians:
              value?.userCount?.technician +
              value?.userCount?.district_admin +
              value?.userCount?.zonal_admin +
              value?.userCount?.super_admin,
          }))
        );
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const zoneColumns = [
    {
      name: "Zone Name",
      selector: (row) => row.zone,
      sortable: true,
    },
    {
      name: "Admin",
      selector: (row) => row.zonalAdminName,
      sortable: true,
    },
    {
      name: "Total District",
      selector: (row) => row.districtCount,
      sortable: true,
    },
    {
      name: "New Technician",
      selector: (row) => row.totalTechnicians,
      sortable: true,
    },
    {
      name: "Total Revenue",
      selector: (row) => row.totalRevenue,
      sortable: true,
    },
  ];

  const districtColumns = [
    {
      name: "District",
      selector: (row) => row.district,
      sortable: true,
    },
    {
      name: "Admin",
      selector: (row) => row.districtAdminName,
      sortable: true,
    },
    {
      name: "Closed SR's",
      selector: (row) => row.ClosedSRs,
      sortable: true,
    },
    {
      name: "New Technicians",
      selector: (row) => row.totalTechnicians,
      sortable: true,
    },
    {
      name: "Total Revenue",
      selector: (row) => row.totalRevenue,
      sortable: true,
    },
  ];

  useEffect(() => {
    if (isData === "district") {
      getDistrictData(zoneId);
    }
    // eslint-disable-next-line
  }, [zoneId]);

  const data =
    isData === "zone" ? zoneData() : isData === "district" && districtData;

  const columns =
    isData === "zone" ? zoneColumns : isData === "district" && districtColumns;

  return (
    <div
      className={`data-table-container ${role === "ZonalAdmin" ? "max-h" : ""}`}
    >
      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={data}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="300px"
        onRowClicked={(data) => {
          if (isData === "zone") {
            handleZoneId(data.zone_id);
          }
        }}
      />
    </div>
  );
};

export default DashboardDataTable;
