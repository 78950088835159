import React, { useEffect } from "react";
import Footer from "./Footer";
import "../landingpage.css";
import TopBanner from "./home/TopBanner";
import Promotion from "./home/Promotion";
import HomeServices from "./home/HomeServices";
import MoreFeatures from "./home/MoreFeatures";
import HelpBanner from "./home/HelpBanner";
import Reviews from "./home/Reviews";
import Navbar from "./common/Navbar";
import Guarantee from "./home/Guarantee";

const LandingPage = ({ innerWidth }) => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div>
        <Navbar innerWidth={innerWidth} />
        <div className="shade-provider"></div>
        <TopBanner />
        <Promotion />
        <HomeServices />
        <MoreFeatures />
        <Guarantee />
        <HelpBanner />
        <Reviews />
        <Footer />
      </div>
    </>
  );
};
export default LandingPage;
