import React from "react";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import S3NewLogo from "../Assets/s3_new_logo/S3NewLogo";
// import app_store from "../Assets/Home/App-store.svg";
import play_store from "../Assets/Home/Play-store.svg";
import TopScroller from "./home/TopScroller";

const Footer = ({ topscroller }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleScrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  const NotShowNewsLetter = [];

  const isNotShowFooter = () => {
    if (NotShowNewsLetter.some((route) => route === pathname)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {!topscroller && (
        <div className={`${isNotShowFooter() ? "d-none" : ""}  container`}>
          <TopScroller />
        </div>
      )}

      <div className="footer">
        <div className="footer-container res-849px-d-block">
          <div className="brand">
            <div className="footer-logo-mobile">
              <S3NewLogo color="white" />
            </div>
            <div className="details">
              A friendly neighbourhood handyman and customers satisfied with
              instant service.
            </div>
            <div
              style={{
                display: "flex",
                gap: "16px",
                color: "rgba(125, 129, 141, 1)",
                marginTop: "30px",
              }}
              className="links-flex"
            >
              <FaTwitter />
              <FaFacebookSquare />
              <FaInstagram />
              <FaLinkedin />
            </div>
          </div>
          <div className="quick-links">
            <div className="heading">Company</div>
            <div className="links-container">
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/about");
                }}
              >
                <span>About us</span>
              </div>
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/Contact-us");
                }}
              >
                <span>Contact us</span>
              </div>
            </div>
          </div>
          <div className="support">
            <div className="heading">Services</div>
            <div className="links-container">
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/tv-service");
                }}
              >
                <span>Television/TV</span>
              </div>
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/refrigerator-service");
                }}
              >
                <span>Refrigerator</span>
              </div>
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/washing-machine-service");
                }}
              >
                <span>Washing Machine</span>
              </div>
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/dth-service");
                }}
              >
                <span>DTH</span>
              </div>
            </div>
          </div>
          <div className="support">
            <div className="heading">Legal</div>
            <div className="links-container">
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/privacy-policy");
                }}
              >
                <span>Privacy Policy</span>
              </div>
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/terms-of-use");
                }}
              >
                <span>Terms of Use</span>
              </div>
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/delete-account");
                }}
              >
                <span>Delete Account</span>
              </div>
            </div>
          </div>
          <div className="mail">
            <div className="heading">Download Our App</div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className="app-play-store res-576px-r-0"
            >
              <img
                className="footer-play-store"
                alt="play_store"
                src={play_store}
                style={{ height: "50px", width: "130px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
