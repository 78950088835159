import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../common.css";
import Logo from "../Images/s3Logo.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button } from "@mui/material";

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleReloadPage = () => {
    if (pathname === "/") {
      window.location.reload();
    } else {
      navigate("/");
    }
  };
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Navbar.Brand>
        <Link className="navbar-brand ms-4" to="/">
          <img
            alt="demo"
            src={Logo}
            style={{ width: "100%" }}
            onClick={handleReloadPage}
          />
        </Link>
      </Navbar.Brand>
      <div style={{ marginRight: "10px" }}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </div>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav
          className="ms-auto pe-2 ps-2"
          style={{ width: "-webkit-fill-available" }}
        >
          <div
            className="d-flex align-items-center nav-d-block"
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            {localStorage.getItem("role") === "SuperAdmin" ||
            localStorage.getItem("role") === "DistrictAdmin" ||
            localStorage.getItem("role") === "ZonalAdmin" ||
            localStorage.getItem("role") === "Technician" ||
            localStorage.getItem("role") === "Consumer" ? (
              ""
            ) : (
              <div
                className="nav-link-center"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                  marginTop: 10,
                }}
              >
                <p
                  className=" log-item"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#1E232C",
                  }}
                >
                  <Button
                    variant="text"
                    className="log-item"
                    onClick={() => navigate("/Login")}
                    style={{
                      textDecoration: "none",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#1E232C",
                    }}
                  >
                    Login
                  </Button>
                  <text style={{ pointerEvents: "none" }}>/</text>
                  <Button
                    onClick={() => navigate("/commonRegister")}
                    style={{
                      textDecoration: "none",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#1E232C",
                    }}
                  >
                    Register
                  </Button>
                </p>
              </div>
            )}
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
