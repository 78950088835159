import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import { RewardZoneListRequest, RewardZoneListFail, RewardZoneListSuccess } from "../slices/rewardZoneListSlice";
export const getAllRewardZoneList = (startDate, endDate, status) => async (dispatch) => {
    const token = localStorage.getItem("token");

    try {
        dispatch(RewardZoneListRequest())
        const response = await axios.get(`${BASE_URL}/technician/reward/zone-count?startDate=${startDate}&endDate=${endDate}&status=${status}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        dispatch(RewardZoneListSuccess(response?.data))
    } catch (error) {
        console.log(error);
        dispatch(RewardZoneListFail(error?.response?.data))
    }
}
