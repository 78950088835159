import React from "react";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import "../../../layout.css";
import PendingTechniciansList from "./PendingTechniciansList";
import "../../../styles/techverify.css";
import { useNavigate } from "react-router-dom";

const PendingTechniciansHome = () => {
  const navigate = useNavigate();

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div
          className="row container-fluid"
          style={{ paddingRight: "0px", overflow: "hidden" }}
        >
          <div className="col-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <div
            className="col-12 user_management"
            style={{ paddingRight: "0px", marginTop: "80px" }}
          >
            <div className="row">
              <div className="mt-lg-2">
                <i
                  className="bi bi-chevron-left"
                  onClick={() => navigate(-1)}
                  style={{
                    fontWeight: "600",
                    fontSize: "21px",
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                ></i>
                <text
                  className="p-0"
                  style={{
                    fontWeight: "600",
                    color: "#1E232C",
                    fontSize: "21px",
                    width: "208px",
                    height: "32px",
                  }}
                >
                  Verify Technicians
                </text>
              </div>
              <div className="container-fluid" style={{ overflow: "hidden" }}>
                <div className="row">
                  <div
                    className="col-lg-12 p-0 technicians_list_home"
                    style={{ paddingTop: "15px" }}
                  >
                    <div className="container-fluid" style={{ padding: "4px" }}>
                      <div className="mt-lg-12">
                        <PendingTechniciansList />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PendingTechniciansHome;
