import React, { useState } from "react";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import { Paper } from "@mui/material";
import ReferralManagementTable from "./ReferralManagementTable";
import ReferralChart from "./ReferralChart";
import { FaArrowRotateRight } from "react-icons/fa6";
import './ReferralManagement.css';  // Import the CSS file where the spin animation is defined
import { useDispatch, useSelector } from "react-redux";
import { getAllreferralManagementList } from "../../../redux/actions/referralManagementAction";
import { getAllreferralChartList } from "../../../redux/actions/referralChartAction";

const ReferralManagement = () => {
  const dispatch = useDispatch();
  const [isSpinning, setIsSpinning] = useState(false);
  const { referralManagementListLoading } = useSelector(
    (state) => state.referralManagementState
  );
  const { referralChartListLoading } = useSelector(
    (state) => state.referralChartState
  );
  const handleClick = () => {
    setIsSpinning(true);
    dispatch(getAllreferralManagementList());
    dispatch(getAllreferralChartList());
    setTimeout(() => {
      setIsSpinning(false);
    }, 3000);

  };

  return (
    <div className="wallet-option" style={{ overflow: "hidden" }}>
      <Topbar />
      <div className="row">
        <div className="col-2 col-12">
          <Sidebar />
        </div>
        <div className="col-10 p-4 pb-0 wallet">
          <header className="d-flex align-items-center justify-content-between">
            <div
              className="mt-2 mb-2"
              style={{ fontWeight: "600", fontSize: "21px" }}
            >
              Referral Management
            </div>
            <div className="glass-effect">
              <div
                className={`reload-icon ${isSpinning || (referralManagementListLoading && referralChartListLoading) ? 'spin' : ''}  ${isSpinning || (referralManagementListLoading && referralChartListLoading) ? 'disabled' : ''}`}
                onClick={isSpinning || (referralManagementListLoading && referralChartListLoading) ? undefined : handleClick} // Disable the click handler when disabled
                style={{ fontSize: '30px' }} // Adjust icon size
              >
                <FaArrowRotateRight />
              </div>
            </div>

          </header>

          <div className="dashboard-charts w-100 d-flex align-items-center gap-3 mt-3 mb-3">
            <div className="chart-container w-100">
              <div
                className="d-flex align-items-center"
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div style={{ fontSize: "20px" }}>
                    <b>Referral Analytics by Source</b>
                  </div>
                </div>
              </div>
              <div className="separator-line" />
              <div>
                <ReferralChart />
              </div>
            </div>
          </div>
          <div>
            <div className="row mt-3 mb-3">
              <div className="col-12">
                <Paper
                  className="mt-1"
                  elevation={2}
                  style={{ borderRadius: "15px" }}
                >
                  <div className="p-2">
                    <ReferralManagementTable />
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};

export default ReferralManagement;
