import React, { useEffect, useState } from "react";
import Topbar from "../../../topbar";
import Sidebar from "../../../Sidebar";
import SpinnerLoader from "../../../../plugins/SpinnerLoader";
import { useNavigate } from "react-router-dom";
import "./techcard.css";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Api from "../../../../service/services";
import CartIcon from "./CartIcon";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Form, Modal } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
export default function TechnicianCategory() {
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(theme.breakpoints.down("sm"));
  const [loadingData, setLoadingData] = useState(false);
  const [getProductList, setGetProductList] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [max, setMax] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [filterApplied, setFilterApplied] = useState(false);
  const [applianceList, setApplianceList] = useState([]);
  const [applianceValue, setApplianceValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const handleSelectAppliance = () => {
    Api.getApplianceDropdown().then((result) => {
      setApplianceList(result);
    });
  };

  function valuetext(value) {
    return `$${value}`;
  }

  const handleMinChange = (event) => {
    setMinValue(Number(event.target.value));
  };

  const handleMaxChange = (event) => {
    setMaxValue(Number(event.target.value));
  };

  const handleChange = (event, newValue) => {
    setMinValue(newValue[0]);
    setMaxValue(newValue[1]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLoadingData(true);
    setTimeout(() => {
      setLoadingData(false);
    }, 1000);
    Api.getTechnicianProducts()
      .then((res) => {
        setGetProductList(res);
        const amountsArray = res?.list.map((item) => item?.sale_price);
        const sortedAmounts = amountsArray.sort((a, b) => a - b);
        const maxAmount = sortedAmounts[sortedAmounts?.length - 1];
        setMax(Math.round(maxAmount));
        setMaxValue(Math.round(maxAmount));
      })
      .catch((err) => console.log("Error", err));
  }, []);

  const getProductById = (id) => {
    Api.getTechnicianProductsById(id)
      .then((res) => {
        navigate(`/technicianCategoryById?product_id=${id}`);
      })
      .catch((err) => console.log("Error", err));
  };

  const searchFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase().trim();
    setSearchTerm(searchTerm); // Update search term state
    applyFilters(searchTerm, minValue, maxValue, applianceValue);
  };

  // Combined function to handle search + filters
  const applyFilters = async (searchTerm, minVal, maxVal, applianceVal) => {
    const res = await Api.getTechnicianProducts();
    let filteredTests = res.list;

    // Apply search filter
    if (searchTerm !== "") {
      filteredTests = filteredTests.filter((service) => {
        const productName = service.name.toLowerCase();
        return productName.includes(searchTerm);
      });
    }

    // Apply price range filter
    filteredTests = filteredTests.filter(
      (item) => item.sale_price >= minVal && item.sale_price <= maxVal
    );

    // Apply appliance filter
    if (applianceVal) {
      filteredTests = filteredTests.filter(
        (item) => item.appliance_type === applianceVal
      );
    }
    setGetProductList({ list: filteredTests });
  };
  // Filter Functions
  const clearData = () => {
    setMinValue(0);
    setMaxValue(max);
    setFilterApplied(false);
    setFilterModal(false);
    setApplianceValue("");
    setSearchTerm(""); // Clear search term as well
    Api.getTechnicianProducts()
      .then((res) => {
        let filteredTests = res.list;
        // If search term exists, filter based on search term
        if (searchTerm) {
          filteredTests = filteredTests.filter((service) => {
            const productName = service.name.toLowerCase();
            return productName.includes(searchTerm.toLowerCase());
          });
        }
        // Update product list after filtering (if any)
        setGetProductList({ list: filteredTests });
        const amountsArray = res?.list.map((item) => item?.sale_price);
        const sortedAmounts = amountsArray.sort((a, b) => a - b);
        const maxAmount = sortedAmounts[sortedAmounts?.length - 1];
        setMax(Math.round(maxAmount));
        setMaxValue(Math.round(maxAmount));
      })
      .catch((err) => console.log("Error", err));
  };
  const handleFilterCancel = () => {
    setMinValue(0);
    setMaxValue(max);
    setApplianceValue("");
    setFilterModal(false);
  };

  const handleApplyFilter = () => {
    applyFilters(searchTerm, minValue, maxValue, applianceValue); // Use combined filter logic
    setFilterModal(false);
    setFilterApplied(true);
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <Topbar />
      <div className="row">
        <div className="col-lg-2 col-12 border border-right">
          <Sidebar />
        </div>
        <div className="col-12 user_management" style={{ marginTop: "80px" }}>
          {loadingData ? (
            <div
              className="d-flex align-items-center"
              style={{ justifyContent: "center", height: "70vh" }}
            >
              <SpinnerLoader />
            </div>
          ) : (
            <div className="container-fluid" style={{ overflow: "hidden" }}>
              <div className="col-lg-12 ">
                <div
                  className="m-0 sticky-top bg-white"
                  style={{ position: "sticky", zIndex: "2" }}
                >
                  <i
                    className="bi bi-chevron-left "
                    onClick={() => navigate(-1)}
                    style={{
                      fontWeight: "600",
                      fontSize: "21px",
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                  ></i>
                  <text
                    className="p-0 res-font"
                    style={{
                      fontWeight: "600",
                      color: "#1E232C",
                      fontSize: "21px",
                      width: "208px",
                      height: "32px",
                    }}
                  >
                    Shop
                  </text>
                  <div
                    style={{
                      cursor: "pointer",
                      float: "right",
                    }}
                    onClick={() => navigate("/addToCart")}
                  >
                    <CartIcon />
                    {/* <TiShoppingCart
                      isAddedCartItem={isAddedCartItem}
                      style={{
                        fontSize: "24px",
                        width: "40px",
                        height: "40px",
                        color: "rgb(235, 129, 40)",
                      }}
                    /> */}
                  </div>
                  <div
                    className="p-0 mt-2"
                    style={{
                      display: "flex",
                      width: "-webkit-fill-available",
                    }}
                  >
                    <div
                      style={{
                        listStyle: "none",
                        width: "-webkit-fill-available",
                      }}
                    >
                      <div className="d-flex col-12 mt-4">
                        <Paper
                          elevation={0}
                          className="w-100  border rounded-0"
                          component="form"
                          sx={{ display: "flex", alignItems: "center" }}
                          style={{ height: "42px" }}
                        >
                          <IconButton type="button" aria-label="search">
                            <SearchIcon />
                          </IconButton>
                          <InputBase
                            sx={{ flex: 1 }}
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "14px",
                              width: "100%",
                            }}
                            placeholder="Search by Product Name"
                            inputProps={{
                              "aria-label": "search google maps",
                            }}
                            onChange={searchFilter}
                          />
                        </Paper>
                        <div
                          className="ms-2"
                          style={{ listStyle: "none" }}
                          onClick={() => setFilterModal(true)}
                        >
                          <IconButton
                            style={{
                              width: "42px",
                              height: "42px",
                              borderRadius: "100px",
                            }}
                            className="border rounded-0"
                            aria-label="delete"
                          >
                            {filterApplied && (
                              <Tooltip title="Filter applied" arrow>
                                <div className="filter-indicator">
                                  <div className="ic" />
                                </div>
                              </Tooltip>
                            )}
                            <FilterListIcon
                              style={{ color: "rgb(235, 129, 40)" }}
                            />
                          </IconButton>
                        </div>
                      </div>
                      <Modal
                        show={filterModal}
                        onHide={() => setFilterModal(false)}
                        centered
                        size="md"
                        className="filter-modal"
                      >
                        <div className="p-3">
                          <div
                            style={{
                              fontWeight: "600",
                              fontSize: "25px",
                            }}
                          >
                            Filter
                          </div>
                          <Form.Select
                            aria-label="Select Appliances"
                            className="mb-2"
                            style={{
                              color: "gray",
                              height: "50px",
                              cursor: "pointer",
                              marginTop: "10px",
                            }}
                            id="custom-select"
                            value={applianceValue}
                            onChange={(e) => {
                              const selectedApplianceValue = e.target.value;
                              setApplianceValue(selectedApplianceValue);
                            }}
                            onClick={handleSelectAppliance}
                          >
                            <option value="" disabled>
                              Select Appliances
                            </option>
                            {applianceList?.map((data) => (
                              <option
                                key={data?.id}
                                value={data?.appliance_name}
                                style={{
                                  width: "100px",
                                  wordWrap: "break-word",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {data?.appliance_name}
                              </option>
                            ))}
                          </Form.Select>

                          <div className="col-lg-12 mt-3 d-flex gap-5">
                            {" "}
                            <TextField
                              value={minValue}
                              label="Min Amount"
                              onChange={handleMinChange}
                            />
                            <TextField
                              value={maxValue}
                              label="Max Amount"
                              onChange={handleMaxChange}
                            />
                          </div>

                          <Slider
                            getAriaLabel={() => "Price range"}
                            value={[minValue, maxValue]}
                            onChange={handleChange}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                            min={0}
                            max={max}
                            disableSwap
                          />

                          <div
                            className="d-flex align-items-center mt-3 gap-2"
                            style={{
                              justifyContent: "flex-end",
                              width: "100%",
                            }}
                          >
                            <button
                              className="filter-clear-btn"
                              onClick={() => clearData()}
                            >
                              Clear
                            </button>
                            <button
                              className="filter-cancel-btn"
                              onClick={() => handleFilterCancel()}
                            >
                              Cancel
                            </button>
                            <button
                              className="filter-apply-btn"
                              onClick={() => handleApplyFilter()}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </Modal>
                      <div style={{ marginTop: 20 }}>
                        Total Products (
                        {getProductList?.list?.length
                          ? getProductList?.list?.length
                          : 0}
                        )
                      </div>
                      {getProductList?.list?.length > 0 ? (
                        <Grid
                          container
                          spacing={2}
                          style={{ margin: mobileMatches ? "auto" : 0 }}
                        >
                          {getProductList?.list?.map((products, index) => (
                            <Grid
                              item
                              xs={13}
                              sm={6}
                              md={4}
                              lg={3}
                              xl={2}
                              key={index}
                            >
                              <Card
                                className="card"
                                style={{
                                  height: "330px",
                                  cursor:
                                    products?.status === "active"
                                      ? "pointer"
                                      : "not-allowed",
                                }}
                                onClick={
                                  products?.status === "active"
                                    ? () => getProductById(products?.id)
                                    : undefined
                                }
                                sx={{
                                  maxWidth: 245,
                                  marginBottom: 1,
                                  marginRight: 1.2,
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  sx={{ height: 140, objectFit: "contain" }}
                                  image={products?.product_img}
                                  title={products?.name}
                                />

                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      maxWidth: "100%", // Ensures the text doesn't break out of the container
                                    }}
                                  >
                                    <b>{products?.name}</b>
                                    {/* <text
                                      style={{
                                        color:
                                          products?.status === "active"
                                            ? "green"
                                            : "red",
                                        marginLeft: 10,
                                      }}
                                    >
                                      <FaCircle size={10} />
                                    </text> */}
                                  </Typography>
                                  {/*  <Tooltip title={`${products?.description}`}> */}
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                      display: "-webkit-box",
                                      WebkitLineClamp: 3,
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      height: "60px",
                                    }}
                                  // onClick={() =>
                                  //   handleOpen(
                                  //     products?.description,
                                  //     products?.name
                                  //   )
                                  // }
                                  >
                                    {products?.description}
                                  </Typography>

                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    style={{
                                      color: "black",
                                      marginTop: "6px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "1000px",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      <b> ₹{products?.total_amount}</b>
                                    </span>
                                    {products?.isDiscount && (
                                      <>
                                        {products?.discounted_price && (
                                          <span
                                            className="ms-3"
                                            style={{
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            ₹{products?.sale_price}
                                          </span>
                                        )}
                                        {products?.discounted_price && (
                                          <span
                                            className="ms-1"
                                            style={{ color: "green" }}
                                          >
                                            <b>
                                              {" "}
                                              {Math.round(
                                                products?.discount_percent
                                              )}
                                              % off
                                            </b>
                                          </span>
                                        )}
                                      </>
                                    )}

                                    {/* <span style={{ color: "gray" }}>/Unit</span> */}
                                  </Typography>
                                  {/*   </Tooltip> */}

                                  <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    key={index}
                                  >
                                    <DialogTitle>{ }-Description</DialogTitle>
                                    <DialogContent>
                                      <Typography>
                                        {/* {selectedDescription} */}
                                      </Typography>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        onClick={handleClose}
                                        style={{ color: "rgb(235, 129, 40)" }}
                                      >
                                        Close
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </CardContent>

                                {products?.net_qty_available >=
                                  products?.minimum_quantity ? (
                                  <CardContent
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginTop: -20,
                                    }}
                                  >
                                    {products?.discounted_price !== 0 &&
                                      products?.discounted_price !== "0.00" &&
                                      products?.discounted_price !== null &&
                                      products?.discounted_price !== undefined &&
                                      new Date() >= new Date(products?.discount_start_at) &&
                                      new Date() <= new Date(products?.discount_end_at) &&
                                      (
                                        <span
                                          style={{
                                            color: "green",
                                            fontSize: "12px",
                                            position: "relative",
                                            bottom: "0.5rem",
                                            fontFamily: "Arial, sans-serif",
                                          }}
                                        >
                                          You might save up to ₹
                                          {products?.discounted_price}
                                        </span>
                                      )}
                                    {/* <Button
                                      size="small"
                                      style={{
                                        color:
                                          products?.status === "inactive"
                                            ? "#FFD68A"
                                            : "rgb(235, 129, 40)",
                                      }}
                                      disabled={
                                        products?.status === "inactive"
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => {
                                        e.stopPropagation();

                                        addToCartt(products?.id);
                                      }}
                                    >
                                      {!addedToCart[products?.id]
                                        ? "ADD TO CART"
                                        : "ADDED TO CART"}
                                    </Button>
                                    <Button
                                      size="small"
                                      style={{
                                        color:
                                          products?.status === "inactive"
                                            ? "#FFD68A"
                                            : "#FF8C00",
                                      }}
                                      disabled={
                                        products?.status === "inactive"
                                          ? true
                                          : false
                                      }
                                      // onClick={(e) => {
                                      //   e.stopPropagation();
                                      //   navigate(`/buynow/${products?.id}`);
                                      // }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        buyNow(products?.id);
                                      }}
                                    >
                                      Buy Now
                                    </Button> */}
                                  </CardContent>
                                ) : (
                                  <Button
                                    size="small"
                                    disabled
                                    style={{
                                      color: "red",
                                      position: "relative",
                                      bottom: "1.3rem",
                                      right: "4rem",
                                      cursor: "not-allowed",
                                      textTransform: "none",
                                      fontSize: "12px ",
                                    }}
                                  >
                                    Out of Stock
                                  </Button>
                                )}
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "50vh",
                          }}
                        >
                          <div
                            style={{
                              color: "rgb(235, 129, 40)",

                              fontSize: 20,
                            }}
                          >
                            No Products Found!
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
