import React, { useState, useEffect, useRef } from "react";
import FormControl from "@mui/material/FormControl";
import { TextField, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Sidebar from "../Sidebar";
import Topbar from "../topbar";
import Api from "../../service/services";
import { IconButton } from "@mui/material";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { useNavigate } from "react-router-dom";
import "../../landingpage.css";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import TrashIcon from "../../Assets/icons/TrashIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  createServiceRequest,
  getAddressLatLong,
  getApplianceDetail,
  getAppliances,
  getBasicCharge,
  reopenServiceRequest,
  updateServiceRequest,
} from "../../redux/actions/serviceRequestAction";
import { basicChargeClear } from "../../redux/slices/basicChargeSlice";
import ToastMessage from "../../plugins/ToastMessage";
import {
  serviceRequestCreationClear,
  serviceRequestUpdateClear,
} from "../../redux/slices/serviceRequestSlice";
import { getQueryParam } from "../../plugins/getQueryParams";
import { BsCurrencyRupee } from "react-icons/bs";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";

const CreateService = () => {
  const [brandList, setBrandList] = useState([]);
  const [capacityList, setCapacityList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [selectedAppliance, setSelectedAppliance] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedWarrantyStatus, setSelectedWarrantyStatus] = useState("");
  const [SelectedWarrantyDate, setSelectedWarrantyDate] = useState("");
  const [description, setDescription] = useState("");
  const [selectedTechVisitDate, setSelectedTechVisitDate] = useState("");
  const [address, setAddress] = useState("");
  const [otherSize, setOtherSize] = useState("");
  const [otherBrand, setOtherBrand] = useState("");
  const [otherModel, setOtherModel] = useState("");
  const [basicCharge, setBasicCharge] = useState("");
  const [commissionCharge, setCommissionCharge] = useState("");
  const [allowAddAddress, setAllowAddAddress] = useState(false);

  //validation
  const [IsApplienceEmpty, setIsApplianceEmpty] = useState(false);
  const [IsBrandEmpty, setIsBrandEmpty] = useState(false);
  const [IsModelEmpty, setIsModelEmpty] = useState(false);
  const [IsSizeEmpty, setIsSizeEmpty] = useState(false);
  const [IsWarrantyEmpty, setIsWarrantyEmpty] = useState(false);
  const [IsTechEmpty, setIsTechEmpty] = useState(false);
  const [IsSlotEmpty, setIsSlotEmpty] = useState(false);
  const [IsStateEmpty, setIsStateEmpty] = useState(false);

  //edit
  const [isEdit, setisEdit] = useState(false);
  const [isReopen, setisReopen] = useState(false);
  const [editDataAppliance, setEditDataAppliance] = useState("");
  const [editDataBrand, setEditDataBrand] = useState("");
  const [editDataModel, setEditDataModel] = useState("");
  const [editDataSize, setEditDataSize] = useState("");
  const [editdDataWarranty, setEditdDataWarranty] = useState("");
  const [editdDataWarrantyDate, setEditdDataWarrantyDate] = useState("");
  const [crossedTimeValues, setCrossedTimeValues] = useState([]);

  const [addressModal, setAddressModal] = useState(false);
  const [newAddressModal, setNewAddressModal] = useState(false);

  const [addName, setAddName] = useState("");
  const [addNameError, setAddNameError] = useState("");
  const [addAddress, setAddAddress] = useState("");
  const [addAddressError, setAddAddressError] = useState("");
  const [addDistrict, setAddDistrict] = useState("");
  const [addDistrictError, setAddDistrictError] = useState("");
  const [addPhoneno, setAddPhoneno] = useState("");
  const [addPhonenoError, setAddPhonenoError] = useState("");
  const [addPostalCode, setAddPostalCode] = useState("");
  const [addPostalCodeError, setAddPostalCodeError] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [districtData, setDistrictData] = useState([]);
  const [deleteAddressId, setDeleteAddressId] = useState("");
  const [deleteAddressModal, setDeleteAddressModal] = useState(false);
  const [addOneImage, setAddOneImage] = useState(true);

  const [addressList, setAddressList] = useState([]);
  const [addressId, setAddressId] = useState("");
  const [addressValue, setAddressValue] = useState("");

  const [brandOthers, setBrandOthers] = useState(false);
  const [modelOthers, setModelOthers] = useState(false);
  const [sizeOthers, setSizeOthers] = useState(false);
  const [otherSizeError, setOtherSizeError] = useState("");

  const [slotTime] = useState([
    { id: 1, slot: "9am - 10am" },
    { id: 2, slot: "10am - 11am" },
    { id: 3, slot: "11am - 12pm" },
    { id: 4, slot: "12pm - 1pm" },
    { id: 5, slot: "1pm - 2pm" },
    { id: 6, slot: "2pm - 3pm" },
    { id: 7, slot: "3pm - 4pm" },
    { id: 8, slot: "4pm - 5pm" },
    { id: 9, slot: "5pm - 6pm" },
  ]);

  const [selectedTechVisitTime, setSelectedTechVisitTime] = useState("");
  const appliance_id = getQueryParam("id");

  const { appliancesData } = useSelector((state) => state.appliancesState);
  const { applianceDetailsData } = useSelector(
    (state) => state.applianceDetailsState
  );
  const { basicChargeData } = useSelector((state) => state.basicChargeState);
  const {
    serviceRequestCreated,
    serviceRequestSuccessMsg,
    error,
    serviceRequestUpdated,
    serviceRequestUpdateSuccessMsg,
  } = useSelector((state) => state.serviceRequestState);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSlotClick = (slot) => {
    setSelectedTechVisitTime(slot);
  };

  const edit_status = getQueryParam("edit");
  const reopen_status = getQueryParam("reopen");
  const sr_id = getQueryParam("sr_id");

  const [selectedImages, setSelectedImages] = useState([]);
  const fileInputRef = useRef(null);

  const handleAppliances = () => {
    dispatch(getAppliances());
  };

  const onImageChange = (event) => {
    const maxSizeKilobytes = 2000; // 2000 KB
    const maxSizeBytes = maxSizeKilobytes * 1024;
    const allowedImageMimetypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxAllowedImages = 2;

    const files = event.target.files;

    const newImages = [];
    let addOneImage = false;
    const invalidSizeFiles = [];
    const invalidFormatFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!allowedImageMimetypes.includes(file.type)) {
        addOneImage = true;
        invalidFormatFiles.push(file);

        Swal.fire({
          text: `File ${file.name} has an invalid format and will be ignored.`,
          confirmButtonText: "OK",
        });
      }

      if (file.size > maxSizeBytes) {
        addOneImage = true;
        invalidSizeFiles.push(file);

        Swal.fire({
          text: `File ${file.name} exceeds the 2MB size limit and will be ignored.`,
          confirmButtonText: "OK",
        });
      }

      if (
        !invalidFormatFiles.includes(file) &&
        !invalidSizeFiles.includes(file)
      ) {
        newImages.push(file);
      }
    }

    if (invalidSizeFiles.length > 0) {
      addOneImage = true;

      Swal.fire({
        text: "One or more selected files are larger than 2 MB. Please select files that are each under 2 MB.",
        confirmButtonText: "OK",
      });
    }

    if (newImages.length > maxAllowedImages) {
      addOneImage = true;

      Swal.fire({
        text: `You have selected ${newImages.length} images, but only ${maxAllowedImages} images (each under 2MB) can be uploaded. Only the first ${maxAllowedImages} images will be processed.`,
        confirmButtonText: "OK",
      });
    }

    setSelectedImages(newImages.slice(0, maxAllowedImages));
    setAddOneImage(addOneImage);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const [removeImages, setRemoveImages] = useState([]);

  const handleApplianceDetails = (e) => {
    setBrandOthers(false);
    // setIsWarrantyEmpty(false)
    setIsBrandEmpty(false);
    setIsModelEmpty(false);
    setIsSizeEmpty(false);
    setSelectedBrand(null);
    setBasicCharge(null);
    // setSelectedWarrantyStatus(null);
    setSelectedSize(null);
    setSelectedModel(null);
    setModelOthers(false);
    setSizeOthers(false);
    const payload = {
      appliance_id: e.target.value,
    };
    dispatch(getApplianceDetail(payload));
  };

  useEffect(() => {
    setBrandList(applianceDetailsData?.brands);
    setModelList(applianceDetailsData?.models);
    setCapacityList(
      applianceDetailsData?.sizes?.size && applianceDetailsData?.sizes?.size
    );
  }, [applianceDetailsData]);
  const isButtonDisabled = !(
    selectedAppliance &&
    selectedWarrantyStatus &&
    (selectedWarrantyStatus === "Active" ? SelectedWarrantyDate : true) &&
    addressId &&
    address &&
    selectedTechVisitDate &&
    selectedTechVisitTime &&
    basicCharge &&
    commissionCharge &&
    ((selectedSize === "capacity-others" && otherSize) || selectedSize) &&
    ((selectedBrand === "brand-others" && otherBrand && otherBrand !== "") ||
      selectedBrand) &&
    ((selectedModel === "model-others" && otherModel && otherModel !== "") ||
      selectedModel)
  );
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsStateEmpty(true);

    const getSelectedModal = () =>
      modelList?.find((val) => val?.id === selectedModel)?.appliance_model;

    // Helper to simplify repetitive ternary checks for emptiness
    const checkEmpty = (value, setState) => setState(!value);

    checkEmpty(selectedAppliance, setIsApplianceEmpty);
    checkEmpty(selectedBrand, setIsBrandEmpty);
    checkEmpty(selectedModel, setIsModelEmpty);
    checkEmpty(selectedSize, setIsSizeEmpty);
    checkEmpty(selectedWarrantyStatus, setIsWarrantyEmpty);
    checkEmpty(selectedTechVisitDate, setIsTechEmpty);
    checkEmpty(selectedTechVisitTime, setIsSlotEmpty);

    const formData = new FormData();
    const payload = {
      appliance_id: selectedAppliance,
      appliance_brand:
        selectedBrand === "brand-others" ? otherBrand : selectedBrand,
      appliance_model:
        selectedModel === "model-others" ? otherModel : getSelectedModal(),
      appliance_size:
        selectedSize === "capacity-others" ? otherSize : selectedSize,
      problem_desc: description,
      warranty_status: selectedWarrantyStatus,
      ...(selectedWarrantyStatus === "Active" && {
        warranty_date: SelectedWarrantyDate,
      }),
      user_address_id: addressId,
      tech_visit_date: selectedTechVisitDate,
      tech_visit_time: selectedTechVisitTime,
      basic_charge: basicCharge,
      commission_charge: commissionCharge,
    };
    const payloadEdit = {
      problem_desc: description,
      tech_visit_date: selectedTechVisitDate,
      tech_visit_time: selectedTechVisitTime,
    };
    const payloadReopen = {
      requested_visit_date: selectedTechVisitDate,
      requested_visit_time: selectedTechVisitTime,
    };
    const payloadString = JSON.stringify(isEdit ? payloadEdit : payload);
    formData.append("fields", payloadString);

    selectedImages.forEach((image) => {
      formData.append("file", image);
    });

    if (isEdit && isReopen) {
      dispatch(reopenServiceRequest(sr_id, payloadReopen));
    } else if (isEdit) {
      dispatch(updateServiceRequest(sr_id, formData, removeImages));
    } else {
      dispatch(createServiceRequest(formData));
    }
  };

  useEffect(() => {
    const currentTime = moment().add(3, "hours");
    const crossedHours = [];
    while (currentTime.format("h A") !== "1 AM") {
      crossedHours.push(currentTime.format("h A"));
      currentTime.subtract(1, "hour");
    }
    crossedHours.reverse();
    setCrossedTimeValues(crossedHours);
  }, []);
  const getCurrentDaySlot = () => {
    const selectedDate = moment(selectedTechVisitDate);
    const isCurrentDate = selectedDate.isSame(moment(), "day");
    const isAfter2PM = moment().hour() >= 14;

    if (isCurrentDate && isAfter2PM) {
      Swal.fire({
        text: "No Slots Available.Please Select a Different Date.",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleAddress = () => {
    setAddressModal(true);
    Api.getUserAddress("").then((res) => {
      setAddressList(res?.data);
    });
  };

  const getLocation = () => {
    setAllowAddAddress(true);
    navigator.geolocation.getCurrentPosition(showPosition, (error) => {
      if (error.code === error.PERMISSION_DENIED) {
        alert(
          "Geolocation is blocked. Please allow access to add your address."
        );
        setAllowAddAddress(false);
      } else if (error.code === error.POSITION_UNAVAILABLE) {
        alert("Geolocation is not supported by this browser.");
        setAllowAddAddress(false);
      } else {
        setAllowAddAddress(true);
      }
    });
  };

  const showPosition = (position) => {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  };

  const validateForm = () => {
    let isValid = true;

    if (!addName) {
      setAddNameError("Please enter the Name of the Contact Person.");
      isValid = false;
    } else {
      setAddNameError("");
    }

    if (!addPhoneno) {
      setAddPhonenoError(
        "Please enter the Mobile Number of the Contact Person."
      );
      isValid = false;
    } else if (addPhoneno.length !== 10) {
      setAddPhonenoError("Please enter a valid mobile number.");
      isValid = false;
    } else {
      setAddPhonenoError("");
    }

    if (!addAddress) {
      setAddAddressError("Please enter the Door No., Street, City/Town.");
      isValid = false;
    } else {
      setAddAddressError("");
    }

    if (!addDistrict) {
      setAddDistrictError("Please select a District");
      isValid = false;
    } else {
      setAddDistrictError("");
    }

    if (!addPostalCode) {
      setAddPostalCodeError("Please enter the Postal Code.");
      isValid = false;
    } else {
      setAddPostalCodeError("");
    }

    return isValid;
  };

  const handleAddAddress = () => {
    const isValid = validateForm();
    const payload = {
      latitude,
      longitude,
      name: addName,
      postalcode: addPostalCode,
      address: addAddress,
      mobile_no: addPhoneno,
      district_id: addDistrict,
    };
    if (isValid) {
      Api.adduserAddress(payload).then((res) => {
        setAddName("");
        setAddAddress("");
        setAddPhoneno("");
        setAddDistrict("");
        setAddPostalCode("");
        setAddAddressError("");
        setAddNameError("");
        setAddAddressError("");
        setAddPhonenoError("");
        setAddDistrictError("");
        setAddPostalCodeError("");
        Api.getUserAddress("").then((res) => {
          setNewAddressModal(false);
          setAddressModal(true);
          setAddressList(res?.data);
        });
      });
    }
  };

  const handleDeleteAddress = (id) => {
    setDeleteAddressId("");
    setDeleteAddressModal(false);
    setAddressModal(true);
    setAddress("");
    Api.deleteuserAddress(id).then((res) => {
      Api.getUserAddress("").then((res) => {
        setAddressList(res?.data);
      });
    });
  };

  const handleConfirmAddress = () => {
    setAddressModal(false);
    setAddress(addressValue);
  };

  useEffect(() => {
    dispatch(basicChargeClear());
    // eslint-disable-next-line
  }, []);

  const handleBasicCharge = (e, src) => {
    const payload = {
      appliance_id: selectedAppliance,
      appliance_model_id: selectedModel,
      appliance_size: src ? e : e?.target?.value,
    };
    if (e) {
      dispatch(getBasicCharge(payload));
    } else {
      setBasicCharge("");
    }
  };

  useEffect(() => {
    setBasicCharge(basicChargeData?.basic_charge);
    setCommissionCharge(basicChargeData?.commission_charge);
    dispatch(getAddressLatLong());
  }, [basicChargeData, dispatch]);

  useEffect(() => {
    if (serviceRequestCreated) {
      setTimeout(() => {
        navigate("/serviceList");
        dispatch(serviceRequestCreationClear());
      }, 2000);
    } else if (serviceRequestUpdated) {
      setTimeout(() => {
        navigate("/serviceList");
        dispatch(serviceRequestUpdateClear());
      }, 2000);
    }
    // eslint-disable-next-line
  }, [serviceRequestCreated, serviceRequestUpdated]);
  const [removeUrl, setRemoveUrl] = useState([]);

  useEffect(() => {
    if (edit_status) {
      setisEdit(true);
    }
    if (reopen_status) {
      setisReopen(true);
    }
    Api.getServiceRequest(sr_id).then((res) => {
      const fileNames = res?.attachments.map((url) => {
        const match = url.match(/\/([^/?]+)\?/);
        return match ? match[1] : null;
      });
      if (res) {
        setEditDataAppliance(res.appliance_name);
        setEditDataBrand(res.appliance_brand);
        setEditDataModel(res.appliance_model);
        setEditDataSize(res.appliance_size);
        setEditdDataWarranty(res.warranty_status);
        setEditdDataWarrantyDate(res.warranty_date);
        setSelectedImages(fileNames);
        setRemoveUrl(res?.attachments);
        setDescription(res.problem_desc);
        setAddress(res?.serviceLocation);
        if (res?.sr_work_history?.length > 0) {
          setSelectedTechVisitDate(
            res?.sr_work_history[0]?.requested_visit_date
          );
          if (!reopen_status) {
            setSelectedTechVisitTime(
              res?.sr_work_history[0]?.requested_visit_time
            );
          }
        }
      }
    });
  }, [edit_status, reopen_status, sr_id]);

  useEffect(() => {
    if (otherSizeError) {
      setOtherSizeError("");
    }
  }, [otherSizeError]);

  useEffect(() => {
    if (appliance_id) {
      setSelectedAppliance(appliance_id);
      const payload = {
        appliance_id,
      };
      dispatch(getApplianceDetail(payload));
    } else {
      setSelectedAppliance("");
    }
    // eslint-disable-next-line
  }, [appliance_id]);

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="sticky-top">
        <Topbar />
      </div>
      <div className="row">
        <div className="col-lg-2 col-12">
          <Sidebar />
        </div>
        <div
          className="Main_menu create_service_request"
          style={{ marginTop: "80px" }}
        >
          <div className="container-fluid portion p-lg-2 p-3  mt-3 m-lg-0">
            <form onSubmit={handleSubmit}>
              <div style={{ fontSize: "21px", fontWeight: "600" }}>
                <i
                  style={{ cursor: "pointer" }}
                  className="bi bi-chevron-left me-1"
                  onClick={() => navigate(-1)}
                ></i>
                <text>
                  {isEdit && isReopen ? "Reopen" : isEdit ? "Edit" : "Create"}{" "}
                  Service Request
                </text>
              </div>
              <div className="row ms-lg-3 mt-lg-4">
                <div className="col-lg col-12  mt-lg-0 mt-3">
                  <text style={{ fontSize: "15px", fontWeight: "400" }}>
                    Device Details*
                  </text>
                  {isEdit ? (
                    <TextField
                      className="w-100 mt-lg-4 mt-2"
                      id="outlined-select-currency"
                      label="Select Appliances"
                      value={editDataAppliance}
                      disabled
                    />
                  ) : (
                    <Form.Select
                      className={`w-100 mt-lg-4 mt-2 custom-select ${
                        IsApplienceEmpty ? "is-invalid" : ""
                      }`}
                      size="lg"
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      label="Select Appliances"
                      placeholder="Select Appliances"
                      value={selectedAppliance}
                      onChange={(e) => {
                        handleApplianceDetails(e);
                        setSelectedAppliance(e.target.value);
                        setIsApplianceEmpty(e.target.value === "");
                      }}
                      onClick={handleAppliances}
                      style={{ cursor: "pointer" }}
                    >
                      <option value="">Select an Appliance</option>
                      {appliancesData?.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.appliance_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  )}
                  {!isEdit && IsApplienceEmpty && (
                    <div
                      className="text-danger mt-1 ms-2"
                      style={{ fontSize: "12px" }}
                    >
                      Please select the Appliance
                    </div>
                  )}
                  {isEdit ? (
                    <TextField
                      className="w-100 mt-lg-4 mt-2"
                      id="outlined-select-currency"
                      label="Selected Brand"
                      value={editDataBrand}
                      disabled
                    />
                  ) : brandOthers ? (
                    <TextField
                      className="w-100 mt-lg-4 mt-3"
                      label="Enter Other Brand"
                      placeholder="enter brand name"
                      value={otherBrand}
                      autoFocus
                      onChange={(e) => setOtherBrand(e.target.value)}
                    />
                  ) : (
                    <Form.Select
                      className={`w-100 mt-lg-4 mt-2 ${
                        IsBrandEmpty ? "is-invalid" : ""
                      }`}
                      size="lg"
                      placeholder="Select Brand"
                      error={IsBrandEmpty}
                      helperText={
                        IsBrandEmpty ? "Please select the Brand" : null
                      }
                      value={selectedBrand}
                      style={{ cursor: "pointer" }}
                      onChange={(e) => {
                        setSelectedBrand(e.target.value);
                        setIsBrandEmpty(e.target.value === "");
                        setBrandOthers(
                          e.target.value === "brand-others" ? true : false
                        );
                      }}
                    >
                      <option value="">Please select the Brand</option>
                      {brandList?.length > 0
                        ? brandList.map((brand, index) => (
                            <option key={brand.id} value={brand.brand_name}>
                              {brand.brand_name}
                            </option>
                          ))
                        : null}
                      <option value="brand-others">Others</option>
                    </Form.Select>
                  )}
                  {!isEdit && IsBrandEmpty && (
                    <div
                      className="text-danger mt-1 ms-2"
                      style={{ fontSize: "12px" }}
                    >
                      Please select the Brand
                    </div>
                  )}
                  {isEdit ? (
                    <TextField
                      className="w-100 mt-lg-4 mt-2"
                      id="outlined-select-currency"
                      label="Selected Model"
                      value={editDataModel}
                      disabled
                    />
                  ) : modelOthers ? (
                    <TextField
                      className="w-100 mt-lg-4 mt-3"
                      label="Enter Other Model"
                      placeholder="enter Model name"
                      value={otherModel}
                      autoFocus
                      onChange={(e) => setOtherModel(e.target.value)}
                    />
                  ) : (
                    <Form.Select
                      className={`w-100 mt-lg-4 mt-2 ${
                        IsModelEmpty ? "is-invalid" : ""
                      }`}
                      size="lg"
                      placeholder="Select Appliances"
                      error={IsModelEmpty}
                      helperText={
                        IsModelEmpty ? "Please select the Model" : null
                      }
                      value={selectedModel}
                      style={{ cursor: "pointer" }}
                      onChange={(e) => {
                        setSelectedModel(e.target.value);
                        setIsModelEmpty(e.target.value === "");
                        setModelOthers(
                          e.target.value === "model-others" ? true : false
                        );
                        handleBasicCharge(
                          sizeOthers ? otherSize : selectedSize,
                          "model"
                        );
                        if (
                          selectedSize === "capacity-others" &&
                          otherSize === ""
                        ) {
                          setSelectedSize("");
                        }
                        if (otherSize === "") {
                          setSizeOthers(false);
                        }
                      }}
                    >
                      <option value="">Please select the Model</option>
                      {modelList?.length > 0
                        ? modelList.map((model, index) => (
                            <option key={model.id} value={model.id}>
                              {model.appliance_model}
                            </option>
                          ))
                        : null}
                      <option value="model-others">Others</option>
                    </Form.Select>
                  )}
                  {!isEdit && IsModelEmpty && (
                    <div
                      className="text-danger mt-1 ms-2"
                      style={{ fontSize: "12px" }}
                    >
                      Please select the Model
                    </div>
                  )}
                  <div className="row">
                    <div className="col">
                      <div>
                        {isEdit ? (
                          <TextField
                            className="w-100 mt-lg-4 mt-2"
                            id="outlined-select-currency"
                            label="Selected Size"
                            value={editDataSize}
                            disabled
                          />
                        ) : sizeOthers ? (
                          <TextField
                            id="outlined-select-currency"
                            className="w-100 mt-lg-4 mt-3"
                            onKeyDown={(e) => {
                              if (e.key === "e" || e.key === "E") {
                                e.preventDefault();
                              }
                              if (e.key === "+" || e.key === "-") {
                                e.preventDefault();
                              }
                            }}
                            label="Enter the size"
                            type="number"
                            autoFocus
                            placeholder="Enter the size"
                            value={otherSize}
                            onChange={(e) => {
                              const newSize = e.target.value;
                              setOtherSize(newSize);
                            }}
                            onBlur={(e) => {
                              handleBasicCharge(e);
                            }}
                          />
                        ) : (
                          <Form.Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className={`w-100 mt-lg-4 mt-2 ${
                              IsSizeEmpty ? "is-invalid" : ""
                            }`}
                            size="lg"
                            placeholder="Please select the Size"
                            error={IsSizeEmpty}
                            helperText={
                              IsSizeEmpty ? "Please select the Variant" : null
                            }
                            value={selectedSize}
                            style={{ cursor: "pointer" }}
                            onChange={(e) => {
                              setSelectedSize(e.target.value);
                              setIsSizeEmpty(e.target.value === "");
                              setSizeOthers(
                                e.target.value === "capacity-others"
                                  ? true
                                  : false
                              );
                              e.target.value !== "capacity-others" &&
                                handleBasicCharge(e);
                            }}
                          >
                            <option value="">Please select the Size</option>
                            {selectedModel && capacityList?.length > 0
                              ? capacityList?.map((capacity, index) => (
                                  <option key={capacity} value={capacity}>
                                    {capacity}{" "}
                                    {
                                      appliancesData.find(
                                        (val) => val?.id === selectedAppliance
                                      )?.unit_of_size
                                    }
                                  </option>
                                ))
                              : null}
                            {selectedModel && (
                              <option value="capacity-others">Others</option>
                            )}
                          </Form.Select>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-lg-4 mt-2">
                    <text style={{ fontSize: "15px", fontWeight: "400" }}>
                      Warranty Details*
                    </text>
                  </div>

                  {isEdit ? (
                    <TextField
                      className="w-100 mt-lg-4 mt-2"
                      id="outlined-select-currency"
                      label="Selected Warranty"
                      value={editdDataWarranty}
                      disabled
                    />
                  ) : (
                    <Form.Select
                      className={`w-100 mt-lg-4 mt-2 ${
                        IsWarrantyEmpty ? "is-invalid" : ""
                      }`}
                      size="lg"
                      placeholder="Select Appliances"
                      error={IsWarrantyEmpty}
                      style={{ cursor: "pointer" }}
                      helperText={
                        IsWarrantyEmpty ? "Please select the Warranty" : null
                      }
                      value={selectedWarrantyStatus}
                      onChange={(e) => {
                        setSelectedWarrantyStatus(e.target.value);
                        setIsWarrantyEmpty(e.target.value === "");
                      }}
                    >
                      <option value="">Please select the Warranty</option>
                      <option value="Active">Active</option>
                      <option value="InActive">InActive</option>
                    </Form.Select>
                  )}
                  {!isEdit && IsWarrantyEmpty && (
                    <div
                      className="text-danger mt-1 ms-2"
                      style={{ fontSize: "12px" }}
                    >
                      Please select the Warranty
                    </div>
                  )}
                  <FormControl className="mt-lg-4 mt-3 " fullWidth>
                    {isEdit && editdDataWarrantyDate ? (
                      <TextField
                        className="w-100 mt-lg-4 mt-2"
                        id="outlined-select-currency"
                        label="Selected Warranty Date"
                        value={editdDataWarrantyDate}
                        disabled
                      />
                    ) : selectedWarrantyStatus === "InActive" ||
                      selectedWarrantyStatus === "" ||
                      editdDataWarrantyDate === null ? null : (
                      <TextField
                        onChange={(e) =>
                          setSelectedWarrantyDate(e.target.value)
                        }
                        disabled={selectedWarrantyStatus === ""}
                        value={SelectedWarrantyDate}
                        type="date"
                        id="outlined-basic"
                        label="Warranty Until"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                          role: "button",
                        }}
                      />
                    )}
                  </FormControl>
                </div>
                <div className="col-lg col-12 mt-lg-0 mt-2">
                  <text style={{ fontSize: "15px", fontWeight: "400" }}>
                    Repair Details
                  </text>
                  <TextField
                    value={description}
                    className="w-100 mt-lg-4 mt-3"
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    label="Problem Description"
                    placeholder="Describe the problem in your electronic appliance"
                    onChange={(e) => {
                      if (e.target.value.length <= 200) {
                        setDescription(e.target.value);
                      } else {
                        setDescription(e.target.value.slice(0, 200));
                      }
                    }}
                    disabled={isReopen ? true : false}
                  />
                  <div style={{ fontSize: "12px", height: "0" }}>
                    {description?.length + "/" + 200}
                  </div>
                  <fieldset
                    className="border rounded-3 p-2 mt-lg-4 mt-3"
                    style={{
                      height: "80px",
                    }}
                  >
                    <legend
                      className="float-none w-auto px-1 header optionss"
                      style={{ fontSize: "12px", color: "#6A707C" }}
                    >
                      Attachment{" "}
                      <span style={{ fontSize: 9 }}>
                        (Only two images can be attached)
                      </span>
                    </legend>
                    <div
                      className="text-start"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="text-start">
                        {selectedImages.map((image, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center"
                          >
                            <Tooltip title={image?.name || image}>
                              {" "}
                              <p
                                className="m-0"
                                style={{ fontSize: "11px", cursor: "pointer" }}
                              >
                                {image?.name
                                  ? image?.name.substring(0, 25)
                                  : image?.name || image}
                                {image?.name ? "..." : ""}
                              </p>
                            </Tooltip>
                          </div>
                        ))}
                      </div>
                      <div className=" text-end">
                        <IconButton
                          component="label"
                          className=""
                          disabled={isReopen}
                          style={{
                            filter: isReopen ? "grayscale(100%)" : "none",
                          }}
                        >
                          <AttachFileOutlinedIcon
                            className="rounded-circle"
                            onClick={() => setRemoveImages(removeUrl)}
                            style={{
                              color: "rgb(235, 129, 40)",
                              rotate: "49deg",
                              fontSize: "22px",
                            }}
                          />
                          <input
                            type="file"
                            ref={fileInputRef}
                            accept="image/*"
                            style={{ display: "none", color: "pink" }}
                            onChange={onImageChange}
                            multiple
                            disabled={isReopen || addOneImage.length >= 2}
                          />
                        </IconButton>
                      </div>
                    </div>
                  </fieldset>
                  <div className="text-end">
                    <p
                      className="m-0"
                      style={{ fontSize: "11px", color: "#6A707C" }}
                    >
                      *Max 2 MB, JPG/JPEG/PNG only
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-12  mt-lg-0 mt-2">
                  <div className="me-2">
                    <div>
                      <text style={{ fontSize: "15px", fontWeight: "400" }}>
                        Confirm Technician Visit*
                      </text>

                      <TextField
                        className="w-100 mt-lg-4 mt-2"
                        type="date"
                        id="outlined-basic"
                        onChange={(e) => {
                          setSelectedTechVisitDate(e.target.value);
                          setIsTechEmpty(false);
                        }}
                        variant="outlined"
                        error={IsTechEmpty ? true : false}
                        helperText={
                          IsTechEmpty
                            ? "please select technician slot date"
                            : ""
                        }
                        value={selectedTechVisitDate}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                          role: "button", // Add this line to make the input non-editable but still allow date selection
                        }}
                      />
                    </div>
                    {selectedTechVisitDate && getCurrentDaySlot()}
                    {selectedTechVisitDate && (
                      <div className="row mt-lg-2 mt-2 ">
                        {slotTime.map((slot) => {
                          const noShowSlots = () => {
                            if (!selectedTechVisitDate) {
                              return false;
                            } else if (
                              crossedTimeValues.find(
                                (time) =>
                                  time?.split(" ").join("").toLowerCase() ===
                                  slot.slot.split(" - ")[0]
                              ) &&
                              selectedTechVisitDate ===
                                moment().format("YYYY-MM-DD")
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          };
                          return (
                            <div
                              key={slot.slot}
                              className="col-lg-4 mt-3 col-6 "
                              style={{
                                display: noShowSlots() && "none",
                                cursor: selectedTechVisitDate
                                  ? ""
                                  : "not-allowed",
                              }}
                            >
                              <button
                                disabled={selectedTechVisitDate ? false : true}
                                type="button"
                                className={`btn btn-white w-100 ${
                                  selectedTechVisitTime === slot.slot
                                    ? "selected"
                                    : ""
                                }`}
                                style={{
                                  backgroundColor:
                                    selectedTechVisitTime === slot.slot
                                      ? "rgb(235, 129, 40)"
                                      : "",
                                  color:
                                    selectedTechVisitTime === slot.slot
                                      ? "white"
                                      : "black",
                                  borderColor: "#E8ECF4",
                                  height: "45px",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                }}
                                onClick={() => {
                                  handleSlotClick(slot.slot);
                                  setIsSlotEmpty(false);
                                }}
                              >
                                {slot.slot}
                              </button>
                            </div>
                          );
                        })}

                        {IsSlotEmpty ? (
                          <p
                            className="m-0"
                            style={{ fontSize: "12px", color: "red" }}
                          >
                            Please select slot time
                          </p>
                        ) : null}
                      </div>
                    )}

                    <div className="row">
                      <div className="col">
                        <TextField
                          className=" mt-lg-4 mt-3 w-100 cursor-pointer"
                          id="outlined-select-currency"
                          label="Select Address*"
                          multiline={4}
                          value={
                            address
                              ? `
${address?.name},
${address?.address},
${address?.city_name}, ${address?.state_name},
${address?.postalcode}
${address?.mobile_no ? `Ph - ${address?.mobile_no}` : ""}
                            `
                              : ""
                          }
                          onClick={() => !isEdit && handleAddress()}
                          InputProps={{ readOnly: true }}
                          disabled={isEdit ? true : false}
                        ></TextField>
                      </div>
                    </div>
                    <div className="row">
                      {basicCharge && (
                        <div className="col d-flex align-items-center mt-3 gap-4">
                          <div>Visiting Charge :</div>
                          <div
                            className="d-flex align-items-center mt-1"
                            style={{
                              fontWeight: "600",
                              color: "rgb(235, 129, 40)",
                            }}
                          >
                            <BsCurrencyRupee className="d-flex align-items-center" />
                            {basicCharge && basicCharge}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border border-bottom mt-3"></div>
              <div className="row mt-lg-4 mt-3">
                <div className="col-lg-6 "></div>
                <div className="col-lg-6 col-12 text-end">
                  <ul className="p-0" style={{ display: "inline-flex" }}>
                    <li style={{ listStyle: "none" }}></li>
                    <li className="me-2" style={{ listStyle: "none" }}>
                      <Button
                        type="button"
                        variant="outlined"
                        style={{
                          height: "45px",
                          width: "120px",
                          color: "rgb(235, 129, 40)",
                          textTransform: "none",
                          border: "1px solid rgb(235, 129, 40)",
                        }}
                        onClick={() => navigate("/serviceList")}
                      >
                        Cancel
                      </Button>
                    </li>
                    {!isEdit && (
                      <li
                        className="me-2"
                        style={{
                          cursor:
                            isButtonDisabled || IsStateEmpty
                              ? "not-allowed"
                              : "pointer",
                          listStyle: "none",
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          style={{
                            height: "45px",
                            color: "white",
                            backgroundColor:
                              isButtonDisabled || IsStateEmpty
                                ? "#FFC55C"
                                : "#EB8128",
                            textTransform: "none",
                          }}
                          disabled={isButtonDisabled || IsStateEmpty}
                        >
                          Submit Request
                        </Button>
                      </li>
                    )}
                    {isEdit && (
                      <li
                        className="me-2"
                        style={{ cursor: "pointer", listStyle: "none" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          style={{
                            cursor: "pointer",
                            height: "45px",
                            color: "white",
                            backgroundColor: "#EB8128",
                            textTransform: "none",
                          }}
                        >
                          {isEdit && isReopen ? "Reopen" : "Update"} Request
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={addressModal}
        size="md"
        onHide={() => setAddressModal(false)}
        centered
      >
        <div className="p-4">
          {addressList?.length > 0 && <div>Select Address</div>}
          <div className="p-2 address-scroll">
            {addressList?.length > 0 ? (
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={addressId}
                onChange={(e) => setAddressId(e.target.value)}
              >
                {addressList?.map((address) => {
                  return (
                    <div className="mt-2">
                      <div className="d-flex align-items-center justify-content-space-between">
                        <div className="d-flex align-items-center">
                          <div>
                            <FormControlLabel
                              value={address.id.toString()}
                              control={<Radio />}
                              onClick={() => setAddressValue(address)}
                            />
                          </div>
                          <div
                            style={{
                              color:
                                addressId === address.id.toString() &&
                                "rgb(235, 129, 40)",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {address.name}
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              setDeleteAddressId(address.id);
                              setDeleteAddressModal(true);
                              setAddressModal(false);
                            }}
                          >
                            <TrashIcon />
                          </div>
                        </div>
                      </div>
                      <div className="pe-4 ps-4">
                        <div className="mt-0" style={{ fontSize: "12px" }}>
                          <span>{address.address},</span>
                        </div>
                        <div className="mt-0" style={{ fontSize: "12px" }}>
                          <span>{address.city_name},</span>
                          <span> </span>
                          <span>{address.state_name},</span>
                        </div>
                        <div style={{ fontSize: "12px" }}>
                          <span>{address.postalcode}</span>
                        </div>
                        <div style={{ fontSize: "12px", marginTop: "5px" }}>
                          <span>
                            {address.mobile_no && "+91"} {address.mobile_no}
                          </span>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </RadioGroup>
            ) : (
              <div className="mt-5 mb-5">
                <div style={{ textAlign: "center" }}>No Address found,</div>
                <div style={{ textAlign: "center" }}>
                  please add an address to continue
                </div>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-center gap-3">
            <button
              onClick={() => {
                setNewAddressModal(true);
                setAddressModal(false);
                Api.getCityByStateId(23).then((res) => {
                  setDistrictData(res.data);
                });
                getLocation();
              }}
              className="add-new-address-btn"
            >
              Add New Address
            </button>
            <button
              className="confirm-address-btn"
              style={{
                backgroundColor:
                  addressList?.length === 0 || !addressId
                    ? "#F7CD8B"
                    : "#EB8128",
              }}
              onClick={() => handleConfirmAddress()}
              disabled={addressList?.length === 0 || !addressId}
            >
              Confirm Address
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        show={newAddressModal}
        size="md"
        onHide={() => {
          setAddName("");
          setAddAddress("");
          setAddPhoneno("");
          setAddDistrict("");
          setAddPostalCode("");
          setAddAddressError("");
          setAddNameError("");
          setAddAddressError("");
          setAddPhonenoError("");
          setAddDistrictError("");
          setAddPostalCodeError("");
          setNewAddressModal(false);
        }}
        centered
      >
        <div className="p-3">
          <div
            className="pe-3 ps-3"
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Add Address
          </div>
          <div className="mt-4">
            <TextField
              label="Name of the Contact Person"
              className="w-100"
              onChange={(e) => setAddName(e.target.value)}
              value={addName}
              helperText={addNameError}
              error={!!addNameError}
              onKeyUp={() => {
                if (!addName) {
                  setAddNameError(
                    "Please enter the Name of the Contact Person."
                  );
                } else {
                  setAddNameError("");
                }
              }}
            />
          </div>
          <div className="mt-2">
            <TextField
              value={addAddress}
              label="Door No., Street, City/Town"
              className="w-100"
              onChange={(e) => setAddAddress(e.target.value)}
              helperText={addAddressError}
              error={!!addAddressError}
              onKeyUp={() => {
                if (!addAddress) {
                  setAddAddressError(
                    "Please enter the Door No., Street, City/Town."
                  );
                } else {
                  setAddAddressError("");
                }
              }}
            />
          </div>
          <div className="mt-2">
            <TextField
              fullWidth
              label="Select your District"
              select
              value={addDistrict}
              helperText={addDistrictError}
              error={!!addDistrictError}
              onKeyUp={() => {
                if (!addDistrict) {
                  setAddDistrictError("Please select a District.");
                } else {
                  setAddDistrictError("");
                }
              }}
              onChange={(e) => {
                setAddDistrictError("");
                setAddDistrict(e.target.value);
              }}
            >
              {districtData?.length > 0 ? (
                districtData.map((district, index) => (
                  <MenuItem key={district.city_id} value={district.city_id}>
                    {district.city_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value=""></MenuItem>
              )}
            </TextField>
          </div>
          <div className="mt-2">
            <TextField
              id="mobile-number"
              value={addPhoneno}
              label="Mobile Number of the Contact Person"
              className="w-100"
              onChange={(e) => {
                const value = e.target.value;
                const numericValue = value.replace(/\D/g, "");
                const formattedValue = numericValue.slice(0, 10);
                setAddPhoneno(formattedValue);
              }}
              type="number"
              helperText={addPhonenoError}
              error={!!addPhonenoError}
              onKeyUp={() => {
                if (!addPhoneno) {
                  setAddPhonenoError(
                    "Please enter the Mobile Number of the Contact Person."
                  );
                } else if (addPhoneno.length !== 10) {
                  setAddPhonenoError("Please enter valid Mobile Number");
                } else {
                  setAddPhonenoError("");
                }
              }}
            />
          </div>
          <div className="mt-3">
            <TextField
              value={addPostalCode}
              label="Postal Code"
              className="w-100"
              onChange={(e) => {
                const postalCode = e.target.value;
                if (postalCode.length <= 6) setAddPostalCode(postalCode);
              }}
              helperText={addPostalCodeError}
              error={!!addPostalCodeError}
              onKeyUp={() => {
                if (!addPostalCode) {
                  setAddPostalCodeError("Please enter the Postal Code.");
                } else {
                  setAddPostalCodeError("");
                }
              }}
            />
          </div>
          {!allowAddAddress && (
            <div
              className="mt-1 mb-1"
              style={{ fontSize: "12px", color: "#f00", textAlign: "justify" }}
            >
              *Geolocation is blocked. Please grant permission to access your
              location.
            </div>
          )}
          <div className="mt-3 d-flex align-items-center justify-content-center gap-3">
            <button
              onClick={() => {
                setNewAddressModal(false);
                setAddressModal(true);
                setAddName("");
                setAddAddress("");
                setAddPhoneno("");
                setAddDistrict("");
                setAddPostalCode("");
                setAddAddressError("");
                setAddNameError("");
                setAddAddressError("");
                setAddPhonenoError("");
                setAddDistrictError("");
                setAddPostalCodeError("");
              }}
              className="add-new-address-btn"
            >
              Cancel
            </button>
            <button
              onClick={() => handleAddAddress()}
              className="confirm-address-btn"
              style={{
                backgroundColor: "#EB8128",
              }}
            >
              Add Address
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        show={deleteAddressModal}
        size="md"
        onHide={() => {
          setDeleteAddressId("");
          setDeleteAddressModal(false);
        }}
        centered
      >
        <div className="p-3">
          <div
            className="pe-3 ps-3"
            style={{
              fontSize: "18px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Delete Address
          </div>
          <div className="mt-4" style={{ textAlign: "center" }}>
            Are you sure want to delete this address?
          </div>
          <div className="mt-4 d-flex align-items-center justify-content-center gap-3">
            <button
              onClick={() => {
                handleDeleteAddress(deleteAddressId);
              }}
              className="delete-address-yes-btn"
              style={{
                height: "40px",
                width: "100px",
                backgroundColor: "#EB8128",
                border: "none",
                outline: "none",
                color: "#fff",
              }}
            >
              Yes
            </button>
            <button
              onClick={() => {
                setDeleteAddressModal(false);
                setDeleteAddressId("");
                setAddressModal(true);
              }}
              className="delete-address-no-btn"
              style={{
                height: "40px",
                width: "100px",
                backgroundColor: "transparent",
                border: "2px solid #EB8128",
                outline: "none",
                color: "#000",
              }}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
      <ToastMessage
        message={
          isEdit ? serviceRequestUpdateSuccessMsg : serviceRequestSuccessMsg
        }
        status={"success"}
        clearFunc={
          isEdit ? serviceRequestUpdateClear : serviceRequestCreationClear
        }
        hideProgressBar={true}
      />
      <ToastMessage
        message={error}
        status={"error"}
        clearFunc={
          isEdit ? serviceRequestUpdateClear : serviceRequestCreationClear
        }
        hideProgressBar={true}
      />
      {otherSizeError.length > 0 && (
        <ToastMessage
          message={otherSizeError}
          status={"error"}
          hideProgressBar={true}
        />
      )}
    </div>
  );
};
export default CreateService;
