import React, { useEffect, useState } from "react";
import { InputBase, Paper, Stack, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Api from "../../../service/services";

const AllCategory = () => {
  const [appliancedata, setApplianceData] = useState([]);

  const GetAllApplianceData = () => {
    Api.getSelectableAppliances().then((res) => {
      setApplianceData(res);
    });
  };
  useEffect(() => {
    GetAllApplianceData();
  }, []);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (index) => {
    setSelectedCard(index === selectedCard ? null : index);
    if (index) {
      localStorage.removeItem("items");
      localStorage.setItem("items", JSON.stringify(index));
    }
  };

  const mobileMatches = useMediaQuery("(min-width:600px)");

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <ul
            className="p-0 mt-lg-3 ms-lg-2"
            style={{ display: "inline-flex" }}
          >
            <li style={{ listStyle: "none" }}>
              <Paper
                elevation={0}
                className="p-lg-3 border rounded-0 custom-border-color"
                component="form"
                sx={{ display: "flex", alignItems: "center" }}
                style={{
                  width: mobileMatches ? "15rem" : "10rem",
                  height: "42px",
                }}
              >
                <IconButton
                  type="button"
                  sx={{ p: "1rem" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  style={{
                    fontFamily: "Arial",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "14px",
                  }}
                  placeholder="Search by Name / ID"
                  inputProps={{ "aria-label": "search google maps" }}
                />
              </Paper>
            </li>
            <li
              className="ms-lg-2"
              style={{
                listStyle: "none",
                paddingLeft: mobileMatches ? "" : "1rem",
              }}
            >
              <IconButton
                style={{ width: "42px", height: "42px", borderRadius: "100px" }}
                className="border rounded-0 custom-border-color"
                aria-label="delete"
              >
                <FilterAltOutlinedIcon style={{ color: "rgb(235, 129, 40)" }} />
              </IconButton>
            </li>
          </ul>
          <div className="custom-scrollbar">
            <div>
              {appliancedata &&
                appliancedata?.appliances?.map((appliance, index) => {
                  return (
                    <Stack direction="row" spacing={2}>
                      <div
                        className={`card w-100 h-25 ${
                          selectedCard === appliance.id ? "selected" : ""
                        }`}
                        style={{
                          borderColor:
                            selectedCard === appliance.id
                              ? "#EB8128"
                              : "#6A707C",
                        }}
                        onClick={() => handleCardClick(appliance.id)}
                      >
                        <img
                          className="w-75 h-50 ms-lg-2 mt-lg-4"
                          src={appliance.appliance_image}
                          alt={appliance.appliance_name}
                        />
                        <p
                          className="mt-lg-3 ms-lg-2"
                          style={{
                            fontSize: "14px",
                            color: selectedCard === 1 ? "#EB8128" : "#6A707C",
                          }}
                        >
                          {appliance.appliance_name}{" "}
                          <i className="bi bi-chevron-right"></i>
                        </p>
                      </div>
                    </Stack>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AllCategory;
