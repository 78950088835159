import React, { useState } from "react";
import Logo from "../../Assets/Cust/reset-pass.svg";
import { useLocation, useNavigate } from "react-router";
import Api from "../../service/services";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Footer from "../Footer";
import S3NewLogo from "../../Assets/s3_new_logo/S3NewLogo";

const Newpassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const location = useLocation();
  const [passwordError, setPasswordError] = useState("");
  const [confirmpasswordError, setConfirmpasswordError] = useState("");
  const passwordPattern = /^(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [hasInteraction, setHasInteraction] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (!e.target.value) {
      setPasswordError("Please enter your new password.");
    } else if (!passwordPattern.test(e.target.value)) {
      setPasswordError(
        "Password must contain at least 8 characters, one digit, and one special character."
      );
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmpasswordChange = (e) => {
    setConfirmpassword(e.target.value);
    setHasInteraction(true);
    if (!e.target.value) {
      setConfirmpasswordError("Please confirm your new password.");
    } else if (e.target.value !== password) {
      setConfirmpasswordError("Passwords do not match.");
    } else {
      setConfirmpasswordError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!passwordError && !confirmpasswordError) {
      const data = {
        username: location?.state?.data,
        password: password,
        confirmpassword: confirmpassword,
      };

      Api.changepassword(data).then((response) => {
        if (response.success === true) {
          Swal.fire({
            text: response.message,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/passworddone", {
                state: {
                  data: location.state.data,
                },
              });
            }
          });
        } else {
          Swal.fire({
            text: response.message,
            confirmButtonText: "OK",
          });
        }
      });
    }
  };

  return (
    <div className="">
      <div className="container-fluid">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
          className="res-576px-d-unset mt--40px"
        >
          <div
            className="p-0 res-576px-d-none"
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              width: "43.5%",
              padding: "60px",
              justifyContent: "center",
            }}
          >
            <div
              className="logo_container"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
                marginTop: "80px",
              }}
            >
              <div style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                <S3NewLogo
                  color={"rgba(235, 129, 40, 1)"}
                />
              </div>
              <img alt="demo" src={Logo} className="logo" />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "center",
                  width: "76%",
                }}
              >
                Secure Sales Service
              </div>
              <div
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  width: "76%",
                  color: "rgba(0, 0, 0, 1)",
                }}
                className="mt--10px"
              >
                A friendly neighbourhood handyman and customers satisfied with
                instant service
              </div>
            </div>
          </div>
          <div className="col-lg-1 border-register-right res-576px-d-none"></div>
          <div className="res-577px-d-none" onClick={() => navigate("/")}>
            <S3NewLogo
              color={"rgba(235, 129, 40, 1)"}
            />
          </div>
          <div
            style={{
              width: "56.5%",
            }}
            className="res-576px-w-fill"
          >
            <div className=" p-md-3 p-5 p-lg-5 res-576px-p-0 ">
              <form onSubmit={handleSubmit}>
                <div className="mt-3">
                  <p
                    style={{
                      fontWeight: "700",
                      fontSize: "21px",
                      lineHeight: "26px",
                    }}
                  >
                    Change New Password?
                  </p>
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#6A707C",
                    }}
                  >
                    Set a password you haven't used before.
                  </p>
                </div>
                <TextField
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Enter Your New User Password"
                  required
                  value={password}
                  onChange={handlePasswordChange}
                  error={!!passwordError}
                  helperText={passwordError}
                  style={{
                    width: "100%",
                    outline: "none",
                    border: "none",
                    backgroundColor: "#F7F8F9",
                    color: "#291409",
                    height: "56px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type={showPassword1 ? "text" : "password"}
                  className="form-control mt-4"
                  placeholder="Enter Your New User Confirm password"
                  value={confirmpassword}
                  onChange={handleConfirmpasswordChange}
                  error={hasInteraction && !!confirmpasswordError}
                  helperText={
                    hasInteraction && confirmpasswordError
                      ? confirmpasswordError
                      : ""
                  }
                  style={{
                    width: "100%",
                    outline: "none",
                    border: "none",
                    backgroundColor: "#F7F8F9",
                    color: "#291409",
                    height: "56px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword1(!showPassword1)}
                          edge="end"
                        >
                          {showPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  className="btn w-100 mt-4"
                  type="submit"
                  style={{
                    height: "56px",
                    color: "white",
                    textTransform: "capitalize",
                    backgroundColor:
                      password &&
                        !passwordError &&
                        !confirmpasswordError &&
                        confirmpassword
                        ? "rgba(235, 129, 40, 1)"
                        : "rgb(235 129 40 / 55%)",
                    cursor:
                      password &&
                        !passwordError &&
                        !confirmpasswordError &&
                        confirmpassword
                        ? "pointer"
                        : "default",
                  }}
                  disabled={
                    password &&
                      !passwordError &&
                      !confirmpasswordError &&
                      confirmpassword
                      ? false
                      : true
                  }
                >
                  Reset password
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "100px" }} className="res-576px-mt-30px">
        <Footer topscroller />
      </div>
    </div>
  );
};

export default Newpassword;
