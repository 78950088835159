import React from "react";
import Topbar from "../topbar";
import Sidebar from "../Sidebar";
import { Card } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import googlepay from "../../Images/googlepay.png";
import paytm from "../../Images/paytm.png";
import visa from "../../Images/visa.png";
import InputMask from "react-input-mask";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";


const Payment = () => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className="container-fluid">
        <Topbar />
        <div className="row">
          <div className="col-lg-2 p-0 border border-right">
            <Sidebar />
          </div>
          <div className="col-lg-10 Main_menu">
            <ul className="p-0" style={{ display: "inline-flex" }}>
              <li style={{ listStyle: "none" }}>
                <i
                  className="bi bi-chevron-left me-2"
                  style={{ fontWeight: "500", fontSize: "12px" }}
                ></i>
              </li>
              <li style={{ listStyle: "none" }}>
                <p style={{ fontWeight: "600", fontSize: "21px" }}>Payments</p>
              </li>
            </ul>
            <div className="container-fluid">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>
                          <div>
                            <input
                              style={{ borderRadius: "10px" }}
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={expanded === "panel1"}
                            />
                            <text
                              className="ms-2"
                              style={{ fontSize: "18px", fontWeight: "400" }}
                            >
                              UPI
                            </text>
                          </div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div>
                            <ul
                              className="m-0 p-0"
                              style={{ display: "inline-flex" }}
                            >
                              <li style={{ listStyle: "none" }}>
                                <div
                                  className="border border rounded"
                                  style={{ width: "123px", height: "48px" }}
                                >
                                  <img
                                    alt="demo"
                                    className="ms-4 mt-3"
                                    src={googlepay}
                                    style={{ width: "55px" }}
                                  />
                                </div>
                              </li>
                              <li
                                className="ms-3"
                                style={{ listStyle: "none" }}
                              >
                                <div
                                  className="border border rounded "
                                  style={{ width: "123px", height: "48px" }}
                                >
                                  <img
                                    alt="demo"
                                    className="ms-4 mt-3"
                                    src={paytm}
                                    style={{ width: "55px" }}
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div className="mt-2">
                            <TextField
                              className="paymetinput"
                              style={{ width: "380px" }}
                              fullWidth
                              label="Enter UPI ID"
                              id="fullWidth"
                            />
                          </div>
                          <button
                            class="btn mt-2"
                            type="button"
                            style={{
                              width: "185px",
                              height: "47px",
                              color: "white",
                              backgroundColor: "#EB8128",
                              borderColor: "#EB8128",
                            }}
                          >
                            Pay 2034.72
                          </button>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      className="mt-2"
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>
                          <div>
                            <input
                              style={{ borderRadius: "10px" }}
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={expanded === "panel2"}
                            />
                            <text
                              className="ms-2"
                              style={{ fontSize: "18px", fontWeight: "400" }}
                            >
                              SBI Credit Card ****4726
                            </text>
                          </div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <h1>welcome all back</h1>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      className="mt-2"
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>
                          <div>
                            <input
                              style={{ borderRadius: "10px" }}
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={expanded === "panel3"}
                            />
                            <text
                              className="ms-2"
                              style={{ fontSize: "18px", fontWeight: "400" }}
                            >
                              Debit / Credit Card
                            </text>
                          </div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <InputMask
                            mask="9999 - 9999 - 9999 - 9999"
                            maskChar="X"
                            formatChars={{ "9": "[0-9]" }}
                          >
                            {() => (
                              <TextField
                                style={{ width: "380px" }}
                                className=" mt-lg-1 paymetinput"
                                id="outlined-basic"
                                defaultValue="4731 - "
                                label="Card Number"
                                variant="outlined"
                                placeholder="XXXX - XXXX - XXXX - XXXX"
                                // error={Iscard ? true : false}
                                // helperText={Iscard ? "Please Type Your Card Details" : null}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      style={{ color: "red" }}
                                      position="start"
                                      className="text-primary"
                                    >
                                      <img src={visa} alt="Visa Card" />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </InputMask>

                          <div className="row mt-3">
                            <div className="col-5">
                              <TextField
                                fullWidth
                                label="Expiry Date"
                                id="fullWidth"
                                placeholder="MM / YYYY"
                              />
                            </div>
                            <div className="col-5">
                              <TextField
                                fullWidth
                                label="CVV Number"
                                id="fullWidth"
                                placeholder="XXX"
                              />
                            </div>
                            <div className="col-3"></div>
                          </div>

                          <TextField
                            className="mt-3 paymetinput"
                            style={{ width: "380px" }}
                            label="Card Holder Name"
                            placeholder="Enter card holder name"
                          />

                          <div className="mt-3">
                            <input
                              style={{ borderRadius: "10px" }}
                              class="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox1"
                              value="option1"
                            />
                            <text
                              className="ms-3"
                              style={{
                                fontSize: "15px",
                                fontWeight: "400",
                                color: "#6A707C",
                              }}
                            >
                              Accept to save card as per the RBI Regulations
                            </text>
                          </div>

                          <button
                            class="btn mt-2"
                            type="button"
                            style={{
                              width: "185px",
                              height: "47px",
                              color: "white",
                              backgroundColor: "#EB8128",
                              borderColor: "#EB8128",
                            }}
                          >
                            Pay 2034.72
                          </button>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      className="mt-2"
                      expanded={expanded === "panel4"}
                      onChange={handleChange("panel4")}
                    >
                      <AccordionSummary
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>
                          <div>
                            <input
                              style={{ borderRadius: "10px" }}
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={expanded === "panel4"}
                            />
                            <text
                              className="ms-2"
                              style={{ fontSize: "18px", fontWeight: "400" }}
                            >
                              Cash on Delivery
                            </text>
                          </div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <h1>welcome all</h1>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className="col-lg-5 col-12 mt-lg-0 mt-4">
                    <div>
                      <Card>
                        <div className="p-3">
                          <TextField
                            fullWidth
                            label="Enter UPI ID"
                            id="fullWidth"
                          />
                        </div>
                      </Card>
                      <Card className="mt-lg-3 mt-4 ">
                        <div className="p-3">
                          <text style={{ fontSize: "18px", fontWeight: "500" }}>
                            Payment Details
                          </text>
                          <div className="row mt-3">
                            <div className="col-9 text-start">
                              <text
                                style={{ fontWeight: "300", fontSize: "16px" }}
                              >
                                Basic Labour Charges (₹):
                              </text>
                            </div>
                            <div className="col-3 text-end">
                              <text
                                style={{ fontWeight: "600", fontSize: "16px" }}
                              >
                                350.00
                              </text>
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-9 text-start">
                              <text
                                style={{ fontWeight: "300", fontSize: "16px" }}
                              >
                                Spare Parts Charges (₹):
                              </text>
                            </div>
                            <div className="col-3 text-end">
                              <text
                                style={{ fontWeight: "600", fontSize: "16px" }}
                              >
                                1246.23
                              </text>
                            </div>
                          </div>

                          <div className="row mt-3 mb-lg-4">
                            <div className="col-9 text-start">
                              <text
                                style={{ fontWeight: "300", fontSize: "16px" }}
                              >
                                Delivery Charges (₹):
                              </text>
                            </div>
                            <div className="col-3 text-end">
                              <text
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                  color: "green",
                                }}
                              >
                                46.00
                              </text>
                            </div>
                          </div>
                          <hr />

                          <div className="row">
                            <div className="col-9 text-start">
                              <text
                                style={{ fontWeight: "600", fontSize: "21px" }}
                              >
                                Total Payable (₹)
                              </text>
                            </div>
                            <div className="col-3 text-end">
                              <text
                                style={{ fontWeight: "600", fontSize: "21px" }}
                              >
                                1642.26
                              </text>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Payment;
