import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import { Tabs, Tab, TextField, Tooltip, AppBar } from "@mui/material";
import SpinnerLoader from "../../../plugins/SpinnerLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  createProduct,
  getProduct,
  getProductList,
  updateProduct,
} from "../../../redux/actions/productAction";
import Form from "react-bootstrap/Form";
import s3Logo from "../../../Images/s3Logo_resposive.png";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FaCalendarDays } from "react-icons/fa6";
import BackdropLoader from "../../../plugins/BackDropLoader";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import FilterModule from "../../../plugins/FilterModule";
import Api from "../../../service/services";
import "../SuperAdminManagement/poductManagement.css";
import { Button, Modal } from "react-bootstrap";
import CountContext from "../../countContext/CountContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ProductManagement = () => {
  const theme = useTheme();
  const [isProductUpdated, setIsProductUpdated] = useState(false);
  const mobileMatches = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { productList, loading: productListLoading } = useSelector(
    (state) => state.productListState
  );
  const { productSelected, loading: productSelectedLoading } = useSelector(
    (state) => state.productSelectedState
  );
  const { loading: productActionLoading } = useSelector(
    (state) => state.productState
  );

  const activeCount =
    productList?.productCounts?.find((product) => product?.status === "active")
      ?.productCount || 0;

  const inActiveCount =
    productList?.productCounts?.find(
      (product) => product?.status === "inactive"
    )?.productCount || 0;

  const allCount = inActiveCount + activeCount || 0;

  const dispatch = useDispatch();
  const [allProducts, setAllProducts] = useState(true);
  const [enbleEdit, setEnbleEdit] = useState(false);
  const [isNewProduct, setIsNewProduct] = useState(false);
  const [active, setActive] = useState(false);
  const [inActive, setInActive] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [viewDatePickerPopover, setViewDatePickerPopover] = useState(false);
  const [isDateNull, setIsDateNull] = useState(false);
  const minimumDate = new Date();
  minimumDate.setDate(minimumDate.getDate() + 1);

  /* Product Inputs State */

  /* Product Info */
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productSummary, setProductSummary] = useState("");
  const [minimumQuantity, setMinimumQuantity] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [profitPercent, setProfitPercent] = useState("");
  /* Discount */
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [discountDateRange, setDiscountDateRange] = useState([
    {
      startDate: minimumDate,
      endDate: minimumDate,
      key: "selection",
    },
  ]);
  const [dateSelected, setDateRangeSelected] = useState(false);
  /* Return Days */
  const [returnDays, setReturnDays] = useState("");
  const [originalPrice, setOriginalPrice] = useState(0);
  const [skuId, setSkuId] = useState("");
  /* Delivery Charges */
  const [withinLocal, setWithinLocal] = useState("");
  const [withinDistrict, setWithinDistrict] = useState("");
  const [withinState, setWithinState] = useState("");
  const [interState, setInterState] = useState("");

  /* Product Inputs Error State */
  const [productNameError, setProductNameError] = useState("");
  const [availableQuantityError, setAvailablQuantityError] = useState("");
  const [productDescriptionError, setProductDescriptionError] = useState("");
  const [productSummaryError, setProductSummaryError] = useState("");
  const [minimumQuantityError, setMinimumQuantityError] = useState("");
  const [unitPriceError, setUnitPriceError] = useState("");
  const [profitPercentError, setProfitPercentError] = useState("");
  const [discountPercentError, setDiscountPercentError] = useState("");
  const [returnDaysError, setReturnDaysError] = useState("");
  const [withinLocalError, setWithinLocalError] = useState("");
  const [withinDistrictError, setWithinDistrictError] = useState("");
  const [withinStateError, setWithinStateError] = useState("");
  const [interStateError, setInterStateError] = useState("");
  const [requiredFieldIsTrue, setRequiredFieldIsTrue] = useState(false);
  const [productId, setProductId] = useState("");
  const [productImage, setProductImage] = useState("");
  const [productImageUrl, setProductImageUrl] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [productStatus, setProductStatus] = useState(false);
  const [availablQuantity, setAvailablQuantity] = useState(null);

  const [errorBrandValue, setErrorBrandValue] = useState("");
  const [errorApplianceValue, setErrorApplianceValue] = useState("");
  const [errorModelValue, setErrorModelValue] = useState("");
  const [isEditProduct, setIsEditProduct] = useState(false);
  const [applianceList, setApplianceList] = useState([]);
  const [applianceModelList, setApplianceModelList] = useState([]);
  const [applianceBrandList, setApplianceBrandList] = useState([]);
  const [applianceValue, setApplianceValue] = useState("");
  const [applianceModelValue, setApplianceModelValue] = useState("");
  const [applianceBrandValue, setApplianceBrandValue] = useState("");
  const [filterData, setFilterData] = useState({});
  const [clickTrue, setClickTrue] = useState(false);

  const [isCreate, setIsCreate] = useState(false);

  const { setSearch } = useContext(CountContext);

  const handleTabChange = (event, newValue) => {
    setIsCreate(false);
    clearInputErrors();
    setSearch("");
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleSelect = (ranges) => {
    setDiscountDateRange([ranges.selection]);
    setDateRangeSelected(false);
  };
  const fileInputRef = useRef(null);

  const onImageChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

    // Check if the file exists and is of an allowed type
    if (file && allowedTypes.includes(file.type)) {
      setProductImage(file);
      setProductImageUrl(URL.createObjectURL(file));
    } else {
      // Handle invalid file type
      Swal.fire({
        text: "Only JPG, JPEG, and PNG files are allowed.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
    // Clear the input field after the change
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleCancelDate = () => {
    // setDateRange(defaultDate);
    // setClickTrue(false);
    setViewDatePickerPopover(false);
    // setDiscountDateRange([
    //   {
    //     startDate: minimumDate,
    //     endDate: minimumDate,
    //     key: "selection",
    //   },
    // ]);
    // setDateRangeSelected(false);
    // discountDateRange();
  };
  const handleSetSelectedDate = () => {
    setClickTrue(true);
    setDiscountDateRange(discountDateRange);
    setViewDatePickerPopover(false);
    setDateRangeSelected(true);
  };

  const clearInputErrors = () => {
    setProductNameError("");
    setProductDescriptionError("");
    setProductSummaryError("");
    setMinimumQuantityError("");
    setUnitPriceError("");
    setProfitPercentError("");
    setDiscountPercentError("");
    setReturnDaysError("");
    setOriginalPrice("");
    setWithinLocalError("");
    setWithinDistrictError("");
    setWithinStateError("");
    setInterStateError("");
    setAvailablQuantityError("");
    setErrorBrandValue("");
    setErrorApplianceValue("");
    setErrorModelValue("");
    setProductSummary("");
  };

  const handleEdit = () => {
    setIsEditProduct(true);
    setIsDateNull(false);
    setIsNewProduct(false);
    setEnbleEdit(true);
  };
  const handleScroll = (e) => {
    e.preventDefault(); // Prevent default scroll behavior
  };

  useEffect(() => {
    const getStatus = () => {
      if (allProducts) {
        return "all";
      } else if (active) {
        return "active";
      } else if (inActive) {
        return "inactive";
      } else {
        return "all";
      }
    };
    const payload = {
      search: searchInput,
      status: getStatus(),
    };
    dispatch(getProductList(payload));
    // eslint-disable-next-line
  }, []);
  const handleGetProductList = (tabStatus) => {
    const getStatus = () => {
      if (tabStatus === "all-products") {
        return "all";
      } else if (tabStatus === "active") {
        return "active";
      } else if (tabStatus === "in-active") {
        return "inactive";
      } else {
        return "all";
      }
    };
    const payload = {
      search: searchInput,
      status: getStatus(),
    };
    dispatch(getProductList(payload));
  };

  const handleProductSearch = useCallback(
    (e) => {
      setSearchInput(e);
      const getStatus = () => {
        if (allProducts) {
          return "all";
        } else if (active) {
          return "active";
        } else if (inActive) {
          return "inactive";
        } else {
          return "all";
        }
      };
      const payload = {
        status: getStatus(),
      };
      const searchInput = e;
      dispatch(getProductList(payload, searchInput, filterData));
    },
    // eslint-disable-next-line
    [setSearchInput, searchInput, filterData]
  );

  const handleFilterData = (e) => {
    setFilterData(e);
    const getStatus = () => {
      if (allProducts) {
        return "all";
      } else if (active) {
        return "active";
      } else if (inActive) {
        return "inactive";
      } else {
        return "all";
      }
    };
    const payload = {
      status: getStatus(),
    };
    const filterPayload = e;
    console.log("filterPayload", filterPayload.product);
    dispatch(getProductList(payload, searchInput, filterPayload));
  };

  const handleProductData = (product) => {
    setEnbleEdit(false);
    setIsEditProduct(false);
    setIsNewProduct(false);
    const payload = {
      id: product?.id,
    };
    dispatch(getProduct(payload));
  };

  useEffect(() => {
    setEnbleEdit(false);
    setIsEditProduct(false);
    setIsNewProduct(false);
    handleProductData(productList?.list[0]);
    // eslint-disable-next-line
  }, [productList]);

  useEffect(() => {
    setIsDateNull(false);
    setEnbleEdit(false);
    setIsEditProduct(false);
    setIsNewProduct(false);

    clearInputErrors();
    setIsNewProduct(false);
    setProductId(productSelected?.id);
    setProductName(productSelected?.name);
    setProductDescription(productSelected?.description);
    setMinimumQuantity(productSelected?.minimum_quantity);
    setProfitPercent(productSelected?.profit_percent);
    setUnitPrice(productSelected?.unit_price);
    setSalePrice(productSelected?.sale_price);
    setDiscountPercent(productSelected?.discount_percent);
    setDiscountedPrice(productSelected?.discounted_price);
    setAvailablQuantity(productSelected?.productInventory?.quantity_available);
    setProductSummary(productSelected?.summary);
    productSelected?.discount_start_at === null && setIsDateNull(true);
    const discountDate = [
      {
        startDate: new Date(productSelected?.discount_start_at),
        endDate: new Date(productSelected?.discount_end_at),
        key: "selection",
      },
    ];
    setDiscountDateRange(discountDate);
    setDateRangeSelected(false);
    setReturnDays(productSelected?.return_days);
    const salePrice = Number(productSelected?.sale_price);
    const discountPrice = Number(productSelected?.discounted_price);
    if (salePrice !== null) {
      let originalPrice = 0;
      if (discountPrice !== null) {
        originalPrice = Number(salePrice) - Number(discountPrice);
      } else {
        originalPrice = Number(salePrice);
      }
      setOriginalPrice(originalPrice);
      console.log("Original Price:", salePrice, discountPrice);
    } else {
      console.log("Sale price is not defined.");
    }

    console.log("original price", originalPrice);
    setSkuId(productSelected?.sku_id);
    setWithinLocal(productSelected?.deliveryCharge?.within_local);
    setWithinState(productSelected?.deliveryCharge?.within_state);
    setWithinDistrict(productSelected?.deliveryCharge?.within_district);
    setInterState(productSelected?.deliveryCharge?.inter_state);
    setProductImageUrl(productSelected?.product_img);
    setProductStatus(
      productSelected?.status === "active"
        ? true
        : productSelected?.status === "inactive" && false
    );
    Api.getApplianceDropdown().then((result) => {
      setApplianceList(result);
      setResult(result);
      const appliance_id = result?.find(
        (list) => list?.appliance_name === productSelected?.appliance
      )?.id;
      setApplianceValue(appliance_id);
      Api.getModelDropdown(appliance_id).then((result) => {
        setApplianceModelList(result?.models);
        setApplianceBrandList(result?.brands);
        setApplianceModelValue(
          result?.models?.find(
            (list) => list?.appliance_model === productSelected?.model
          )?.appliance_model
        );
        setApplianceBrandValue(
          result?.brands?.find(
            (list) => list?.brand_name === productSelected?.brand
          )?.brand_name
        );
      });
    });
    // eslint-disable-next-line
  }, [productSelected]);
  const handleNewProduct = () => {
    setIsCreate(true);
    setIsDateNull(false);
    setIsEditProduct(false);
    clearInputErrors();
    setIsNewProduct(true);
    setEnbleEdit(true);
    setProductId("");
    setProductName("");
    setProductDescription("");
    setMinimumQuantity("");
    setProfitPercent("");
    setUnitPrice("");
    setSalePrice("");
    setDiscountPercent(0);
    setDiscountedPrice("");
    setProductSummary("");
    setApplianceValue("");
    setApplianceModelValue("");
    setApplianceBrandValue("");
    setApplianceBrandList([]);
    setApplianceModelList([]);
    setProductSummary("");
    setDiscountDateRange([
      {
        startDate: minimumDate,
        endDate: minimumDate,
        key: "selection",
      },
    ]);
    setDateRangeSelected(false);
    setReturnDays("");
    setWithinLocal("");
    setWithinState("");
    setWithinDistrict("");
    setInterState("");
    setMinimumQuantity("");
    setProductImageUrl("");
    setProductStatus(true);
    setAvailablQuantity("");
  };
  const handleKeyDown = (event) => {
    // Allow: backspace, delete, tab, escape, enter
    if (
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'Tab' ||
      event.key === 'Escape' ||
      event.key === 'Enter'
    ) {
      return;
    }
    // Prevent negative numbers and symbols
    if (/\D/.test(event.key)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (
      productName?.length > 0 &&
      productDescription.length > 0 &&
      minimumQuantity.length > 0 &&
      profitPercent > 0 &&
      unitPrice > 0 &&
      salePrice > 0 &&
      discountPercent > 0 &&
      discountedPrice > 0 &&
      productSummary.length > 0 &&
      applianceValue !== "" &&
      interStateError === "" &&
      availableQuantityError === "" &&
      withinStateError === "" &&
      withinDistrictError === "" &&
      withinLocalError === "" &&
      productImage !== ""
    ) {
      return setRequiredFieldIsTrue(true);
    } else {
      return setRequiredFieldIsTrue(false);
    }
  }, [
    productId,
    productName,
    productDescription,
    minimumQuantity,
    profitPercent,
    unitPrice,
    salePrice,
    discountPercent,
    discountedPrice,
    productSummary,
    applianceValue,
    interStateError,
    availableQuantityError,
    withinStateError,
    withinDistrictError,
    withinLocalError,
  ]);

  useEffect(() => {
    const hasChanges =
      productSelected?.name !== productName ||
      productSelected?.description !== productDescription ||
      productSelected?.minimum_quantity !== minimumQuantity ||
      productSelected?.profit_percent !== profitPercent ||
      productSelected?.unit_price !== unitPrice ||
      productSelected?.sale_price !== salePrice ||
      productSelected?.discount_percent !== discountPercent ||
      productSelected?.discounted_price !== discountedPrice ||
      productSelected?.summary !== productSummary ||
      productSelected?.applianceValue !== applianceValue ||
      productSelected?.withinLocal !== withinLocal ||
      productSelected?.withinDistrict !== withinDistrict ||
      productSelected?.withinState !== withinState ||
      productSelected?.interState !== interState ||
      productSelected?.availablQuantity !== availablQuantity;
    setIsProductUpdated(hasChanges);
  }, [
    productName,
    productDescription,
    minimumQuantity,
    profitPercent,
    unitPrice,
    salePrice,
    discountPercent,
    discountedPrice,
    productSummary,
    productSelected,
    applianceValue,
    availablQuantity,
    interState,
    withinState,
    withinDistrict,
    withinLocal,
  ]);

  const validateProductInput = () => {
    let isValid = true;
    if (parseInt(discountPercent) > 0 && !dateSelected && !clickTrue) {
      isValid = false;
      Swal.fire({
        text: "Please select the discount date range",
        confirmButtonText: "OK",
      });
    }
    if (!productName) {
      setProductNameError("Please enter the Name of the Product.");
      isValid = false;
    }

    if (!applianceValue) {
      setErrorApplianceValue("Please enter the Name of the Appliance.");
      isValid = false;
    } else {
      setProductNameError("");
    }
    if (!productDescription) {
      isValid = false;
      setProductDescriptionError("Please enter the Product Description.");
    } else {
      setProductDescriptionError("");
    }
    if (!productSummary) {
      isValid = false;
      setProductSummaryError("Please enter the Product Summary.");
    } else {
      setProductSummaryError("");
    }
    if (!minimumQuantity) {
      isValid = false;
      setMinimumQuantityError("Please enter Minimum Quantity.");
    } else {
      setMinimumQuantityError("");
    }
    if (!unitPrice) {
      isValid = false;
      setUnitPriceError("Please enter the Unit Price.");
    } else {
      setUnitPriceError("");
    }
    if (!profitPercent) {
      isValid = false;
      setProfitPercentError("Please enter the Profit Percent.");
    } else {
      setProfitPercentError("");
    }
    if (!withinLocal) {
      isValid = false;
      setWithinLocalError("Please enter the Within local.");
    } else {
      setWithinLocalError("");
    }
    if (!withinDistrict) {
      isValid = false;
      setWithinDistrictError("Please enter the Within District.");
    } else {
      setWithinDistrictError("");
    }
    if (!withinState) {
      isValid = false;
      setWithinStateError("Please enter the Within State.");
    } else {
      setWithinStateError("");
    }
    if (!interState || interState.length === 0) {
      isValid = false;
      setInterStateError("Please enter the Inter State.");
    } else {
      setInterStateError("");
    }
    if (!availablQuantity) {
      isValid = false;
      setAvailablQuantityError("Please enter the Minimum quantity.");
    } else {
      setAvailablQuantityError("");
    }
    return isValid;
  };
  const handleProductSubmit = () => {
    setIsCreate(false);
    let appliance = result?.find((data) => data?.id === applianceValue);
    const isValid = validateProductInput();
    const formData = new FormData();
    if (!productImageUrl) {
      Swal.fire({
        text: "Product image required",
        showConfirmButton: false,
        timer: 3000,
        iconSize: "1.5em",
      });
    } else {
      const discountPayload = {
        name: productName,
        ...(applianceBrandValue !== "select brand" &&
          applianceBrandValue !== "" && {
          brand: applianceBrandValue,
        }),
        ...(applianceModelValue !== "Select Model" &&
          applianceModelValue !== "" && {
          model: applianceModelValue,
        }),
        appliance: appliance?.appliance_name,
        description: productDescription,
        summary: productSummary,
        minimum_quantity: minimumQuantity,
        unit_price: unitPrice,
        sale_price: Number(salePrice)?.toFixed(2),
        profit_percent: profitPercent,
        discount_percent: discountPercent && Number(discountPercent),
        discounted_price:
          Number(discountedPrice) && Number(discountedPrice)?.toFixed(2),
        discount_start_at: moment(discountDateRange[0].startDate).format(
          "YYYY-MM-DD 08:00:00"
        ),
        discount_end_at: moment(discountDateRange[0].endDate).format(
          "YYYY-MM-DD 07:59:59"
        ),
        return_days: returnDays || 0,
        within_local: withinLocal,
        within_district: withinDistrict,
        within_state: withinState,
        inter_state: interState,

        status:
          productStatus === true
            ? "active"
            : productStatus === false && "inactive",
        quantity_available: availablQuantity,
      };
      const payloadString = JSON.stringify(discountPayload);
      formData.append("fields", payloadString);
      formData.append("files", productImage);
      if (isValid) {
        clearInputErrors();
        const getStatus = () => {
          if (allProducts) {
            return "all";
          } else if (active) {
            return "active";
          } else if (inActive) {
            return "inactive";
          } else {
            return "all";
          }
        };
        if (isNewProduct) {
          const payload = {
            search: searchInput,
            status: getStatus(),
            data: formData,
          };
          dispatch(createProduct(payload));
          clearInputErrors();
          setIsNewProduct(true);
          setProductId("");
          setProductName("");
          setProductDescription("");
          setMinimumQuantity("");
          setProfitPercent("");
          setUnitPrice("");
          setSalePrice("");
          setDiscountPercent(0);
          setDiscountedPrice("");
          setDiscountDateRange([
            {
              startDate: minimumDate,
              endDate: minimumDate,
              key: "selection",
            },
          ]);
          setDateRangeSelected(false);
          setReturnDays("");
          setSalePrice("");
          setWithinLocal("");
          setWithinState("");
          setWithinDistrict("");
          setInterState("");
          setMinimumQuantity("");
          setProductImageUrl("");
          setProductStatus(false);
          setAvailablQuantity("");
          setApplianceValue("");
          setApplianceModelValue("");
          setApplianceBrandValue("");
          setApplianceBrandList([]);
          setApplianceList([]);
          setApplianceModelList([]);
        } else {
          const payload = {
            id: productId,
            search: searchInput,
            status: getStatus(),
            data: formData,
          };
          dispatch(updateProduct(payload));
        }
      }
    }
  };

  useEffect(() => {
    const profitMargin = profitPercent / 100;
    const sellingPrice = unitPrice * profitMargin;
    if (Number(sellingPrice) + Number(unitPrice) > 0) {
      setSalePrice(Number(sellingPrice) + Number(unitPrice));
    }
    // eslint-disable-next-line
  }, [unitPrice]);

  useEffect(() => {
    const discountMargin = discountPercent / 100;
    const discountedPrice = salePrice * discountMargin;
    if (Number(discountedPrice) > 0) {
      setDiscountedPrice(Number(discountedPrice));
    }
    if (salePrice !== null) {
      let originalPrice = 0;
      if (discountedPrice !== null) {
        originalPrice = Number(salePrice) - Number(discountedPrice)?.toFixed(2);
      } else {
        originalPrice = Number(salePrice)?.toFixed(2);
      }
      setOriginalPrice(originalPrice);
    }
    // eslint-disable-next-line
  }, [salePrice]);

  useEffect(() => {
    if (productList?.list?.length > 0) {
      handleProductData(productList?.list[0]);
    }
    // eslint-disable-next-line
  }, []);

  const [result, setResult] = useState([]);
  const handleSelectAppliance = () => {
    Api.getApplianceDropdown().then((result) => {
      setApplianceList(result);
      setResult(result);
    });
  };

  const handleSelectApplianceModel = (id) => {
    Api.getModelDropdown(id).then((result) => {
      setApplianceModelList(result?.models);
      setApplianceBrandList(result?.brands);
    });
    if (mobileMatches) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    handleSelectApplianceModel(applianceValue);
    // eslint-disable-next-line
  }, [applianceValue]);

  useEffect(() => {
    handleSelectAppliance();
  }, []);

  return (
    <div>
      {productActionLoading && <BackdropLoader />}
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div className="row container-fluid">
          <div className="col-lg-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <div
            className="col-12 service_list Main_menu"
            style={{ marginTop: "80px" }}
          >
            {false ? (
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "center", height: "70vh" }}
              >
                <SpinnerLoader />
              </div>
            ) : (
              <div className="row mt-2">
                <div className="col-lg-4 col-12 service_listing">
                  <div>
                    <div
                      className="m-0 sticky-top bg-white"
                      style={{ position: "sticky", zIndex: "2" }}
                    >
                      <i
                        className="bi bi-chevron-left "
                        style={{
                          fontWeight: "600",
                          fontSize: "21px",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(-1)}
                      ></i>
                      <text
                        className="p-0"
                        style={{
                          fontWeight: "600",
                          color: "#1E232C",
                          fontSize: "21px",
                          width: "208px",
                          height: "32px",
                        }}
                      >
                        Product Management
                      </text>
                      <AppBar
                        style={{ boxShadow: "none" }}
                        position="static"
                        className="w-100 bg-white"
                        aria-label="basic tabs example"
                      >
                        <Tabs
                          className="w-100 bg-white"
                          value={tabValue}
                          onChange={handleTabChange}
                          aria-label="basic tabs example"
                        >
                          <Tab
                            style={{
                              color: allProducts
                                ? "rgb(235, 129, 40)"
                                : "#6A707C",
                            }}
                            label={`All`}
                            {...a11yProps(0)}
                            onClick={() => {
                              setAllProducts(true);
                              setActive(false);
                              setInActive(false);
                              handleGetProductList("all-products");
                            }}
                          />
                          <Tab
                            style={{
                              color: active ? "rgb(235, 129, 40)" : "#6A707C",
                            }}
                            label={`Active`}
                            {...a11yProps(1)}
                            onClick={() => {
                              setAllProducts(false);
                              setActive(true);
                              setInActive(false);
                              handleGetProductList("active");
                            }}
                          />
                          <Tab
                            style={{
                              color: inActive ? "rgb(235, 129, 40)" : "#6A707C",
                            }}
                            label={`Inactive`}
                            {...a11yProps(2)}
                            onClick={() => {
                              setAllProducts(false);
                              setActive(false);
                              setInActive(true);
                              handleGetProductList("in-active");
                            }}
                          />
                        </Tabs>
                      </AppBar>
                      <div>
                        <div>
                          <FilterModule
                            className="product-search-input"
                            inputPlaceholder={"Search by Name"}
                            onSearchInputChange={handleProductSearch}
                            onChangeFilterData={handleFilterData}
                            tabValue={tabValue}
                          />
                        </div>

                        <div className="product-list">
                          {productListLoading ? (
                            <div
                              style={{
                                height: "50vh",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <SpinnerLoader />
                            </div>
                          ) : (
                            <>
                              {productList?.list.length > 0 ? (
                                <>
                                  {productList?.list?.map((product) => {
                                    return (
                                      <div
                                        className="product-card"
                                        onClick={() =>
                                          handleProductData(product)
                                        }
                                        style={{
                                          overflow: "hidden",
                                          background:
                                            product.id === productId
                                              ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                              : "white",
                                          border:
                                            product.id === productId
                                              ? "rgb(235, 129, 40) solid 1px"
                                              : "",
                                          padding: "0px",
                                          cursor: "pointer",
                                          borderRadius: "10px",
                                          marginTop: "0.5rem",
                                        }}
                                      >
                                        <div className="product-img-none">
                                          <div>
                                            {product.product_img ? (
                                              <img
                                                src={product.product_img}
                                                alt="img-error"
                                                className="product-img-view"
                                              />
                                            ) : (
                                              <img
                                                src={s3Logo}
                                                alt="img-error"
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="product-content">
                                          <div className="product-detail">
                                            {product?.name}
                                          </div>
                                          <div className="product-detail">
                                            {product?.appliance}
                                          </div>
                                          <div
                                            className="product-id"
                                            id="product-description"
                                          >
                                            {/* eslint-disable-next-line */}
                                            {product?.summary == undefined
                                              ? ""
                                              : product?.summary}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <p className="text-center mt-2">
                                    No data found!
                                  </p>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {productSelectedLoading ? (
                  <div className="col-lg-8 col-12 product-inputs mt-3 d-flex align-items-center">
                    <SpinnerLoader />
                  </div>
                ) : (
                  <>
                    <div className="col-lg-8 col-12 product-inputs mt-3">
                      {isNewProduct ? (
                        <div className="new-product-btn mt-3 mb-3"></div>
                      ) : (
                        <div
                          className="new-product-btn mt-3 mb-3"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          {isNewProduct === false &&
                            isEditProduct === false && (
                              <>
                                {tabValue === 0 && (
                                  <Button
                                    className="btn mt-3 product-submit-btn "
                                    variant="contained"
                                    type="submit"
                                    style={{
                                      height: "45px",
                                      color: "white",
                                      width: "6rem",
                                      backgroundColor: "#EB8128",
                                      textTransform: "none",
                                      float: "right",
                                      marginRight: "4px",
                                    }}
                                    onClick={() => handleNewProduct()}
                                  >
                                    + Create
                                  </Button>
                                )}
                                {productList?.list.length > 0 ? (
                                  <Button
                                    className="btn mt-3"
                                    variant="contained"
                                    type="submit"
                                    style={{
                                      backgroundColor: "white",
                                      color: "rgb(235, 129, 40)",
                                      border: "1px solid ",
                                      height: "43px",
                                      width: "6rem",
                                      textTransform: "none",
                                      float: "right",
                                    }}
                                    id="edit-button"
                                    onClick={() => handleEdit()}
                                  >
                                    Edit
                                  </Button>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                        </div>
                      )}
                      {isCreate || productList?.list.length > 0 ? (
                        <div className="row">
                          <div className="col-xl-6 col-md-12 col-12">
                            <div className="product-image-container mb-3">
                              <div className="product-img-none-detail">
                                <div>
                                  {productImageUrl ? (
                                    <img
                                      src={productImageUrl}
                                      alt="img-error"
                                      className="product-img-view"
                                    />
                                  ) : (
                                    <img src={s3Logo} alt="s3Logo-img-error" />
                                  )}
                                  <input
                                    type="file"
                                    id="product-img"
                                    style={{ display: "none" }}
                                    onChange={onImageChange}
                                    ref={fileInputRef}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="d-flex align-items-center gap-2">
                                  <label
                                    className="toggle-switch"
                                    style={{
                                      cursor: enbleEdit && "not-allowed",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      style={{
                                        opacity: enbleEdit && "0.5",
                                      }}
                                      disabled={!enbleEdit}
                                      checked={productStatus}
                                      onChange={() =>
                                        setProductStatus(!productStatus)
                                      }
                                    />
                                    <span
                                      className="slider"
                                      style={{
                                        cursor: !enbleEdit && "not-allowed",
                                      }}
                                    ></span>
                                  </label>
                                  {productStatus ? (
                                    <label
                                      style={{
                                        fontSize: "14px",
                                        color: "#6A707C",
                                      }}
                                    >
                                      ACTIVE
                                    </label>
                                  ) : (
                                    <label
                                      style={{
                                        fontSize: "14px",
                                        color: "#6A707C",
                                      }}
                                    >
                                      INACTIVE
                                    </label>
                                  )}
                                </div>
                                <div className="product-image-btns mt-2">
                                  {!productImageUrl && (
                                    <label
                                      className="product-image-add-btn"
                                      htmlFor="product-img"
                                      style={{ width: "5rem" }}
                                    >
                                      Add
                                    </label>
                                  )}

                                  {!enbleEdit ? (
                                    <></>
                                  ) : (
                                    <>
                                      {productImageUrl && (
                                        <label
                                          disabled={!enbleEdit}
                                          className="product-image-remove-btn"
                                          onClick={() => setProductImageUrl("")}
                                          style={{ width: "5rem" }}
                                        >
                                          Remove
                                        </label>
                                      )}
                                      {productImageUrl && (
                                        <label
                                          className="product-image-change-btn mt-2"
                                          htmlFor="product-img"
                                          style={{ width: "5rem" }}
                                        >
                                          Change
                                        </label>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  fontSize: "9px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <b>Note:</b> Supported file formats are PNG,
                                SVG, JPEG, with a maximum size of 2 MB.
                              </div>
                              <TextField
                                label="Product Name"
                                placeholder="Product Name"
                                className="mt-2 mb-2 "
                                fullWidth
                                value={productName}
                                disabled={!enbleEdit}
                                onChange={(e) => {
                                  setProductName(e.target.value);
                                }}
                                helperText={productNameError}
                                error={!!productNameError}
                                onKeyUp={() => {
                                  if (!productName) {
                                    setProductNameError(
                                      "Please enter the Name of the Product."
                                    );
                                  } else {
                                    setProductNameError("");
                                  }
                                }}
                                InputLabelProps={{
                                  shrink: !isNewProduct ? true : undefined,
                                }}
                              />
                              <>
                                <div className="mt-1">
                                  <TextField
                                    multiline
                                    disabled={!enbleEdit}
                                    maxRows={4}
                                    fullWidth
                                    variant="outlined"
                                    label="Product Summary"
                                    placeholder="Product Summary"
                                    className="mt-4  text-area mb-3"
                                    value={productSummary}
                                    onChange={(e) => {
                                      if (e.target.value.length <= 50) {
                                        setProductSummary(e.target.value);
                                      } else {
                                        setProductSummary(
                                          e.target.value.slice(0, 50)
                                        );
                                      }
                                    }}
                                    helperText={productSummaryError}
                                    error={!!productSummaryError}
                                    onKeyUp={() => {
                                      if (!productSummary) {
                                        setProductSummaryError(
                                          "Please enter the Product Summary."
                                        );
                                      } else {
                                        setProductSummaryError("");
                                      }
                                    }}
                                    InputLabelProps={{
                                      shrink: !isNewProduct ? true : undefined,
                                    }}
                                  />
                                  <div
                                    style={{ fontSize: "12px", height: "0" }}
                                  >
                                    {productSummary?.length > 0
                                      ? productSummary?.length
                                      : 0}{" "}
                                    / 50
                                  </div>
                                </div>
                              </>
                              <div className="mt-4">
                                <TextField
                                  disabled={!enbleEdit}
                                  multiline
                                  fullWidth
                                  maxRows={3}
                                  variant="outlined"
                                  label="Product Description"
                                  placeholder="Product Description"
                                  className="mt-4 bt-2 text-area mb-3"
                                  value={productDescription}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 255) {
                                      setProductDescription(e.target.value);
                                    } else {
                                      setProductDescription(
                                        e.target.value.slice(0, 255)
                                      );
                                    }
                                  }}
                                  helperText={productDescriptionError}
                                  error={!!productDescriptionError}
                                  onKeyUp={() => {
                                    if (!productDescription) {
                                      setProductDescriptionError(
                                        "Please enter the Product Description."
                                      );
                                    } else {
                                      setProductDescriptionError("");
                                    }
                                  }}
                                  InputLabelProps={{
                                    shrink: !isNewProduct ? true : undefined,
                                  }}
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      overflowY: "auto", // Controls scroll behavior
                                    },
                                  }}
                                />
                                <div style={{ fontSize: "12px", height: "0" }}>
                                  {productDescription?.length > 0
                                    ? productDescription?.length
                                    : 0}{" "}
                                  / 255
                                </div>
                              </div>
                              <div className="row mt-3 mb-3">
                                <div className="col-12 mt-4">
                                  <TextField
                                    disabled={!enbleEdit}
                                    label="Minimum Quantity"
                                    placeholder="0"
                                    className="mt-2 mb-2 "
                                    fullWidth
                                    type="number"
                                    value={minimumQuantity}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (
                                        inputValue === "" ||
                                        (inputValue.length === 1 &&
                                          inputValue !== "0")
                                      ) {
                                        // Allow input if value is empty or if the first digit is not 0
                                        setMinimumQuantity(inputValue);
                                      } else if (
                                        /^[1-9][0-9]*$/.test(inputValue)
                                      ) {
                                        // Allow input if the value is a non-zero digit followed by optional digits
                                        setMinimumQuantity(inputValue);
                                      }
                                    }}
                                    onKeyDown={handleKeyDown}
                                    helperText={minimumQuantityError}
                                    error={!!minimumQuantityError}
                                    onKeyUp={() => {
                                      if (!minimumQuantity) {
                                        setMinimumQuantityError(
                                          "Please enter Minimum Quantity."
                                        );
                                      } else {
                                        setMinimumQuantityError("");
                                      }
                                    }}
                                    InputLabelProps={{
                                      shrink: !isNewProduct ? true : undefined,
                                    }}
                                  />
                                  <TextField
                                    disabled={!enbleEdit}
                                    label="Unit Price (₹)"
                                    placeholder="0"
                                    className="mt-4 mb-2 "
                                    fullWidth
                                    type="number"
                                    value={unitPrice}
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (
                                        inputValue === "" ||
                                        (inputValue.length === 1 &&
                                          inputValue !== "0")
                                      ) {
                                        // Allow input if value is empty or if the first digit is not 0
                                        setUnitPrice(inputValue);
                                      } else if (
                                        /^[1-9][0-9]*$/.test(inputValue)
                                      ) {
                                        // Allow input if the value is a non-zero digit followed by optional digits
                                        setUnitPrice(inputValue);
                                      }
                                    }}
                                    // onChange={(e) => {
                                    //   setUnitPrice(e.target.value);
                                    // }}
                                    helperText={unitPriceError}
                                    error={!!unitPriceError}
                                    onKeyUp={() => {
                                      if (!unitPrice) {
                                        setUnitPriceError(
                                          "Please enter the Unit Price."
                                        );
                                      } else {
                                        setUnitPriceError("");
                                      }
                                    }}
                                    InputLabelProps={{
                                      shrink: !isNewProduct ? true : undefined,
                                    }}
                                  />
                                </div>
                                <div className="col-12 mt-4">
                                  <TextField
                                    disabled={!enbleEdit}
                                    label="Profit Percent(%)"
                                    placeholder="0"
                                    className="mt-2 mb-2"
                                    fullWidth
                                    type="number"
                                    value={profitPercent}
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => {
                                      let enteredProfit = parseFloat(
                                        e.target.value
                                      );
                                      if (enteredProfit > 100) {
                                        enteredProfit = 100;
                                      }
                                      setProfitPercent(enteredProfit);
                                      const profitMargin = enteredProfit / 100;
                                      const sellingPrice =
                                        unitPrice * profitMargin;
                                      setSalePrice(
                                        Number(sellingPrice) + Number(unitPrice)
                                      );
                                    }}
                                    InputLabelProps={{
                                      shrink: !isNewProduct ? true : undefined,
                                    }}
                                    helperText={profitPercentError}
                                    error={!!profitPercentError}
                                    onKeyUp={() => {
                                      if (!profitPercent) {
                                        setProfitPercentError(
                                          "Please enter the Profit Price."
                                        );
                                      } else {
                                        setProfitPercentError("");
                                      }
                                    }}
                                  />

                                  <TextField
                                    disabled={!enbleEdit}
                                    label="Sale Price (₹)"
                                    placeholder="0"
                                    className="mt-4 mb-2 "
                                    fullWidth
                                    type="number"
                                    value={Number(salePrice)?.toFixed(2)}
                                    InputLabelProps={{
                                      shrink: !isNewProduct ? true : undefined,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-12 col-12">
                            <div className="">
                              <div className="row">
                                <>
                                  <div>
                                    <Form.Select
                                      aria-label="Select Appliance"
                                      disabled={!enbleEdit}
                                      className="mb-2"
                                      style={{ color: "gray" }}
                                      id="custom-select"
                                      value={
                                        applianceValue === undefined
                                          ? ""
                                          : applianceValue
                                      }
                                      onChange={(e) => {
                                        const selectedApplianceValue =
                                          e.target.value;
                                        setApplianceValue(
                                          selectedApplianceValue
                                        );
                                        setErrorApplianceValue("");
                                      }}
                                      onClick={handleSelectAppliance}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    >
                                      {/* This option will act as a placeholder and won't be selectable */}
                                      <option value="" disabled hidden>
                                        Select Appliance
                                      </option>

                                      {/* Mapping through the appliance list */}
                                      {applianceList?.map((data) => (
                                        <option
                                          key={data?.id}
                                          value={data?.id}
                                          style={{
                                            width: "100px",
                                            wordWrap: "break-word",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {data?.appliance_name}
                                        </option>
                                      ))}
                                    </Form.Select>

                                    {errorApplianceValue && (
                                      <div
                                        className=""
                                        style={{
                                          fontSize: "11px",
                                          height: "0",
                                          position: "relative",
                                          bottom: "0.2rem",
                                          color: "red",
                                          left: "1rem",
                                          fontFamily: "Arial",
                                        }}
                                      >
                                        {errorApplianceValue}
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    <Form.Select
                                      disabled={!enbleEdit}
                                      style={{ color: "gray" }}
                                      aria-label="Select Brand"
                                      className="mt-3 mb-2"
                                      id="custom-select"
                                      value={applianceBrandValue}
                                      onChange={(e) => {
                                        const selectedBrandValue =
                                          e.target.value;
                                        setApplianceBrandValue(
                                          selectedBrandValue
                                        );
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    >
                                      {/* Placeholder option, not selectable */}
                                      <option value="" disabled hidden>
                                        Select Brand
                                      </option>

                                      {/* Mapping through the applianceBrandList */}
                                      {applianceBrandList?.map((data) => (
                                        <option
                                          key={data.id}
                                          value={data.brand_name}
                                        >
                                          {data.brand_name}
                                        </option>
                                      ))}
                                    </Form.Select>

                                    {errorBrandValue && (
                                      <div
                                        className=""
                                        style={{
                                          fontSize: "11px",
                                          height: "0",
                                          position: "relative",
                                          bottom: "0.2rem",
                                          color: "red",
                                          left: "1rem",
                                          fontFamily: "Arial",
                                        }}
                                      >
                                        {errorBrandValue}
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    <Form.Select
                                      aria-label="Select model"
                                      disabled={!enbleEdit}
                                      className="mt-3 mb-2"
                                      style={{ color: "gray" }}
                                      id="custom-select"
                                      value={applianceModelValue}
                                      onChange={(e) => {
                                        const selectedModelValue =
                                          e.target.value;
                                        setApplianceModelValue(
                                          selectedModelValue
                                        );
                                      }}
                                    >
                                      {/* Placeholder option, not selectable */}
                                      <option value="" disabled hidden>
                                        Select Model
                                      </option>

                                      {/* Mapping through the applianceModelList */}
                                      {applianceModelList?.map((data) => (
                                        <option
                                          key={data.id}
                                          value={data.appliance_model}
                                        >
                                          {data.appliance_model}
                                        </option>
                                      ))}
                                    </Form.Select>

                                    {errorModelValue && (
                                      <div
                                        className=""
                                        style={{
                                          fontSize: "11px",
                                          height: "0",
                                          position: "relative",
                                          bottom: "0.2rem",
                                          color: "red",
                                          left: "1rem",
                                          fontFamily: "Arial",
                                        }}
                                      >
                                        {errorModelValue}
                                      </div>
                                    )}
                                  </div>
                                </>
                                <div className="col-6">
                                  {console.log(
                                    "discountPercent",
                                    discountPercent
                                  )}
                                  <TextField
                                    disabled={!enbleEdit}
                                    label="Discount Percent"
                                    placeholder="0"
                                    className="mt-4"
                                    fullWidth
                                    type="number"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    value={
                                      discountPercent === null
                                        ? 0
                                        : discountPercent
                                    }
                                    onChange={(e) => {
                                      let enteredDiscount = parseFloat(
                                        e.target.value
                                      );
                                      if (enteredDiscount > 100) {
                                        enteredDiscount = 100;
                                      }
                                      setDiscountPercent(enteredDiscount);
                                      if (
                                        parseInt(enteredDiscount) === 0 ||
                                        !enteredDiscount
                                      ) {
                                        setClickTrue(false);
                                      }
                                      const discountMargin =
                                        enteredDiscount / 100;
                                      const discountedPrice =
                                        salePrice * discountMargin;
                                      setDiscountedPrice(
                                        Number(discountedPrice)
                                      );

                                      if (salePrice !== null) {
                                        let originalPrice = 0;
                                        if (
                                          discountedPrice !== null &&
                                          isNaN(discountedPrice) === false
                                        ) {
                                          originalPrice =
                                            salePrice - discountedPrice;
                                        } else {
                                          originalPrice = salePrice;
                                        }
                                        setOriginalPrice(
                                          Number(originalPrice)?.toFixed(2)
                                        );
                                      }
                                    }}
                                    helperText={discountPercentError}
                                    error={!!discountPercentError}
                                  />
                                </div>
                                <div className="col-6">
                                  <Tooltip title="Discounted Sale Price">
                                    <TextField
                                      disabled={!enbleEdit}
                                      label="Discounted Sale Price"
                                      placeholder="0"
                                      className="mt-4 mb-2 "
                                      fullWidth
                                      type="number"
                                      value={Number(discountedPrice)?.toFixed(
                                        2
                                      )}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                              <div>
                                {isDateNull ? (
                                  <></>
                                ) : (
                                  <>
                                    {discountedPrice > 0 && (
                                      <div className="mt-2 mb-2">
                                        <div
                                          onClick={() => {
                                            if (
                                              enbleEdit &&
                                              parseInt(discountPercent) !== 0 &&
                                              discountPercent
                                            ) {
                                              setViewDatePickerPopover(
                                                !viewDatePickerPopover
                                              );
                                              setDiscountDateRange(
                                                discountDateRange
                                              );
                                              setDateRangeSelected(false);
                                            }
                                          }}
                                          className={`selected-date-filter ${(!enbleEdit ||
                                              parseInt(discountPercent) === 0 ||
                                              !discountPercent) &&
                                            "selected-date-filter-disabled"
                                            }`}
                                        >
                                          {clickTrue ? (
                                            <div className="d-flex align-items-center gap-3">
                                              <div>
                                                {moment(
                                                  discountDateRange[0].startDate
                                                ).format("DD-MM-YYYY")}
                                              </div>
                                              {moment(
                                                discountDateRange[0].startDate
                                              ).format("DD-MM-YYYY") !==
                                                moment(
                                                  discountDateRange[0].endDate
                                                ).format("DD-MM-YYYY") && (
                                                  <div>-</div>
                                                )}
                                              {moment(
                                                discountDateRange[0].startDate
                                              ).format("DD-MM-YYYY") !==
                                                moment(
                                                  discountDateRange[0].endDate
                                                ).format("DD-MM-YYYY") && (
                                                  <div>
                                                    {moment(
                                                      discountDateRange[0].endDate
                                                    ).format("DD-MM-YYYY")}
                                                  </div>
                                                )}
                                            </div>
                                          ) : (
                                            <div
                                              className={`d-flex align-items-center gap-3 ${!enbleEdit &&
                                                "selected-date-filter-disabled"
                                                }`}
                                              style={{
                                                color: "gray",
                                                display: true,
                                              }}
                                            >
                                              DD-MM-YYYY
                                            </div>
                                          )}
                                          {enbleEdit ? (
                                            <FaCalendarDays
                                              style={{
                                                color: "rgb(235, 129, 40)",
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        {enbleEdit && (
                                          <Modal
                                            show={viewDatePickerPopover}
                                            onHide={() =>
                                              setViewDatePickerPopover(false)
                                            }
                                            className="date-picker-modal"
                                          >
                                            <div
                                              className="filter-module-date-picker"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            >
                                              <div>
                                                <DateRangePicker
                                                  ranges={discountDateRange}
                                                  onChange={handleSelect}
                                                  minDate={minimumDate}
                                                  defaultValue={{
                                                    startDate: new Date(),
                                                    endDate: new Date(),
                                                  }}
                                                />
                                              </div>
                                              <div className="date-set-btns">
                                                <button
                                                  className="filter-apply-btn"
                                                  onClick={() =>
                                                    handleSetSelectedDate()
                                                  }
                                                >
                                                  Set
                                                </button>
                                                <button
                                                  className="filter-cancel-btn  mt-2"
                                                  onClick={() =>
                                                    handleCancelDate()
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </div>
                                          </Modal>
                                        )}
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <TextField
                                  disabled={!enbleEdit}
                                  label="Original price"
                                  placeholder="0"
                                  className="mt-3 mb-2 "
                                  fullWidth
                                  type="text"
                                  style={{ cursor: "not-allowed" }}
                                  InputLabelProps={{
                                    shrink: !isNewProduct ? true : undefined,
                                  }}
                                  value={Number(originalPrice)?.toFixed(2)}
                                />
                              </div>
                              <div className="col-12">
                                <TextField
                                  disabled={!enbleEdit}
                                  label="Return Days"
                                  placeholder="0"
                                  className="mt-3 mb-2 "
                                  fullWidth
                                  type="number"
                                  InputLabelProps={{
                                    shrink: !isNewProduct ? true : undefined,
                                  }}
                                  value={returnDays}
                                  onChange={(e) => {
                                    setReturnDays(e.target.value);
                                  }}
                                  helperText={returnDaysError}
                                  error={!!returnDaysError}
                                  onKeyDown={handleKeyDown}
                                />
                              </div>
                              <div className="col-12">
                                {!isNewProduct && (
                                  <TextField
                                    style={{ cursor: "not-allowed" }}
                                    disabled={true}
                                    label="Sku id"
                                    placeholder="0"
                                    className="mt-3 mb-2 "
                                    fullWidth
                                    type="text"
                                    InputLabelProps={{
                                      shrink: !isNewProduct ? true : undefined,
                                    }}
                                    value={skuId}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="product-input-sub-heading">
                                Delivery Charges:
                              </div>
                              <div className="col-12 mt-4">
                                <TextField
                                  disabled={!enbleEdit}
                                  InputLabelProps={{
                                    shrink: !isNewProduct ? true : undefined,
                                  }}
                                  label="Within local"
                                  placeholder="0"
                                  className="mt-3"
                                  fullWidth
                                  type="number"
                                  value={withinLocal}
                                  onKeyDown={handleKeyDown}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const decimalValue =
                                      value.match(/^\d*\.?\d{0,3}$/);

                                    if (decimalValue) {
                                      setWithinLocal(decimalValue[0]);
                                    }
                                  }}
                                  helperText={withinLocalError}
                                  error={!!withinLocalError}
                                  onKeyUp={() => {
                                    if (!withinLocal) {
                                      setWithinLocalError(
                                        "Please enter the Within local."
                                      );
                                    } else {
                                      setWithinLocalError("");
                                    }
                                  }}
                                />

                                <TextField
                                  disabled={!enbleEdit}
                                  label="Within District"
                                  placeholder="0"
                                  style={{ marginTop: "40px" }}
                                  fullWidth
                                  type="number"
                                  value={withinDistrict}
                                  onKeyDown={handleKeyDown}
                                  InputLabelProps={{
                                    shrink: !isNewProduct ? true : undefined,
                                  }}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const decimalValue =
                                      value.match(/^\d*\.?\d{0,3}$/);

                                    if (decimalValue) {
                                      setWithinDistrict(e.target.value);
                                    }
                                  }}
                                  helperText={withinDistrictError}
                                  error={!!withinDistrictError}
                                  onKeyUp={() => {
                                    if (!withinDistrict) {
                                      setWithinDistrictError(
                                        "Please enter the Within District."
                                      );
                                    } else {
                                      setWithinDistrictError("");
                                    }
                                  }}
                                />
                              </div>
                              <div className="col-12 mt-3">
                                <TextField
                                  disabled={!enbleEdit}
                                  label="Within State"
                                  placeholder="0"
                                  className="mt-3"
                                  fullWidth
                                  type="number"
                                  InputLabelProps={{
                                    shrink: !isNewProduct ? true : undefined,
                                  }}
                                  value={withinState}
                                  onKeyDown={handleKeyDown}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const decimalValue =
                                      value.match(/^\d*\.?\d{0,3}$/);

                                    if (decimalValue) {
                                      setWithinState(e.target.value);
                                    }
                                  }}
                                  helperText={withinStateError}
                                  error={!!withinStateError}
                                  onKeyUp={() => {
                                    if (!withinState) {
                                      setWithinStateError(
                                        "Please enter the Within State."
                                      );
                                    } else {
                                      setWithinStateError("");
                                    }
                                  }}
                                />
                                <TextField
                                  disabled={!enbleEdit}
                                  label="Inter State"
                                  placeholder="0"
                                  style={{ marginTop: "40px" }}
                                  fullWidth
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  InputLabelProps={{
                                    shrink: !isNewProduct ? true : undefined,
                                  }}
                                  value={interState}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const decimalValue =
                                      value.match(/^\d*\.?\d{0,3}$/);

                                    if (decimalValue) {
                                      setInterState(e.target.value);
                                    }
                                  }}
                                  helperText={interStateError}
                                  error={!!interStateError}
                                  onKeyUp={() => {
                                    if (
                                      !interState ||
                                      interState.length === 0
                                    ) {
                                      setInterStateError(
                                        "Please enter the Inter State."
                                      );
                                    } else {
                                      setInterStateError("");
                                    }
                                  }}
                                />
                              </div>

                              <div className="col-12  mb-2">
                                <TextField
                                  disabled={!enbleEdit}
                                  label="Available Quantity"
                                  placeholder="0"
                                  style={{ marginTop: "40px" }}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: !isNewProduct ? true : undefined,
                                  }}
                                  onKeyDown={handleKeyDown}
                                  value={availablQuantity}
                                  onChange={(e) => {
                                    setAvailablQuantity(e.target.value);
                                  }}
                                  helperText={availableQuantityError}
                                  error={!!availableQuantityError}
                                  onKeyUp={() => {
                                    if (!availablQuantity) {
                                      setAvailablQuantityError(
                                        "Please enter the Minimum quantity of the Product."
                                      );
                                    } else {
                                      setAvailablQuantityError("");
                                    }
                                  }}
                                  onWheel={handleScroll}
                                />
                                {isNewProduct && requiredFieldIsTrue && (
                                  <Button
                                    type="button"
                                    className="product-submit-btn mt-5"
                                    onClick={() => handleProductSubmit()}
                                  >
                                    Create
                                  </Button>
                                )}
                                {isEditProduct && isProductUpdated && (
                                  <Button
                                    type="button"
                                    className="product-submit-btn mt-5"
                                    onClick={() => handleProductSubmit()}
                                  >
                                    Update
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductManagement;
