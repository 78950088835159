import React, { useState } from "react";
import bottom_wave from "../../../src/Assets/Home/home_bottom_wave.png";
import phone_img from "../../../src/Assets/Home/phone-img.svg";
import phone_img_mob from "../../../src/Assets/Home/home-top-banner-mob.svg";
import play_store from "../../../src/Assets/Home/Play-store.svg";
import { useNavigate } from "react-router-dom";
const isSticky = false;
const isFixedNavRoute = () => false;

const navButtonStyle = {
  backgroundColor: "rgba(235, 129, 40, 1)",
  color: "white",
  outline: "none",
  boxShadow: "none",
  borderRadius: "8px",
  fontSize: "13px",
  padding: "12px 40px",
  border: "1px solid transparent",
  marginLeft: "20px",
  letterSpacing: "0.3px",
  cursor: "default",
  marginTop: '30px'
};

const stickyRemovedStyle = {
  backgroundColor: "rgba(235, 129, 40, 1)",
};

const navbarButtonStyle = {
  backgroundColor: "transparent",
  border: "1px solid transparent",
  outline: "none",
  boxShadow: "none",
  color: "white",
  cursor: "pointer",
};

const loginHoverStyle = {
  color: "black",
};

const registerHoverStyle = {
  color: "black",
};
const TopBanner = () => {
  const navigate = useNavigate();
  const [isLoginHovered, setIsLoginHovered] = useState(false);
  const [isRegisterHovered, setIsRegisterHovered] = useState(false);

  return (
    <div className="top-banner res-576px-mt-60px">
      <div style={{ height: 0, width: 0 }} className="res-576px-d-none">
        <div className="circle_vector">
          <div className="circle_1">
            <div className="circle_2">
              <div className="circle_3"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-banner res-576px-d-none">
        <div>
          <div style={{ fontSize: "36px", color: "white", fontWeight: 1000 }}>
            Get Electronics Appliance Repair with a Tap!
          </div>
          <div
            style={{
              color: "rgba(255, 255, 255, 0.6)",
              fontSize: "14px",
              marginTop: "20px",
            }}
          >
            {" "}
            The 3-Step Fix to your Electronic Appliance Jinx!
          </div>
          <div
            style={{
              color: "rgba(255, 255, 255, 0.6)",
              fontSize: "14px",
              marginTop: "10px",
            }}
          >
            Download App - Register Account - Relax!
          </div>
          <div
            style={{
              color: "rgba(255, 255, 255, 0.6)",
              fontSize: "14px",
              marginTop: "10px",
            }}
          >
            Certified technicians available 24/7, all major brands supported.
          </div>
          <button
            className="sticky-removed-btn"
            onClick={() => {
              navigate("/login");
            }}
            style={{
              cursor: "pointer",
              zIndex: 100,
              position: "relative",
              padding: "12px 40px",
            }}
          >
            Sign in now
          </button>
          <div
            style={{
              textTransform: "uppercase",
              color: "white",
              fontSize: "10px",
              letterSpacing: "0.6px",
              marginTop: "60px",
            }}
          >
            Download our app
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              marginTop: "16px",
              scale: "0.8",
              position: "relative",
              right: "50px",
            }}
          >
            <img alt="play_store" src={play_store} />
          </div>
        </div>
        <div className="phone-img res-576px-d-none">
          <img
            src={phone_img}
            alt="phone_img"
          />
        </div>
      </div>
      <div className="vector res-576px-d-none">
        <img src={bottom_wave} alt="bottom_wave" />
      </div>
      <div className="phone-img res-577px-d-none res-576px-w-fill">
        <img
          src={phone_img_mob}
          alt="phone_img_mob"
          className="res-576px-w-fill"
        />
      </div>
      <div className="res-577px-d-none">
        <div>
          <div
            style={{
              fontSize: "25px",
              color: "black",
              fontWeight: 1000,
              textAlign: "center",
              padding: "40px 20px 20px 20px",
            }}
          >
            Get Electronics Appliance Repair with a Tap!
          </div>
          <div
            style={{
              color: "rgba(0, 0, 0, 1)",
              fontSize: "14px",
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            {" "}
            The 3-Step Fix to your Electronic Appliance Jinx!
          </div>
          <div
            style={{
              color: "rgba(0, 0, 0, 1)",
              fontSize: "14px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            Download App - Register Account - Relax!
          </div>
          <div
            style={{
              color: "rgba(0, 0, 0, 1)",
              fontSize: "14px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            Certified technicians available 24/7, all major brands supported.
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              style={{
                ...navButtonStyle,
                ...(isSticky || isFixedNavRoute() ? {} : stickyRemovedStyle),
              }}
            >
              <div className="d-flex align-items-center">
                <button
                  onClick={() => navigate("/login")}
                  onMouseEnter={() => setIsLoginHovered(true)}
                  onMouseLeave={() => setIsLoginHovered(false)}
                  style={{
                    ...navbarButtonStyle,
                    ...(isLoginHovered && loginHoverStyle),
                  }}
                >
                  Login
                </button>
                <span style={{ color: "white" }}>/</span>
                <button
                  onClick={() => navigate("/commonRegister")}
                  onMouseEnter={() => setIsRegisterHovered(true)}
                  onMouseLeave={() => setIsRegisterHovered(false)}
                  style={{
                    ...navbarButtonStyle,
                    ...(isRegisterHovered && registerHoverStyle),
                  }}
                >
                  Register
                </button>
              </div>
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                textTransform: "uppercase",
                color: "black",
                fontSize: "10px",
                letterSpacing: "0.6px",
                marginTop: "60px",
              }}
            >
              Download our app
            </div>
            <div
              className="res-576px-r-0"
              style={{
                display: "flex",
                gap: "20px",
                marginTop: "16px",
                scale: "0.8",
                position: "relative",
                right: "49px",
              }}
            >
              <img alt="play_store" src={play_store} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
