import React, { useEffect, useState } from "react";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import { Tab, Tabs, InputBase, TextField, Tooltip } from "@mui/material";
import { Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import RectIcon from "../../../Assets/icons/RectIcon";
import ArrowIconHighlighted from "../../../Assets/icons/ArrowIconHighlighted";
import ArrowIconDefault from "../../../Assets/icons/ArrowIconDefault";
import { BsCurrencyRupee } from "react-icons/bs";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getRevenueShareDetail,
  getRevenueShareList,
  getRevenueTransactionsDetail,
  initiateRevenueShare,
} from "../../../redux/actions/revenueShareAction";
import SpinnerLoader2 from "../../../plugins/SpinnerLoader2";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const RevenueManagement = () => {
  const theme = useTheme();
  const mobileMatches = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { revenueShareListData, loading: revenueShareListLoading } =
    useSelector((state) => state.revenueShareListState);
  const { revenueShareDetail, loading: revenueShareDetailLoading } =
    useSelector((state) => state.revenueShareDetailState);
  const { loading: initiateRevenueShareLoading } = useSelector(
    (state) => state.initiateRevenueShareState
  );
  const { revenueTransactionsData, loading: revenueTransactionsLoading } =
    useSelector((state) => state.revenueTransactionsState);
  const [currentTabvalue, setCurrentTabValue] = useState(0);
  const [selectedRevenue, setSelectedRevenue] = useState("");
  const [paymentType, setPaymentType] = useState(1);
  const [inputAmount, setInputAmount] = useState("");
  const [markAsReadBtn, setMarkAsReadBtn] = useState(true);
  const [revenueList, setRevenueList] = useState([]);
  const [revenueShareDetailData, setRevenueShareDetailData] = useState({});
  const [revenueTransactions, setRevenueTransactions] = useState([]);
  const [maxAmount, setMaxAmount] = useState("");
  const [districtAdminTab, setDirstrictAdminTab] = useState(true);
  const [zonalAdminTab, setZonalAdminTab] = useState(false);
  const [superAdminTab, setSuperAdminTab] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const Role = localStorage.getItem("role");
  const handleChangeTab = (currentValue, newValue) => {
    setCurrentTabValue(newValue);
    setSearchInput("");
  };
  const handleRevenue = (id) => {
    setSelectedRevenue(id);
    const payload = {
      user_id: id,
    };
    dispatch(getRevenueShareDetail(payload));
    dispatch(getRevenueTransactionsDetail(payload));
  };
  useEffect(() => {
    const payload = {
      admin: "districtAdmin",
    };
    if (Role === "SuperAdmin") {
      dispatch(getRevenueShareList(payload));
    }
    // eslint-disable-next-line
  }, []);
  const handleInputAmount = (e) => {
    const enteredAmount = parseFloat(e.target.value);
    if (!isNaN(enteredAmount) && enteredAmount <= 0) {
      setInputAmount(enteredAmount);
      setMarkAsReadBtn(false);
    } else if (!isNaN(enteredAmount) && enteredAmount < maxAmount) {
      setInputAmount(enteredAmount);
      setMarkAsReadBtn(true);
    } else if (!isNaN(enteredAmount) && enteredAmount >= maxAmount) {
      setInputAmount(maxAmount);
      setMarkAsReadBtn(true);
      setPaymentType(1);
    } else {
      setInputAmount("");
      setMarkAsReadBtn(false);
    }
  };
  const handleMarkAsPaid = () => {
    const payload = {
      user_id: revenueShareDetailData?.id,
      amount: inputAmount,
    };
    const subPayload = {
      admin: districtAdminTab
        ? "districtAdmin"
        : zonalAdminTab
        ? "zonalAdmin"
        : superAdminTab && "superAdmin",
    };
    dispatch(initiateRevenueShare(payload, subPayload));
    setPaymentType(1);
  };
  useEffect(() => {
    setSelectedRevenue("");
    if (searchInput === "") {
      setRevenueList([]);
    }
    if (Role === "SuperAdmin") {
      if (!revenueShareListLoading && searchInput === "") {
        if (revenueShareListData?.length > 0) {
          setRevenueList(revenueShareListData);
          setSelectedRevenue(revenueShareListData[0].id);
          const payload = {
            user_id: revenueShareListData[0].id,
          };
          dispatch(getRevenueShareDetail(payload));
          dispatch(getRevenueTransactionsDetail(payload));
        } else {
          setRevenueList([]);
        }
      }
    } else {
      dispatch(getRevenueShareDetail());
      dispatch(getRevenueTransactionsDetail());
    }
    // eslint-disable-next-line
  }, [revenueShareListLoading]);
  const searchFilter = (event) => {
    setSearchInput(event.target.value);
    const searchTerm = event.target.value;
    if (districtAdminTab) {
      if (searchTerm === "") {
        const payload = {
          admin: "districtAdmin",
        };
        dispatch(getRevenueShareList(payload));
      } else if (searchTerm) {
        const payload = {
          admin: "districtAdmin",
        };
        dispatch(getRevenueShareList(payload));
        const filteredRevenueShareList = revenueShareListData?.filter(
          (revenue) => {
            const revenue_share_name = revenue?.fullname.toLowerCase().trim();
            return revenue_share_name?.includes(
              searchTerm?.toLowerCase().trim()
            );
          }
        );
        setRevenueList(filteredRevenueShareList);
      }
    } else if (zonalAdminTab) {
      if (searchTerm === "") {
        const payload = {
          admin: "zonalAdmin",
        };
        dispatch(getRevenueShareList(payload));
      } else if (searchTerm) {
        const payload = {
          admin: "zonalAdmin",
        };
        dispatch(getRevenueShareList(payload));
        const filteredRevenueShareList = revenueShareListData?.filter(
          (revenue) => {
            const revenue_share_name = revenue?.fullname.toLowerCase().trim();
            return revenue_share_name?.includes(
              searchTerm?.toLowerCase().trim()
            );
          }
        );
        setRevenueList(filteredRevenueShareList);
      }
    } else if (superAdminTab) {
      if (searchTerm === "") {
        const payload = {
          admin: "superAdmin",
        };
        dispatch(getRevenueShareList(payload));
      } else if (searchTerm) {
        const payload = {
          admin: "superAdmin",
        };
        dispatch(getRevenueShareList(payload));
        const filteredRevenueShareList = revenueShareListData?.filter(
          (revenue) => {
            const revenue_share_name = revenue?.fullname.toLowerCase().trim();
            return revenue_share_name?.includes(
              searchTerm?.toLowerCase().trim()
            );
          }
        );
        setRevenueList(filteredRevenueShareList);
      }
    }
  };
  useEffect(() => {
    setRevenueShareDetailData({});
    setRevenueTransactions([]);
    if (!revenueShareDetailLoading) {
      if (revenueShareDetail) {
        setRevenueShareDetailData(revenueShareDetail);
        setMaxAmount(revenueShareDetail?.due);
        setInputAmount(revenueShareDetail?.due);
      } else {
        setRevenueShareDetailData({});
      }
    }
    if (!revenueTransactionsLoading) {
      if (revenueTransactionsData) {
        setRevenueTransactions(revenueTransactionsData);
      } else {
        setRevenueTransactions([]);
      }
    }
    // eslint-disable-next-line
  }, [revenueShareDetailLoading, revenueTransactionsLoading]);

  const revenueRBAC = (roles) => {
    if (roles.some((role) => role === Role)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <Topbar />
      <div className="row container-fluid">
        <div className="col-2 col-12 p-0 border border-right">
          <Sidebar />
        </div>
        <div className="col-12 user_management" style={{ marginTop: "80px" }}>
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="mt-lg-2 ">
              <i
                className="bi bi-chevron-left "
                style={{
                  fontWeight: "600",
                  fontSize: "21px",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              ></i>
              <text
                className="p-0"
                style={{
                  fontWeight: "600",
                  color: "#1E232C",
                  fontSize: "21px",
                  width: "208px",
                  height: "32px",
                }}
              >
                Revenue {revenueRBAC(["SuperAdmin"]) ? "Management" : ""}
              </text>
            </div>

            <div className="row">
              <div className="col-12">
                {revenueRBAC(["SuperAdmin"]) && (
                  <div
                    className={
                      mobileMatches
                        ? "col-12 d-flex align-items-center justify-content-center"
                        : "col-4 d-flex align-items-center justify-content-center"
                    }
                  >
                    <Tabs
                      className="w-100 bg-white scroll-tabs"
                      value={currentTabvalue}
                      onChange={handleChangeTab}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        style={{
                          /*    fontSize: "12px",
                          fontWeight: "400", */
                          color: "#6A707C",
                        }}
                        label={`District `}
                        onClick={() => {
                          setDirstrictAdminTab(true);
                          setZonalAdminTab(false);
                          setSuperAdminTab(false);
                          const payload = {
                            admin: "districtAdmin",
                          };
                          dispatch(getRevenueShareList(payload));
                        }}
                      />
                      <Tab
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#6A707C",
                        }}
                        label={`Zonal`}
                        onClick={() => {
                          setDirstrictAdminTab(false);
                          setZonalAdminTab(true);
                          setSuperAdminTab(false);
                          const payload = {
                            admin: "zonalAdmin",
                          };
                          dispatch(getRevenueShareList(payload));
                        }}
                      />
                      <Tab
                        style={{
                          /*   fontSize: "12px",
                          fontWeight: "400", */
                          color: "#6A707C",
                        }}
                        label={`Super`}
                        onClick={() => {
                          setDirstrictAdminTab(false);
                          setZonalAdminTab(false);
                          setSuperAdminTab(true);
                          const payload = {
                            admin: "superAdmin",
                          };
                          dispatch(getRevenueShareList(payload));
                        }}
                      />
                    </Tabs>
                  </div>
                )}
              </div>
            </div>
            <div className="card-vh-container">
              {revenueRBAC(["SuperAdmin"]) && (
                <div className="card-vh">
                  <div
                    className="p-2 search-top-sticky"
                    style={{ display: "flex", width: "-webkit-fill-available" }}
                  >
                    <div
                      style={{
                        listStyle: "none",
                        width: "-webkit-fill-available",
                      }}
                    >
                      <Paper
                        elevation={0}
                        className="w-100 "
                        component="form"
                        sx={{ display: "flex", alignItems: "center" }}
                        style={{
                          width: "100%",
                          height: "42px",
                          borderRadius: "4px",
                          border: "1px solid #FFE5CC",
                        }}
                      >
                        <IconButton type="button" aria-label="search">
                          <SearchIcon />
                        </IconButton>
                        <InputBase
                          value={searchInput}
                          sx={{ flex: 1 }}
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "14px",
                          }}
                          placeholder="Search by admin name"
                          onChange={(e) => searchFilter(e)}
                        />
                      </Paper>
                    </div>
                  </div>
                  <div className="p-2 pt-0 pb-0">
                    {revenueList?.length > 0 &&
                      revenueList.map((revenue) => {
                        return (
                          <div>
                            <div style={{ height: 0 }}>
                              {revenue?.due > 0 && (
                                <div className="due-indicator"></div>
                              )}
                            </div>
                            <div
                              key={revenue.id}
                              style={{
                                height: "75px",
                                cursor: "pointer",
                                background:
                                  selectedRevenue === revenue.id
                                    ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                    : undefined,
                                color:
                                  selectedRevenue === revenue.id
                                    ? "rgb(235, 129, 40)"
                                    : "black",
                              }}
                              className={`border rounded-3 mt-2 d-flex ${
                                selectedRevenue === revenue.id &&
                                "boder-rgb(235, 129, 40)"
                              }`}
                              onClick={() => handleRevenue(revenue.id)}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  top: "8px",
                                  right: "1px",
                                  display:
                                    selectedRevenue !== revenue.id && "none",
                                }}
                              >
                                <RectIcon />
                              </div>
                              <div
                                className="p-2 d-flex"
                                style={{
                                  width: "-webkit-fill-available",
                                  marginLeft:
                                    selectedRevenue !== revenue.id && "5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  <div
                                    className="d-flex align-items-center"
                                    style={{
                                      height: "43px",
                                      width: "43px",
                                      color: "#EB8128",
                                      borderRadius: "100%",
                                      background: "#FFDDBC",
                                      border: "1px solid #EB8128",
                                      justifyContent: "center",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {revenue?.fullname?.split("")[0]}
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {revenue.fullname}
                                    </div>
                                    <div
                                      style={{
                                        color: "#000",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {revenue.district}
                                    </div>
                                    <div
                                      style={{
                                        color: "#000",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <Tooltip title={revenue?.emailid}>
                                        <span>
                                          {revenue?.emailid?.length <= 20
                                            ? revenue?.emailid
                                            : `${revenue?.emailid?.slice(
                                                0,
                                                20
                                              )}...`}
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{ position: "relative", top: "20px" }}
                                >
                                  {selectedRevenue === revenue.id ? (
                                    <ArrowIconHighlighted />
                                  ) : (
                                    <ArrowIconDefault />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {revenueShareListLoading && (
                      <div
                        className="mt-5"
                        style={{ justifyContent: "center" }}
                      >
                        <SpinnerLoader2 />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="card-vh">
                {revenueShareDetailLoading ? (
                  <div className="mt-5">
                    {revenueShareListLoading ? "" : <SpinnerLoader2 />}
                  </div>
                ) : (
                  (Role !== "SuperAdmin" || revenueList.length > 0) && (
                    <div className="p-3 pt-0" style={{ fontSize: "14px" }}>
                      <div
                        className="d-flex align-items-center"
                        style={{
                          height: "40px",
                          fontWeight: "600",
                          position: "sticky",
                          top: "0px",
                          backgroundColor: "#fff",
                          paddingTop: "5px",
                        }}
                      >
                        {revenueRBAC(["SuperAdmin"]) &&
                          revenueShareDetailData?.fullname + " - "}
                        Revenue Details
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div style={{ fontSize: "14px" }}>
                          Total Revenue Earned:
                        </div>
                        <div
                          className="d-flex align-items-center mt-2"
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          <BsCurrencyRupee className="d-flex align-items-center" />
                          {revenueShareDetailData?.total_revenue}
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="mt-2" style={{ fontSize: "14px" }}>
                          Total Revenue Shared:
                        </div>
                        <div
                          className="d-flex align-items-center mt-2"
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          <BsCurrencyRupee className="d-flex align-items-center" />
                          {revenueShareDetailData?.total_amount_paid}
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="mt-2" style={{ fontSize: "14px" }}>
                          Total Revenue Due:
                        </div>
                        <div
                          className="d-flex align-items-center mt-2"
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          <BsCurrencyRupee className="d-flex align-items-center" />
                          {revenueShareDetailData?.due}
                        </div>
                      </div>
                      {revenueShareDetailData?.due > 0 &&
                        revenueRBAC(["SuperAdmin"]) && (
                          <div>
                            <div className="custom-hr-revenue mt-3 mb-3"></div>
                            <div className="mt-3 mb-2">Make Payment</div>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={paymentType}
                            >
                              <div
                                className="d-flex align-items-center"
                                style={{ justifyContent: "space-between" }}
                              >
                                <div className="d-flex align-items-center">
                                  <div>
                                    <FormControlLabel
                                      value={1}
                                      control={<Radio />}
                                      onClick={() => {
                                        setPaymentType(1);
                                        setInputAmount(maxAmount);
                                        setMarkAsReadBtn(true);
                                      }}
                                    />
                                  </div>
                                  <div>Full Payment</div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <FormControlLabel
                                      value={2}
                                      control={<Radio />}
                                      onClick={() => {
                                        setPaymentType(2);
                                        setInputAmount("");
                                        setMarkAsReadBtn(false);
                                      }}
                                    />
                                  </div>
                                  <div>Partial Payment</div>
                                </div>
                              </div>
                            </RadioGroup>
                            <div
                              className="d-flex align-items-center mt-3 mb-3"
                              style={{ justifyContent: "center" }}
                            >
                              <TextField
                                value={inputAmount}
                                type="number"
                                label="Enter the Amount(₹)"
                                style={{
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  width: "100%",
                                }}
                                placeholder="Enter the Amount"
                                onChange={(e) => handleInputAmount(e)}
                                disabled={paymentType === 1 ? true : false}
                              />
                            </div>
                            <div className="custom-hr-revenue mt-3 mb-3"></div>
                            <button
                              className={`d-flex align-items-center gap-2 mark-as-paid-btn ${
                                !markAsReadBtn || revenueShareDetailLoading
                                  ? "disabled"
                                  : ""
                              }`}
                              style={{
                                justifyContent: "center",
                                textTransform: "none",
                              }}
                              onClick={() => {
                                if (
                                  markAsReadBtn ||
                                  !initiateRevenueShareLoading
                                ) {
                                  handleMarkAsPaid();
                                }
                              }}
                            >
                              {initiateRevenueShareLoading && (
                                <div>
                                  <SpinnerLoader2 />
                                </div>
                              )}{" "}
                              <div className={`d-flex align-items-center`}>
                                {initiateRevenueShareLoading
                                  ? "Please wait!"
                                  : "Mark as Paid"}
                              </div>
                            </button>
                          </div>
                        )}
                    </div>
                  )
                )}
              </div>
              <div className="card-vh">
                {revenueTransactionsLoading ? (
                  <div className="mt-5">
                    {revenueShareListLoading ? "" : <SpinnerLoader2 />}
                  </div>
                ) : (
                  (Role !== "SuperAdmin" || revenueList.length > 0) && (
                    <div className="p-3 pt-0" style={{ fontSize: "14px" }}>
                      <div
                        className="d-flex align-items-center"
                        style={{
                          height: "40px",
                          fontWeight: "600",
                          position: "sticky",
                          top: "0px",
                          backgroundColor: "#fff",
                          paddingTop: "5px",
                        }}
                      >
                        Payment Logs
                      </div>
                      <div>
                        {revenueTransactions?.length > 0 &&
                          revenueTransactions.map((transaction) => {
                            const formatedDate = moment(
                              transaction.created_at
                            ).format("MMM DD YYYY, hh:mm a");
                            return (
                              <div
                                className="d-flex align-items-center mt-2"
                                style={{ justifyContent: "space-between" }}
                              >
                                <div style={{ color: "#6A707C" }}>
                                  {formatedDate}
                                </div>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ fontWeight: 600 }}
                                >
                                  <BsCurrencyRupee className="d-flex align-items-center" />
                                  {transaction.amount_paid}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueManagement;
