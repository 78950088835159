import { createSlice } from "@reduxjs/toolkit";


const ParticipationTechCountSlice = createSlice({
    name: 'ParticipationTechCount',
    initialState: {
        loading: false,
        ParticipationTechCountData: [],
        error: null,
        ParticipationTechstatusCode: null,
        successMsg: null,
        errorMsg: null,
        totalCount: 0,

    },
    reducers: {
        ParticipationTechCountRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        ParticipationTechCountSuccess(state, action) {
            return {
                ...state,
                loading: true,
                ParticipationTechCountData: action?.payload,
                // successMsg: action.payload.data.message,
                // totalCount: action.payload.data?.totalCount,
                errorMsg: null,
                ParticipationTechstatusCode: 'success'
            }
        },
        ParticipationTechCountFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
                ParticipationTechstatusCode: 'error',
                errorMsg: action?.payload?.error?.message,
                // totalCount: 0,
                ParticipationTechCountData: [],
                successMsg: null
            }
        },
        clearParticipationTechStatusCode(state, action) {
            return {
                ...state,
                ParticipationTechstatusCode: null
            }
        }

    }
});

const { actions, reducer } = ParticipationTechCountSlice;

export const { ParticipationTechCountRequest, ParticipationTechCountFail, ParticipationTechCountSuccess, clearParticipationTechStatusCode } = actions

export default reducer;
