import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Popover } from "react-tiny-popover";
import { FaCalendarDays } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { getTechnicianDashboard } from "../../redux/actions/technicainDashboardAction";
import {
  getDistrictAdminDashboard,
  getSuperAdminDashboard,
  getZonalAdminDashboard,
} from "../../redux/actions/adminDashboard";

const DatePickerDashboard = () => {
  const dispatch = useDispatch();
  const [viewDatePickerPopover, setViewDatePickerPopover] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [endDateTrigger, setEndDateTrigger] = useState("");
  const [startDateTrigger, setStartDateTrigger] = useState("");
  const [selectedDateRangeId, setSelectedDateRangeId] = useState(0);
  const role = localStorage.getItem("role");
  const currentDate = new Date();

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    setEndDateTrigger(ranges.selection.endDate);
    setStartDateTrigger(ranges.selection.startDate);
  };
  useEffect(() => {
    const initialStartDate = moment()
      .subtract(6, "days")
      .startOf("day")
      .toDate();
    const initialEndDate = moment().endOf("day").toDate();
    setDateRange([
      {
        startDate: initialStartDate,
        endDate: initialEndDate,
        key: "selection",
      },
    ]);
    setEndDateTrigger(initialEndDate);
    setStartDateTrigger(initialStartDate);
  }, []);

  useEffect(() => {
    const payload = {
      startDate: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
      endDate: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
    };
    if (role === "Technician") {
      dispatch(getTechnicianDashboard(payload));
    } else if (role === "DistrictAdmin") {
      dispatch(getTechnicianDashboard(payload));
      dispatch(getDistrictAdminDashboard(payload));
    } else if (role === "ZonalAdmin") {
      dispatch(getTechnicianDashboard(payload));
      dispatch(getZonalAdminDashboard(payload));
    } else if (role === "SuperAdmin") {
      dispatch(getTechnicianDashboard(payload));
      dispatch(getSuperAdminDashboard(payload));
    }
    // eslint-disable-next-line
  }, [endDateTrigger, startDateTrigger]);

  const ranges = [
    {
      id: 1,
      range: "Last Week",
    },
    {
      id: 2,
      range: "Last Month",
    },
    {
      id: 3,
      range: "Last Two Months",
    },
    {
      id: 4,
      range: "Last Six Months",
    },
    {
      id: 5,
      range: "Last Year",
    },
  ];

  const handlePredefinedRange = (range) => {
    let startDate, endDate;
    setSelectedDateRangeId(range.id);
    switch (range.range) {
      case "Last Week":
        startDate = moment().subtract(1, "week").startOf("week").toDate();
        endDate = moment().subtract(1, "week").endOf("week").toDate();
        break;
      case "Last Month":
        startDate = moment().subtract(1, "month").startOf("month").toDate();
        endDate = moment().subtract(1, "month").endOf("month").toDate();
        break;
      case "Last Two Months":
        startDate = moment().subtract(2, "months").startOf("month").toDate();
        endDate = moment().subtract(1, "month").endOf("month").toDate();
        break;
      case "Last Six Months":
        startDate = moment().subtract(6, "months").startOf("month").toDate();
        endDate = moment().subtract(1, "month").endOf("month").toDate();
        break;
      case "Last Year":
        startDate = moment().subtract(1, "year").startOf("year").toDate();
        endDate = moment().subtract(1, "year").endOf("year").toDate();
        break;
      default:
        startDate = moment().subtract(1, "month").startOf("month").toDate();
        endDate = moment().subtract(1, "month").endOf("month").toDate();
        break;
    }

    setDateRange([{ startDate, endDate, key: "selection" }]);
    setEndDateTrigger(endDate);
    setStartDateTrigger(startDate);
  };
  const clearData = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setEndDateTrigger("");
    setStartDateTrigger("");
    setSelectedDateRangeId(0);
  };
  return (
    <div>
      <Popover
        isOpen={viewDatePickerPopover}
        positions={["top", "bottom", "left", "right"]}
        content={
          <div style={{ boxShadow: "0 0 10px 1px #80808091" }}>
            <DateRangePicker
              ranges={dateRange}
              onChange={handleSelect}
              maxDate={currentDate}
            />
            <div className="date-range-quick-access">
              {ranges?.map((range) => {
                return (
                  <div
                    className={`range ${range.id === selectedDateRangeId && "active"
                      }`}
                    onClick={() => handlePredefinedRange(range)}
                  >
                    {range?.range}
                  </div>
                );
              })}
              <button
                className="clear-button-dashboard"
                onClick={() => clearData()}
              >
                Clear
              </button>{" "}
            </div>
          </div>
        }
        onClickOutside={() => setViewDatePickerPopover(!viewDatePickerPopover)}
      >
        <div
          onClick={() => setViewDatePickerPopover(!viewDatePickerPopover)}
          className="selected-date"
        >
          <div>{moment(dateRange[0].startDate).format("DD-MM-YYYY")}</div>
          {moment(dateRange[0].startDate).format("DD-MM-YYYY") !==
            moment(dateRange[0].endDate).format("DD-MM-YYYY") && <div>-</div>}
          {moment(dateRange[0].startDate).format("DD-MM-YYYY") !==
            moment(dateRange[0].endDate).format("DD-MM-YYYY") && (
              <div>{moment(dateRange[0].endDate).format("DD-MM-YYYY")}</div>
            )}
          <FaCalendarDays style={{ color: "rgb(235, 129, 40)" }} />
        </div>
      </Popover>
    </div>
  );
};

export default DatePickerDashboard;
