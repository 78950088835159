import { InputAdornment, TextField, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import PercentIcon from "@mui/icons-material/Percent";
import { useDispatch, useSelector } from "react-redux";
import { getRevenueConfig } from "../../../redux/actions/revenueConfigAction";
import Swal from "sweetalert2";
import Api from "../../../service/services";

const ConfigureRevenue = () => {
  const { revenueConfig } = useSelector((state) => state.revenueConfigState);
  const dispatch = useDispatch();
  const [districtAdminShare, setDistrictAdminShare] = useState(0);
  const [zonalAdminShare, setZonalAdminShare] = useState(0);
  const [superAdminShare, setSuperAdminShare] = useState(0);
  useEffect(() => {
    dispatch(getRevenueConfig());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setDistrictAdminShare(revenueConfig?.dist_admin_share);
    setZonalAdminShare(revenueConfig?.zonal_admin_share);
    setSuperAdminShare(revenueConfig?.super_admin_share);
  }, [revenueConfig]);
  const onsubmit = (id) => {
    const payload = {
      dist_admin_share: districtAdminShare,
      super_admin_share: superAdminShare,
      zonal_admin_share: zonalAdminShare,
    };
    Api.updateRevenueConfig(id, payload)
      .then((response) => {
        Swal.fire({
          text: response,
        });
      })
      .catch((err) => {});
  };
  const mobileView = useMediaQuery("(min-width: 200px) and (max-width: 799px)");
  return (
    <>
      <div className="container-fluid font-family-Arial">
        <div className="row mt-3">
          <div className="col-lg-6 col-12">
            <div
              className="border border rounded p-lg-4 pb-sm-3 mb-sm-3 p-2 "
              style={{
                margin: mobileView && "0rem  auto 1rem auto",
              }}
            >
              <p
                className="m-0"
                style={{
                  fontSize: "14px",
                  color: "rgb(235, 129, 40)",
                  fontWeight: "500",
                }}
              >
                <b> District Admin Share / SR</b>
              </p>
              <p
                className="m-0 mt-2"
                style={{
                  color: "#1E232C",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Commission per SR will be auto-calculated based on this
                configurations and apply which is higher
              </p>
              <div
                className="row mt-3"
                style={{
                  display: mobileView && "flex",
                  flexDirection: mobileView && "row",
                }}
              >
                <div className="col text-start">
                  <p
                    className="m-0"
                    style={{
                      color: "#1E232C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Commission / SR
                  </p>
                  <p
                    className="m-0"
                    style={{ color: "#6A707C", fontSize: "12px" }}
                  >
                    Wallet configuration
                  </p>
                </div>
                <div className="col-4 text-end">
                  <TextField
                    style={{ height: "16px" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={districtAdminShare}
                    onChange={(e) => {
                      setDistrictAdminShare(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-12"
            style={{ margin: mobileView && "1rem auto 0rem auto" }}
          >
            <div className="border border rounded p-lg-4 pb-sm-3 p-2">
              <p
                className="m-0"
                style={{
                  color: "rgb(235, 129, 40)",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Zonal Admin Share / SR
              </p>
              <p
                className="m-0 mt-2"
                style={{
                  color: "#1E232C",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Commission per SR will be auto-calculated based on this
                configurations and apply which is higher
              </p>
              <div className="row mt-3">
                <div className="col text-start">
                  <p
                    className="m-0"
                    style={{
                      color: "#1E232C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Commission / SR
                  </p>
                  <p
                    className="m-0"
                    style={{ color: "#6A707C", fontSize: "12px" }}
                  >
                    Wallet configuration
                  </p>
                </div>
                <div className="col-4 text-end">
                  <TextField
                    style={{ height: "16px" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={zonalAdminShare}
                    onChange={(e) => {
                      setZonalAdminShare(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div
            className="col-lg-6 col-12"
            style={{ margin: mobileView && "1rem auto 2rem auto" }}
          >
            <div className="border border rounded p-lg-4 pb-sm-3 p-2">
              <p
                className="m-0"
                style={{
                  fontSize: "14px",
                  color: "rgb(235, 129, 40)",
                  fontWeight: "600",
                }}
              >
                Super Admin Share / SR
              </p>
              <p
                className="m-0 mt-2"
                style={{
                  color: "#1E232C",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Commission per SR will be auto-calculated based on this
                configurations and apply which is higher
              </p>
              <div className="row mt-3">
                <div className="col text-start">
                  <p
                    className="m-0"
                    style={{
                      color: "#1E232C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Commission / SR
                  </p>
                  <p
                    className="m-0"
                    style={{ color: "#6A707C", fontSize: "12px" }}
                  >
                    Wallet configuration
                  </p>
                </div>
                <div className="col-4 text-end">
                  <TextField
                    style={{ height: "16px" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PercentIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={superAdminShare}
                    onChange={(e) => {
                      setSuperAdminShare(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="border border rounded p-lg-4 p-2">
              <p
                className="m-0"
                style={{
                  fontSize: "14px",
                  color: "rgb(235, 129, 40)",
                  fontWeight: "600",
                }}
              >
                Association Share / SR
              </p>
              <p
                className="m-0 mt-2"
                style={{
                  color: "#1E232C",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Commission per SR will be auto-calculated based on this
                configurations and apply which is higher
              </p>
              <div className="row mt-3">
                <div className="col text-start">
                  <p
                    className="m-0"
                    style={{
                      color: "#1E232C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Remaining %
                  </p>
                  <p
                    className="m-0"
                    style={{
                      color: "#6A707C",
                      fontSize: "12px",
                      visibility: "hidden",
                    }}
                  >
                    ---
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => onsubmit(revenueConfig?.id)}
          className="mt-3 mb-3"
          style={{
            background: "rgb(235, 129, 40)",
            color: "#fff",
            border: "none",
            outline: "none",
            borderRadius: "5px",
            height: "40px",
            width: "120px",
          }}
        >
          Save
        </button>
      </div>
    </>
  );
};
export default ConfigureRevenue;
