import React, { useEffect, useState } from "react";
import Topbar from "../topbar";
import Sidebar from "../Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import OtpInput from "react-otp-input";
import Api from "../../service/services";

function DeleteAccountOtp() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const [intervalId, setIntervalId] = useState(null);
  const { deleteOtpResponse } = location.state || {};
  const [remainingTime, setRemainingTime] = useState(
    deleteOtpResponse?.data?.otp_expiry_time * 60
  );
  useEffect(() => {
    const isMessageShown = localStorage.getItem("isMessageShown");

    if (!isMessageShown) {
      Swal.fire({
        text: deleteOtpResponse?.message,
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });

      localStorage.setItem("isMessageShown", "true");
    } else {
      localStorage.removeItem("isMessageShown");
      navigate(-1);
    }
    // eslint-disable-next-line
  }, []);

  const Resendotp = (e) => {
    e.preventDefault();
    let payload = {
      purpose: "delete_account",
    };
    setOtp("");

    Api?.accountDeleteSendOtpApi(payload).then((response) => {
      if (response?.error) {
        Swal?.fire({
          text: response?.error?.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          text: response?.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        }).then(() => {
          const otpExpiryTime = response?.data?.otp_expiry_time * 60;
          setRemainingTime(otpExpiryTime);
          if (intervalId) {
            clearInterval(intervalId);
          }
          const newIntervalId = setInterval(() => {
            setRemainingTime((prevTime) => {
              if (prevTime <= 0) {
                clearInterval(newIntervalId);
                return 0;
              }
              return prevTime - 1;
            });
          }, 1000);
          setIntervalId(newIntervalId);
        });
      }
    });
  };

  useEffect(() => {
    if (remainingTime === 0) {
      Swal.fire({
        text: "OTP timeout. Please resend the OTP.",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
    const countdownInterval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(countdownInterval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [remainingTime]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const onSubmitData = (e) => {
    e.preventDefault();
    let data = {
      purpose: "delete_account",
      otp: parseInt(otp),
    };
    if (otp.length === 4) {
      Api.accountDeleteSendOtpVerificationApi(data).then((response) => {
        if (response?.error) {
          Swal?.fire({
            text: response?.error?.message,
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          Swal?.fire({
            text: response?.message,
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          }).then(() => {
            Swal.fire({
              text: "Are you sure you want to delete your account? This action cannot be undone.Your account and all associated data will be permanently deleted.Please confirm if you wish to proceed.",
              confirmButtonText: "Confirm",
              cancelButtonText: "Cancel",
              showCancelButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                Api.accountDeleteApi(data)?.then((deleteAccountResponse) => {
                  if (deleteAccountResponse?.error?.message) {
                    Swal?.fire({
                      text: deleteAccountResponse?.error?.message,
                      timer: 2000,
                      timerProgressBar: true,
                    });
                  } else {
                    Swal.fire({
                      text: deleteAccountResponse?.message,
                      timer: 2000,
                      timerProgressBar: true,
                    }).then(() => {
                      navigate("/Login");
                    });
                  }
                });
              } else {
                navigate("/customerDashboard");
              }
            });
          });
        }
      });
    } else {
      Swal?.fire({
        text: "Please Enter Your OTP",
        timer: 2000,
        timerProgressBar: true,
      });
    }
  };

  useEffect(() => {
    if (!deleteOtpResponse?.message) {
      navigate(-1);
    }
  }, [deleteOtpResponse?.message, navigate]);

  if (!deleteOtpResponse?.message) {
    return null;
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <Topbar />
      <div
        className="row container-fluid"
        style={{ overflow: "hidden", paddingRight: "0px" }}
      >
        <div className="col-2 col-12 p-0 border border-right">
          <Sidebar />
        </div>
        <div
          className="col-12 user_management"
          style={{ paddingRight: "0px", marginTop: "80px" }}
        >
          <div className="mt-lg-2">
            <i
              className="bi bi-chevron-left "
              style={{
                fontWeight: "600",
                fontSize: "21px",
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            ></i>
            <text
              className="p-0"
              style={{
                fontWeight: "600",
                color: "#1E232C",
                fontSize: "21px",
                width: "208px",
                height: "32px",
              }}
            >
              Delete Account
            </text>
            <div className="sub-head">
              Enter the verification code we've sent to your email.
            </div>
            <div style={{ marginLeft: "10px" }}>
              <div style={{ marginLeft: "7px" }}>
                <OtpInput
                  className="otp_input"
                  inputStyle={{
                    width: "90px",
                    height: "50px",
                    borderRadius: "8px",
                    outline: "none",
                    outlineColor: "#E8ECF4",
                    border: "1px solid #E8ECF4",
                    fontSize: "22px",
                    fontWeight: "700",
                  }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  isInputNum={true}
                  renderSeparator={
                    <span className="separator" style={{ color: "white" }}>
                      ___
                    </span>
                  }
                  renderInput={(props) => (
                    <input
                      {...props}
                      placeholder="x"
                      className="custom-placeholder-otp"
                    />
                  )}
                />
              </div>
              <div style={{ marginLeft: "8px", marginTop: "15px" }}>
                <div
                  className=""
                  style={{
                    display: "flex",
                    alignItems: "center",

                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: "13px",
                      marginLeft: "5px",
                    }}
                  >
                    <span>Please enter the OTP within</span>
                    <span style={{ color: "#EB8128", padding: "0px 3px" }}>
                      {remainingTime > 0
                        ? `${Math.floor(remainingTime / 60)
                            .toString()
                            .padStart(2, "0")}:${(remainingTime % 60)
                            .toString()
                            .padStart(2, "0")}`
                        : "00:00"}
                    </span>
                    Didn’t get the OTP yet?
                    <span
                      type="button"
                      onClick={Resendotp}
                      style={{
                        fontWeight: "300",
                        fontSize: "13px",
                        color: "#EB8128",
                        padding: "0px 4px",
                      }}
                    >
                      Resend OTP
                    </span>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginLeft: "5px" }}>
                <div className="col">
                  <div className="d-grid gap-2">
                    {otp.length === 4 && (
                      <button
                        className="btn otp_btn"
                        type="submit"
                        onClick={onSubmitData}
                        style={{
                          height: "50px",
                          width: "140px",
                          color: "white",
                          backgroundColor: "#EB8128",
                          marginTop: "10px",
                          marginLeft: "0px",
                        }}
                      >
                        Verify Code
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteAccountOtp;
