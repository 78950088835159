import { Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const SearchableDropdown = ({
  options,
  label,
  id,
  selectedVal,
  handleChange,
  type,
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectOption = (option) => {
    const selectedAdmin = {
      id: option.id,
      zone_name: `${option[label]}`,
    };
    setQuery("");
    handleChange(selectedAdmin);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;
    return "";
  };

  const filter = (options) => {
    return options.filter(
      (option) =>
        option[label]?.toLowerCase().includes(query?.toLowerCase())
    );
  };

  return (
    <div className="dropdown-input" ref={inputRef}>
      <div className="control" onClick={toggleDropdown}>
        <div className="selected-value">
          <div className="custom-label">
            <div className="label">{type}</div>
          </div>
          <input
            style={{
              height: "52px",
              borderRadius: "5px",
              paddingLeft: "13px",
              paddingRight: "13px",
              cursor: "pointer",
            }}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            readOnly
            onChange={(e) => {
              handleChange(null);
              setQuery(e.target.value);
            }}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`} style={{ cursor: 'pointer' }}></div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {filter(options).map((option, index) => (
          <div
            onClick={() => selectOption(option)}
            className={`option ${option[label] === selectedVal ? "selected" : ""}`}
            key={`${id}-${index}`}
          >
            <Tooltip title={`${option[label]}`} arrow>
              <span className="text-start text-content-elipsis">
                {`${option[label]}`}
              </span>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchableDropdown;
