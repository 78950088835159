import React, { useEffect, useState } from "react";
import { TextField, useMediaQuery } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Api from "../../../service/services";
import Swal from "sweetalert2";

const ConfigureService = () => {
  const [autoAssignConfigList, setAutoAssignConfigList] = useState({});
  const [maxResponseTime, setMaxResponseTime] = useState("");
  const [autoAssignLimit, setAutoAssignLimit] = useState("");
  const [maxRadius, setMaxRadius] = useState("");
  const mobileView = useMediaQuery("(min-width: 200px) and (max-width: 799px)");
  useEffect(() => {
    Api.autoAssignConfig().then((res) => {
      setAutoAssignConfigList(res);
      setMaxResponseTime(res?.max_response_time);
      setAutoAssignLimit(res?.auto_assign_limit);
      setMaxRadius(res?.max_radius);
    });
  }, []);
  const onsubmit = (id) => {
    const payload = {
      max_response_time: maxResponseTime,
      auto_assign_limit: autoAssignLimit,
      max_radius: maxRadius,
    };
    Api.updateAutoAssign(id, payload)
      .then((res) => {
        Swal.fire({
          text: res,
        });
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="container-fluid w-100 font-family-Arial">
        <div>
          <div className="row w-100">
            <div className="col-lg-8 col-12">
              <div className="row">
                <div
                  className="col-9"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <>
                    <p
                      className="m-0"
                      style={{
                        color: "rgb(235, 129, 40)",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Waiting Time
                    </p>
                    <p
                      className="m-0 mt-2"
                      style={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Set the frequency of waiting time / re-assign of each SR
                    </p>
                  </>
                  <div className="col-3 mt-3 mb-3 w-100">
                    <TextField
                      id="outlined-adornment-weight"
                      label="Time"
                      aria-describedby="outlined-weight-helper-text"
                      InputProps={{
                        "aria-label": "weight",
                        endAdornment: (
                          <InputAdornment position="start">
                            Minutes
                          </InputAdornment>
                        ),
                      }}
                      onChange={(val) => setMaxResponseTime(val.target.value)}
                      value={maxResponseTime}
                      style={{ width: mobileView ? "10rem" : "200px" }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="mt-2 mb-2"
                style={{ border: "1px dotted #000", borderWidth: "1px" }}
              ></div>

              <p
                className="m-0 mt-4"
                style={{
                  color: "rgb(235, 129, 40)",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Boundary Setting
              </p>
              <p
                className="m-0 mt-2"
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Set the search boundry for finding the technicians. S3 will look
                for the technicians within the given radius from the SR
                location.
              </p>
              <div className="row mt-3 mb-4 w-100">
                <div className="col-3 mt-3 mb-3 w-100">
                  <TextField
                    label="Radius"
                    id="outlined-adornment-weight"
                    size="sm"
                    value={maxRadius}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">Kms</InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (/^[0-9]*$/.test(value)) {
                        setMaxRadius(value);
                      }
                    }}
                    style={{ width: mobileView ? "10rem" : "200px" }}
                  />
                </div>
              </div>
              <div
                className="mt-2 mb-2"
                style={{ border: "1px dotted #000", borderWidth: "1px" }}
              ></div>
              <p
                className="m-0 mt-4"
                style={{
                  color: "rgb(235, 129, 40)",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Hierarchy Setting
              </p>
              <p style={{ fontWeight: "bold", marginTop: "10px" }}>
                Set Auto Assignment Limit
              </p>
              <p
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Set how many times a Service Request can be auto assigned to
                technicians. After reaching the limit, the request will be
                notified to the district admin for action.
              </p>
              <div className="row mt-3 mb-4 w-100">
                <div className="col-3 mt-3 mb-3 w-100">
                  <TextField
                    label="Assign"
                    size="sm"
                    value={autoAssignLimit}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          Person(s)
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (/^[1-9]*$/.test(value)) {
                        setAutoAssignLimit(value);
                      }
                    }}
                    style={{ width: mobileView ? "10rem" : "200px" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => onsubmit(autoAssignConfigList?.id)}
            className="mt-3 mb-3"
            style={{
              background: "rgb(235, 129, 40)",
              color: "#fff",
              border: "none",
              outline: "none",
              borderRadius: "5px",
              height: "40px",
              width: "120px",
            }}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};
export default ConfigureService;
