import React from "react";

class WeekDates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weekDates: [],
      currentWeek: 0,
    };
  }

  componentDidMount() {
    this.calculateWeekDates(0);
  }

  calculateWeekDates = (weekOffset) => {
    const today = new Date();
    today.setDate(today.getDate() + weekOffset * 7);

    const dayOfWeek = today.getDay();

    const offset = dayOfWeek === 0 ? 1 : 8 - dayOfWeek;
    const monday = new Date(today);
    monday.setDate(today.getDate() + offset);

    const weekDates = [];
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    for (let i = 0; i < 7; i++) {
      const date = new Date(monday);
      date.setDate(monday.getDate() + i);
      weekDates.push({
        date: date.toISOString().slice(0, 10),
        dayName: dayNames[date.getDay()],
      });
    }

    this.setState({ weekDates, currentWeek: weekOffset });
  };

  handlePrevWeek = () => {
    const { currentWeek } = this.state;
    this.calculateWeekDates(currentWeek - 1);
  };

  handleNextWeek = () => {
    const { currentWeek } = this.state;
    this.calculateWeekDates(currentWeek + 1);
  };

  render() {
    const { weekDates, currentWeek } = this.state;
    return (
      <div>
        <h3>Week {currentWeek}</h3>
        <button onClick={this.handlePrevWeek}>Previous Week</button>
        <button onClick={this.handleNextWeek}>Next Week</button>
        <h3>Upcoming Monday to Sunday Week Dates</h3>
        <ul>
          {weekDates.map((dateObj, index) => (
            <li key={index}>
              <strong>{dateObj.dayName}</strong>: {dateObj.date}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default WeekDates;
