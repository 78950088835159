import * as React from 'react';
import { AppBar, Tabs, Tab, Box, MenuItem, Select, FormControl } from '@mui/material';
import TotalProducts from './TotalProducts';
import Sidebar from '../../Sidebar';
import Topbar from '../../topbar';
import Api from '../../../service/services';

const AllCategories = () => {
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [appliances, setAppliances] = React.useState([]);

    React.useEffect(() => {
        Api.getAppliances().then((res) => {
            console.log("Appliances", res.data)
            setAppliances(res.data)
        })
    }, [])

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const TabPanel = ({ value, index, children }) => {
        return (
            <div role="tabpanel" hidden={value !== index}>
                {value === index && <Box p={3}>{children}</Box>}
            </div>
        );
    };

    return (
        <div className='container-fluid' style={{ overflow: 'hidden' }}>
            <Topbar />
            <div className='row'>
                {/* Sidebar */}
                <div className='col-lg-2 col-12 p-0 border border-left fixed-sidebar' style={{ height: '100vh' }}>
                    <Sidebar />
                </div>

                {/* Main Content */}
                <div className='col-lg-10 p-0 Main_menu' style={{ paddingTop: '15px' }}>
                    <div className="container-fluid" style={{ padding: '1px' }} >
                        <div>
                            <i className="bi bi-chevron-left me-2" style={{ fontWeight: '600', fontSize: '21px', width: '20px', height: '20px' }}></i>
                            <text className="p-0" style={{ fontWeight: '600', color: '#1E232C', fontSize: '21px', width: '208px', height: '32px' }}>All Categories</text>
                        </div>

                        <div className='mt-3'>
                            <div className='row' style={{ backgroundColor: '#FFE5CC' }}>
                                <div className='col-lg-8 m-0'>
                                    <AppBar style={{ boxShadow: 'none' }} position="static">
                                        <Tabs value={selectedTab} onChange={handleChange} variant="fullWidth" >

                                            {appliances.length > 0 ? (
                                                <>
                                                    {/* Display the first three items in cards */}
                                                    {appliances.slice(0, 4).map((appliance, index) => (
                                                        <Tab style={{ backgroundColor: "#FFE5CC", color: "black", textTransform: 'none', flexGrow: 1 }} label={appliance.appliance_name} />
                                                    ))}
                                                </>
                                            ) : (
                                                ""
                                            )}

                                        </Tabs>
                                    </AppBar>
                                </div>
                                <div className='col-lg-4  m-0'>
                                    <FormControl className="" sx={{ m: 1, minWidth: 160, border: 'none' }}>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            label="Age"
                                            variant="standard"
                                            defaultValue="10"
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            className="text-start"
                                        >
                                            {appliances.length > 0 ? (
                                                <>
                                                    {/* Display the first three items in cards */}
                                                    {appliances.slice(4, 11).map((appliance, index) => (
                                                        <MenuItem value={appliance.id}>{appliance.appliance_name}</MenuItem>
                                                    ))}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            <MenuItem value="10">Other Appliances</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <TabPanel value={selectedTab} index={0}>
                                <TotalProducts />
                            </TabPanel>

                        </div>



                    </div>
                </div>
            </div>

        </div>
    )
}

export default AllCategories;