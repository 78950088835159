import React, { useState, useEffect } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { IconButton } from "@mui/material";
import profile from "../../Images/sample_avatar.jpg";
import { TextField, Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneIcon from "@mui/icons-material/Phone";
import Topbar from "../topbar";
import Sidebar from "../Sidebar";
import Api from "../../service/services";
import Swal from "sweetalert2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const TechProfile = () => {
  const [fullname, setFullname] = useState("");
  const [email, setMail] = useState("");
  const [mobile_no, setMobilenumber] = useState("");
  const [dob, setdob] = useState("");
  const [address, setAddress] = useState("");
  const [address1, setAddress1] = useState("");
  const [country] = useState("India");
  const [state] = useState("Tamil Nadu");
  const [city, setCity] = useState("");
  const [postal_code, setPostalcode] = useState("");
  const [image, setImage] = useState(null);
  const [images, setImages] = useState({});
  const [selectedGender, setSelectedGender] = useState("");
  const [cityData] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [IsFullNameEmpty, setIsFullEmpty] = useState(false);
  const [IsDobEmpty, setIsDobEmpty] = useState(false);
  const [IsGenderEmpty, setIsGenderEmpty] = useState(false);
  const [IsCityEmpty, setIsCityEmpty] = useState(false);
  const [IsPostalEmpty, setIsPostalEmpty] = useState(false);

  const GenderData = [
    { id: 1, label: "Male" },
    { id: 2, label: "Female" },
  ];

  useEffect(() => {
    getProfileDetails();
    // eslint-disable-next-line
  }, []);

  const handleChange = (event) => {
    setIsGenderEmpty(false);
    setSelectedGender(event.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const fileList = Array.from(event.target.files); // Convert FileList to an array
      const imageUrls = fileList.map((file) => URL.createObjectURL(file));

      setImage(imageUrls);
      setImages(fileList);
    }
  };

  const convertDateFormat = (dateString) => {
    // Split the input date string into day, month, and year parts
    const [day, month, year] = dateString.split("/");

    // Create a new Date object with the provided day, month, and year
    const date = new Date(`${year}-${month}-${day}`);

    // Get the year, month, and day in the desired format
    const newYear = date.getFullYear();
    const newMonth = String(date.getMonth() + 1).padStart(2, "0");
    const newDay = String(date.getDate()).padStart(2, "0");

    // Return the formatted date as a string in "yyyy-mm-dd" format
    return `${newYear}-${newMonth}-${newDay}`;
  };

  const getProfileDetails = () => {
    Api.getProfile().then((res) => {
      if (res.data) {
        setFullname(res.data.fullname);
        setMail(res.data.emailid);
        setMobilenumber(res.data.mobile_no);
        setdob(res.data.dob);
        setImage(res.data.images);
        setSelectedGender(res.data.gender);

        Api.getCityByStateId(23).then((response) => {
          const newCity = response.data.find(
            (item) =>
              item.city_name.toLowerCase() === res.data.city.toLowerCase()
          );
          setCity(newCity ? newCity.city_id : "");
        });
        const gen = GenderData.find(
          (item) => item.label.toLowerCase() === res.data.gender.toLowerCase()
        );
        setSelectedGender(gen.id);

        const convertedDate = convertDateFormat(res.data.dob);
        setdob(convertedDate);
        setAddress(res.data.address);
        setPostalcode(res.data.postalcode);
      }
    });
  };

  const register = (e) => {
    e.preventDefault();
    setIsFullEmpty(!fullname);
    setEmailError(email ? "" : "please fill email");
    setMobileError(mobile_no ? "" : "please fill phone");
    setIsDobEmpty(!dob);
    setIsGenderEmpty(!selectedGender);
    setIsCityEmpty(!city);
    setIsPostalEmpty(!postal_code);

    const data = {
      role: 1,
      fullname: fullname,
      email: email,
      mobile_no: mobile_no,
      dob: dob,
      gender: selectedGender,
      country: 1,
      state: state,
      city: city,
      postalcode: postal_code,
      address: address,
      address1: address1,
      images: images,
    };

    Api.Profile(data).then((response) => {
      if (response.status === 200) {
        Swal.fire({
          text: response.message,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.replace("/customerDashboard");
          }
        });
      } else {
        Swal.fire({
          text: response.message,
          confirmButtonText: "OK",
        });
      }
    });
  };

  return (
    <div className="container-fluid" style={{ overflow: "hidden" }}>
      <Topbar />
      <div className="row">
        <div className="col-lg-2 p-0 border border-right">
          <Sidebar open={true} />
        </div>
        <div className="col-lg-10 p-0">
          <div style={{ fontSize: "21px", fontWeight: "600" }}>
            <i className="bi bi-chevron-left me-1"></i>
            <text>Profile</text>
          </div>
          <form onSubmit={register}>
            <div className="row ms-lg-3 mt-lg-4">
              <div className="col-3 mt-lg-0 mt-3">
                <div>
                  <img
                    alt="demo"
                    src={image ? image : profile}
                    className="rounded-circle img"
                    style={{ width: 130, height: 130 }}
                  />
                  <IconButton
                    color="primary"
                    component="label"
                    style={{ marginTop: "28%", right: "40px" }}
                  >
                    <AddCircleOutlineIcon
                      fontSize="large"
                      className="rounded-circle circlebtn"
                      style={{ backgroundColor: "#E2EEFF" }}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={onImageChange}
                    />
                  </IconButton>
                </div>
              </div>

              <div className="col-lg-4 mt-lg-0 mt-3">
                <div>
                  <TextField
                    fullWidth
                    label="Full Name"
                    id="full-name"
                    value={fullname}
                    onChange={(e) => {
                      let inputValue = e.target.value;

                      // Remove extra spaces and allow only alphabets (no numbers or special characters)
                      var onlyText = inputValue
                        .replace(/[^A-Za-z ]/g, "")
                        .replace(/  +/g, " ");

                      // Remove leading space if present
                      if (onlyText.startsWith(" ")) {
                        onlyText = onlyText.slice(1);
                      }

                      // Limit the input length to 20 characters
                      const truncatedText = onlyText.slice(0, 20);
                      setFullname(truncatedText);
                      setIsFullEmpty(false);
                    }}
                    type="text"
                    placeholder="Enter your full name"
                    error={IsFullNameEmpty ? true : false}
                    helperText={IsFullNameEmpty ? "please fill fullname" : ""}
                    FormHelperTextProps={{}}
                  />
                </div>

                <div className="mt-lg-4 mt-3">
                  <TextField
                    fullWidth
                    style={{ height: "56px" }}
                    id="email"
                    value={email}
                    onChange={(e) => setMail(e.target.value)}
                    label="Enter your email"
                    type="email"
                    placeholder="Enter your email address"
                    error={!!emailError}
                    helperText={emailError}
                    FormHelperTextProps={{
                      style: {
                        position: "absolute",
                        bottom: "-20px",
                        whiteSpace: "nowrap",
                      },
                    }}
                    onKeyUp={() => {
                      const trimmedEmail = email.trim();
                      setMail(trimmedEmail);

                      if (!trimmedEmail) {
                        setEmailError("Please enter your email address.");
                      } else if (!emailPattern.test(trimmedEmail)) {
                        setEmailError("Please enter a valid email address.");
                      } else {
                        setEmailError("");
                      }
                    }}
                  />
                </div>

                <div className="mt-lg-4 mt-3">
                  <TextField
                    className="w-100 mt-4 mt-lg-4"
                    style={{ height: "47px" }}
                    id="mobile-number"
                    value={mobile_no}
                    label="Enter your phone number"
                    type="number"
                    placeholder=" 90000 00009"
                    error={!!mobileError}
                    helperText={mobileError}
                    FormHelperTextProps={{
                      style: {
                        position: "absolute",
                        bottom: "-20px",
                        whiteSpace: "nowrap",
                      },
                    }}
                    onKeyUp={() => {
                      if (!mobile_no) {
                        setMobileError("Please enter your phone number.");
                      } else if (mobile_no.length !== 10) {
                        setMobileError("please enter valid phone number");
                      } else {
                        setMobileError("");
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Remove any non-digit characters
                      const numericValue = value.replace(/\D/g, "");
                      // Enforce length of 6
                      const formattedValue = numericValue.slice(0, 10);
                      setMobilenumber(formattedValue);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">+91 |</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ color: "rgb(235, 129, 40)" }}
                        >
                          <PhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <div className="mt-lg-4 mt-3">
                    <TextField
                      fullWidth
                      id="datePicker"
                      label="Select a DOB"
                      type="date"
                      onChange={(e) => {
                        setdob(e.target.value);
                        setIsDobEmpty(false);
                      }}
                      value={dob}
                      error={IsDobEmpty ? true : false}
                      helperText={IsDobEmpty ? "please select Dob" : ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end"></InputAdornment>
                        ),
                      }}
                      style={{ height: "47px" }}
                    />
                  </div>
                </div>

                <div className="mt-lg-4 mt-3">
                  <text
                    className="mt-3"
                    style={{
                      fontSize: "11px",
                      fontWeight: "300",
                      color: "#6A707C",
                    }}
                  >
                    Gender
                  </text>
                  <br />
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={selectedGender}
                      name="radio-buttons-group"
                      onChange={handleChange}
                    >
                      <span>
                        {GenderData.map((gen) => (
                          <FormControlLabel
                            className=""
                            control={<Radio />}
                            label={gen.label}
                            value={gen.id}
                          />
                        ))}
                      </span>
                      {IsGenderEmpty ? (
                        <p
                          className="m-0"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          please choose gender
                        </p>
                      ) : (
                        ""
                      )}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              <div className="col-lg-4 col-12 mt-lg-0 mt-3">
                <div className="mt-lg-0 mt-3">
                  <TextField
                    fullWidth
                    label="Country"
                    value={country}
                    aria-readonly={true}
                  />
                </div>

                <div className="mt-lg-4 mt-3">
                  <TextField
                    fullWidth
                    label="State"
                    value={state}
                    aria-readonly={true}
                  />
                </div>

                <div className="mt-lg-4 mt-3">
                  <TextField
                    fullWidth
                    label="Address Line 1"
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Type your door no, street name"
                  />
                </div>

                <div className="mt-lg-4 mt-3">
                  <TextField
                    fullWidth
                    label="Address Line 2"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                    type="text"
                    placeholder="Nearest Landmark"
                  />
                </div>

                <div className="row mt-lg-4 mt-3">
                  <div className="col-6">
                    <TextField
                      fullWidth
                      label="City"
                      select
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                        setIsCityEmpty(false);
                      }}
                      error={IsCityEmpty ? true : false}
                      helperText={IsCityEmpty ? "please select city" : ""}
                    >
                      {cityData.length > 0 ? (
                        cityData.map((state, index) => (
                          <MenuItem key={state.city_id} value={state.city_id}>
                            {state.city_name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value=""></MenuItem>
                      )}
                    </TextField>
                  </div>

                  <div className="col-6">
                    <div>
                      <TextField
                        fullWidth
                        label="Postal Code"
                        value={postal_code}
                        onChange={(e) => {
                          setPostalcode(e.target.value);
                          setIsPostalEmpty(false);
                        }}
                        type="text"
                        error={IsPostalEmpty ? true : false}
                        helperText={IsPostalEmpty ? "please enter postal" : ""}
                        FormHelperTextProps={{}}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
            <div className="border border-bottom mt-3"></div>
            <div className="row mt-lg-4 mt-3">
              <div className="col"></div>
              <div className="col text-end">
                <ul className="p-0" style={{ display: "inline-flex" }}>
                  <li style={{ listStyle: "none" }}></li>
                  <li className="me-2" style={{ listStyle: "none" }}>
                    <Button
                      variant="outlined"
                      style={{
                        height: "45px",
                        width: "120px",
                        color: "rgb(235, 129, 40)",
                        border: "1px soid rgb(235, 129, 40)",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>
                  </li>
                  <li className="me-2" style={{ listStyle: "none" }}>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{
                        height: "45px",
                        color: "white",
                        backgroundColor: "#EB8128",
                        textTransform: "none",
                      }}
                    >
                      Save Profile
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TechProfile;
