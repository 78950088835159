import React from "react";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import { AppBar, Tabs, Tab, Box } from "@mui/material";
import AllAppliance from "./AllAppliance";
import ActiveAppliance from "./ActiveAppliance";
import InActiveAppliance from "./InActiveAppliance";
import { useNavigate } from "react-router-dom";

const CategoryManagement = () => {
  const [selectedTab1, setSelectedTab1] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setSelectedTab1(newValue);
  };

  const TabPanel = ({ value, index, children }) => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div
          className="row container-fluid"
          style={{ paddingRight: "0px", overflow: "hidden" }}
        >
          <div className="col-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <div
            className="col-12 user_management"
            style={{ paddingRight: "0px", marginTop: "80px" }}
          >
            <div className="row">
              <div className="mt-lg-2">
                <i
                  className="bi bi-chevron-left "
                  style={{
                    fontWeight: "600",
                    fontSize: "21px",
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(-1)}
                ></i>
                <text
                  className="p-0"
                  style={{
                    fontWeight: "600",
                    color: "#1E232C",
                    fontSize: "21px",
                    width: "208px",
                    height: "32px",
                  }}
                >
                  Appliance Management
                </text>
              </div>
            </div>

            <div className="container-fluid" style={{ overflow: "hidden" }}>
              <div className="row">
                <div className="col-lg-12 p-0 mt-1">
                  <div className="col-lg-4 ms-1">
                    <AppBar
                      style={{ boxShadow: "none" }}
                      position="static"
                      className="w-100 bg-white"
                      aria-label="basic tabs example"
                    >
                      <Tabs
                        value={selectedTab1}
                        onChange={handleChange}
                        variant="fullWidth"
                        className="scroll-tabs"
                      >
                        <Tab
                          style={{
                            backgroundColor: "white",
                            textTransform: "none",
                          }}
                          label="All"
                        />
                        <Tab
                          style={{
                            backgroundColor: "white",
                            textTransform: "none",
                          }}
                          label="Active"
                        />
                        <Tab
                          style={{
                            backgroundColor: "white",
                            textTransform: "none",
                          }}
                          label="Inactive"
                        />
                      </Tabs>
                    </AppBar>
                  </div>

                  <TabPanel value={selectedTab1} index={0}>
                    <AllAppliance />
                  </TabPanel>
                  <TabPanel value={selectedTab1} index={1}>
                    <ActiveAppliance />
                  </TabPanel>
                  <TabPanel value={selectedTab1} index={2}>
                    <InActiveAppliance />
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CategoryManagement;
