import React, { useEffect, useState } from "react";
import Topbar from "../topbar";
import Sidebar from "../Sidebar";
import {
  Paper,
  InputBase,
  Card,
  Stack,
  Typography,
  CardContent,
  useMediaQuery,
  Tooltip,
  Avatar,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box } from "@mui/material";
import { CardMedia, TextareaAutosize } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Api from "../../service/services";
import moment from "moment";
import ProgressBar from "../Customers/progress";
import { Modal } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import { FaCalendarDays } from "react-icons/fa6";
import Ratings from "../../plugins/Ratings";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaStar } from "react-icons/fa";
import { useTheme } from "@mui/material/styles";
const MyOrders = () => {
  const isSmallScreen = useMediaQuery("(max-width:595px)");
  const containerStyles = {
    marginTop: isSmallScreen ? 30 : 20,
    marginLeft: isSmallScreen ? 0 : -55,
    marginBottom: isSmallScreen ? 30 : 20,
    color: "rgb(235, 129, 40)",
  };
  const steps = ["Order Placed", "Order Shipped", "Order Delivered"];

  const navigate = useNavigate();
  const [ordersList, setOrdersList] = useState([]);
  const [userid, setUserId] = useState("");
  const [orderListById, setOrderListById] = useState({});
  const [filterModal, setFilterModal] = useState(false);
  const [cartListOrderItems, setCartListOrderItems] = useState([]);
  const [ratingModal, setRatingModal] = useState(false);
  const [productId, setProductId] = useState("");
  const [productImage, setProductImage] = useState("");
  const [productName, setProductName] = useState("");
  const [rating, setRating] = useState(4);
  const [comment, setComment] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const searchFilter = async (event) => {
    const searchTerm = event.target.value.toLowerCase().trim();

    if (searchTerm === "") {
      const res = await Api.myOrdersList();
      setOrdersList(res);
    } else {
      const res = await Api.myOrdersList();
      const filteredTests = res.filter((service) => {
        const productName = service?.id;
        return productName.includes(searchTerm);
      });
      setOrdersList(filteredTests);
    }
  };
  const getOrderById = (id, list) => {
    setUserId(id);
    const order = list.find((item) => item?.id === id.toString());
    setOrderListById(order);
    setCartListOrderItems(order?.carts);
    if (isMobile) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }
  useEffect(() => {
    Api.myOrdersList()
      .then((res) => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Optional smooth scrolling effect
        });
        setOrdersList(res);
        if (res?.length > 0) {
          getOrderById(res[0]?.id, res);
        }
      })
      .catch((err) => console.log("err", err));
    // eslint-disable-next-line
  }, []);
  const handlePostReview = () => {
    if (rating < 1) {
      toast.error("Please select the rating!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const payload = {
      review_type: "product",
      rating_value: rating,
      comment: comment,
      product_id: productId,
      order_id: orderListById?.id,
    };
    Api.postReview(payload)
      .then((res) => {
        setRatingModal(false);
        setRating("");
        setComment("");
        Swal.fire({
          text: res,
          confirmButtonText: "OK",
        });
      })
      .catch((err) => console.log("err", err));
  };
  const currentDate = new Date();

  const defaultDate = [
    {
      startDate: currentDate,
      endDate: currentDate,
      key: "selection",
    },
  ];
  const [dateRange, setDateRange] = useState(defaultDate);
  const [viewDatePickerPopover, setViewDatePickerPopover] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);

  const DatePicker = () => {
    const [dateRangeSelected, setDateRangeSelected] = useState(defaultDate);
    const handleSelectDate = (ranges) => {
      setDateRangeSelected([ranges.selection]);
    };
    const handleCancelDate = () => {
      setDateRange(defaultDate);
      setViewDatePickerPopover(false);
      setDateRangeSelected(defaultDate);
    };
    const handleSetSelectedDate = () => {
      setDateRange(dateRangeSelected);
      setViewDatePickerPopover(false);
      setDateRangeSelected(defaultDate);
      setClearFilter(false);
    };
    return (
      <div>
        <div
          onClick={() => {
            setViewDatePickerPopover(!viewDatePickerPopover);
            setDateRangeSelected(dateRange);
          }}
          className="selected-date-filter "
        >
          <div className="d-flex align-items-center gap-3">
            <div>{moment(dateRange[0].startDate).format("DD-MM-YYYY")}</div>
            {moment(dateRange[0].startDate).format("DD-MM-YYYY") !==
              moment(dateRange[0].endDate).format("DD-MM-YYYY") && <div>-</div>}
            {moment(dateRange[0].startDate).format("DD-MM-YYYY") !==
              moment(dateRange[0].endDate).format("DD-MM-YYYY") && (
              <div>{moment(dateRange[0].endDate).format("DD-MM-YYYY")}</div>
            )}
          </div>
          <FaCalendarDays style={{ color: "rgb(235, 129, 40)" }} />
        </div>
        <Modal
          show={viewDatePickerPopover}
          onHide={() => setViewDatePickerPopover(false)}
          className="date-picker-modal"
        >
          <div className="datepicker-myOrders">
            <div>
              <DateRangePicker
                ranges={dateRangeSelected}
                onChange={handleSelectDate}
                maxDate={currentDate}
                rangeColors={"rgb(235, 129, 40)"}
              />
            </div>
            <div>
              <button
                className="filter-cancel-btn"
                onClick={() => handleCancelDate()}
              >
                Cancel
              </button>
              <button
                className="filter-apply-btn mt-2 set-cancel-button"
                onClick={() => handleSetSelectedDate()}
              >
                Set
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  };
  //Filter Functions
  const handleApplyFilter = () => {
    const payload = {
      startDate: clearFilter
        ? ""
        : moment(dateRange[0]?.startDate).format("YYYY-MM-DD"),
      endDate: clearFilter
        ? ""
        : moment(dateRange[0]?.endDate).format("YYYY-MM-DD"),
    };
    setFilterModal(false);
    setFilterApplied(true);
    Api.myOrdersList(payload)
      .then((res) => {
        setOrdersList(res);
        if (res?.length > 0) {
          getOrderById(res[0]?.id, res);
        }
      })
      .catch((err) => console.log(err));
  };
  const clearData = () => {
    setDateRange(defaultDate);
    setClearFilter(true);
    setFilterApplied(false);
    setFilterModal(false);

    Api.myOrdersList()
      .then((res) => {
        setOrdersList(res);
        if (res?.length > 0) {
          getOrderById(res[0]?.id, res);
        }
      })
      .catch((err) => console.log("err", err));
  };
  const handleFilterCancel = () => {
    setDateRange(defaultDate);
    setFilterModal(false);
  };
  const handleChangeRating = (e) => {
    setRating(e);
  };
  const getReviewbyId = (revieweeId, entityId) => {
    Api.getReview(revieweeId, entityId).then((res) => {
      setRating(res[0]?.rating_value ? res[0]?.rating_value : 4);
      setComment(res[0]?.comment ? res[0]?.comment : "");
    });
  };

  return (
    <div className="" style={{ overflow: "hidden" }}>
      <Topbar />
      <div className="row">
        <div className="col-lg-2 col-12 border border-right">
          <Sidebar open1={true} />
        </div>
        <div
          className="technician-my-orders col-12 "
          // style={{ marginTop: "80px" }}
        >
          <div className="row" style={{ marginTop: "80px" }}>
            <div
              className="col-lg-4 col-12 p-2 technician-order-listing"
              style={{
                // zIndex: 1,
                background: "#fff",
              }}
            >
              <div
                className="m-0 sticky-top bg-white"
                style={{ position: "sticky", zIndex: "2" }}
              >
                <i
                  onClick={() => navigate(-1)}
                  className="bi bi-chevron-left"
                  style={{
                    fontWeight: "600",
                    fontSize: "21px",
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                ></i>
                <text
                  className="p-0"
                  style={{
                    fontWeight: "600",
                    color: "#1E232C",
                    fontSize: "21px",
                    width: "208px",
                    height: "32px",
                  }}
                >
                  {`Orders (${ordersList?.length})`}
                </text>
              </div>

              <ul
                className="p-0 mt-3"
                style={{ display: "inline-flex", width: "100%" }}
              >
                <li
                  className="ms-2"
                  style={{ listStyle: "none", width: "100%" }}
                >
                  <Paper
                    elevation={0}
                    className="w-100  border rounded-0"
                    component="form"
                    sx={{ display: "flex", alignItems: "center" }}
                    style={{ width: "100%", height: "42px" }}
                  >
                    <IconButton type="button" aria-label="search">
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      sx={{ flex: 1 }}
                      style={{
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "14px",
                        fontFamily: "Arial",
                      }}
                      onChange={searchFilter}
                      placeholder="Search by Order ID"
                      inputProps={{ "aria-label": "search google maps" }}
                    />
                  </Paper>
                </li>
                <li className="ms-2" style={{ listStyle: "none" }}>
                  <IconButton
                    style={{ width: "42px", height: "42px" }}
                    className="border rounded-0"
                    aria-label="delete"
                    onClick={() => setFilterModal(true)}
                  >
                    {filterApplied && (
                      <Tooltip title="Filter applied" arrow>
                        <div className="filter-indicator">
                          <div className="ic" />
                        </div>
                      </Tooltip>
                    )}
                    <FilterListIcon style={{ color: "rgb(235, 129, 40)" }} />
                  </IconButton>
                </li>
              </ul>
              <Modal
                show={filterModal}
                onHide={() => setFilterModal(false)}
                centered
                size="md"
                className="filter-modal"
              >
                <div className="p-3">
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "25px",
                    }}
                  >
                    Filter
                  </div>
                  <div className="mt-3">
                    <div>
                      <div className="row">
                        <div className="col-12 col-lg-12">
                          <DatePicker />
                        </div>

                        <div
                          className="d-flex align-items-center mt-3 gap-2"
                          style={{
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          <button
                            className="filter-clear-btn"
                            onClick={() => clearData()}
                          >
                            Clear
                          </button>
                          <button
                            className="filter-cancel-btn"
                            onClick={() => handleFilterCancel()}
                          >
                            Cancel
                          </button>
                          <button
                            className="filter-apply-btn"
                            onClick={() => handleApplyFilter()}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              {ordersList?.length > 0 ? (
                <div className="">
                  <div
                    className="custom-scrollbar-tech-orders"
                    style={{
                      // height: "65vh",
                      marginLeft: "10px",
                    }}
                  >
                    {ordersList?.map((item, index) => {
                      const progressPercentage = () => {
                        if (item?.status === "submitted") {
                          return "50";
                        } else if (item?.status === "failed") {
                          return "0";
                        } else if (item?.status === "pending") {
                          return "0";
                        } else if (item?.status === "dispatched") {
                          return "80";
                        } else if (item?.status === "delivered") {
                          return "100";
                        } else if (item?.status === "cancelled") {
                          return "0";
                        } else {
                          return "0";
                        }
                      };
                      return (
                        <Card
                          key={index}
                          onClick={() => getOrderById(item?.id, ordersList)}
                          style={{
                            marginBottom:
                              index === ordersList?.length - 1
                                ? "60px"
                                : "10px",
                            background:
                              item?.id === userid
                                ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                : "white",
                            border:
                              item?.id === userid
                                ? "rgb(235, 129, 40) solid 1px"
                                : "",
                            margin: "2px",
                            padding: "0px",
                            cursor: "pointer",
                            borderRadius: "10px",
                            marginTop: "0.5rem",
                          }}
                        >
                          <Stack
                            direction="row"
                            spacing={1}
                            className="mt-1 mb-2 p-2 me-1 "
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <div style={{ fontSize: "13px" }}>
                                <text style={{ fontWeight: "bold" }}>
                                  Order Id #:{" "}
                                </text>
                                <text style={{ color: "#1E232C" }}>
                                  {item?.id?.slice(-6)}
                                </text>

                                <div
                                  style={{ fontSize: "13px", marginTop: "6px" }}
                                >
                                  <text style={{ fontWeight: "bold" }}>
                                    Total Amount:{" "}
                                  </text>
                                  <text style={{ color: "#1E232C" }}>
                                    ₹ {item?.net_payable_amount}
                                  </text>
                                  <div
                                    style={{
                                      fontSize: "13px",
                                      marginTop: "6px",
                                    }}
                                  >
                                    <b>Delivery by: </b>
                                    {moment(item?.delivery_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Tooltip title={item?.status}>
                                <div style={{}}>
                                  <ProgressBar
                                    percentage={progressPercentage()}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </Stack>
                        </Card>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    top: "150px",
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#ffa5005c",
                  }}
                >
                  No Orders yet!
                </div>
              )}
            </div>
            {ordersList?.length > 0 && (
              <div
                className="col-lg-8 mt-lg-5 technician-order-details"
                style={{ fontSize: "14px", background: "#fff" }}
              >
                <div className="order-details">
                  <p className="ms-lg-1 mb-lg-1" style={{ fontSize: "13px" }}>
                    <b>Order ID #: </b>
                    {orderListById?.id?.slice(-6)}
                  </p>
                  <p className="ms-lg-1 mb-lg-1" style={{ fontSize: "13px" }}>
                    <b>Created at :</b>{" "}
                    {moment(orderListById?.created_at).format(
                      "DD-MM-YYYY, hh:mm A"
                    )}
                  </p>
                </div>
                <p className="ms-lg-1 mb-lg-1" style={{ fontSize: "13px" }}>
                  <b>Shipping Address: </b>
                  {orderListById?.address?.address},{" "}
                  {orderListById?.address?.city_name},{" "}
                  {orderListById?.address?.state_name},{" "}
                  {orderListById?.address?.postalcode}
                </p>
                {/* eslint-disable-next-line */}
                {orderListById?.status === "failed" ||
                orderListById?.status === "pending" ? (
                  <div
                    style={{
                      color: "rgb(235, 129, 40)",
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 20,
                      marginBottom: "15px",
                      fontSize: "18px",
                      textTransform: "capitalize",
                    }}
                  >
                    Payment {orderListById?.status} !
                  </div>
                ) : (
                  <div style={containerStyles}>
                    <Box sx={{ width: "100%", marginLeft: "6px" }}>
                      <Stepper
                        activeStep={
                          //  eslint-disable-next-line
                          orderListById?.status == "submitted"
                            ? 1
                            : //  eslint-disable-next-line
                            orderListById?.status == "dispatched"
                            ? 2
                            : 3
                        }
                        alternativeLabel
                      >
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel
                              StepIconProps={{
                                style: { color: "rgb(235, 129, 40)" },
                              }}
                            >
                              {label}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                  </div>
                )}
                <div className="row">
                  {orderListById?.carts?.length > 0 && (
                    <div className="col-sm-12 col-lg-6">
                      <div
                        className="mb-lg-2 sticky-top bg-white"
                        style={{ position: "sticky", zIndex: "2" }}
                      >
                        {orderListById?.carts?.length > 1 ? (
                          <text
                            className="p-2"
                            style={{
                              fontWeight: "600",
                              color: "#1E232C",
                              width: "208px",
                              height: "32px",
                            }}
                          >
                            Ordered Items ({orderListById?.carts?.length})
                          </text>
                        ) : (
                          <text
                            className="p-2"
                            style={{
                              fontWeight: "600",
                              color: "#1E232C",
                              width: "208px",
                              height: "32px",
                            }}
                          >
                            Ordered Item ({orderListById?.carts?.length})
                          </text>
                        )}
                      </div>
                      <div className="addtocart">
                        <div
                          className="technician-my-orders-custom-scrollbar"
                          style={{
                            height: "0px",
                            marginLeft: "10px",
                            minHeight:
                              orderListById?.carts?.length > 1
                                ? "calc(100vh - 420px)"
                                : "150px",
                          }}
                        >
                          {cartListOrderItems.map((item, index) => {
                            return (
                              <div key={index}>
                                <Card
                                  elevation={2}
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "12px",
                                    marginLeft: 1,
                                    marginRight: 3,
                                    height: "120px",
                                    width: "300px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div style={{ padding: 0 }}>
                                      <CardMedia
                                        component="img"
                                        sx={{ height: 140 }}
                                        image={item?.product_img}
                                        title={item?.name}
                                        style={{
                                          height: "120px",
                                          width: "100px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </div>

                                    <CardContent>
                                      <Typography
                                        style={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          maxWidth: "100%",
                                          marginTop: "10px",
                                        }}
                                        gutterBottom
                                        variant="div"
                                        component="p"
                                      >
                                        <b>{item?.name}</b>
                                      </Typography>

                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        style={{
                                          color: "black",
                                          marginTop: "0px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Total Amount:{" "}
                                        <span style={{ color: "gray" }}>
                                          ₹{" "}
                                          {Number(item?.amount) % 1 === 0
                                            ? Number(item?.amount)
                                            : item?.amount}
                                        </span>
                                      </Typography>

                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        style={{
                                          color: "black",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Quantity:{" "}
                                        <span style={{ color: "gray" }}>
                                          {item?.quantity}{" "}
                                        </span>
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        style={{ color: "black" }}
                                        onClick={() => {
                                          getReviewbyId(
                                            orderListById?.id,
                                            item?.product_id
                                          );
                                          setProductName(item?.name);
                                          setProductImage(item?.product_img);
                                          setProductId(item?.product_id);
                                          setRatingModal(true);
                                        }}
                                      >
                                        {orderListById?.status ===
                                          "delivered" && (
                                          <div
                                            style={{
                                              textDecoration: "underline",
                                              textDecorationColor:
                                                "rgb(235, 129, 40)",
                                              cursor: "pointer",
                                              color: "rgb(235, 129, 40)",
                                            }}
                                            onMouseOver={(e) =>
                                              (e.currentTarget.style.color =
                                                "darkorange")
                                            }
                                            onMouseOut={(e) =>
                                              (e.currentTarget.style.color =
                                                "rgb(235, 129, 40)")
                                            }
                                          >
                                            <span>
                                              <FaStar />
                                            </span>{" "}
                                            <span>Rate and Review</span>
                                          </div>
                                        )}
                                      </Typography>
                                    </CardContent>
                                  </div>
                                </Card>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div style={{ marginLeft: "10px", marginBottom: "20px" }}>
                        <b>Total Value : </b>₹{" "}
                        {orderListById?.net_payable_amount}
                      </div>
                    </div>
                  )}
                  {orderListById?.status === "pending" ||
                  orderListById?.status === "failed" ? (
                    ""
                  ) : (
                    <div className="col-sm-12 col-lg-5 service">
                      <div
                        className="mb-lg-3  ms-lg-1 sticky-top bg-white"
                        style={{
                          position: "sticky",
                          zIndex: "2",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <text
                          className="p-0"
                          style={{
                            fontWeight: "600",
                            color: "#1E232C",
                            width: "208px",
                            height: "32px",
                          }}
                        >
                          Payment Details
                        </text>
                      </div>
                      <Stack
                        className="mb-lg-3 ms-lg-1 mt-3"
                        direction="row"
                        spacing={15}
                        style={{
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ color: "gray", fontSize: "13px" }}>
                          Payment Mode:
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          {orderListById?.transaction?.payment_method}
                        </p>
                      </Stack>
                      <Tooltip
                        title={
                          orderListById?.transaction?.internal_transaction_id
                        }
                      >
                        <Stack
                          className="mb-lg-3 ms-lg-1"
                          direction="row"
                          spacing={10}
                          style={{
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ color: "gray", fontSize: "13px" }}>
                            {" "}
                            Transaction ID:
                          </p>
                          <p style={{ fontSize: "13px" }}>
                            ***
                            {orderListById?.transaction?.internal_transaction_id?.slice(
                              -7
                            )}
                          </p>
                        </Stack>
                      </Tooltip>

                      <Stack
                        className="mb-lg-3 ms-lg-1"
                        direction="row"
                        spacing={12}
                        style={{
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ color: "gray", fontSize: "13px" }}>
                          Total Order (₹):
                        </p>

                        <p style={{ fontSize: "13px" }}>
                          {orderListById?.total_amount}
                        </p>
                      </Stack>

                      <Stack
                        className="mb-lg-3 ms-lg-1"
                        direction="row"
                        spacing={10}
                        style={{
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ color: "gray", fontSize: "13px" }}>
                          Delivery charges (₹):
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          {orderListById?.total_delivery_charge}
                        </p>
                      </Stack>

                      {/* <Stack
  className="mb-lg-3 ms-lg-1"
  direction="row"
  spacing={15}
  style={{
    width: "100%",
    justifyContent: "space-between",
  }}
>
  <p style={{ color: "gray", fontSize: "13px" }}>
    Discount (₹):
  </p>
  <p
    style={{
      fontSize: "13px",
      color: "#36A93F",
    }}
  >
    -30.00
  </p>
</Stack> */}

                      <div className="border border-bottom w-100  mb-lg-3 mt-3"></div>
                      <Stack
                        className="mb-lg-3 ms-lg-1 mt-3"
                        direction="row"
                        spacing={10}
                        style={{
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            color: "gray",
                            fontSize: "13px",
                            marginBottom: "30px",
                          }}
                        >
                          Total Payable (₹)
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          {orderListById?.net_payable_amount}
                        </p>
                      </Stack>
                      {/* <TextField
  className=" mt-lg-4 mt-3 w-100 cursor-pointer"
  id="outlined-select-currency"
  label="Order Address"
  multiline={1}
  value={
    orderListById
      ? `
${orderListById?.address?.address},
${orderListById?.address?.city_name}, ${orderListById?.address?.state_name},
${orderListById?.address?.postalcode}
          `
      : ""
  }
  InputProps={{ readOnly: true }}
  disabled
></TextField> */}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={ratingModal}
        onHide={() => setRatingModal(false)}
        centered
        size="md"
        className="filter-modal"
      >
        <div className="p-3">
          <div
            style={{
              fontWeight: "600",
              fontSize: "25px",
            }}
          >
            Ratings and Reviews
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <Avatar
              className="centered-text mt-3 ms-2"
              src={stringAvatar(techName)}
              {...stringAvatar(techName)}
            /> */}
            <Avatar
              className="centered-text mt-3 ms-2"
              src={productImage ? productImage : stringAvatar(productName)}
              {...stringAvatar(productName)}
            />
            <div style={{ marginTop: "10px", marginLeft: "2px" }}>
              <div>{productName}</div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Ratings
              rating={rating || 4}
              onChangeRating={handleChangeRating}
              fontSize={"40px"}
              isEditable
            />
          </div>
          <TextareaAutosize
            aria-label="Comment"
            placeholder="Write your review here..."
            minRows={3}
            style={{ width: "100%", marginTop: "10px", padding: "8px" }}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          <div
            className="d-flex align-items-center mt-3 gap-2"
            style={{
              justifyContent: "flex-end",
              width: "100%",
              cursor: rating < 0 ? "not-allowed" : "pointer",
            }}
          >
            <button
              className="filter-apply-btn"
              onClick={() => handlePostReview()}
              disabled={rating === 0 ? true : false}
              style={{ cursor: rating < 0 ? "not-allowed" : "pointer" }}
            >
              Post
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default MyOrders;
