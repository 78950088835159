import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import AllAdmins from "./AllAdmins";
import AllCustomers from "./AllCustomers";
import AllTechnicians from "./AllTechnicians";
import { AppBar, Tabs, Tab, Box } from "@mui/material";
import CountContext from "../../countContext/CountContext";

const UserManagement = () => {
  const { setSearch } = useContext(CountContext);


  const role = localStorage.getItem("role");

  const [selectedTab1, setSelectedTab1] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setSearch("");
    setSelectedTab1(newValue);
  };
  const TabPanel = ({ value, index, children }) => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div
          className="row container-fluid"
          style={{ overflow: "hidden", paddingRight: "0px" }}
        >
          <div className="col-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <div
            className="col-12 user_management"
            style={{ paddingRight: "0px", marginTop: "80px" }}
          >
            <div className="mt-lg-2">
              <i
                className="bi bi-chevron-left "
                style={{
                  fontWeight: "600",
                  fontSize: "21px",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              ></i>
              <text
                className="p-0"
                style={{
                  fontWeight: "600",
                  color: "#1E232C",
                  fontSize: "21px",
                  width: "208px",
                  height: "32px",
                }}
              >
                User Management
              </text>
            </div>
            <div className="container-fluid" style={{ overflow: "hidden" }}>
              <div className="row">
                <div className="col-lg-12 p-0 mt-1">
                  <div className="col-lg-4 ms-1">
                    <div style={{ width: "95%" }}>
                      <AppBar
                        style={{ boxShadow: "none" }}
                        position="static"
                        className="w-100 bg-white"
                        aria-label="basic tabs example"
                      >
                        <Tabs
                          value={selectedTab1}
                          onChange={handleChange}
                          variant="fullWidth"
                        >
                          <Tab
                            style={{
                              backgroundColor: "white",
                              textTransform: "none",
                            }}
                            label={`Technicians `}
                          />
                          <Tab
                            style={{
                              backgroundColor: "white",
                              textTransform: "none",
                            }}
                            label={`Customers `}
                          />
                          {role === "SuperAdmin" || role === "ZonalAdmin" ? (
                            <Tab
                              style={{
                                backgroundColor: "white",
                                textTransform: "none",
                              }}
                              label={`Admins`}
                            />
                          ) : (
                            <></>
                          )}
                        </Tabs>
                      </AppBar>
                    </div>
                  </div>
                  <TabPanel value={selectedTab1} index={0}>
                    <AllTechnicians />
                  </TabPanel>
                  <TabPanel value={selectedTab1} index={1}>
                    <AllCustomers />
                  </TabPanel>
                  <TabPanel value={selectedTab1} index={2}>
                    <AllAdmins />
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserManagement;
