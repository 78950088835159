import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import IconButton from "@mui/material/IconButton";
import { Card, Avatar, Stack, TextField, Tooltip } from "@mui/material/";
import { Rating } from "@mui/material";
import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import profile from "../../../Images/sample_avatar.jpg";
import femaleclor from "../../../../src/Assets/profile/female-clicked.svg";
import maleclor from "../../../../src/Assets/profile/male-clicked.svg";
import female from "../../../../src/Assets/profile/female-normal.svg";
import male from "../../../../src/Assets/profile/male-normal.svg";
import Api from "../../../service/services";
import SpinnerLoader2 from "../../../plugins/SpinnerLoader2";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import HalfRatings from "../../../plugins/HalfRatings";
import { Modal } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomerFilter from "../../../plugins/CustomerFilter";

const AllCustomers = () => {
  const theme = useTheme();
  const mobileMatches = useMediaQuery(theme.breakpoints.down("sm"));
  const [email, setEmail] = useState("");
  const [dob, setdob] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("Tamil Nadu");
  const [city, setCity] = useState("");
  const [postal_code, setPostalcode] = useState("");
  const [image, setImage] = useState(null);

  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  const [IsFullNameEmpty] = useState(false);
  const [IsDobEmpty] = useState(false);
  const [IsPostalEmpty] = useState(false);

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [Ismale, setIsMale] = useState(false);
  const [IsFemale, setIsFemale] = useState(false);

  const [checked, setChecked] = React.useState(true);
  const [userRatings, setUserRatings] = useState({});
  const [ratingsModal, setRatingsModal] = useState(false);

  const [showFullReviews, setShowFullReviews] = useState(
    Array(userRatings?.user_ratings?.length).fill(false)
  );

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [allCustomersDetails, setAllCustomersDetails] = useState([]);
  const [name, setName] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [role, setRole] = useState("");
  const [userid, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredDate, setFilteredDate] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const UserRole = localStorage.getItem("role");
  useEffect(() => {
    const Role = localStorage.getItem("role");
    setRole(Role);
  }, [role]);

  const getAllCustomer = () => {
    setLoading(true);
    // eslint-disable-next-line
    if (UserRole == "DistrictAdmin") {
      Api.DistrictUserManagementAllCust().then((result) => {
        setAllCustomersDetails(result?.data);
        setFilteredDate(result?.data);
        setLoading(false);
        result?.data?.length > 0 && HandelSingleUser(result?.data[0]?.id);
      });
    }
    // eslint-disable-next-line
    if (UserRole == "ZonalAdmin") {
      Api.ZonalUserManagementAllCust().then((result) => {
        setName(result?.data?.fullname);
        setAllCustomersDetails(result?.data);
        setFilteredDate(result?.data);
        setLoading(false);
        result?.data?.length > 0 && HandelSingleUser(result?.data[0]?.id);
      });
    }
    // eslint-disable-next-line
    if (UserRole == "SuperAdmin") {
      Api.SuperAdminUsermanagementGetAllCust().then((result) => {
        setName(result?.data?.fullname);
        setAllCustomersDetails(result?.data);
        setFilteredDate(result?.data);
        if (allCustomersDetails?.length > 0) {
          setLoading(false);
        } else {
          setLoading(false);
        }
        result?.data?.length > 0 && HandelSingleUser(result?.data[0]?.id);
      });
    }
  };

  const clear = () => {
    setName("");
    setEmail("");
    setPhoneno("");
    setState("");
    setdob("");
    setPostalcode("");
    setCity("");
    setAddress("");
  };
  const HandelSingleUser = (id, user) => {
    setUserId(id);
    if (mobileMatches) {
      if (user?.id) {
        window.scrollTo({
          top: document.documentElement.scrollHeight, // Scroll to the bottom of the page
          behavior: "smooth", // Smooth scrolling behavior
        });
      }
    }
    if (id) {
      Api.DistrictUserManagementGetOneUser(id).then((res) => {
        setName(res?.fullname);
        setEmail(res?.emailid);
        setPhoneno(res?.mobile_no);
        setState(res?.userDetails?.state);

        if (res?.userDetails?.dob) {
          const dobMoment = moment(
            res.userDetails.dob,
            ["YYYY-MM-DD", "DD-MM-YYYY"],
            true
          );
          setdob(dobMoment.isValid() ? dobMoment.format("YYYY-MM-DD") : "");
        } else {
          setdob("");
        }

        setPostalcode(res?.userDetails?.postalcode);
        setCity(res?.userDetails?.district || res?.userDetails?.city);
        setAddress(res?.userDetails?.address);
        setImage(res?.avatar || null);
        setChecked(res?.is_active !== 1);

        const gender = res?.userDetails?.gender?.toLowerCase();
        if (gender === "male") {
          setIsMale(true);
          setIsFemale(false);
        } else if (gender === "female") {
          setIsMale(false);
          setIsFemale(true);
        } else {
          setIsMale(false);
          setIsFemale(false);
        }
      });
      Api.reviewsAndRatingsList(id)
        .then((res) => {
          setUserRatings(res);
        })
        .catch((err) => console.log(err));
      clear();
    }
  };
  useEffect(() => {
    getAllCustomer();
    // eslint-disable-next-line
  }, []);

  const handleBlock = () => {
    // eslint-disable-next-line
    if (
      role == "SuperAdmin" ||
      role == "ZonalAdmin" ||
      role == "DistrictAdmin"
    ) {
      Swal.fire({
        text: `Do you want to save the profile changes?`,
        confirmButtonText: "OK",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let formdata = new FormData();
          formdata.append("user_id", userid);
          formdata.append("is_block", checked ? 0 : 1);
          formdata.append("is_delete", 0);
          Api.ParticularUserDelete(formdata).then((res) => {
            if ((res.message = "Profile Updated Successfully")) {
              getAllCustomer();
            }
          });
        }
      });
    }
  };
  const handleDelete = (tech_id) => {
    const deleteData = filteredDate?.find((val) => val?.id === tech_id);
    // eslint-disable-next-line
    if (
      role == "SuperAdmin" ||
      role == "ZonalAdmin" ||
      role == "DistrictAdmin"
    ) {
      if (userid) {
        Swal.fire({
          text: `Are you sure, you want to remove ${deleteData?.fullname} ?`,
          confirmButtonText: "OK",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            let formdata = new FormData();
            formdata.append("user_id", tech_id);
            formdata.append("is_block", 0);
            formdata.append("is_delete", 1);
            Api.ParticularUserDelete(formdata).then((res) => {
              if ((res.message = "Profile Updated Successfully")) {
                getAllCustomer();
                Swal.fire({
                  text: `${deleteData?.fullname} Account Deleted`,
                });
              }
            });
          }
        });
      } else {
        Swal.fire({
          text: `Please Select User`,
          confirmButtonText: "OK",
          showCancelButton: true,
        });
      }
    }
  };

  /*  */
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */

    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name ? `${name?.split(" ")[0][0]}` : "",
    };
  }
  const handleFilterData = (filterData) => {
    // eslint-disable-next-line
    if (role == "DistrictAdmin") {
      Api.DistrictUserManagementAllCust(searchInput, filterData).then(
        (result) => {
          setAllCustomersDetails(result?.data);
          setFilteredDate(result?.data);
          result && HandelSingleUser(result?.data[0]?.id);
        }
      );
    }
    // eslint-disable-next-line
    if (role == "ZonalAdmin") {
      Api.ZonalUserManagementAllCust(searchInput, filterData).then((result) => {
        setAllCustomersDetails(result?.data);
        setFilteredDate(result?.data);
        result && HandelSingleUser(result?.data[0]?.id);
      });
    }
    // eslint-disable-next-line
    if (role == "SuperAdmin") {
      Api.SuperAdminUsermanagementGetAllCust(searchInput, filterData).then(
        (result) => {
          setAllCustomersDetails(result?.data);
          setFilteredDate(result?.data);
          result && HandelSingleUser(result?.data[0]?.id);
          /* setUserValue(result?.data?.length); */
        }
      );
    }
  };
  const handleSearchInput = (e) => {
    setSearchInput(e);
  };

  useEffect(() => {
    // eslint-disable-next-line
    if (role == "DistrictAdmin") {
      Api.DistrictUserManagementAllCust(searchInput).then((result) => {
        setAllCustomersDetails(result?.data);
        setFilteredDate(result?.data);
        result && HandelSingleUser(result?.data[0]?.id);
      });
    }
    // eslint-disable-next-line
    if (role == "ZonalAdmin") {
      Api.ZonalUserManagementAllCust(searchInput).then((result) => {
        setAllCustomersDetails(result?.data);
        setFilteredDate(result?.data);
        result && HandelSingleUser(result?.data[0]?.id);
      });
    }
    // eslint-disable-next-line
    if (role == "SuperAdmin") {
      Api.SuperAdminUsermanagementGetAllCust(searchInput).then((result) => {
        setAllCustomersDetails(result?.data);
        setFilteredDate(result?.data);
        result && HandelSingleUser(result?.data[0]?.id);
      });
    }
    // eslint-disable-next-line
  }, [searchInput]);

  const mobileView = useMediaQuery("(min-width: 200px) and (max-width: 799px)");
  return (
    <>
      {loading ? (
        <SpinnerLoader2 />
      ) : (
        <>
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-lg-4">
              <CustomerFilter
                inputPlaceholder={"Search by Name"}
                onSearchInputChange={handleSearchInput}
                onChangeFilterData={handleFilterData}
                pageRole={"customer"}
              />
              {filteredDate?.length > 0 ? (
                <div
                  className="custom-scrollbar-appliance mt-2"
                  style={{ width: "100%", height: "86vh", overflowY: "scroll" }}
                >
                  {filteredDate &&
                    filteredDate?.map((cust) => (
                      <Card
                        onClick={() => HandelSingleUser(cust?.id, cust)}
                        style={{
                          background:
                            cust.id === userid
                              ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                              : "white",
                          border:
                            cust.id === userid
                              ? "rgb(235, 129, 40) solid 1px"
                              : "",
                          marginBottom: "10px",
                          padding: "0px",
                          cursor: "pointer",
                          borderRadius: "10px",
                        }}
                      >
                        <Stack
                          direction="row p-0 m-0"
                          spacing={1}
                          className="center-container"
                        >
                          {cust?.fullname && !mobileView ? (
                            cust.avatar ? (
                              <Avatar
                                className="centered-text mt-4 ms-2"
                                src={cust.avatar}
                                style={{
                                  margin: "auto",
                                }}
                              />
                            ) : (
                              <Avatar
                                className="centered-text mt-4 ms-2"
                                {...stringAvatar(cust?.fullname)}
                                style={{
                                  margin: "auto",
                                }}
                              />
                            )
                          ) : (
                            <></>
                          )}
                          <p
                            className="centered-text ms-2 mt-3"
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            {cust.fullname}
                            <br></br>
                            <Tooltip title={cust?.email}>
                              <span>
                                {cust?.email?.length <= 20
                                  ? cust?.email
                                  : `${cust?.email?.slice(0, 20)}...`}
                              </span>
                            </Tooltip>
                          </p>
                          <Tooltip title={`Remove ${cust.fullname}`}>
                            <DeleteIcon
                              className="mt-4 me-4 custom-delete-icon"
                              style={{
                                color: "rgb(235, 129, 40)",
                                cursor: "pointer",
                                backgroundColor: "none",
                              }}
                              onClick={() => handleDelete(cust?.id)}
                            />
                          </Tooltip>
                        </Stack>
                        <span
                          className="ms-5 mt-0 p-1"
                          style={{ fontSize: "13px", fontWeight: "500" }}
                        ></span>
                      </Card>
                    ))}
                </div>
              ) : (
                <div>
                  <p className="text-center">No Records Found</p>
                </div>
              )}
            </div>
            {userid ? (
              <>
                <div className="col-lg-8 custom-appliance-input-scrollbar">
                  <div className={mobileMatches ? "column" : "row"}>
                    <div className="col-lg-2 mt-3">
                      <div
                        className="ms-lg-4"
                        style={{
                          margin: mobileMatches ? "0rem auto" : "0.1rem auto",
                          position: mobileMatches && "relative",
                          right: mobileMatches && "1.5rem",
                        }}
                      >
                        <img
                          src={image ? image : profile}
                          className="rounded-circle img"
                          style={{ width: 100, height: 100 }}
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className={mobileMatches ? "column" : "col-lg-2"}>
                      <Stack
                        direction="row"
                        spacing={2}
                        style={{
                          display: "flex",
                          flexDirection: mobileMatches && "column",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            margin: mobileMatches
                              ? "1rem auto 0rem auto"
                              : "4rem auto 0rem auto",
                          }}
                        >
                          <FormControlLabel
                            sx={{
                              display: "block",
                            }}
                            control={
                              <Switch
                                checked={checked}
                                onChange={handleChange}
                                color="warning"
                                {...label}
                              />
                            }
                          />
                          {checked ? (
                            <p
                              className="mt-2"
                              style={{ color: "rgb(235, 129, 40)" }}
                            >
                              {checked}ACTIVE
                            </p>
                          ) : (
                            <p
                              className="mt-2"
                              style={{ color: "rgb(235, 129, 40)" }}
                            >
                              {checked}INACTIVE
                            </p>
                          )}
                        </Box>
                        <div
                          onClick={() =>
                            setRatingsModal(
                              userRatings?.user_ratings?.length > 0 && true
                            )
                          }
                          className="viewreview"
                          style={{
                            /*  marginTop: "66px", */
                            margin: mobileView
                              ? "0px auto"
                              : "66px 0px 0px 30px",
                          }}
                        >
                          <div
                            style={{
                              textDecoration: "underline",
                              fontSize: "12px",
                              cursor:
                                userRatings?.user_ratings?.length > 0 &&
                                "pointer",
                            }}
                            onClick={() =>
                              setRatingsModal(
                                userRatings?.user_ratings?.length > 0 && true
                              )
                            }
                          >
                            Ratings & Reviews
                          </div>
                          <HalfRatings
                            rating={userRatings?.average_rating}
                            fontSize={"20px"}
                            isEditable
                          />
                        </div>
                      </Stack>
                    </div>
                  </div>

                  <div className="row ms-lg-3 mt-3">
                    <div className="col-lg-6 mt-lg-0">
                      <div>
                        <TextField
                          fullWidth
                          label="Full Name"
                          id="full-name"
                          value={name}
                          type="text"
                          placeholder="Customer Name"
                          error={IsFullNameEmpty ? true : false}
                          cursor="pointer"
                          helperText={
                            IsFullNameEmpty ? "please fill fullname" : ""
                          }
                          disabled
                          FormHelperTextProps={{}}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </div>

                      <div className="mt-lg-4 mt-3">
                        <TextField
                          fullWidth
                          style={{ height: "56px" }}
                          id="email"
                          value={email}
                          label="Email"
                          type="email"
                          placeholder="customer_name@mail.com"
                          InputProps={{
                            readOnly: true,
                          }}
                          disabled
                          error={!!emailError}
                          helperText={emailError}
                          FormHelperTextProps={{
                            style: {
                              position: "absolute",
                              bottom: "-20px",
                              whiteSpace: "nowrap",
                            },
                          }}
                          onKeyUp={() => {
                            const trimmedEmail = email.trim();
                            setEmail(trimmedEmail);

                            if (!trimmedEmail) {
                              setEmailError("Please enter your email address.");
                            } else if (!emailPattern.test(trimmedEmail)) {
                              setEmailError(
                                "Please enter a valid email address."
                              );
                            } else {
                              setEmailError("");
                            }
                          }}
                        />
                      </div>

                      <div>
                        <TextField
                          className="w-100 mt-3 mt-lg-3"
                          style={{ height: "47px" }}
                          id="mobile-number"
                          value={phoneno}
                          label="Phone number"
                          type="number"
                          placeholder=" 90000 00009"
                          error={!!mobileError}
                          InputProps={{
                            readOnly: true,
                          }}
                          disabled
                          helperText={mobileError}
                          FormHelperTextProps={{
                            style: {
                              position: "absolute",
                              bottom: "-20px",
                              whiteSpace: "nowrap",
                            },
                          }}
                          onKeyUp={() => {
                            if (!phoneno) {
                              setMobileError("Please enter your phone number.");
                            } else if (phoneno?.length !== 10) {
                              setMobileError("please enter valid phone number");
                            } else {
                              setMobileError("");
                            }
                          }}
                        />
                      </div>
                      <div>
                        <div className="mt-lg-4 mt-3">
                          <TextField
                            fullWidth
                            id="datePicker"
                            label="DOB"
                            type="date"
                            value={dob}
                            error={IsDobEmpty ? true : false}
                            helperText={IsDobEmpty ? "please select Dob" : ""}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end"></InputAdornment>
                              ),
                            }}
                            style={{
                              height: "47px",
                              marginTop: mobileView && "0.5rem",
                            }}
                          />
                        </div>
                      </div>

                      <div
                        className="row m-0 p-0 border border rounded-3 mt-3 mt-lg-3"
                        style={{ height: "54px" }}
                      >
                        <div
                          className="col border-right p-lg-2"
                          style={{
                            borderRight: "1px solid grey ",
                            height: "52px",
                            color: Ismale ? "rgb(235, 129, 40)" : "black",
                            cursor: "not-allowed",
                          }}
                        >
                          <IconButton
                            color="primary"
                            component="label"
                            style={{
                              cursor: "not-allowed",
                            }}
                          >
                            <img alt="demo" src={Ismale ? maleclor : male} />
                            <input
                              c={Ismale}
                              type="radio"
                              style={{ display: "none" }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </IconButton>
                          Male
                        </div>
                        <div
                          className="col p-lg-2"
                          style={{
                            color: IsFemale ? "rgb(235, 129, 40)" : "black",
                            cursor: "not-allowed",
                          }}
                        >
                          <IconButton
                            color="primary"
                            component="label"
                            style={{
                              cursor: "not-allowed",
                            }}
                          >
                            <img
                              alt="demo"
                              src={IsFemale ? femaleclor : female}
                            />
                            <input
                              c={IsFemale}
                              type="radio"
                              style={{ display: "none" }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </IconButton>
                          Female
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-12 mt-lg-0 mt-3">
                      <div className="mt-lg-0">
                        <TextField
                          fullWidth
                          label="Country"
                          value={"India"}
                          aria-readonly={true}
                          InputProps={{
                            readOnly: true,
                          }}
                          disabled
                        />
                      </div>

                      <div className="mt-lg-4 mt-3">
                        <TextField
                          fullWidth
                          label="State"
                          value={state}
                          aria-readonly={true}
                          InputProps={{
                            readOnly: true,
                          }}
                          disabled
                        />
                      </div>

                      <div className="mt-lg-4 mt-3">
                        <TextField
                          fullWidth
                          label="Address"
                          type="text"
                          value={address}
                          InputProps={{
                            readOnly: true,
                          }}
                          disabled
                        />
                      </div>
                      <div className="row mt-lg-4 mt-3">
                        <div className="col-6">
                          <TextField
                            fullWidth
                            label="City"
                            value={city}
                            type="text"
                            placeholder="Nearest Landmark"
                            InputProps={{
                              readOnly: true,
                            }}
                            disabled
                          />
                        </div>

                        <div className="col-6">
                          <div>
                            <TextField
                              fullWidth
                              label="Postal Code"
                              value={postal_code}
                              type="text"
                              error={IsPostalEmpty ? true : false}
                              helperText={IsPostalEmpty ? "postal" : ""}
                              FormHelperTextProps={{}}
                              InputProps={{
                                readOnly: true,
                              }}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-1"></div>
                  </div>
                  <div className="border border-bottom mt-3"></div>
                  <div className="row mt-lg-4 mt-3">
                    <div className="col"></div>
                    <div className="col text-end">
                      {userid ? (
                        <ul className="p-0" style={{ display: "inline-flex" }}>
                          <li style={{ listStyle: "none" }}></li>
                          <li className="me-2" style={{ listStyle: "none" }}>
                            <Button
                              onClick={handleBlock}
                              variant="contained"
                              type="submit"
                              style={{
                                height: "45px",
                                color: "white",
                                backgroundColor: "#EB8128",
                                textTransform: "none",
                              }}
                            >
                              Save Profile
                            </Button>
                          </li>
                        </ul>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <Modal
                  show={ratingsModal}
                  size="md"
                  onHide={() => setRatingsModal(false)}
                  centered
                  className="filter-modal"
                  dialogClassName="modal-scroll"
                >
                  <div className="p-2">
                    <div>
                      <b>Ratings & Reviews</b>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            marginTop: "14px",
                          }}
                        >
                          <div>
                            <HalfRatings
                              rating={userRatings?.average_rating}
                              fontSize={"26px"}
                            />
                          </div>
                          <div style={{ marginTop: "5px" }}>
                            {userRatings?.average_rating} out of 5
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        maxHeight: "300px",
                      }}
                    >
                      {userRatings?.user_ratings?.map((item, index) => {
                        return (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "6px",
                              }}
                              key={index}
                            >
                              <Avatar
                                className="centered-text mt-3 ms-2"
                                src={
                                  item?.reviewer_image
                                    ? item?.reviewer_image
                                    : stringAvatar(item?.reviewer_name)
                                }
                                {...stringAvatar(item?.reviewer_name)}
                              />
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "2px",
                                }}
                              >
                                <div>
                                  <span style={{}}>
                                    <b>{item?.reviewer_name}</b>
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "11px",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    on{" "}
                                    {moment(item?.created_at).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </span>
                                </div>
                                <Rating
                                  name="read-only"
                                  value={item?.rating_value}
                                  readOnly
                                  precision={0.5}
                                />
                              </div>
                            </div>
                            <div style={{ marginLeft: "50px" }}>
                              <text style={{ fontSize: "13px" }}>
                                {showFullReviews[index]
                                  ? item?.comment
                                  : `${item?.comment.slice(0, 177)}`}
                              </text>
                              {item?.comment?.length > 177 && (
                                <text
                                  style={{
                                    color: "rgb(235, 129, 40)",
                                    cursor: "pointer",
                                    fontSize: "12px",
                                  }}
                                  onClick={() => {
                                    const updatedShowFullReviews = [
                                      ...showFullReviews,
                                    ];
                                    updatedShowFullReviews[index] =
                                      !updatedShowFullReviews[index];
                                    setShowFullReviews(updatedShowFullReviews);
                                  }}
                                >
                                  {showFullReviews[index]
                                    ? " Show Less"
                                    : " ...Show More"}
                                </text>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Modal>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default AllCustomers;
