import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import { UpdateRewardRequest, UpdateRewardFail, UpdateRewardSuccess } from "../slices/updateRewardSlice";
export const UpdateReward = (payload) => async (dispatch) => {
    const token = localStorage.getItem("token");

    try {
        dispatch(UpdateRewardRequest())
        const response = await axios.patch(`${BASE_URL}/reward`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch(UpdateRewardSuccess(response?.data))
    } catch (error) {
        console.log(error);
        dispatch(UpdateRewardFail(error?.response?.data))
    }
}
