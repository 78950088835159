import React, { useEffect, useState } from "react";
import Topbar from "../../../topbar";
import Sidebar from "../../../Sidebar";
import SpinnerLoader from "../../../../plugins/SpinnerLoader";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from "@mui/material";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import Api from "../../../../service/services";
import { useDispatch, useSelector } from "react-redux";
import {
  PaymentOrder,
  paymentErrorClear,
} from "../../../../redux/actions/paymentAction";
import OrderAddress from "../../../../components/Technicians/OrderAddress";
import { Modal } from "react-bootstrap";
import { IoCartOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import ToastMessage from "../../../../plugins/ToastMessage";
import { BsBookmarkHeart } from "react-icons/bs";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
export default function AddToCart() {
  const theme = useTheme();
  const mobileMatches = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(false);
  const [addressModalValue, setAddressModalValue] = useState(false);
  const [cartList, setCartList] = useState({});
  const [address, setAddress] = useState({});
  const [showDeleteCart, setShowDeleteCart] = useState(false);
  const [showDeleteCartWishList, setShowDeleteCartWishList] = useState(false);
  const [savedForLaterList, setSavedForLaterList] = useState({});
  const { paymentOrder, error } = useSelector(
    (state) => state.paymentOrderState
  );
  const [selectedCartId, setSelectedCartId] = useState(null);
  const [selectedWishListId, setSelectedWishListId] = useState(null);
  const [deliveryChargesAndTaxes, setDeliveryChargesAndTaxes] = useState({});
  const [loading, setLoading] = useState(false);
  const [totalCartItems, setTotalCartItems] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const paymentURLTrigger = paymentOrder?.redirectInfo?.url;
  useEffect(() => {
    if (paymentURLTrigger) {
      window.location.replace(paymentURLTrigger);
    }
  }, [paymentURLTrigger]);
  const moveToCart = (id) => {
    Api.moveToCart(id)
      .then((cartRes) => {
        if (cartRes && !error) {
          toast.success(cartRes, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        Api.savedForLaterList()
          .then((res) => {
            setSavedForLaterList(res);
            Api.cartLists(address ? address?.id : "")
              .then((res) => {
                setCartList(res);
                setDeliveryChargesAndTaxes(res);
                Api.cartLists()
                  .then((res) => {
                    setCartList(res);
                    const total = res.cart.reduce((acc, item) => {
                      return acc + item?.quantity;
                    }, 0);
                    setTotalCartItems(total);
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          })

          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  const increaseQuantityCart = (index) => {
    const updatedCart = [...cartList.cart];
    updatedCart[index].quantity += 1;
    const payload = {
      cart_id: updatedCart[index].id,
      quantity: updatedCart[index].quantity,
    };
    Api.updateCart(payload)
      .then((res) => {
        if (res?.response?.status === 400) {
          toast.error(res?.response?.data, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        Api.cartLists(address ? address?.id : "")
          .then((res) => {
            setCartList(res);
            setDeliveryChargesAndTaxes(res);
            Api.cartLists()
              .then((res) => {
                setCartList(res);
                const total = res.cart.reduce((acc, item) => {
                  return acc + item?.quantity;
                }, 0);
                setTotalCartItems(total);
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
    setCartList({ ...cartList, cart: updatedCart });
  };
  const decreaseQuantityCart = (index) => {
    const updatedCart = [...cartList.cart];
    const minQuantity = updatedCart[index]?.minimum_quantity || 1;

    if (updatedCart[index].quantity > minQuantity) {
      updatedCart[index].quantity -= 1;
      const payload = {
        cart_id: updatedCart[index].id,
        quantity: updatedCart[index].quantity,
      };
      Api.updateCart(payload)
        .then((res) => {
          if (res?.response?.status === 400) {
            toast.error(res?.response?.data, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          Api.cartLists(address ? address?.id : "")
            .then((res) => {
              setCartList(res);
              setDeliveryChargesAndTaxes(res);
              Api.cartLists()
                .then((res) => {
                  setCartList(res);
                  const total = res.cart.reduce((acc, item) => {
                    return acc + item?.quantity;
                  }, 0);
                  setTotalCartItems(total);
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
      setCartList({ ...cartList, cart: updatedCart });
    }
  };
  const handleSaveForLater = (id) => {
    Api.saveForLater(id)
      .then((res) => {
        if (res?.response?.status === 400) {
          toast.error(res?.response?.data, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (!error) {
          toast.success(res, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        Api.savedForLaterList()
          .then((res) => {
            setSavedForLaterList(res);
            Api.cartLists(address ? address?.id : "")
              .then((res) => {
                setCartList(res);
                setDeliveryChargesAndTaxes(res);
                Api.cartLists()
                  .then((res) => {
                    setCartList(res);
                    const total = res.cart.reduce((acc, item) => {
                      return acc + item?.quantity;
                    }, 0);
                    setTotalCartItems(total);
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const handleSelectedAddress = (addressValue) => {
    console.log("addressValue", addressValue);

    setAddress(addressValue);
  };

  const handleSelectedFirstAddress = (addressValue) => {
    setAddress(addressValue);
  };

  const openDeleteModalCart = (item) => {
    setSelectedCartId(item?.id);
    setShowDeleteCart(true);
  };
  const openDeleteModalWishList = (item) => {
    setSelectedWishListId(item?.id);
    setShowDeleteCartWishList(true);
  };
  useEffect(() => {
    setLoadingData(true);
    setTimeout(() => {
      setLoadingData(false);
    }, 1000);
    Api.savedForLaterList()
      .then((res) => {
        setSavedForLaterList(res);
      })
      .catch((err) => console.log(err));

    Api.cartLists()
      .then((res) => {
        setCartList(res);
        const total = res.cart.reduce((acc, item) => {
          return acc + item?.quantity;
        }, 0);
        setTotalCartItems(total);
      })
      .catch((err) => console.log(err));
    cartList?.cart?.map((discount, i) => {
      // setDiscountPrice(discount?.discounted_price);
    }); // eslint-disable-next-line
  }, []);

  const handlePayment = () => {
    setLoading(true);
    const arr = [];
    cartList?.cart?.map((val, i) => {
      arr.push(val?.id);
    });

    const cart_ids = cartList?.cart?.map((val) => val?.id) || [];
    const payload = {
      cart_ids,
      address_id: address?.id?.toString(),
    };
    dispatch(PaymentOrder(payload));
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(paymentErrorClear());
      }, 3000);
    } // eslint-disable-next-line
  }, []);
  const deleteItemFromCart = () => {
    Api.RemoveFromCart(selectedCartId)
      .then((res) => {
        if (res?.response?.status === 400) {
          toast.error(res?.response?.data, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          Api.cartLists(address ? address?.id : "")
            .then((res) => {
              setCartList(res);
              setDeliveryChargesAndTaxes(res);
              Api.cartLists()
                .then((res) => {
                  setCartList(res);
                  const total = res.cart.reduce((acc, item) => {
                    return acc + item?.quantity;
                  }, 0);
                  setTotalCartItems(total);
                })
                .catch((err) => {
                  console.log("deleteItemFromCart", err);
                });
            })
            .catch((err) => console.log(err));
        }
        if (!error) {
          toast.success(res, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setSelectedCartId(null);
        Api.cartLists()
          .then((res) => {
            setCartList(res);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  const deleteItemFromCartt = () => {
    Api.RemoveFromCart(selectedWishListId)
      .then((res) => {
        Api.cartLists(address ? address?.id : "")
          .then((res) => {
            setCartList(res);
            setDeliveryChargesAndTaxes(res);
            Api.cartLists()
              .then((res) => {
                setCartList(res);
                const total = res.cart.reduce((acc, item) => {
                  return acc + item?.quantity;
                }, 0);
                setTotalCartItems(total);
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
        if (!error) {
          toast.success("Item successfully deleted", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setSelectedWishListId(null);
        Api.cartLists()
          .then((res) => {
            setCartList(res);
          })
          .catch((err) => console.log(err));
        Api.savedForLaterList()
          .then((res) => {
            setSavedForLaterList(res);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  const handleAddressChangeRes = (res) => {
    setDeliveryChargesAndTaxes(res);
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <Topbar />
      <div className="row">
        <div className="col-lg-2 col-12 border border-right">
          <Sidebar />
        </div>
        <div
          className="col-12 user_management"
          style={{ marginTop: "80px", fontFamily: "Arial, sans-serif" }}
        >
          {loadingData ? (
            <div
              className="d-flex align-items-center"
              style={{ justifyContent: "center", height: "70vh" }}
            >
              <SpinnerLoader />
            </div>
          ) : (
            <div
              style={{
                overflow: "hidden",
                padding: addressModalValue ? "0px 5px" : "0px 10px",
              }}
            >
              <div className="col-lg-12 ">
                <div
                  className="m-0 sticky-top bg-white"
                  style={{ position: "sticky", zIndex: "2" }}
                >
                  <i
                    className="bi bi-chevron-left me-2"
                    onClick={() => navigate(-1)}
                    style={{
                      fontWeight: "600",
                      fontSize: "21px",
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                  ></i>
                  <text
                    className="p-0"
                    style={{
                      fontWeight: "600",
                      color: "#1E232C",
                      fontSize: "21px",
                      width: "208px",
                      height: "32px",
                    }}
                  >
                    Cart
                  </text>
                  {cartList?.cart?.length > 0 ? (
                    <div
                      className="orderaddress"
                      style={{
                        marginTop: "0.5rem",
                        width: "100%",
                        marginBottom: "1rem",
                        // marginLeft: "15px",
                      }}
                    >
                      <OrderAddress
                        handleSelectedAddress={handleSelectedAddress}
                        handleSelectedFirstAddress={handleSelectedFirstAddress}
                        onAddressChange={handleAddressChangeRes}
                        addressModalValue={(e) => setAddressModalValue(e)}
                      />
                    </div>
                  ) : (
                    <div
                      className="d-flex align-items-center"
                      style={{ justifyContent: "center" }}
                    >
                      <div
                        style={{
                          color: "rgb(235, 129, 40)",
                          textAlign: "center",
                          fontSize: "20px",
                        }}
                      >
                        No Items in Cart !
                      </div>
                    </div>
                  )}
                  <div className="addtocart">
                    <div
                      className="addtocartwidth"
                      style={{
                        height:
                          // cartList?.cart?.length > 0 &&
                          // savedForLaterList?.cart?.length > 0
                          //   ? "68vh"
                          //   : cartList?.cart?.length > 0
                          //   ? "68vh"
                          //   :
                          address?.address && cartList?.cart?.length > 0
                            ? "60vh"
                            : "68vh",
                        width:
                          (cartList?.cart?.length > 0 &&
                            savedForLaterList?.cart?.length > 0) ||
                          cartList?.cart?.length > 0
                            ? "76%"
                            : savedForLaterList?.cart?.length > 0 && "100%",
                        overflowX: "hidden",
                        overflowY:
                          cartList?.cart?.length > 0 ||
                          savedForLaterList?.cart?.length > 0
                            ? "auto"
                            : "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {cartList?.cart?.length > 0 ? (
                        cartList?.cart.map((item, index) => (
                          <Card
                            className="cart-list"
                            key={index}
                            sx={{ maxWidth: 480 }}
                            style={{
                              marginTop: "1px",
                              marginBottom: "15px",
                              marginLeft: 3,
                              height: "200px",
                            }}
                          >
                            <div
                              style={{ display: "flex" }}
                              onClick={() => setSelectedCartId(item?.id)}
                            >
                              <div style={{ padding: 6 }}>
                                <CardMedia
                                  component="img"
                                  sx={{ height: 140 }}
                                  image={item?.product_img}
                                  title={item?.name}
                                  style={{
                                    height: "120px",
                                    width: "100px",
                                    objectFit: "contain",
                                  }}
                                />
                                <div
                                  style={{
                                    marginTop: "20px",
                                    fontSize: "13px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <text
                                    onClick={() => decreaseQuantityCart(index)}
                                    style={{
                                      color:
                                        cartList.cart[index].quantity <=
                                        cartList.cart[index].minimum_quantity
                                          ? "#FF8A8A"
                                          : "red",
                                      cursor:
                                        cartList.cart[index].quantity <=
                                        cartList.cart[index].minimum_quantity
                                          ? "not-allowed"
                                          : "pointer",
                                    }}
                                  >
                                    <FaMinus />
                                  </text>
                                  <text
                                    style={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                      width: "40px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item?.quantity}
                                  </text>
                                  <text
                                    onClick={() => increaseQuantityCart(index)}
                                    style={{
                                      marginLeft: "6px",
                                      color: "green",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <FaPlus />
                                  </text>
                                </div>
                              </div>

                              <CardContent style={{ width: "100%" }}>
                                <Tooltip title={item?.name}>
                                  <h3
                                    className="res-open"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      fontFamily: "Arial, sans-serif",
                                      marginBottom: "1px",
                                      marginTop: "-10px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item?.name?.length > 16
                                      ? `${item?.name.slice(0, 16)}...`
                                      : item?.name}
                                  </h3>
                                  <h3
                                    className="res-none"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      fontFamily: "Arial, sans-serif",
                                      marginBottom: "1px",
                                      marginTop: "-10px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item?.name}
                                  </h3>
                                </Tooltip>
                                <div>
                                  <div
                                    className="res-none"
                                    style={{
                                      fontSize: "12px",
                                      height: item?.summary ? "34px" : "12px",
                                      textWrap: "wrap",
                                    }}
                                  >
                                    {item?.summary}
                                  </div>
                                  <div
                                    className="res-open"
                                    style={{
                                      fontSize: "14px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      height: "50px",
                                    }}
                                  >
                                    {item?.summary?.length > 16
                                      ? `${item?.summary.slice(0, 16)}...`
                                      : item?.summary}
                                  </div>
                                </div>

                                <div style={{ marginTop: "20px" }}>
                                  <span
                                    style={{
                                      fontWeight: "1000px",
                                      fontSize: "1rem",
                                      fontFamily: "Arial, sans-serif",
                                    }}
                                  >
                                    <b> ₹{item?.amount}</b>
                                  </span>
                                  {item?.isDiscount && (
                                    <span
                                      className="ms-3"
                                      style={{
                                        textDecoration: "line-through",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      ₹
                                      {(
                                        item?.sale_price * item?.quantity
                                      )?.toFixed(2)}
                                    </span>
                                  )}
                                  {item?.isDiscount && (
                                    <span
                                      className="ms-1"
                                      style={{
                                        color: "green",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      <b>
                                        {" "}
                                        {Math.round(item?.discount_percent)}%
                                        off
                                      </b>
                                    </span>
                                  )}
                                </div>
                                <div>
                                  {item?.isDiscount && (
                                    <span
                                      className="res-none"
                                      style={{
                                        color: "green",
                                        fontSize: "13px",
                                        position: "relative",
                                        // bottom: "0.5rem",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      <b>
                                        {" "}
                                        You might save up to ₹
                                        {(
                                          item?.discounted_price *
                                          item?.quantity
                                        ).toFixed(2)}
                                      </b>
                                    </span>
                                  )}
                                </div>
                                <div
                                  style={{
                                    height: item?.discounted_price
                                      ? "14px"
                                      : "36px",
                                  }}
                                >
                                  {item?.delivery_date && (
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        position: "relative",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      Delivery by{" "}
                                      {moment(item?.delivery_date).format(
                                        "ddd, MMM D"
                                      )}
                                    </span>
                                  )}
                                </div>

                                <div
                                  className="saveForLaterButton"
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <Button
                                    className="res-none save-for-later"
                                    onClick={() => handleSaveForLater(item?.id)}
                                  >
                                    <b>Save for Later</b>
                                  </Button>

                                  <button
                                    className="res-open"
                                    onClick={() => handleSaveForLater(item?.id)}
                                    style={{
                                      cursor: "pointer",
                                      color: "rgb(235, 129, 40)",
                                      backgroundColor: "transparent",
                                      border: "1px solid transparent",
                                    }}
                                  >
                                    <BsBookmarkHeart
                                      size={20}
                                      style={{
                                        color: "rgb(235, 129, 40)",
                                      }}
                                    />
                                  </button>
                                  <Button
                                    className="save-for-later"
                                    onClick={() => openDeleteModalCart(item)}
                                    style={{
                                      color: "black",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <b>Remove</b>
                                  </Button>
                                </div>
                              </CardContent>
                            </div>
                          </Card>
                        ))
                      ) : (
                        <></>
                      )}

                      <div>
                        {savedForLaterList?.cart?.length > 0 && (
                          <div>
                            {savedForLaterList?.cart?.length > 0 && (
                              <div style={{ marginTop: "20px" }}>
                                <b>
                                  {" "}
                                  Saved For Later (
                                  {savedForLaterList?.cart?.length
                                    ? savedForLaterList?.cart?.length
                                    : 0}
                                  ){" "}
                                </b>
                              </div>
                            )}
                            <div
                              style={{
                                display:
                                  savedForLaterList?.cart?.length > 0 &&
                                  cartList?.cart?.length === 0 &&
                                  "grid",
                                gridTemplateColumns:
                                  savedForLaterList?.cart?.length > 0 &&
                                  cartList?.cart?.length === 0 &&
                                  "repeat(auto-fit, minmax(400px, 1fr))",
                              }}
                            >
                              {savedForLaterList?.cart.map((item, index) => (
                                <Card
                                  className="cart-list"
                                  key={index}
                                  sx={{ maxWidth: 480 }}
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "15px",
                                    marginLeft: 3,
                                    height: "200px",
                                  }}
                                >
                                  <div
                                    style={{ display: "flex" }}
                                    onClick={() =>
                                      setSelectedWishListId(item?.id)
                                    }
                                  >
                                    <div style={{ padding: 6 }}>
                                      <CardMedia
                                        component="img"
                                        sx={{ height: 140 }}
                                        image={item?.product_img}
                                        title={item?.name}
                                        style={{
                                          height: "120px",
                                          width: "100px",
                                          objectFit: "contain",
                                        }}
                                      />
                                      <div
                                        style={{
                                          marginTop: "20px",
                                          fontSize: "13px",
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                        disabled
                                      >
                                        <div
                                          disabled={true}
                                          style={{
                                            color: "#FF8A8A",
                                            cursor: "not-allowed",
                                          }}
                                        >
                                          <FaMinus />
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                          }}
                                        >
                                          {item?.quantity}
                                        </div>
                                        <div
                                          disabled
                                          style={{
                                            marginLeft: "6px",
                                            color: "#00800087",
                                            cursor: "not-allowed",
                                          }}
                                        >
                                          <FaPlus />
                                        </div>
                                      </div>
                                    </div>

                                    <CardContent style={{ width: "100%" }}>
                                      <Tooltip title={item?.name}>
                                        <h3
                                          className="res-open"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            fontFamily: "Arial, sans-serif",
                                            marginBottom: "1px",
                                            marginTop: "-10px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {item?.name?.length > 16
                                            ? `${item?.name.slice(0, 16)}...`
                                            : item?.name}
                                        </h3>
                                        <h3
                                          className="res-none"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            fontFamily: "Arial, sans-serif",
                                            marginBottom: "1px",
                                            marginTop: "-10px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {item?.name}
                                        </h3>
                                      </Tooltip>
                                      <div>
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            height: "22px",
                                            textWrap: "wrap",
                                          }}
                                          className="res-none"
                                        >
                                          {item?.summary}
                                        </div>
                                        <div
                                          className="res-open"
                                          style={{
                                            fontSize: "14px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            height: "22px",
                                          }}
                                        >
                                          {item?.summary?.length > 16
                                            ? `${item?.summary.slice(0, 16)}...`
                                            : item?.summary}
                                        </div>
                                      </div>

                                      <div style={{ marginTop: "10px" }}>
                                        <span
                                          style={{
                                            fontWeight: "1000px",
                                            fontSize: "1rem",
                                            fontFamily: "Arial, sans-serif",
                                            marginTop: "20px",
                                          }}
                                        >
                                          <b> ₹{item?.amount}</b>
                                        </span>
                                        {item?.discounted_price && (
                                          <span
                                            className="ms-3"
                                            style={{
                                              textDecoration: "line-through",
                                              fontFamily: "Arial, sans-serif",
                                            }}
                                          >
                                            ₹
                                            {(
                                              item?.sale_price * item?.quantity
                                            )?.toFixed(2)}
                                          </span>
                                        )}
                                        {item?.discounted_price && (
                                          <span
                                            className="ms-1"
                                            style={{
                                              color: "green",
                                              fontFamily: "Arial, sans-serif",
                                            }}
                                          >
                                            <b>
                                              {" "}
                                              {Math.round(
                                                item?.discount_percent
                                              )}
                                              % off
                                            </b>
                                          </span>
                                        )}
                                      </div>
                                      <div>
                                        {item?.discounted_price && (
                                          <span
                                            className="res-none"
                                            style={{
                                              color: "green",
                                              fontSize: "13px",
                                              position: "relative",
                                              fontFamily: "Arial, sans-serif",
                                            }}
                                          >
                                            <b>
                                              {" "}
                                              You might save up to ₹
                                              {(
                                                item?.discounted_price *
                                                item?.quantity
                                              ).toFixed(2)}
                                            </b>
                                          </span>
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          height: item?.discounted_price
                                            ? "14px"
                                            : "36px",
                                        }}
                                      >
                                        {item?.delivery_date && (
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              position: "relative",
                                              fontFamily: "Arial, sans-serif",
                                            }}
                                          >
                                            Delivery by{" "}
                                            {moment(item?.delivery_date).format(
                                              "ddd, MMM D"
                                            )}
                                          </span>
                                        )}
                                      </div>
                                      <div
                                        className="saveForLaterButton"
                                        style={{
                                          display: "flex",
                                        }}
                                      >
                                        <Button
                                          className="res-none save-for-later"
                                          onClick={() => {
                                            moveToCart(item?.id);
                                          }}
                                        >
                                          <b>Move to cart</b>
                                        </Button>

                                        <button
                                          className="res-open"
                                          onClick={() => moveToCart(item?.id)}
                                          style={{
                                            cursor: "pointer",
                                            color: "rgb(235, 129, 40)",
                                            backgroundColor: "transparent",
                                            border: "1px solid transparent",
                                            marginTop: "7px",
                                          }}
                                        >
                                          <IoCartOutline
                                            size={20}
                                            style={{
                                              color: "rgb(235, 129, 40)",
                                            }}
                                          />
                                        </button>
                                        <Button
                                          className="save-for-later"
                                          onClick={() =>
                                            openDeleteModalWishList(item)
                                          }
                                          style={{
                                            color: "black",
                                            textDecoration: "none",
                                            marginTop: mobileMatches
                                              ? "7px"
                                              : "0px",
                                          }}
                                        >
                                          <b>Remove</b>
                                        </Button>
                                        <Modal
                                          show={showDeleteCartWishList}
                                          onHide={() =>
                                            setShowDeleteCartWishList(false)
                                          }
                                          centered
                                        >
                                          <div className="p-3">
                                            <div
                                              className="pt-3 pb-4"
                                              style={{ textAlign: "center" }}
                                            >
                                              Are you sure, you want to remove
                                              this item?
                                            </div>
                                            <div
                                              className="d-flex align-items-center pb-2"
                                              style={{
                                                justifyContent: "center",
                                                gap: "10px",
                                              }}
                                            >
                                              <Button
                                                variant="secondary"
                                                onClick={() =>
                                                  setShowDeleteCartWishList(
                                                    false
                                                  )
                                                }
                                              >
                                                Cancel
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  deleteItemFromCartt();
                                                  setShowDeleteCartWishList(
                                                    false
                                                  );
                                                }}
                                                style={{
                                                  backgroundColor:
                                                    "rgb(235, 129, 40)",
                                                  color: "white",
                                                  border:
                                                    "1px solid rgb(235, 129, 40)",
                                                }}
                                              >
                                                Remove
                                              </Button>
                                            </div>
                                          </div>
                                        </Modal>
                                      </div>
                                    </CardContent>
                                  </div>
                                </Card>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {cartList?.cart?.length > 0 && (
                      <div
                        className="payment_detail"
                        style={
                          {
                            // marginTop: "0.9rem",
                          }
                        }
                      >
                        <Card
                          sx={{ maxWidth: "100%" }}
                          style={{
                            height: "59.8vh",
                          }}
                        >
                          <CardContent>
                            <div style={{}}>
                              <Typography
                                gutterBottom
                                variant="div"
                                component="div"
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                Payment Details
                              </Typography>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {totalCartItems > 1 ? (
                                  <p
                                    style={{ color: "gray", marginTop: "10px" }}
                                  >
                                    Price ({totalCartItems} items)
                                  </p>
                                ) : (
                                  <p
                                    style={{ color: "gray", marginTop: "10px" }}
                                  >
                                    Price ({totalCartItems} item)
                                  </p>
                                )}

                                <p
                                  style={{ color: "black", marginTop: "10px" }}
                                >
                                  ₹
                                  {cartList?.total_amount
                                    ? cartList?.total_amount
                                    : 0}
                                </p>
                              </div>
                              {deliveryChargesAndTaxes?.total_delivery_charge && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p style={{ color: "gray" }}>
                                    Total Delivery Charge
                                  </p>
                                  <p
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    ₹
                                    {deliveryChargesAndTaxes?.total_delivery_charge
                                      ? deliveryChargesAndTaxes?.total_delivery_charge
                                      : "-"}
                                  </p>
                                </div>
                              )}
                              {deliveryChargesAndTaxes?.tax_amount && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p style={{ color: "gray" }}>
                                    Tax Amount ({" "}
                                    {deliveryChargesAndTaxes?.tax_percentage
                                      ? deliveryChargesAndTaxes?.tax_percentage
                                      : "0"}{" "}
                                    )
                                  </p>
                                  <p
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    ₹
                                    {deliveryChargesAndTaxes?.tax_amount
                                      ? deliveryChargesAndTaxes?.tax_amount
                                      : " -"}
                                  </p>
                                </div>
                              )}

                              <hr
                                style={{
                                  border: "1px solid gray",
                                }}
                              />
                              {deliveryChargesAndTaxes?.net_payable_amount && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "22px",
                                  }}
                                >
                                  <p style={{ color: "black" }}>
                                    {" "}
                                    <b>Net Payable Amount</b>
                                  </p>
                                  <p style={{ color: "black" }}>
                                    <b>
                                      ₹
                                      {deliveryChargesAndTaxes?.net_payable_amount
                                        ? deliveryChargesAndTaxes?.net_payable_amount
                                        : "-"}
                                    </b>
                                  </p>
                                </div>
                              )}
                              {cartList?.cart?.length > 0 && (
                                <div
                                  style={{
                                    position: "sticky",
                                    bottom: 0,
                                    zIndex: 1,
                                    background: "white",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "rgb(235, 129, 40)",
                                      width: "100%",
                                      marginTop: "10px",
                                      transition:
                                        "background-color 0.3s ease-in-out",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      padding: "10px",
                                      borderRadius: "5px",
                                      color: "#fff",
                                    }}
                                    disabled={
                                      loading ||
                                      Object.keys(address)?.length === 0
                                    }
                                    onClick={() => {
                                      setConfirmModal(true);
                                    }}
                                    onMouseOver={(e) =>
                                      (e.currentTarget.style.backgroundColor =
                                        "darkorange")
                                    }
                                    onMouseOut={(e) =>
                                      (e.currentTarget.style.backgroundColor =
                                        "rgb(235, 129, 40)")
                                    }
                                  >
                                    {!loading && (
                                      <ShoppingCartIcon
                                        style={{ marginRight: "5px" }}
                                      />
                                    )}
                                    Place Order
                                  </Button>
                                  <div
                                    className="m-1 mt-1 "
                                    style={{ color: "red", fontSize: "11px" }}
                                  >
                                    *Please avoid going back while making
                                    payment.
                                  </div>
                                  <Modal
                                    className="filter-modal"
                                    show={confirmModal}
                                    onHide={() => setConfirmModal(false)}
                                    centered
                                  >
                                    <div className="p-3">
                                      <h3>
                                        <b>Product(s)</b>
                                      </h3>
                                      <div
                                        style={{
                                          maxHeight: "100px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        {cartList?.cart.map((item, index) => (
                                          <div
                                            key={index}
                                            style={{ marginBottom: "10px" }}
                                          >
                                            {index + 1}. {item?.name} - Qty(
                                            {item?.quantity})
                                          </div>
                                        ))}
                                      </div>

                                      <div
                                        className="pt-3 pb-4"
                                        style={{
                                          textAlign: "center",
                                          color: "red",
                                        }}
                                      >
                                        Please avoid going back while making a
                                        payment.
                                        <br />
                                        If you do, you won't be able to place an
                                        order for the next 20 minutes.{" "}
                                      </div>
                                      <div
                                        className="d-flex align-items-center pb-2"
                                        style={{
                                          justifyContent: "center",
                                          gap: "10px",
                                        }}
                                      >
                                        <Button
                                          variant="secondary"
                                          style={{
                                            border:
                                              "1px solid rgb(235, 129, 40)",
                                          }}
                                          onClick={() => setConfirmModal(false)}
                                        >
                                          Cancel
                                        </Button>
                                        {loading ? (
                                          <Button
                                            onClick={handlePayment}
                                            style={{
                                              backgroundColor:
                                                "rgb(235, 129, 40)",
                                              color: "white",
                                              border:
                                                "1px solid rgb(235, 129, 40)",
                                              height: "35px",
                                              width: "130px",
                                            }}
                                          >
                                            <CircularProgress
                                              size={24}
                                              style={{
                                                position: "absolute",
                                                color: "white",
                                              }}
                                            />
                                          </Button>
                                        ) : (
                                          <Button
                                            onClick={handlePayment}
                                            style={{
                                              backgroundColor:
                                                "rgb(235, 129, 40)",
                                              color: "white",
                                              border:
                                                "1px solid rgb(235, 129, 40)",
                                            }}
                                          >
                                            Proceed to Pay
                                          </Button>
                                        )}
                                      </div>
                                    </div>
                                  </Modal>
                                </div>
                              )}
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ToastMessage
        message={error}
        status={"error"}
        clearFunc={paymentErrorClear}
        hideProgressBar={true}
      />
      <Modal
        show={showDeleteCart}
        onHide={() => setShowDeleteCart(false)}
        centered
      >
        <div className="p-3">
          <div className="pt-3 pb-4" style={{ textAlign: "center" }}>
            Are you sure, you want to remove this item?
          </div>
          <div
            className="pb-2"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              variant="secondary"
              onClick={() => setShowDeleteCart(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                deleteItemFromCart();
                setShowDeleteCart(false);
              }}
              style={{
                backgroundColor: "rgb(235, 129, 40)",
                color: "white",
                border: "1px solid rgb(235, 129, 40)",
              }}
            >
              Remove
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
