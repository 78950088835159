import { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const InfoPopover = ({
  isOpen,
  onToggle,
  size,
  ondelete,
  image,
  onImageChange,
  fileInputRef,
}) => {
  const popoverRef = useRef(null);
  const iconRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = (event) => {
        if (
          popoverRef.current &&
          !popoverRef.current.contains(event.target) &&
          iconRef.current &&
          !iconRef.current.contains(event.target)
        ) {
          setIsHovered(false);
          setIsRemovedHover(false);
          onToggle();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    } else {
      setIsHovered(false);
      setIsRemovedHover(false);
    }
  }, [isOpen, onToggle]);
  const [isHovered, setIsHovered] = useState(false);
  const [isRemovedHover, setIsRemovedHover] = useState(false);

  const popoverStyles = {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    background: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    zIndex: 9999,
    width: "21vh",
  };

  const arrowStyles = {
    position: "absolute",
    width: 0,
    height: 0,
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderBottom: "8px solid #fff",
    top: "-8px",
    left: "50%",
    transform: "translateX(-50%)",
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <div ref={iconRef} onClick={onToggle}>
        <IconButton
          component="span"
          sx={{
            backgroundColor: "#EB8128",
            "&:hover": {
              backgroundColor: "#EB8128",
            },
          }}
        >
          {image ? (
            <EditIcon
              size={size}
              style={{ cursor: "pointer", color: "white" }}
            />
          ) : (
            <AddIcon
              size={size}
              style={{ cursor: "pointer", color: "white" }}
            />
          )}
        </IconButton>
      </div>
      {isOpen && (
        <div style={popoverStyles} ref={popoverRef}>
          <div style={arrowStyles}></div>
          <div className="popover-content">
            <label
              htmlFor="profile-img"
              style={{
                cursor: "pointer",
                fontSize: "12px",
                fontWeight: "400",
                backgroundColor: isHovered ? "#ccc" : "#fff",
                borderRadius: "4px",
                padding: "4px 8px",
                display: "block",
                textAlign: "center",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {image ? "Replace Photo" : "Upload Photo"}
              <input
                type="file"
                accept="image/*"
                id="profile-img"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={onImageChange}
              />
            </label>
            {/* Remove section */}
            {image && (
              <div
                onMouseEnter={() => setIsRemovedHover(true)}
                onMouseLeave={() => setIsRemovedHover(false)}
                onClick={ondelete}
                style={{
                  cursor: "pointer",
                  // marginTop: "4px",
                  fontSize: "12px",
                  backgroundColor: isRemovedHover ? "#ccc" : "#fff",
                  borderRadius: "4px",
                  padding: "4px 8px",
                  display: "block",
                  textAlign: "center",
                }}
              >
                Remove
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoPopover;
