import React from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Dashboardchart1 = () => {
  const { technicianDashboardData } = useSelector(
    (state) => state.technicianDashboardState
  );

  // Extract revenue data
  const revenue =
    technicianDashboardData?.applianceSRCount?.map((val) => val?.revenue) || [];

  // Extract appliance names
  const appliance_name =
    technicianDashboardData?.applianceSRCount?.map((val) => {
      const name = val?.appliance_name || "";
      return name.length > 15 ? `${name.slice(0, 10)}...` : name;
    }) || [];

  // Chart configuration with tooltip formatting and fixed positioning
  const chartOptions = {
    series: revenue,
    labels: appliance_name,
    chart: {
      type: "pie",
    },
    tooltip: {
      y: {
        formatter: (val) => `₹${val}`, // Adds ₹ symbol to revenue in tooltip
      },
      fixed: {
        enabled: true, // Keep tooltip fixed
        position: "topLeft", // You can adjust the position: topLeft, topRight, etc.
        offsetY: 0, // Adjust Y offset if needed
        offsetX: 0, // Adjust X offset if needed
      },
    },
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      className="d-flex align-items-center"
      id="chart"
      style={{ height: 320 }}
    >
      {revenue.length > 0 && appliance_name.length > 0 ? (
        <div style={{ overflowY: "auto", width: "100%" }}>
          <Chart
            options={chartOptions}
            series={chartOptions.series}
            type="pie"
            width={isSmallScreen ? "470px" : "470px"}
          />
        </div>
      ) : (
        <div
          className="d-flex align-items-center"
          style={{ width: "100%", justifyContent: "center" }}
        >
          No data found
        </div>
      )}
    </div>
  );
};

export default Dashboardchart1;
