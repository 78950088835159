import React, { useState, useCallback } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "600px",
};

const center = {
  lat: 37.7749,
  lng: -122.4194,
};

const WeatherApp = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentAddress, setCurrentAddress] = useState("");

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBhL5rK2MXq8piHqNLxRDBEhE2gkGmkfjs",
    libraries,
  });

  const onMapClick = useCallback((event) => {
    const newLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setSelectedLocation(newLocation);

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: newLocation }, (results, status) => {
      if (status === "OK" && results[0]) {
        setSelectedAddress(results[0].formatted_address);
      } else {
        setSelectedAddress("");
      }
    });
  }, []);

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
  };
  const handleGetCurrentLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            lat: 9.906862,
            lng: 78.0984409,
          };
          setCurrentLocation(currentLocation);

          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ location: currentLocation }, (results, status) => {
            if (status === "OK" && results[0]) {
              setCurrentAddress(results[0].formatted_address);
            } else {
              setCurrentAddress("");
            }
          });
        },
        (error) => {
          console.error("Error getting current location:", error);
        },
        options
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
    // eslint-disable-next-line
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading maps";

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={center}
        onClick={onMapClick}
      >
        {selectedLocation && <Marker position={selectedLocation} />}
        {currentLocation && <Marker position={currentLocation} />}
      </GoogleMap>
      {selectedAddress && <p>Selected Address: {selectedAddress}</p>}
      {currentAddress && <p>Current Address: {currentAddress}</p>}
      <button onClick={handleGetCurrentLocation}>Get Current Location</button>
    </div>
  );
};

export default WeatherApp;
