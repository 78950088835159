import "./App.css";
import "./loader.css";
import "./styles/wallet.css";
import "./styles/home.scss";
import "./styles/footer.scss";
import "./styles/about.scss";
import "./styles/contact.scss";
import "./styles/services.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import LandingPage from "./components/Landingpage";
import CommonRegister from "./components/Customers/customerRegister";
import CustomerDashBoard from "./components/Customers/customerDashboard";
import Forgetpassword from "./components/Customers/customerForgetpassword";
import OTP from "./components/Customers/customerOTP";
import CreateService from "./components/Customers/createServiceRequest";
import Profile from "./components/Customers/Profile";
import Newpassword from "./components/Customers/customerNewpassword";
import Passworddone from "./components/Customers/customerPassworddone";
import CustomerResetpassword from "./components/Customers/Resetpassword";
import Common from "./components/Customers/commonRegister";
import Header from "./components/Header";
import ServiceList from "./components/Customers/serviceRequestList";
import CommonLogin from "./components/Customers/commonLogin";
import ProgressBar from "./components/Customers/progress";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/topbar";
import Payment from "./components/Customers/payment";
import Footer from "./components/Footer";
import AllAppliences from "./components/Customers/AllAppliences";
import WeatherApp from "./components/userLocation";
import Login from "./components/Login";
import TechnicianproductDetails from "./components/Technicians/AllCategories/technicianproductDetails";
import Dashboardchart1 from "./components/Technicians/DashboardChart1";
import Dashboardchart2 from "./components/Technicians/DashboardChart2";
import DashboardChart3 from "./components/Technicians/DashboardChart3";
import WeekDates from "./components/week";
import TechnicianRegister from "./components/Technicians/technicianRegister";
import Starrating from "./starrating";
import AllCategories from "./components/Technicians/AllCategories/Categories";
import TotalProducts from "./components/Technicians/AllCategories/TotalProducts";
import SuperAdminDashboard from "./components/SuperAdmin/superAdminDashboard/SuperAdminDashboard";
import SuperAdminChart1 from "./components/SuperAdmin/SuperAdminChart1";
import TechProfile from "./components/Technicians/technicianProfile";
import InventoryAlertdashboard from "./components/SuperAdmin/InventoryAlertdashboard";
import MyOrders from "./components/Technicians/TechnicianMyorders";
import Checkout from "./components/Technicians/Checkout";
import SavedCards from "./components/Technicians/SavedCards";
import CustomerSaveCard from "./components/Customers/customerSaveCard";
import Wallet from "./components/Technicians/Technicianwallet";
import Techcart from "./components/Technicians/AllCategories/cart";
import UserManagement from "./components/SuperAdmin/SuperAdminManagement/UserManagement";
import PendingTechniciansHome from "./components/SuperAdmin/SuperAdminManagement/PendingTechniciansHome";
import PendingTechniciansList from "./components/SuperAdmin/SuperAdminManagement/PendingTechniciansList";
import AdminSRlist from "./components/SuperAdmin/SuperAdminSRlist";
import CategoryManagement from "./components/SuperAdmin/SuperAdminCategoryManagement/Category Management";
import AllCategory from "./components/SuperAdmin/SuperAdminCategoryManagement/AllCategory";
import ProductManagement from "./components/SuperAdmin/SuperAdminManagement/ProductManagement";
import Configurations from "./components/SuperAdmin/Configure/configurations";
import OrderManagement from "./components/SuperAdmin/SuperAdminOrderManagement/OrderManagement";
import OrderOpen from "./components/SuperAdmin/SuperAdminOrderManagement/OrderOpen";
import OrderinTransit from "./components/SuperAdmin/SuperAdminOrderManagement/OrderIn-Transit";
import OrderDelivered from "./components/SuperAdmin/SuperAdminOrderManagement/OrderDelivered";
import AreaManagement from "./components/SuperAdmin/SuperAdminManagement/AreaManagement";
import React, { useEffect, useState } from "react";
import TechnicianLandingPage from "./components/Technicians/TechnicianLandingPage";
import ProtectedRouteAdmin from "./routes/ProtectedRouteAdmin";
import ErrorPage from "./components/Error";
import ServiceListManagement from "./components/SuperAdmin/SuperAdminSRManagement/SRManagement";
import ProtectedRouteTechnician from "./routes/ProtectedRouteTechnician";
import ServiceRequestmanagementTechnician from "./components/Technicians/SRmanagement/ServiceRequestmanagementTechnician";
import WalletOptions from "./components/common/WalletOptions";
import TechDasboard from "./components/Technicians/Dashboard/TechDasboard";
import { getNotificationsCount } from "./redux/actions/notificationsAction";
import { useDispatch } from "react-redux";
import "./styles/revenue.css";
import RevenueManagement from "./components/SuperAdmin/SuperAdminManagement/RevenueManagement";
import ProtectedRoutetZonalAdmin from "./routes/ProtectedRouteZonalAdmin";
import ProtectedRoutetSuperAdmin from "./routes/ProtectedRouteSuperAdmin";
import DistrictAdminDashboard from "./components/SuperAdmin/districtAdminDashboard/DistrictAdminDasboard";
import ZonalAdminDashboard from "./components/SuperAdmin/zonalAdminDashboard/ZonalAdminDashboard";
import "./styles/product.css";
import TermsAndConditions from "./components/TermsAndConditions";
import ProtectedRouteConsumer from "./routes/ProtectedRouteConsumer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProductPricing from "./components/ProductPricing";
import TurnAroundTime from "./components/TurnAroundTime";
import OrderAddress from "./components/Technicians/OrderAddress";
import BuyNow from "./components/Technicians/SRmanagement/CategoryTechnician/BuyNow";
import AddToCart from "./components/Technicians/SRmanagement/CategoryTechnician/AddToCart";
import TechnicianCategory from "./components/Technicians/SRmanagement/CategoryTechnician/TechnicianCategory";
import TechProductId from "./components/Technicians/SRmanagement/CategoryTechnician/TechProductId";
import SuperAdminClaimDashboard from "./components/SuperAdmin/superAdminDashboard/ClaimDashboard";
import UpdatedAbout from "./components/about/UpdatedAbout";
import Contact from "./components/contact/Contact";
import Services from "./components/services/Services";
import TelevisionService from "./components/television/TelevisionService";
import FridgeService from "./components/fridge/FridgeService";
import WAService from "./components/washing-machine/WAService";
import DTHService from "./components/dth/DTHService";
import CustAppliances from "./components/Customers/CustAppliances";
import SuperAdminCustomerClaimDashboard from "./components/SuperAdmin/superAdminDashboard/ClaimDashboardCustomer";
import DeleteAccountConfirmation from "./components/deleteAccount/DeleteAccountConfirmation";
import DeleteAccountOtp from "./components/deleteAccount/DeleteAccountOtp";
import ReferralManagement from "./components/SuperAdmin/superAdminDashboard/ReferralManagement";

function App() {
  const dispatch = useDispatch();

  const docValues = () => {
    document.documentElement.style.setProperty(
      "--doc-width",
      window.innerWidth + "px"
    );
  };
  const [innerWidth, setInnerWidth] = useState(0);
  useEffect(() => {
    const documentDimensions = () => {
      const doc = document.documentElement;
      const innerheight = window.innerHeight;
      const innerwidth = window.innerWidth;
      setInnerWidth(innerwidth);
      // Update CSS variables directly
      doc.style.setProperty("--doc-height", `${innerheight}px`);
      doc.style.setProperty("--doc-width", `${innerwidth}px`);
    };

    // Initial setup and event listener
    documentDimensions();
    window.addEventListener("resize", documentDimensions);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", documentDimensions);
    };
  }, []);

  useEffect(() => {
    // Set the initial value
    setTimeout(() => {
      docValues();
    }, 1000);

    // Add the resize event listener
    window.addEventListener("resize", docValues);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", docValues);
    };
  }, []);
  useEffect(() => {
    const user_token = localStorage.getItem("token");
    if (user_token) {
      dispatch(getNotificationsCount(user_token));
    }

    const handlePopState = () => {
      // eslint-disable-next-line
      const isOnPrivacyPolicyPage = window.location.pathname == "/super-admin-dashboard" ||
        "/zonal-admin-dashboard" ||
        "/customerDashboard" ||
        "/DistrictAdminDashboard" ||
        "/technicianDashboard";
      if (isOnPrivacyPolicyPage) {
        window.history.pushState(null, "", window.location.href);
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [dispatch]);

  return (
    <>
      <Router>
        {/* <Navbar /> */}

        <Routes>
          {/* before login */}
          <Route path="/" element={<LandingPage innerWidth={innerWidth} />} />
          <Route path="/home-terms-use" element={<LandingPage />} />
          <Route path="/home-privacy-policy" element={<LandingPage />} />
          <Route path="Login" element={<Login />} />
          <Route path="passworddone" element={<Passworddone />} />
          <Route path="newpassword" element={<Newpassword />} />
          <Route path="forgotPassword" element={<Forgetpassword />} />
          <Route path="password" element={<Passworddone />} />
          <Route path="customerRegister" element={<CommonRegister />} />
          <Route path="commonLogin" element={<CommonLogin />} />
          <Route path="OTP" element={<OTP />} />
          <Route path="header" element={<Header />} />
          <Route path="commonRegister" element={<Common />} />
          <Route path="footer" element={<Footer />} />
          <Route path="weather" element={<WeatherApp />} />

          {/* after customer login      */}
          <Route
            path="createService"
            element={
              <ProtectedRouteConsumer>
                <CreateService />
              </ProtectedRouteConsumer>
            }
          />
          <Route
            path="cust-appliances"
            element={
              <ProtectedRouteConsumer>
                <CustAppliances />
              </ProtectedRouteConsumer>
            }
          />
          <Route
            path="editService"
            element={
              <ProtectedRouteConsumer>
                <CreateService />
              </ProtectedRouteConsumer>
            }
          />
          <Route path="profile" element={<Profile />} />
          <Route
            path="customerDashboard"
            element={
              <ProtectedRouteConsumer>
                <CustomerDashBoard />
              </ProtectedRouteConsumer>
            }
          />
          <Route path="resetpassword" element={<CustomerResetpassword />} />
          <Route
            path="serviceList"
            element={
              <ProtectedRouteConsumer>
                <ServiceList />
              </ProtectedRouteConsumer>
            }
          />
          <Route path="about" element={<UpdatedAbout />} />
          <Route path="progress" element={<ProgressBar />} />
          <Route path="sidebar" element={<Sidebar />} />
          <Route path="topbar" element={<Topbar />} />
          <Route path="payment" element={<Payment />} />
          <Route path="appliances" element={<AllAppliences />} />
          <Route path="customerSaveCard" element={<CustomerSaveCard />} />

          {/* technician */}
          <Route path="technicianRegister" element={<TechnicianRegister />} />
          <Route
            path="technicianDashboard"
            element={
              <ProtectedRouteTechnician>
                <TechDasboard />
              </ProtectedRouteTechnician>
            }
          />
          <Route path="chart1" element={<Dashboardchart1 />} />
          <Route path="chart2" element={<Dashboardchart2 />} />
          <Route path="chart3" element={<DashboardChart3 />} />
          <Route path="productdetails" element={<TechnicianproductDetails />} />
          <Route path="star" element={<Starrating />} />
          <Route path="AllCategories" element={<AllCategories />} />
          <Route path="TotalProducts" element={<TotalProducts />} />
          <Route path="cart" element={<Techcart />} />
          {/* <Route path="myorders" element={<MyOrders />} /> */}
          <Route path="checkout" element={<Checkout />} />
          <Route path="wallet" element={<Wallet />} />
          <Route path="SavedCards" element={<SavedCards />} />
          <Route path="techProfile" element={<TechProfile />} />
          <Route path="techlanding" element={<TechnicianLandingPage />} />
          <Route
            path="service-list-technician"
            element={
              <ProtectedRouteTechnician>
                <ServiceRequestmanagementTechnician />
              </ProtectedRouteTechnician>
            }
          />
          <Route
            path="buynow/:id"
            element={
              <ProtectedRouteTechnician>
                <BuyNow />
              </ProtectedRouteTechnician>
            }
          />
          <Route
            path="myorders"
            element={
              <ProtectedRouteTechnician>
                <MyOrders />
              </ProtectedRouteTechnician>
            }
          />

          <Route
            path="addToCart/"
            element={
              <ProtectedRouteTechnician>
                <AddToCart />
              </ProtectedRouteTechnician>
            }
          />
          <Route
            path="techniciancategory/"
            element={
              <ProtectedRouteTechnician>
                <TechnicianCategory />
              </ProtectedRouteTechnician>
            }
          />
          <Route
            path="technicianCategoryById"
            element={
              <ProtectedRouteTechnician>
                <TechProductId />
              </ProtectedRouteTechnician>
            }
          />
          <Route
            path="addToCart/"
            element={
              <ProtectedRouteTechnician>
                <OrderAddress />
              </ProtectedRouteTechnician>
            }
          />
          {/* SuperAdmin */}

          <Route
            path="district-admin-dashboard"
            element={
              <ProtectedRouteAdmin>
                <DistrictAdminDashboard />
              </ProtectedRouteAdmin>
            }
          />
          <Route
            path="zonal-admin-dashboard"
            element={
              <ProtectedRoutetZonalAdmin>
                <ZonalAdminDashboard />
              </ProtectedRoutetZonalAdmin>
            }
          />
          <Route
            path="buynow/:id"
            element={
              <ProtectedRoutetZonalAdmin>
                <BuyNow />
              </ProtectedRoutetZonalAdmin>
            }
          />
          <Route
            path="myorders"
            element={
              <ProtectedRoutetZonalAdmin>
                <MyOrders />
              </ProtectedRoutetZonalAdmin>
            }
          />

          <Route
            path="addToCart/"
            element={
              <ProtectedRoutetZonalAdmin>
                <AddToCart />
              </ProtectedRoutetZonalAdmin>
            }
          />
          <Route
            path="techniciancategory/"
            element={
              <ProtectedRoutetZonalAdmin>
                <TechnicianCategory />
              </ProtectedRoutetZonalAdmin>
            }
          />
          <Route
            path="technicianCategoryById"
            element={
              <ProtectedRoutetZonalAdmin>
                <TechProductId />
              </ProtectedRoutetZonalAdmin>
            }
          />
          <Route
            path="addToCart/"
            element={
              <ProtectedRoutetZonalAdmin>
                <OrderAddress />
              </ProtectedRoutetZonalAdmin>
            }
          />
          <Route path="adminchart1" element={<SuperAdminChart1 />} />
          <Route path="Inventory" element={<InventoryAlertdashboard />} />
          <Route path="week" element={<WeekDates />} />
          <Route
            path="usermanagement"
            element={
              <ProtectedRouteAdmin>
                <UserManagement />
              </ProtectedRouteAdmin>
            }
          />
          <Route path="orderAddress" element={<OrderAddress />} />
          <Route
            path="SRmanagement"
            element={
              <ProtectedRouteAdmin>
                <AdminSRlist />
              </ProtectedRouteAdmin>
            }
          />
          <Route
            path="appliancemanagement"
            element={
              <ProtectedRoutetSuperAdmin>
                <CategoryManagement />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route path="Allcategory" element={<AllCategory />} />
          <Route
            path="productManagement"
            element={
              <ProtectedRoutetSuperAdmin>
                <ProductManagement />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route
            path="OrderManagement"
            element={
              <ProtectedRoutetSuperAdmin>
                <OrderManagement />{" "}
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route
            path="Configurations"
            element={
              <ProtectedRoutetSuperAdmin>
                <Configurations />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route path="orderopen" element={<OrderOpen />} />
          <Route path="orderin-transit" element={<OrderinTransit />} />
          <Route path="orderin-transit" element={<OrderDelivered />} />
          <Route
            path="AreaManagement"
            element={
              <ProtectedRoutetSuperAdmin>
                <AreaManagement />{" "}
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route
            path="approve-pending-technicians"
            element={
              <ProtectedRouteAdmin>
                <PendingTechniciansHome />
              </ProtectedRouteAdmin>
            }
          />
          <Route
            path="pendingTechinicians"
            element={
              <ProtectedRouteAdmin>
                <PendingTechniciansList />
              </ProtectedRouteAdmin>
            }
          />
          <Route
            path="service-list-management"
            element={
              <ProtectedRouteAdmin>
                <ServiceListManagement />
              </ProtectedRouteAdmin>
            }
          />
          <Route
            path="revenue-management"
            element={
              <ProtectedRouteAdmin>
                <RevenueManagement />
              </ProtectedRouteAdmin>
            }
          />

          {/* SuperAdmin */}

          <Route
            path="super-admin-dashboard"
            element={
              <ProtectedRoutetSuperAdmin>
                <SuperAdminDashboard />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route
            path="super-admin-reward-dashboard-technician"
            element={
              <ProtectedRoutetSuperAdmin>
                <SuperAdminClaimDashboard />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route
            path="super-admin-reward-dashboard-customer"
            element={
              <ProtectedRoutetSuperAdmin>
                <SuperAdminCustomerClaimDashboard />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route
            path="referral-management"
            element={
              <ProtectedRoutetSuperAdmin>
                <ReferralManagement />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route path="adminchart1" element={<SuperAdminChart1 />} />
          <Route path="Inventory" element={<InventoryAlertdashboard />} />
          <Route path="week" element={<WeekDates />} />

          <Route
            path="SRmanagement"
            element={
              <ProtectedRouteAdmin>
                <AdminSRlist />
              </ProtectedRouteAdmin>
            }
          />

          <Route
            path="appliancemanagement"
            element={
              <ProtectedRoutetSuperAdmin>
                <CategoryManagement />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route path="Allcategory" element={<AllCategory />} />

          <Route
            path="productManagement"
            element={
              <ProtectedRoutetSuperAdmin>
                <ProductManagement />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route
            path="OrderManagement"
            element={
              <ProtectedRoutetSuperAdmin>
                <OrderManagement />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route
            path="configurations"
            element={
              <ProtectedRoutetSuperAdmin>
                <Configurations />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route path="orderopen" element={<OrderOpen />} />
          <Route path="orderin-transit" element={<OrderinTransit />} />
          <Route path="orderin-transit" element={<OrderDelivered />} />
          <Route
            path="AreaManagement"
            element={
              <ProtectedRoutetSuperAdmin>
                <AreaManagement />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route
            path="approve-pending-technicians"
            element={
              <ProtectedRouteAdmin>
                <PendingTechniciansHome />
              </ProtectedRouteAdmin>
            }
          />
          <Route
            path="pendingTechinicians"
            element={
              <ProtectedRouteAdmin>
                <PendingTechniciansList />
              </ProtectedRouteAdmin>
            }
          />
          <Route
            path="service-list-management"
            element={
              <ProtectedRouteAdmin>
                <ServiceListManagement />
              </ProtectedRouteAdmin>
            }
          />
          <Route
            path="revenue-management"
            element={
              <ProtectedRoutetSuperAdmin>
                <RevenueManagement />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route path="terms-of-use" element={<TermsAndConditions />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="Contact-us" element={<Contact />} />
          <Route path="services" element={<Services />} />
          <Route path="tv-service" element={<TelevisionService />} />
          <Route path="refrigerator-service" element={<FridgeService />} />
          <Route path="washing-machine-service" element={<WAService />} />
          <Route path="dth-service" element={<DTHService />} />
          <Route path="ProductPricing" element={<ProductPricing />} />
          <Route path="TurnAroundTime" element={<TurnAroundTime />} />

          <Route
            path="appliancemanagement"
            element={
              <ProtectedRoutetSuperAdmin>
                <CategoryManagement />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route path="Allcategory" element={<AllCategory />} />

          <Route
            path="productManagement"
            element={
              <ProtectedRoutetSuperAdmin>
                <ProductManagement />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route
            path="OrderManagement"
            element={
              <ProtectedRoutetSuperAdmin>
                <OrderManagement />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route
            path="Configurations"
            element={
              <ProtectedRoutetSuperAdmin>
                <Configurations />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route path="orderopen" element={<OrderOpen />} />
          <Route path="orderin-transit" element={<OrderinTransit />} />
          <Route path="orderin-transit" element={<OrderDelivered />} />
          <Route
            path="AreaManagement"
            element={
              <ProtectedRoutetSuperAdmin>
                <AreaManagement />
              </ProtectedRoutetSuperAdmin>
            }
          />
          <Route
            path="approve-pending-technicians"
            element={
              <ProtectedRouteAdmin>
                <PendingTechniciansHome />
              </ProtectedRouteAdmin>
            }
          />
          <Route
            path="pendingTechinicians"
            element={
              <ProtectedRouteAdmin>
                <PendingTechniciansList />
              </ProtectedRouteAdmin>
            }
          />
          <Route
            path="service-list-management"
            element={
              <ProtectedRouteAdmin>
                <ServiceListManagement />
              </ProtectedRouteAdmin>
            }
          />
          <Route
            path="revenue-management"
            element={
              <ProtectedRoutetSuperAdmin>
                <RevenueManagement />
              </ProtectedRoutetSuperAdmin>
            }
          />

          {/* Common */}

          <Route
            path="payment-wallet"
            element={
              <ProtectedRouteTechnician>
                <WalletOptions />
              </ProtectedRouteTechnician>
            }
          />
          <Route
            path="account-delete-confirmation"
            element={<DeleteAccountConfirmation />}
          />
          <Route path="account-delete-otp" element={<DeleteAccountOtp />} />
          {/* Error */}

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
