import React, { useEffect, useState } from "react";
import Logo from "../../Assets/Cust/forgot-pass.svg";
import { useNavigate } from "react-router-dom";
import Api from "../../service/services";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import S3NewLogo from "../../Assets/s3_new_logo/S3NewLogo";
import Footer from "../Footer";

const Forgetpassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [isvalid, setIsvalid] = useState(false);
  const handleEmailChange = (e) => {
    const value = e.target.value.replace(/\s+/g, "");
    setEmail(value);
    if (!value) {
      setMobileError("Please Enter the Registered Email.");
      setIsvalid(false);
    } else if (/[a-zA-Z]/.test(value)) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z][a-zA-Z][a-zA-Z]$/;

      if (!emailPattern.test(value)) {
        setMobileError("Please Enter a Valid Email Address.");
      } else {
        setMobileError("");
        setIsvalid(true);
      }
    } else {
      const numberPattern = /^\d{10}$/;
      if (!numberPattern.test(value)) {
        setMobileError("Please Enter a Valid 10-digit Mobile Number.");
      } else {
        setMobileError("");
        setIsvalid(true);
      }
    }
  };

  const forgot_password = (e) => {
    e.preventDefault();

    if (isvalid) {
      const formData = {
        username: email,
      };
      Api.forgetPassword(formData).then((response) => {
        if (response.success === true) {
          Swal.fire({
            text: response?.message,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/OTP", {
                state: {
                  data: email,
                  expirtDateOtp: response.data.otp_expiry_time,
                },
              });
            }
          });
        } else {
          Swal.fire({
            text: "User not Found! Please Enter your Registered Email.",
            confirmButtonText: "OK",
          });
        }
      });
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="">
      <div className="container-fluid">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
          className="res-576px-d-unset mt--40px"
        >
          <div
            className="p-0 res-576px-d-none"
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              width: "43.5%",
              padding: "60px",
              justifyContent: "center",
            }}
          >
            <div
              className="logo_container"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
                marginTop: "100px",
              }}
            >
              <div style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                <S3NewLogo
                  color={"rgba(235, 129, 40, 1)"}
                />
              </div>
              <img alt="demo" src={Logo} className="logo" />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "center",
                  width: "76%",
                }}
              >
                Secure Sales Service
              </div>
              <div
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  width: "76%",
                  color: "rgba(0, 0, 0, 1)",
                }}
                className="mt--10px"
              >
                A friendly neighbourhood handyman and customers satisfied with
                instant service
              </div>
            </div>
          </div>
          <div className="col-lg-1 border-register-right res-576px-d-none"></div>
          <div className="res-577px-d-none" onClick={() => navigate("/")}>
            <S3NewLogo
              color={"rgba(235, 129, 40, 1)"}
            />
          </div>
          <div
            style={{
              width: "56.5%",
            }}
            className="res-576px-w-fill"
          >
            <div className="p-lg-5 p-md-3 p-2" style={{}}>
              <form
                style={{ marginLeft: "10px", marginTop: "80px" }}
                onSubmit={forgot_password}
              >
                <div>
                  <p
                    className="mt-2"
                    style={{
                      fontWeight: "700",
                      fontSize: "21px",
                      lineHeight: "26px",
                    }}
                  >
                    Forgot Password?
                  </p>
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#6A707C",
                    }}
                  >
                    No worries! We’ll help you. Please enter your registered
                    email address.
                  </p>
                </div>
                <div className="mb-lg-3">
                  <input
                    type="text"
                    className="form-control"
                    value={email}
                    onChange={handleEmailChange}
                    aria-describedby="emailHelp"
                    placeholder="Enter Your Registered Email"
                    style={{
                      width: "100%",
                      outline: "none",
                      border: "none",
                      backgroundColor: "#F7F8F9",
                      color: "#291409",
                      height: "56px",
                    }}
                  />
                  {mobileError && (
                    <p
                      style={{
                        color: "red",
                        marginTop: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {mobileError}
                    </p>
                  )}
                </div>
                <div className="d-grid gap-lg-2">
                  <button
                    className="btn mt-2"
                    type="submit"
                    style={{
                      height: "56px",
                      width: "100%",
                      color: "white",
                      cursor: !mobileError && email ? "pointer" : "default",
                      backgroundColor:
                        !mobileError && email
                          ? "#EB8128"
                          : "rgb(235 129 40 / 55%)",
                    }}
                  >
                    Send Code
                  </button>
                </div>
                <div className="mt-5 text-center">
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "15px",
                      lineHeight: "21px",
                      alignItems: "center",
                      marginLeft: "10px",
                    }}
                  >
                    Remember password?{" "}
                    <Link
                      to="/Login"
                      style={{
                        color: "#EB8128",
                        fontWeight: "500",
                        fontSize: "15px",
                        textDecoration: "none",
                      }}
                    >
                      Login
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "100px" }}>
        <Footer topscroller />
      </div>
    </div>
  );
};

export default Forgetpassword;
