import React, { useState } from "react";
import wave from "../../Assets/Home/Wave-2.png";
import { DashedLine, DashedLineVr } from "../../Assets/Home/DashedLine";
import ReactPlayer from "react-player/youtube";

const HelpBanner = () => {
  const [video] = useState(false);

  return (
    <div className="help-banner-container" style={{ position: "relative" }}>
      <img
        src={wave}
        alt=""
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          objectFit: "contain",
        }}
      />
      <div className="help-content">
        <div className="title mb-2">How does it work?</div>
        <div className="quote mb-2 res-576px-w-5">
          3 simple steps to turn your appliance frown upside down!
        </div>
        <div className="res-576px-d-flex gap-5">
          <div className="card-container res-576px-d-none ">
            <div className="card-item">
              <div className="circle _1">1</div>
            </div>
            <DashedLine width={"-webkit-fill-available"} />

            <div className="card-item">
              <div className="circle _2">2</div>
            </div>
            <DashedLine width={"-webkit-fill-available"} />

            <div className="card-item">
              <div className="circle _3">3</div>
            </div>
          </div>
          <div className="card-container res-577px-d-none res-576px-gap-1 res-576px-d-flex res-576px-flex-direction-column res-576px-justify-content-unset">
            <div className="card-item">
              <div className="circle _1">1</div>
            </div>
            <DashedLineVr height={"40"} />
            <div className="card-item">
              <div className="circle _2">2</div>
            </div>
            <DashedLineVr height={"48"} />
            <div className="card-item">
              <div className="circle _3">3</div>
            </div>
          </div>
          <div className="content-flex-option res-576px-d-block res-576px-text-align-start">
            <div className="card-width">
              <div className="heading">Install App</div>
              <div className="card-content">
                Download and install the S3-app from Google Play Store.
              </div>
            </div>
            <div className="card-width">
              <div className="heading">Register</div>
              <div className="card-content">
                Create an account. Book a service at any location in Tamil Nadu
                on a convenient date and time slot.
              </div>
            </div>
            <div className="card-width">
              <div className="heading">Pay post service​!</div>
              <div className="card-content">
                Track technician assignment. Enjoy on-time service and pay only
                after service is rendered!
              </div>
            </div>
          </div>
        </div>
      </div>

      {!video && (
        <div className="pop-up-box-container">
          <div className="video-img">
            <ReactPlayer
              url="https://www.youtube.com/embed/Xr6BJ8CLvgY?autoplay=1"
              // width="100%" // Full width on smaller screens
              controls
              width="100%"
              height="100%"
            />
          </div>
        </div>
      )}
      <div className="border-line-container">
        <div className="border-line res-576px-visibility-hidden"></div>
      </div>
    </div>
  );
};

export default HelpBanner;
