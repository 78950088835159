import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import {
  districtAdminDashboardFail,
  districtAdminDashboardRequest,
  districtAdminDashboardSuccess,
} from "../slices/districtAdminDashboardSlice";
import {
  zonalAdminDashboardFail,
  zonalAdminDashboardRequest,
  zonalAdminDashboardSuccess,
} from "../slices/zonalAdminDashboardSlice";
import {
  superAdminDashboardFail,
  superAdminDashboardRequest,
  superAdminDashboardSuccess,
} from "../slices/superAdminDashboardSlice";

const handleUnAuthorizedError = (err) => {
  if (err.response.status === 401) {
    localStorage.clear();
    window.location.href = "/";
    window.location.reload();
  }
};

export const getDistrictAdminDashboard = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(districtAdminDashboardRequest());
    // const response = await axios.get(
    //   `${BASE_URL}/admin/dashboard/view?startDate=${payload.startDate}&endDate=${payload.endDate}`,
    //   {
    //     headers: { Authorization: `Bearer ${token}` },
    //   }
    // );

    const user_count_response = await axios.get(
      `${BASE_URL}/dashboard/user/count?startDate=${payload.startDate}&endDate=${payload.endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const sr_count_response = await axios.get(
      `${BASE_URL}/dashboard/sr/count?startDate=${payload.startDate}&endDate=${payload.endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const data_response = await axios.get(
      `${BASE_URL}/dashboard/zone?startDate=${payload.startDate}&endDate=${payload.endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const dashboard_data = {
      userCount: user_count_response?.data,
      srCount: sr_count_response?.data?.srCountDetails,
      totalRevenue: sr_count_response?.data?.totalRevenue,
      // associationShare: sr_count_response?.data?.associationShare,
      closedSrCount: sr_count_response?.data?.closedSrCount,
      data: data_response?.data,
      start_date: payload.startDate,
      end_date: payload.endDate,
      tech_profit: sr_count_response?.data?.techProfit,
    };
    dispatch(districtAdminDashboardSuccess(dashboard_data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(districtAdminDashboardFail(error));
  }
};

export const getZonalAdminDashboard = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const zone_id = localStorage.getItem("zone_id");
  try {
    dispatch(zonalAdminDashboardRequest());
    const user_count_response = await axios.get(
      `${BASE_URL}dashboard/user/count?startDate=${payload.startDate}&endDate=${payload.endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const sr_count_response = await axios.get(
      `${BASE_URL}/dashboard/sr/count?startDate=${payload.startDate}&endDate=${payload.endDate}&zone_id=${zone_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const dashboard_data = {
      userCount: user_count_response?.data,
      srCount: sr_count_response?.data?.srCountDetails,
      totalRevenue: sr_count_response?.data?.totalRevenue,
      techProfit: sr_count_response?.data?.techProfit,
      associationShare: sr_count_response?.data?.associationShare,
      closedSrCount: sr_count_response?.data?.closedSrCount,
      start_date: payload.startDate,
      end_date: payload.endDate,
    };
    dispatch(zonalAdminDashboardSuccess(dashboard_data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(zonalAdminDashboardFail(error));
  }
};

export const getSuperAdminDashboard = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(superAdminDashboardRequest());
    const user_count_response = await axios.get(
      `${BASE_URL}/dashboard/user/count?startDate=${payload.startDate}&endDate=${payload.endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const sr_count_response = await axios.get(
      `${BASE_URL}/dashboard/sr/count?startDate=${payload.startDate}&endDate=${payload.endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data_response = await axios.get(
      `${BASE_URL}/dashboard/zone?startDate=${payload.startDate}&endDate=${payload.endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const dashboard_data = {
      userCount: user_count_response?.data,
      srCount: sr_count_response?.data?.srCountDetails,
      totalRevenue: sr_count_response?.data?.totalRevenue,
      associationShare: sr_count_response?.data?.associationShare,
      technicianProfit: sr_count_response?.data?.technicianProfit,
      superAdminShare: sr_count_response?.data?.superAdminShare,
      distAdminShare: sr_count_response?.data?.distAdminShare,
      revenueShareAmount: sr_count_response?.data?.revenueShareAmount,
      commission_charge: sr_count_response?.data?.commission_charge,
      zonalAdminShare: sr_count_response?.data?.zonalAdminShare,
      closedSrCount: sr_count_response?.data?.closedSrCount,
      data: data_response?.data,
      start_date: payload.startDate,
      end_date: payload.endDate,
    };
    dispatch(superAdminDashboardSuccess(dashboard_data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(superAdminDashboardFail(error));
  }
};

export const getDashboardDistrictById = async (payload) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${BASE_URL}/dashboard/district?startDate=${payload?.startDate}&endDate=${payload.endDate}&zone_id=${payload?.zone_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    handleUnAuthorizedError(err);
  }
};
