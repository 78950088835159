import React, { useEffect, useRef, useState } from "react";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import Dashboardchart1 from "../DashboardChart1";
import ApexChart from "../DashboardChart2";
import { BsCurrencyRupee } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import DatePickerDashboard from "../DatePickerDashboard";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CreateReward } from "../../../redux/actions/createReward";
import { getAllWinningTechnician } from "../../../redux/actions/winningTechnicianAction";
import Swal from "sweetalert2";
import { clearStatusCode } from "../../../redux/slices/createRewardSlice";
import confetti from "../../../Assets/Home/confetti.gif";
import { getAllTodayParticipants } from "../../../redux/actions/todayParticipants";
import moment from "moment";
import rectangle from "../../../Assets/Cust/Rectangle-cust-reward.svg";

const TechDasboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isUserIncluded, setIsUserIncluded] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const todayString = new Date().toISOString().split('T')[0];
  const todayString = moment(new Date()).format("YYYY-MM-DD");

  const { technicianDashboardData } = useSelector(
    (state) => state.technicianDashboardState
  );

  const { statusCode, successMsg, errorMsg } = useSelector(
    (state) => state.createRewardState
  );
  const { WinningTechnicianData } = useSelector(
    (state) => state.WinningTechnicianState
  );
  const { TodayParticipantsData } = useSelector(
    (state) => state.todayParticipantsState
  );

  useEffect(() => {
    dispatch(getAllWinningTechnician(todayString, todayString));
    dispatch(getAllTodayParticipants()); // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    const user_id = Number(localStorage.getItem("id")); // Convert the ID to a number
    if (TodayParticipantsData?.user_id?.includes(user_id)) {
      setIsUserIncluded(true);
    }
  }, [TodayParticipantsData]);

  useEffect(() => {
    switch (statusCode) {
      case "success": {
        dispatch(getAllTodayParticipants());
        dispatch(clearStatusCode());
        Swal.fire({
          text: `${successMsg}`,
          confirmButtonText: "OK",
        });
        break;
      }
      case "error": {
        dispatch(clearStatusCode());
        Swal.fire({
          text: `${errorMsg}`,
          confirmButtonText: "OK",
        });
        break;
      }
      default: {
        dispatch(clearStatusCode());
        break;
        // return null;
      }
    } // eslint-disable-next-line
  }, [statusCode]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [winnersPerPage, setWinnersPerPage] = useState(3);
  const scrollRef = useRef(null);

  const handleScroll = () => {
    const scrollPosition = scrollRef.current.scrollLeft;
    const containerWidth = scrollRef.current.clientWidth;
    const itemWidth = containerWidth / winnersPerPage;

    let index = Math.round(scrollPosition / itemWidth);
    const totalPages = Math.ceil(WinningTechnicianData.length / winnersPerPage);
    index = Math.min(index, totalPages - 1);

    setActiveIndex(index);
  };

  const updateWinnersPerPage = () => {
    const width = window.innerWidth;
    setWinnersPerPage(width <= 768 ? 1 : 3);
  };

  useEffect(() => {
    updateWinnersPerPage();
    window.addEventListener("resize", updateWinnersPerPage);
    const scrollElement = scrollRef?.current;
    scrollElement?.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", updateWinnersPerPage);
      scrollElement?.removeEventListener("scroll", handleScroll);
    }; // eslint-disable-next-line
  }, [WinningTechnicianData]);

  const totalPages = Math.ceil(WinningTechnicianData.length / winnersPerPage);
  const maxDots = 5;

  const getDots = () => {
    const dots = [];
    const start = Math.max(0, activeIndex - 2);
    const end = Math.min(totalPages, start + maxDots);

    for (let i = start; i < end; i++) {
      dots.push(
        <span
          key={i}
          className={`dot ${i === activeIndex ? "active" : ""}`}
        ></span>
      );
    }

    if (totalPages > maxDots && activeIndex >= maxDots - 1) {
      dots.shift(); // Remove the first dot if activeIndex reaches the end
    }

    return dots;
  };

  const filteredWinners = WinningTechnicianData.filter(
    (winner) => winner.winning_status === true
  );
  const handleSubmit = () => {
    dispatch(CreateReward());
  };
  const username = localStorage.getItem("name");
  return (
    <div style={{ overflow: "hidden" }}>
      <Topbar />
      <div className="row">
        <div className="col-2 col-12">
          <Sidebar />
        </div>
        <div className="col-10 p-4 pb-0 wallet" style={{ marginTop: "60px" }}>
          <div
            className="d-flex align-items-center"
            style={{
              marginTop: isMobile ? 0 : "8px",
              justifyContent: "space-between",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <div>
              <div
                className="mt-2 mb-2"
                style={{ fontWeight: "600", fontSize: "21px" }}
              >
                Welcome {username}!
              </div>
            </div>
            <div>
              <DatePickerDashboard />
            </div>
          </div>
          <div className="mt-4 mb-4 cust-container-reward">
            <img src={rectangle} alt="rectangle" className="cust-rectangle" />
            <div className="cust-reward-content res-576px-d-unset">
              <div>
                <h4 style={{ textAlign: "start" }}>
                  <b>Welcome! We're thrilled to see you.</b>
                </h4>
                {isUserIncluded ? (
                  <p style={{ width: "100%" }}>
                    Thank you for participating in the Daily Reward Program!
                  </p>
                ) : (
                  <p style={{ width: "100%" }}>
                    Tap the button here to participate in the Daily Reward
                    Program!
                  </p>
                )}
              </div>
              <button
                className="cust-reward-submit-button"
                style={{
                  opacity:
                    isUserIncluded || filteredWinners?.length > 0 ? 0.5 : 1,
                  cursor:
                    (isUserIncluded || filteredWinners?.length > 0) &&
                    "not-allowed",
                }}
                disabled={isUserIncluded || filteredWinners?.length > 0}
                onClick={handleSubmit}
              >
                {isUserIncluded ? "Already Participated" : "Participate"}
              </button>
            </div>
          </div>
          {filteredWinners?.length > 0 && (
            <div className="mt-3 mb-3 chart-container-winners">
              <div className="winner-content">
                <b>Congratulations Winners!</b>
                <p>
                  Your participation means a lot and we hope you enjoy the
                  prize!
                </p>
              </div>
              <div className="winner-list" ref={scrollRef}>
                {filteredWinners.map((winner, i) => (
                  <div className="winner-item" key={winner.id || i}>
                    <img
                      className="winner-image"
                      src={
                        winner.avatar ||
                        "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8ODw4NDg0QDw4ODQ0NDg0NDg8NDQ4NFREWFhURFRMYHSggGBolGxUTITEhJSkrLi4uGB8zODMtNygtLisBCgoKDQ0NDg0NDzcZFRk3KysrKy0rNysrKysrLSsrKysrKys3KysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAOEA4QMBIgACEQEDEQH/xAAaAAEBAAMBAQAAAAAAAAAAAAAAAwECBQQH/8QAMhABAAIAAwUFBgYDAAAAAAAAAAECAwQRITFRUpESE0FhcQUUMqHR8CJCcoGx4TOSwf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8A+4gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAATIA0nEhrN5BUR7U8WNQXENWe1PFRYSi8toxEG4xEsgAAAAAAAAAAAAAAAADFp0StbUG1sTg0mQVAAAAAAAABvW/FoAtEsoxOilbaorYAAAAAAAAAAABi1tCZSmdQYmQFQAAAAHlxs7EbK/inj4f281s5efHT0iBXTHLjN4nN1iFsLPeFo/ePoGPcMVtExrE6x5MiAAAAK0tq2QiVqzqisgAAAAAAAA1tOkA0vZqCoAAAAOfncxrM0rujfPGeD15nE7NJnx3R6y5IoAigAK5fHmk+U74+/F1azExExunbDivf7OxNYmvDbHpIj2AKgAAzS2jAC41pOxsigAAAAACeJKiMyDACoAAAA8vtH4Y/XH8S5zp56utJ8pifvq5iLAAUAAer2d8c/pn+YeV7fZtdtreUR/0R7gFQAAABtSdqqC8IoAAAAADEoq33SkIAKAAAAMTGuxysxhdi2nh4Txh1mmLhRaNJ/uBXHF8bKWrujtRxj6IIoCmFgWtuj952QDStZmYiI1mXWwMPsVivWeMtMvl4p524/RcQAVAAAABWm6ElcPcitgAAAAAa33SktKIgAoAAAxa0RtmdI8wZHlxM9WPhibfKHnvnbzu0j0jWfmK6TW1InfWJ9Y1cqce877z10aTaZ8Z6yg7EYVY3Vj/AFbOLrPGerMYlo3WnrIY7I5Vc1ePza+u1amfn81YnzjYo94jhZmlt06TwnZKwgAAAArh7klaboRWwAAAAACErpYkbQagKgCOaxuxXXxnZHrxBrmc1FNkbbfKPVzsTEm062nVrMiNAAAAAAAAD04Gbmuy34q/OHmAdqtomImJ1ifFlzcljdm3ZndaekukrIAAtCdI2qooAAAAAA1vGsNgEBm8aSwqDne0La304RHz+4dFy87/AJLft/ECxABFAAAAAAAAAAHapbWInjES4rr5b4K/pgSqAzWNVRvhw3BFAAAAAAAAYtGqMwu1vXUEmlsOs7ZrEzxmG8ion3NOSvSDuaclekKAJ9xTkr0g7inJXpCgCfcU5K9IO4pyV6QoAl3FOSvQ93pyR0VAT7inJHQ7inJHRQBP3enJHQ93pyR0UAS93pyR0UiGQBWldGKVbooAAAAAAAAAAADW1dUpjRdiY1BEb2o0VAAAAAAAAAG1aSDWIUrTRtFdGUUAAAAAAAAAAAAAAAAYmsSyAnOHwazSVgEBdjQERbRkEYrPBtGHxUAYisQyAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP/9k="
                      }
                      alt={winner.name}
                    />
                    <div className="winner-info">
                      <img className="small-image" src={confetti} alt="popup" />
                      <b className="winner-name ">{winner.name}</b>
                      <img
                        className="small-image1"
                        src={confetti}
                        alt="popup"
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="scroll-dots">{getDots()}</div>
            </div>
          )}
          <div className="mt-3 mb-3 chart-container">
            <div className="total-summary">
              <div className="dashboard-tech">
                <div>
                  <div style={{ fontWeight: "600", fontSize: "14px" }}>
                    Closed Service Requests
                  </div>
                  <div style={{ fontWeight: "600", fontSize: "20px" }}>
                    {technicianDashboardData?.totalServiceRequest}
                  </div>
                </div>
                {/* <div>
                  <img src={total_service_request} alt="total_service_req" />
                </div> */}
              </div>
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "space-between" }}
              >
                {/* <div>
                  <div style={{ fontWeight: "600", fontSize: "14px" }}>
                    Total Spare Orders
                  </div>
                  <div style={{ fontWeight: "600", fontSize: "20px" }}>0</div>
                </div> */}
                {/* <div>
                  <img src={total_spare_orders} alt="total_service_req" />
                </div> */}
              </div>
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <div style={{ fontWeight: "600", fontSize: "14px" }}>
                    Total Revenue(
                    <BsCurrencyRupee />)
                  </div>
                  <div style={{ fontWeight: "600", fontSize: "20px" }}>
                    {technicianDashboardData?.totalRevenue}
                  </div>
                </div>
                {/* <div>
                  <img src={total_revenue} alt="total_service_req" />
                </div> */}
              </div>
            </div>
          </div>
          <div className="dashboard-charts d-flex align-items-center gap-3 mt-3 mb-3">
            <div className="chart-container w-50">
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <div style={{ fontSize: "12px" }}>Service Request</div>
                  <div style={{ fontSize: "14px", fontWeight: "600" }}>
                    Appliances
                  </div>
                </div>
              </div>
              <div>
                <ApexChart />
              </div>
            </div>
            <div className="chart-container w-50">
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <div style={{ fontSize: "12px" }}>Service Request</div>
                  <div style={{ fontSize: "14px", fontWeight: "600" }}>
                    Revenue
                  </div>
                </div>
              </div>
              <div>
                <Dashboardchart1 />
              </div>
            </div>
          </div>
          {/* <HomeFooter /> */}
        </div>
      </div>
    </div>
  );
};

export default TechDasboard;
