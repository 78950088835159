import React from "react";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ConfigureService from "./configureServiceRequest";
import ConfigureRevenue from "./configureRevenue";
import { useNavigate } from "react-router";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Configurations = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <Topbar />
      </div>
      <div
        className="row container-fluid"
        style={{ paddingRight: "0px", overflow: "hidden" }}
      >
        <div className="col-2 col-12 p-0 border border-right">
          <Sidebar />
        </div>
        <div
          className="col-12 user_management"
          style={{ paddingRight: "0px", marginTop: "80px" }}
        >
          <div className="row">
            <div className="mt-lg-2">
              <i
                onClick={() => navigate(-1)}
                className="bi bi-chevron-left "
                style={{
                  fontWeight: "600",
                  fontSize: "21px",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
              ></i>
              <text
                className="p-0 mt-5"
                style={{
                  fontWeight: "600",
                  color: "#1E232C",
                  fontSize: "21px",
                  width: "208px",
                  height: "32px",
                }}
              >
                Configurations
              </text>
            </div>
            <div className="mt-1">
              <div className="row">
                <div className="col-lg-6 m-0">
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Tabs
                        variant="fullWidth"
                        style={{ textTransform: "none" }}
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        className="Arial"
                      >
                        <Tab
                          style={{
                            textTransform: "none",
                            fontFamily: "Arial",
                            color: value === 0 && "rgb(235, 129, 40)",
                          }}
                          label="Service Request"
                        />
                        <Tab
                          style={{
                            textTransform: "none",
                            fontFamily: "Arial",
                            color: value === 1 && "rgb(235, 129, 40)",
                          }}
                          label="Revenue Sharing"
                        />
                      </Tabs>
                    </Box>
                  </Box>
                </div>
                <div className="col-lg-4  m-0"></div>
              </div>
              <div>
                <CustomTabPanel value={value} index={0}>
                  <ConfigureService />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <ConfigureRevenue />
                </CustomTabPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Configurations;
