import React from "react";
import { Paper, Stack } from "@mui/material";
import { Box } from "@mui/material";
import { Rating } from "@mui/material";
import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Avatar from "@mui/material/Avatar";
import img1 from "../../../Images/t-img-1.png";
import Sidebar from "../../Sidebar";
import Topbar from "../../topbar";
import { Link } from "react-router-dom";

const ProductDetails = () => {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <div className="sticky-top">
          <Topbar />
        </div>
        <div className="row">
          <div className="col-lg-2 p-0 col-12">
            <Sidebar />
          </div>
          <div className="col-lg-10 p-0 Main_menu">
            <div className=""></div>
            <div className=" mt-lg-3 ms-lg-3">
              <Link to="/AllCategories">
                <i
                  className="bi bi-chevron-left me-1 fa fa-bold ms-lg-3"
                  style={{ fontWeight: "500", fontSize: "21px" }}
                ></i>
              </Link>
              <text
                className="p-0 fa fa-bold"
                style={{
                  fontWeight: "500",
                  color: "#292929",
                  fontSize: "22px",
                }}
              >
                Product Details
              </text>
              <div className="ms-lg-4">
                <text
                  className="p-0 fa fa-bold "
                  style={{
                    fontWeight: "500",
                    color: "#6A707C",
                    fontSize: "14px",
                  }}
                >
                  Category
                </text>
                <i
                  className="bi bi-chevron-right me-lg-1  fa fa-bold"
                  style={{ fontWeight: "500", fontSize: "12px" }}
                ></i>
                <text
                  className="p-0 fa fa-bold"
                  style={{
                    fontWeight: "500",
                    color: "#6A707C",
                    fontSize: "14px",
                  }}
                >
                  Television
                </text>
                <i
                  className="bi bi-chevron-right me-1 fa fa-bold"
                  style={{ fontWeight: "500", fontSize: "12px" }}
                ></i>
                <text
                  className="p-0 fa fa-bold"
                  style={{
                    fontWeight: "500",
                    color: "#6A707C",
                    fontSize: "14px",
                  }}
                >
                  Product
                </text>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-5 ms-lg-1 mt-lg-3 ">
                <Paper className="w-100">
                  <img alt="demo" className="ms-lg-3 w-100" src={img1} />
                </Paper>
              </div>
              <div className="col-lg-6 ms-lg-5 mt-lg-3">
                <p style={{ color: "#6A707C", fontSize: "15px" }}>SKU: CA266</p>
                <p>
                  CA-266 Input-12V-28V, 22-65 inch LED TV
                  <br /> Backlight Driver Board LED
                </p>

                <Stack className="mt-lg-1 ms-lg-1" spacing={1} direction="row">
                  <Box sx={{ fontSize: 25 }}>
                    {/* <Starrating/> */}
                    <Rating name="size-lager" defaultValue={4} />
                  </Box>
                  <Stack
                    className="mt-lg-1 ms-lg-3"
                    spacing={1}
                    direction="row"
                  >
                    <span
                      className=""
                      style={{
                        fontWeight: "500",
                        fontSize: "12px",
                        background: "#EB8128",
                        height: "18px",
                        borderRadius: "3px",
                        color: "white",
                      }}
                    >
                      4.0
                    </span>
                    <p
                      className=""
                      style={{ fontWeight: "500", fontSize: "13px" }}
                    >
                      (29)
                    </p>
                  </Stack>
                </Stack>

                <Stack className="mt-lg-1 ms-lg-1" spacing={16} direction="row">
                  <span
                    className=""
                    style={{ fontWeight: "500", fontSize: "18px" }}
                  >
                    ₹1017.36 /Unit
                  </span>
                  <p
                    className=""
                    style={{
                      fontWeight: "500",
                      fontSize: "18px",
                      color: "#36A93F",
                    }}
                  >
                    In-Stock
                  </p>
                </Stack>
                <Stack
                  className="mt-lg-1 ms-lg-1 mt-lg-4"
                  spacing={2}
                  direction="row"
                >
                  <button
                    type="button"
                    onClick={() => window.location.replace("cart")}
                    class="btn btn-md w-25"
                    style={{
                      color: "#EB8128",
                      borderColor: "#EB8128",
                      fontWeight: "600px",
                    }}
                  >
                    {" "}
                    <i class="bi bi-check-circle-fill"></i> GO TO CART
                  </button>
                  <button
                    type="button"
                    class="btn btn-md w-25"
                    style={{
                      backgroundColor: "#EB8128",
                      borderColor: "#EB8128",
                      color: "white",
                    }}
                  >
                    BUY NOW
                  </button>
                </Stack>
                <div className="mt-lg-4 ms-lg-1">
                  <text
                    className="p-0 fa fa-bold "
                    style={{
                      fontWeight: "500",
                      color: "#292929",
                      fontSize: "19px",
                    }}
                  >
                    Description
                  </text>
                  <p
                    className="mt-lg-3"
                    style={{
                      color: "#6A707C",
                      fontSize: "15px",
                      lineHeight: 2,
                    }}
                  >
                    Lorem ipsum is placeholder text commonly used in the
                    <br />
                    graphic, print, and publishing industries for previewing
                    <br />
                    layouts and visual mockups. Lorem is holder text
                    <br />
                    commonly used in the gra {/* eslint-disable-next-line */}
                    <a href="#" role="button" style={{ color: "#EB8128" }}>
                      Show More
                    </a>
                  </p>
                </div>
                {/* carousel */}
                <section className="pt-lg-5 pb-lg-5">
                  <div className="container">
                    <Stack className="mb-lg-3" direction="row" spacing={46}>
                      <text
                        className="p-0 fa fa-bold"
                        style={{
                          fontWeight: "500",
                          color: "#292929",
                          fontSize: "18px",
                        }}
                      >
                        Similar Products (4)
                      </text>
                      <div className=" text-end">
                        <Stack direction="row" spacing={1}>
                          <IconButton
                            aria-label="fingerprint"
                            href="#carouselExampleIndicators2"
                            role="button"
                            backgroundColor="#EB8128"
                            data-bs-slide="prev"
                          >
                            <ChevronLeftIcon />
                          </IconButton>
                          <IconButton
                            aria-label="fingerprint"
                            color="#EB8128"
                            href="#carouselExampleIndicators2"
                            role="button"
                            data-bs-slide="next"
                          >
                            <ChevronRightIcon />
                          </IconButton>
                        </Stack>
                      </div>
                    </Stack>

                    <div
                      id="carouselExampleIndicators2"
                      className="carousel slide"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="card">
                            <div className="row">
                              <div className="col-lg-4 h-75">
                                <img
                                  alt="demo"
                                  className=" h-75 w-75"
                                  src={img1}
                                />
                              </div>
                              <div className="col-lg-8">
                                <text
                                  style={{ fontSize: "14px", color: "#6A707C" }}
                                >
                                  SKU: CA266
                                </text>
                                <br />
                                <text>
                                  CA-266 Input-12V-28V, 22-65 inch LED TV
                                  Backlight Driver...
                                </text>
                                <br />
                                <p style={{ fontSize: "14px" }}>
                                  ₹1017.36 /Unit{" "}
                                  {/* eslint-disable-next-line */}
                                  <a href="" style={{ fontSize: "14px" }}>
                                    {" "}
                                    Add to Card
                                  </a>{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="card">
                            <div className="row">
                              <div className="col-lg-4 h-75">
                                <img
                                  alt="demo"
                                  className=" h-75 w-75"
                                  src={img1}
                                />
                              </div>
                              <div className="col-lg-8">
                                <text
                                  style={{ fontSize: "14px", color: "#6A707C" }}
                                >
                                  SKU: CA266
                                </text>
                                <br />
                                <text>
                                  CA-266 Input-12V-28V, 22-65 inch LED TV
                                  Backlight Driver...
                                </text>
                                <br />
                                <p style={{ fontSize: "14px" }}>
                                  ₹1017.36 /Unit{" "}
                                  <Link to="/cart">
                                    {/* eslint-disable-next-line */}
                                    <a href="" style={{ fontSize: "14px" }}>
                                      {" "}
                                      Add to Card
                                    </a>
                                  </Link>{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="row w-75 mt-3 ms-2">
                  <text
                    className="p-0 fa fa-bold mb-3"
                    style={{
                      fontWeight: "500",
                      color: "#292929",
                      fontSize: "18px",
                    }}
                  >
                    Reviews (29)
                  </text>
                  <div className="col-2 p-0 text-center">
                    <Avatar
                      style={{ color: "#EB8128", backgroundColor: "#FFE5CC" }}
                    >
                      TN
                    </Avatar>
                  </div>
                  <div className="col p-0 text-start">
                    <p
                      className="mb-2"
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#1E232C",
                      }}
                    >
                      <span
                        className=""
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          background: "#EB8128",
                          height: "18px",
                          borderRadius: "3px",
                          color: "white",
                        }}
                      >
                        4.0
                      </span>{" "}
                      Nice Product...
                    </p>
                    <p
                      className="mb-2"
                      style={{
                        fontSize: "12px",
                        fontWeight: "300",
                        color: "#6A707C",
                      }}
                    >
                      Technician_Name
                    </p>
                  </div>
                  <div className="col-3 p-0">
                    <p
                      className="mt-4"
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#6A707C",
                      }}
                    >
                      Today, 12 Jul at 10.20
                    </p>
                  </div>
                </div>
                <div className="border border-bottom w-75"></div>

                <div className="row w-75 mt-5 ms-2">
                  <div className="col-2 p-0 text-center">
                    <Avatar
                      style={{ color: "#EB8128", backgroundColor: "#FFE5CC" }}
                    >
                      TN
                    </Avatar>
                  </div>
                  <div className="col p-0 text-start">
                    <p
                      className="mb-2"
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#1E232C",
                      }}
                    >
                      <span
                        className=""
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          background: "#EB8128",
                          height: "18px",
                          borderRadius: "3px",
                          color: "white",
                        }}
                      >
                        4.0
                      </span>{" "}
                      Nice Product...
                    </p>
                    <p
                      className="mb-2"
                      style={{
                        fontSize: "12px",
                        fontWeight: "300",
                        color: "#6A707C",
                      }}
                    >
                      Technician_Name
                    </p>
                  </div>
                  <div className="col-3 p-0">
                    <p
                      className="mt-4"
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#6A707C",
                      }}
                    >
                      Today, 12 Jul at 10.20
                    </p>
                  </div>
                </div>
                <div className="border border-bottom w-75"></div>

                <div className="row w-75 mt-5 ms-2">
                  <div className="col-2 p-0 text-center">
                    <Avatar
                      style={{ color: "#EB8128", backgroundColor: "#FFE5CC" }}
                    >
                      TN
                    </Avatar>
                  </div>
                  <div className="col p-0 text-start">
                    <p
                      className="mb-2"
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#1E232C",
                      }}
                    >
                      <span
                        className=""
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          background: "#EB8128",
                          height: "18px",
                          borderRadius: "3px",
                          color: "white",
                        }}
                      >
                        4.0
                      </span>{" "}
                      Nice Product...
                    </p>
                    <p
                      className="mb-2"
                      style={{
                        fontSize: "12px",
                        fontWeight: "300",
                        color: "#6A707C",
                      }}
                    >
                      Technician_Name
                    </p>
                  </div>
                  <div className="col-3 p-0">
                    <p
                      className="mt-4"
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#6A707C",
                      }}
                    >
                      Today, 12 Jul at 10.20
                    </p>
                  </div>
                </div>
                <div className="border border-bottom w-75"></div>

                <div className="row w-75 mt-5 ms-2">
                  <div className="col-2 p-0 text-center">
                    <Avatar
                      style={{ color: "#EB8128", backgroundColor: "#FFE5CC" }}
                    >
                      TN
                    </Avatar>
                  </div>
                  <div className="col p-0 text-start">
                    <p
                      className="mb-2"
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#1E232C",
                      }}
                    >
                      <span
                        className=""
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          background: "#EB8128",
                          height: "18px",
                          borderRadius: "3px",
                          color: "white",
                        }}
                      >
                        4.0
                      </span>{" "}
                      Nice Product...
                    </p>
                    <p
                      className="mb-2"
                      style={{
                        fontSize: "12px",
                        fontWeight: "300",
                        color: "#6A707C",
                      }}
                    >
                      Technician_Name
                    </p>
                  </div>
                  <div className="col-3 p-0">
                    <p
                      className="mt-4"
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#6A707C",
                      }}
                    >
                      Today, 12 Jul at 10.20
                    </p>
                  </div>
                </div>
                <div className="border border-bottom w-75"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductDetails;
