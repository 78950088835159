import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import "./LoginTechnician.css";
import { useDispatch, useSelector } from "react-redux";
import { UpdateReward } from "../../../redux/actions/updateReward";
import { clearStatusCode } from "../../../redux/slices/updateRewardSlice";
import Swal from "sweetalert2";
import { getAllParticipationTechCount } from "../../../redux/actions/ParticipationTechCountAction";
import { clearParticipationTechStatusCode } from "../../../redux/slices/ParticipationTechCountSlice";
import moment from "moment";
import confiti from "../../../Assets/Home/confitii.svg";
import { getAllrewardLoginList } from "../../../redux/actions/rewardLoginTechnician";
import { getAllrewardWinningList } from "../../../redux/actions/rewardWinningTechnicianAction";
import FilterModule from "../../../plugins/FilterModule";

const LoginTechDataTable = ({ isData }) => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState(null);
  const [filterData, setFilterData] = useState({});

  const today = moment(new Date()).format("YYYY-MM-DD");
  // eslint-disable-next-line
  const currentDate = new Date();

  const role = localStorage.getItem("role");
  const [loginTechnicianData, setLoginTechnicianData] = useState([]);
  const [select, setSelect] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);

  const { rewardLoginListData } = useSelector(
    (state) => state.rewardLoginListState
  );
  const { statusCode, successMsg, errorMsg } = useSelector(
    (state) => state.updateRewardState
  );
  const { selectedDate } = useSelector((state) => state.todayParticipantsState);

  useEffect(() => {
    if (rewardLoginListData && rewardLoginListData.length > 0) {
      const transformedData = rewardLoginListData.map((tech) => ({
        user_id: tech.user_id,
        name: tech.name,
        avatar: tech.avatar,
        zone_name: tech.zone_name,
        city_name: tech.city_name,
        participated_at: tech.participated_at,
        winning_status: tech.winning_status,
        phone: tech.mobile_no,
      }));
      setLoginTechnicianData(transformedData);
    } else {
      setLoginTechnicianData([]); // Ensure loginTechnicianData is empty when no data
    }
  }, [rewardLoginListData]);
  const formattedDate = useMemo(() => {
    setSearchInput(null);
    return moment(selectedDate).format("YYYY-MM-DD");
  }, [selectedDate]);

  useEffect(() => {
    switch (statusCode) {
      case "success": {
        setSelectedIds([]);
        setSelect(false);
        dispatch(clearStatusCode());
        dispatch(getAllParticipationTechCount("technician"));
        setSearchInput(null)
        setFilterData({})
        dispatch(
          getAllrewardLoginList(formattedDate, formattedDate, "technician")
        );
        dispatch(
          getAllrewardWinningList("technician", formattedDate, formattedDate)
        );
        Swal.fire({
          text: `${successMsg}`,
          confirmButtonText: "OK",
        });
        break;
      }
      case "error": {
        dispatch(clearStatusCode());
        setSelectedIds([]);
        setSelect(false);
        Swal.fire({
          text: `${errorMsg}`,
          confirmButtonText: "OK",
        });
        break;
      }
      default: {
        dispatch(clearStatusCode());
        dispatch(clearParticipationTechStatusCode());

        break;
        // return null;
      }
    }
    // eslint-disable-next-line
  }, [statusCode]);

  const handleCheckboxChange = (userId, name) => {
    setSelectedIds((prevSelectedIds) => {
      const isSelected = prevSelectedIds.includes(userId);
      let updatedSelectedIds = isSelected
        ? prevSelectedIds.filter((id) => id !== userId)
        : [...prevSelectedIds, userId];
      return updatedSelectedIds;
    });
    setSelectedNames((prevSelectedNames) => {
      const isSelected = prevSelectedNames.includes(name);
      let updatedSelectedNames = isSelected
        ? prevSelectedNames.filter((id) => id !== name)
        : [...prevSelectedNames, name];
      return updatedSelectedNames;
    });
  };

  const formattedTime = (isoString) => {
    const date = new Date(isoString);
    return date
      .toLocaleTimeString("en-IN", { hour: "2-digit", minute: "2-digit" })
      .toUpperCase();
  };

  // Function to create the HTML table
  function createNamesTable(names) {
    // Limit to first 5 names for display, and show scrollbar if more than 5
    const displayNames = names.slice(0, 5);
    const isScrollable = names.length > 5;

    const tableHeader = "<tr><th>Selected Names are:</th></tr>";
    const tableRows = displayNames
      .map((name) => `<tr><td>${name}</td></tr>`)
      .join("");
    const moreIndicator = isScrollable ? "<tr><td>...</td></tr>" : "";

    return `
        <div style="display: flex; justify-content: center; align-items: center; max-height: 150px; overflow-y: ${isScrollable ? "scroll" : "visible"
      }; padding: 5px;">
            <table style="width: 100%; border-collapse: collapse;">
                <thead>
                    ${tableHeader}
                </thead>
                <tbody>
                    ${tableRows}
                    ${moreIndicator}
                </tbody>
            </table>
        </div>
    `;
  }
  const handleSubmit = () => {
    Swal.fire({
      html: createNamesTable(selectedNames),
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          UpdateReward({
            user_id: selectedIds,
            Date: today,
            status: "technician",
          })
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          text: "Action cancelled",
          icon: "info",
        });
      }
    });
  };
  // Helper function to check if any technician has winning_status true
  const anyWinningStatusInData = () => {
    return loginTechnicianData?.some((tech) => tech?.winning_status);
  };

  const loginTechnicianColumns = [
    {
      name: "Select",
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedIds.includes(row.user_id)}
          onChange={() => handleCheckboxChange(row.user_id, row.name)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "56px", // Width of the checkbox column
    },
    {
      name: "Name",
      selector: (row) => row.name,
      grow: 1.5,
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={
              row.avatar ||
              "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8ODw4NDg0QDw4ODQ0NDg0NDg8NDQ4NFREWFhURFRMYHSggGBolGxUTITEhJSkrLi4uGB8zODMtNygtLisBCgoKDQ0NDg0NDzcZFRk3KysrKy0rNysrKysrLSsrKysrKys3KysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAOEA4QMBIgACEQEDEQH/xAAaAAEBAAMBAQAAAAAAAAAAAAAAAwECBQQH/8QAMhABAAIAAwUFBgYDAAAAAAAAAAECAwQRITFRUpESE0FhcQUUMqHR8CJCcoGx4TOSwf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8A+4gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAATIA0nEhrN5BUR7U8WNQXENWe1PFRYSi8toxEG4xEsgAAAAAAAAAAAAAAAADFp0StbUG1sTg0mQVAAAAAAAABvW/FoAtEsoxOilbaorYAAAAAAAAAAABi1tCZSmdQYmQFQAAAAHlxs7EbK/inj4f281s5efHT0iBXTHLjN4nN1iFsLPeFo/ePoGPcMVtExrE6x5MiAAAAK0tq2QiVqzqisgAAAAAAAA1tOkA0vZqCoAAAAOfncxrM0rujfPGeD15nE7NJnx3R6y5IoAigAK5fHmk+U74+/F1azExExunbDivf7OxNYmvDbHpIj2AKgAAzS2jAC41pOxsigAAAAACeJKiMyDACoAAAA8vtH4Y/XH8S5zp56utJ8pifvq5iLAAUAAer2d8c/pn+YeV7fZtdtreUR/0R7gFQAAABtSdqqC8IoAAAAADEoq33SkIAKAAAAMTGuxysxhdi2nh4Txh1mmLhRaNJ/uBXHF8bKWrujtRxj6IIoCmFgWtuj952QDStZmYiI1mXWwMPsVivWeMtMvl4p524/RcQAVAAAABWm6ElcPcitgAAAAAa33SktKIgAoAAAxa0RtmdI8wZHlxM9WPhibfKHnvnbzu0j0jWfmK6TW1InfWJ9Y1cqce877z10aTaZ8Z6yg7EYVY3Vj/AFbOLrPGerMYlo3WnrIY7I5Vc1ePza+u1amfn81YnzjYo94jhZmlt06TwnZKwgAAAArh7klaboRWwAAAAACErpYkbQagKgCOaxuxXXxnZHrxBrmc1FNkbbfKPVzsTEm062nVrMiNAAAAAAAAD04Gbmuy34q/OHmAdqtomImJ1ifFlzcljdm3ZndaekukrIAAtCdI2qooAAAAAA1vGsNgEBm8aSwqDne0La304RHz+4dFy87/AJLft/ECxABFAAAAAAAAAAHapbWInjES4rr5b4K/pgSqAzWNVRvhw3BFAAAAAAAAYtGqMwu1vXUEmlsOs7ZrEzxmG8ion3NOSvSDuaclekKAJ9xTkr0g7inJXpCgCfcU5K9IO4pyV6QoAl3FOSvQ93pyR0VAT7inJHQ7inJHRQBP3enJHQ93pyR0UAS93pyR0UiGQBWldGKVbooAAAAAAAAAAADW1dUpjRdiY1BEb2o0VAAAAAAAAAG1aSDWIUrTRtFdGUUAAAAAAAAAAAAAAAAYmsSyAnOHwazSVgEBdjQERbRkEYrPBtGHxUAYisQyAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP/9k="
            }
            alt="avatar"
            style={{
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              marginRight: "5px",
            }}
          />
          <span>{row.name}</span>
          {row.winning_status === true && (
            <img
              src={confiti}
              alt="avatar"
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                marginRight: "5px",
                transform: "scaleX(-1)",
              }}
            />
          )}
        </div>
      ),
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Zone",
      selector: (row) => row.zone_name || "-",
    },
    {
      name: "District",
      selector: (row) => row.city_name,
    },
    {
      name: "Participated Time",
      selector: (row) => row.participated_at,
      cell: (row) => formattedTime(row.participated_at),
      sortable: true,
    },
  ];

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  useEffect(() => {
    if (!isSameDay(selectedDate, currentDate)) {
      setSelect(false);
    }
  }, [selectedDate, currentDate]);

  const handleSearchInput = useCallback(
    (e) => {
      setSearchInput(e);
      dispatch(
        getAllrewardLoginList(
          formattedDate,
          formattedDate,
          "technician",
          e,
          filterData
        )
      );
      // }
    },
    // eslint-disable-next-line
    [searchInput]
  );
  const handleFilterData = (e) => {
    setFilterData(e);
    dispatch(
      getAllrewardLoginList(
        formattedDate,
        formattedDate,
        "technician",
        searchInput,
        e
      )
    );
  };
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };
  return (
    <div
      className={`data-table-container ${role === "ZonalAdmin" ? "max-h" : ""}`}
    >
      <div className="header-container">
        <p className="left-text">
          Total Participations ({rewardLoginListData?.length})
        </p>
        <div className="right-section">
          <FilterModule
            inputPlaceholder={"Search by Name"}
            onSearchInputChange={handleSearchInput}
            onChangeFilterData={handleFilterData}
            tabValue={formattedDate}
            clearValues={statusCode === "success"}
          />
          {isSameDay(selectedDate, currentDate) &&
            (!select ? (
              <p
                className="toggle-select"
                style={{
                  cursor: anyWinningStatusInData() ? "not-allowed" : "pointer",
                  opacity: anyWinningStatusInData() ? 0.5 : 1,
                }}
                onClick={() =>
                  anyWinningStatusInData()
                    ? undefined
                    : setSelect((prevSelect) => !prevSelect)
                }
              >
                Select
              </p>
            ) : (
              <p
                className="submit-button"
                style={{
                  opacity: !selectedIds?.length ? 0.5 : 1,
                  cursor: !selectedIds?.length && "not-allowed",
                }}
                onClick={selectedIds?.length ? handleSubmit : undefined}
              >
                Update
              </p>
            ))}
        </div>
      </div>
      <DataTable
        columns={
          select ? loginTechnicianColumns : loginTechnicianColumns.slice(1)
        }
        data={loginTechnicianData}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="300px"
        customStyles={{
          ...customStyles,
          rows: {
            style: {
              cursor: "default !important",
            },
          },
        }}
      />
    </div>
  );
};

export default LoginTechDataTable;
