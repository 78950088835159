import { useEffect, useRef, useState, useCallback } from "react";

const SearchableDropdownAppliance = ({
    options, // applianceList
    label = "appliance_name", // key for appliance name
    id,
    selectedVal, // selected appliance name
    handleChange, // function to handle selected appliance
    type = "Appliance", // label type, defaults to Appliance
}) => {
    const [query, setQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => setIsOpen((prev) => !prev);

    const handleClickOutside = useCallback((event) => {
        if (
            inputRef.current && !inputRef.current.contains(event.target) &&
            dropdownRef.current && !dropdownRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [handleClickOutside]);

    const selectOption = (option) => {
        const selectedAppliance = {
            id: option.id,
            appliance_name: option[label],
        };
        setQuery(selectedAppliance.appliance_name); // Set the query to selected value
        handleChange(selectedAppliance); // Update parent component with selected value
        setIsOpen(false); // Close dropdown
    };

    const getDisplayValue = () => query || selectedVal || "";

    const filteredOptions = options.filter((option) =>
        option[label]?.toLowerCase().includes(query.toLowerCase())
    );

    return (
        <div className="dropdown-input" ref={inputRef}>
            <div className="control" onClick={toggleDropdown}>
                <div className="selected-value">
                    <div className="custom-label">
                        <div className="label">{type}</div>
                    </div>
                    <input
                        style={{
                            height: "52px",
                            borderRadius: "5px",
                            paddingLeft: "13px",
                            paddingRight: "13px",
                            cursor: "pointer"
                        }}
                        type="text"
                        value={getDisplayValue()} // Show the selected value or query
                        name="searchTerm"
                        readOnly
                    />
                </div>
                <div className={`arrow ${isOpen ? "open" : ""}`} style={{ cursor: 'pointer' }}></div>
            </div>

            {isOpen && (
                <div
                    className="options open"
                    ref={dropdownRef}
                    style={{
                        maxHeight: "200px",
                        overflowY: "auto",
                        position: "absolute",
                        zIndex: 1000,
                    }}
                >
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map((option, index) => (
                            <div
                                key={`${id}-${index}`}
                                onClick={() => selectOption(option)}
                                className={`option ${option[label] === selectedVal ? "selected" : ""}`}
                                style={{
                                    padding: "10px",
                                    borderBottom: "1px solid #e0e0e0",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <span className="text-start text-content-elipsis">
                                    {option[label]}
                                </span>
                            </div>
                        ))
                    ) : (
                        <div className="option" style={{ padding: "10px" }}>
                            No results found
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchableDropdownAppliance;
