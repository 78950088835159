import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressBar = (props) => {
  const { percentage } = props;

  const progressbarStyles = buildStyles({
    pathTransitionDuration: 0.5,
    pathColor: `#EB8128`,
    trailColor: "#d6d6d6",
    textColor: "#000000",
    trailWidth: 10,
    pathWidth: 10,
    textWeight: 600,
  });

  return (
    <div style={{ width: 70, height: 70, position: "relative" }}>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={progressbarStyles}
      />
    </div>
  );
};

export default ProgressBar;
