import { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import Logo from "../../Assets/Cust/otp-verify.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../service/services";
import Swal from "sweetalert2";
import "../../common.css";
import "../../layout.css";
import Footer from "../Footer";
import S3NewLogo from "../../Assets/s3_new_logo/S3NewLogo";

const OTP = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const { expirtDateOtp } = location.state || {};
  const [intervalId, setIntervalId] = useState(null);
  const [remainingTime, setRemainingTime] = useState(expirtDateOtp * 60);
  useEffect(() => {
    if (remainingTime === 0) {
      Swal.fire({
        text: "OTP timeout. Please resend the OTP.",
        confirmButtonText: "OK",
      });
    }
    const countdownInterval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(countdownInterval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(countdownInterval);
  }, [remainingTime]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (otp.length === 4) {
      if (location.state != null && location.state.data != null) {
        var data = {
          otp,
          username: location.state.data,
          otpTime: location.state.expirtDateOtp,
        };
      }

      Api.otpverify(data).then((response) => {
        if (response.success === true) {
          Swal.fire({
            text: response.message,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/newpassword", {
                state: {
                  data: location.state.data,
                },
              });
            }
          });
        } else {
          Swal.fire({
            text: "Invalid OTP",
            confirmButtonText: "OK",
          });
        }
      });
    }
  };

  const Resendotp = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("username", location?.state?.data);

    Api.Resendotp(data).then((response) => {
      if (response.success === true) {
        Swal.fire({
          text: response.message,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            setRemainingTime(expirtDateOtp * 60);
            clearInterval(intervalId);
            const newIntervalId = setInterval(() => {
              setRemainingTime((prevTime) => {
                if (prevTime <= 0) {
                  clearInterval(newIntervalId);
                  return 0;
                }
                return prevTime - 1;
              });
            }, 1000);
            setIntervalId(newIntervalId);
          }
        });
      } else {
        Swal.fire({
          text: response?.message,
          confirmButtonText: "OK",
        });
      }
    });
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="">
      <div className="container-fluid">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
          className="res-576px-d-unset mt--40px"
        >
          <div
            className="p-0 res-576px-d-none"
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              width: "43.5%",
              padding: "60px",
              justifyContent: "center",
            }}
          >
            <div
              className="logo_container"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
                marginTop: "100px",
              }}
            >
              <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                <S3NewLogo
                  color={"rgba(235, 129, 40, 1)"}
                />
              </div>
              <img alt="demo" src={Logo} className="logo" />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "center",
                  width: "76%",
                }}
              >
                Secure Sales Service
              </div>
              <div
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  width: "76%",
                  color: "rgba(0, 0, 0, 1)",
                }}
                className="mt--10px"
              >
                A friendly neighbourhood handyman and customers satisfied with
                instant service
              </div>
            </div>
          </div>
          <div className="col-lg-1 border-register-right res-576px-d-none"></div>
          <div className="res-577px-d-none mb-4" onClick={() => navigate("/")}>
            <S3NewLogo
              color={"rgba(235, 129, 40, 1)"}
            />
          </div>
          <div
            style={{
              width: "56.5%",
              display: "flex",
              justifyContent: "center",
            }}
            className="res-576px-w-fill"
          >
            <div className="p-lg-5 p-md-3 p-2" style={{}}>
              <form onSubmit={handleSubmit}>
                <div>
                  <p
                    style={{
                      fontWeight: "700",
                      fontSize: "21px",
                      lineHeight: "26px",
                    }}
                  >
                    OTP Verification
                  </p>
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#6A707C",
                    }}
                  >
                    Enter the verification code we just sent to your email
                    address.
                  </p>
                </div>
                <div className=" mb-3">
                  <OtpInput
                    className="otp_input"
                    inputStyle={{
                      width: "90px",
                      height: "50px",
                      borderRadius: "8px",
                      outline: "none",
                      outlineColor: "#E8ECF4",
                      border: "1px solid #E8ECF4",
                      fontSize: "22px",
                      fontWeight: "700",
                    }}
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={
                      <span className="separator" style={{ color: "white" }}>
                        ___
                      </span>
                    }
                    renderInput={(props) => (
                      <input
                        {...props}
                        placeholder="x"
                        className="custom-placeholder-otp"
                      />
                    )}
                  />
                </div>
                <div className=" mt-4 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: "13px",
                        color: "#EB8128",
                      }}
                    >
                      {remainingTime > 0
                        ? `${Math.floor(remainingTime / 60)
                          .toString()
                          .padStart(2, "0")}:${(remainingTime % 60)
                            .toString()
                            .padStart(2, "0")}`
                        : "00:00"}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <span
                        type="button"
                        onClick={Resendotp}
                        style={{
                          fontWeight: "300",
                          fontSize: "13px",
                          color: "#8391A1",
                        }}
                      >
                        Resend OTP
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="d-grid gap-2">
                      <button
                        className="btn otp_btn"
                        type="submit"
                        style={{
                          height: "56px",
                          width: "100%",
                          color: "white",
                          backgroundColor:
                            otp?.length === 4
                              ? "#EB8128"
                              : "rgb(235 129 40 / 55%)",
                          marginTop: "10px",
                          cursor: otp?.length === 4 ? "pointer" : "default",
                        }}
                      >
                        Verify Code
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "100px" }}>
        <Footer topscroller />
      </div>
    </div>
  );
};

export default OTP;
