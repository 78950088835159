import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Card,
  Avatar,
  Stack,
  TextField,
  FormControl,
} from "@mui/material/";
import Swal from "sweetalert2";
import SpinnerLoader2 from "../../../plugins/SpinnerLoader2";
import FilterModule from "../../../plugins/FilterModule";
import Api from "../../../service/services";
import SearchableDropdownDict from "../../common/searchableDropDownDict";

const AllDistrict = () => {
  console.log("working");
  const [allDistricts, setAllDistricts] = useState([]);
  const [query, setQuery] = useState("");

  const role = localStorage.getItem("role");
  const [selDistrict, setSelDistrict] = useState(null);
  const [loading, setLoading] = useState(false);
  const [adminDetails, setAdminDetails] = useState(null);
  const [distAdmins, setDistAdmins] = useState([]);
  const [searchText] = useState("");
  // const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState({});
  const {
    name: districtName,
    id: districtId,
    admin_email,
    admin_id,
    admin_mobile,
    admin_name,
    admin_district,
  } = selDistrict || {};

  const filteredDistricts = useMemo(
    () =>
      searchText.trim()
        ? allDistricts?.filter(({ name }) =>
          name?.toLowerCase().includes(searchText.toLowerCase())
        )
        : allDistricts,
    [searchText, allDistricts]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await Api.getAllDistricts();
      setLoading(false);

      if (response?.length) {
        setAllDistricts(response);
        setSelDistrict(response[0]);
      }
    })();
  }, [role]);

  useEffect(() => {
    (async () => {
      if (districtId) {
        const response = await Api.getDistrictWiseTechnician(districtId);
        setDistAdmins(response || []);
        setAdminDetails(
          admin_name
            ? {
              id: admin_id,
              fullname: `${admin_name} (${admin_district})`,
              mobile_no: admin_mobile,
              emailid: admin_email,
            }
            : null
        );
      }
    })();
    // eslint-disable-next-line
  }, [selDistrict]);

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name ? `${name.split(" ")[0][0]}` : "",
    };
  }

  const handleChange = (e) => {
    setAdminDetails(e);
  };

  const handleSubmit = () => {
    if (adminDetails?.id) {
      const { id, mobile_no, emailid } = adminDetails;
      const payload = {
        admin_id: `${adminDetails?.id}`,
      };
      Api.AreaManagementUpdateCity(districtId, payload).then(async (result) => {
        Swal.fire({
          text: result,
          confirmButtonText: "OK",
        });

        const adminsArr = distAdmins.filter(
          ({ id }) => id !== adminDetails?.id
        );

        if (admin_id) {
          adminsArr.push({
            id: admin_id,
            fullname: admin_name,
            mobile_no: admin_mobile,
            emailid: admin_email,
            admin_district,
          });
        }

        setDistAdmins(adminsArr);

        setAllDistricts((prev) =>
          prev.map((district) =>
            district.id === districtId
              ? {
                ...district,
                admin_id: id,
                admin_name: admin_name,
                admin_mobile: mobile_no,
                admin_email: emailid,
                admin_district,
              }
              : district
          )
        );
        const response = await Api.getAllDistricts();
        if (response?.length) {
          setAllDistricts(response);
        }
      });
    } else {
      Swal.fire({
        text: "Please Select the Admin",
        confirmButtonText: "OK",
      });
    }
  };

  const handleSearchInput = useCallback(
    (e) => {
      // setSearchInput(e);
      if (role === "SuperAdmin") {
        Api.getAllDistricts(e, filterData).then((response) => {
          setAllDistricts(response);
          setSelDistrict(response[0]);
        });
      }
    },
    // eslint-disable-next-line
    [setAllDistricts, filterData]
  );

  const handleFilterData = (e) => {
    setFilterData(e);
    if (role === "SuperAdmin") {
      Api.getAllDistricts(e, filterData).then((response) => {
        setAllDistricts(response);
        setSelDistrict(response[0]);
      });
    }
  };

  const handleCardClick = (data) => {
    setSelDistrict(null);
    setQuery(null);
    setAdminDetails(null);
    setTimeout(() => {
      setSelDistrict(data);
    }, 0);
  };

  return (
    <>
      {loading ? (
        <SpinnerLoader2 />
      ) : (
        <>
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-lg-4">
              <FilterModule
                onSearchInputChange={handleSearchInput}
                onChangeFilterData={handleFilterData}
                inputPlaceholder={"Search by District Name"}
              />
              {filteredDistricts?.length !== 0 ? (
                <div
                  className="custom-scrollbar"
                  style={{
                    width: "100%",
                    height: "70vh",
                    overflowY: "auto",
                  }}
                >
                  {filteredDistricts &&
                    filteredDistricts?.map((data) => (
                      <Card
                        onClick={() => handleCardClick(data)}
                        style={{
                          background:
                            data.id === districtId
                              ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                              : "white",
                          border:
                            data.id === districtId
                              ? "rgb(235, 129, 40) solid 1px"
                              : "",
                          margin: "2px",
                          padding: "0px",
                          cursor: "pointer",
                          borderRadius: "10px",
                          marginTop: "0.5rem",
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          className="mt-1 mb-1 p-2 me-1"
                        >
                          <Avatar
                            className=""
                            {...stringAvatar(data?.name ? data?.name : "")}
                          />
                          <div className="mt-2">
                            <text style={{ color: "#1E232C" }}>
                              {data?.name ? data?.name : ""}
                            </text>
                          </div>
                        </Stack>
                      </Card>
                    ))}
                </div>
              ) : (
                <div>
                  <p>No Records Found</p>
                </div>
              )}
            </div>
            <div className="col-lg-4 mt-4">
              <FormControl fullWidth className="mt-4">
                <TextField
                  className="mt-2"
                  label="District Name"
                  sx={{ width: "100%" }}
                  value={districtName}
                />
              </FormControl>
              <div className={`App width-100`}>
                <SearchableDropdownDict
                  options={distAdmins}
                  label="fullname"
                  id="admin-list"
                  selectedVal={adminDetails ? adminDetails.fullname : ""}
                  handleChange={handleChange}
                  type="District Admin"
                  autocomplete="off"
                  query={query}
                  setQuery={setQuery}
                />
              </div>
              <button
                className="btn mt-4"
                variant="contained"
                type="submit"
                disabled={!adminDetails}
                style={{
                  height: "45px",
                  color: "white",
                  backgroundColor: "#EB8128",
                  textTransform: "none",
                }}
                onClick={handleSubmit}
              >
                Update
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AllDistrict;
