import React, { useEffect, useState } from "react";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import { AppBar, Tabs, Tab, Box } from "@mui/material";
import AllZone from "./AllZone";
import AllDistrict from "./AllDistrict";
import { useNavigate } from "react-router-dom";
const AreaManagement = () => {
  const navigate = useNavigate();
  const [selectedTab1, setSelectedTab1] = React.useState(0);
  const [role, setRole] = useState("");
  useEffect(() => {
    const Role = localStorage.getItem("role");
    setRole(Role);
  }, [role]);

  const handleChange = (event, newValue) => {
    setSelectedTab1(newValue);
  };

  const TabPanel = ({ value, index, children }) => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div
          className="row container-fluid"
          style={{ paddingRight: "0px", overflow: "hidden" }}
        >
          <div className="col-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <div
            className="col-12 user_management"
            style={{ paddingRight: "0px", marginTop: "80px" }}
          >
            <div className="row">
              <div className="mt-lg-2">
                <i
                  className="bi bi-chevron-left"
                  onClick={() => navigate(-1)}
                  style={{
                    fontWeight: "600",
                    fontSize: "21px",
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                ></i>
                <text
                  className="p-0"
                  style={{
                    fontWeight: "600",
                    color: "#1E232C",
                    fontSize: "21px",
                    width: "208px",
                    height: "32px",
                  }}
                >
                  Area Management
                </text>
              </div>
            </div>
            <div className="container-fluid" style={{ overflow: "hidden" }}>
              <div className="row">
                <div className="col-lg-12 p-0 mt-1">
                  <div className="col-lg-4 ms-1">
                    <div style={{ width: "95%" }} >
                      <AppBar
                        style={{ boxShadow: "none" }}
                        position="static"
                        className="w-100 bg-white"
                        aria-label="basic tabs example"
                      >
                        <Tabs
                          value={selectedTab1}
                          onChange={handleChange}
                          variant="fullWidth"
                          className="scroll-tabs"
                        >
                          <Tab
                            style={{
                              /*  fontSize: "14px", */
                              backgroundColor: "white",
                              textTransform: "none",
                            }}
                            label={`All Zones`}
                          />
                          <Tab
                            style={{
                              /*   fontSize: "14px", */
                              backgroundColor: "white",
                              textTransform: "none",
                            }}
                            label={`All Districts`}
                          />
                        </Tabs>
                      </AppBar>
                    </div>
                  </div>
                  <TabPanel value={selectedTab1} index={0}>
                    <AllZone />
                  </TabPanel>
                  <TabPanel value={selectedTab1} index={1}>
                    <AllDistrict />
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AreaManagement;
