import React, { useEffect, useState } from "react";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "@mui/material";
import { Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ZoneChart from "./ClaimZoneChart";
import DistrictChart from "./ClaimDistrictChart";
import { getAllParticipationTechCount } from "../../../redux/actions/ParticipationTechCountAction";
import DatePickerCustClaimDashboard from "./DatePickerClaimCustDashboard";
import LoginCustDataTable from "./LoginCustomerTable";
import RewardWinnigCustDataTable from "./RewardWinningCustTable";
import { clearSelectedDate } from "../../../redux/slices/todayParticipantsSlice ";
import AllCustHistoryDataTable from "./AllCustomerHistory";
import DatePickerRewardHistory from "../../Technicians/DatePickerRewardHistory";

const SuperAdminCustomerClaimDashboard = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { ParticipationTechCountData } = useSelector(
    (state) => state.ParticipationTechCountState
  );
  const { RewardZoneListData } = useSelector(
    (state) => state.RewardZoneListState
  );

  const { RewardDistrictListData } = useSelector(
    (state) => state.RewardDistrictListState
  );

  const [totalZoneWiseTechCount, setTotalZoneWiseTechCount] = useState(0);
  const [totalDistrictWiseTechCount, setTotalDistrictWiseTechCount] =
    useState(0);
  const [dashboardValue, setDashboardValue] = useState(0);
  const [customerDashboard, setCustomerDashboard] = useState(true);
  const [adminDashboard, setAdminDashboard] = useState(false);
  const [allDashboard, setAllDashboard] = useState(false);

  useEffect(() => {
    dispatch(clearSelectedDate());
    dispatch(getAllParticipationTechCount("customer"));
  }, [dispatch]);

  // Update state when RewardZoneListData changes
  useEffect(() => {
    if (RewardZoneListData) {
      const counts = RewardZoneListData.map((item) => item.count);
      const total = counts.reduce((sum, count) => sum + count, 0);
      setTotalZoneWiseTechCount(total);
    }
  }, [RewardZoneListData]);

  // Update state when RewardDistrictListData changes
  useEffect(() => {
    if (RewardDistrictListData) {
      // Sort the data by count in descending order and slice the top 7
      const topDistricts = [...RewardDistrictListData]
        .sort((a, b) => b.count - a.count)
        .slice(0, 7);
      const counts = topDistricts.map((item) => item.count);
      const total = counts.reduce((sum, count) => sum + count, 0);
      setTotalDistrictWiseTechCount(total);
    }
  }, [RewardDistrictListData]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeDashboard = (event, newValue) => {
    setDashboardValue(newValue);
  };
  return (
    <div className="wallet-option" style={{ overflow: "hidden" }}>
      <Topbar />
      <div className="row">
        <div className="col-2 col-12">
          <Sidebar />
        </div>
        <div className="col-10 p-4 pb-0 wallet">
          <div
            className="d-flex align-items-center"
            style={{
              justifyContent: "space-between",
              flexDirection: isMobile ? "column" : "row",
              marginTop: isMobile && "8%",
            }}
          >
            <div>
              <div
                className="mt-2 mb-2"
                style={{ fontWeight: "600", fontSize: "21px" }}
              >
                Reward Management - Customer
              </div>
            </div>
            {customerDashboard && (
              <div>
                <DatePickerCustClaimDashboard />
              </div>
            )}
            {allDashboard && (
              <div>
                <DatePickerRewardHistory />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-lg-12 col-12">
              <Tabs
                className="w-100 bg-white scroll-tabs"
                value={dashboardValue}
                onChange={handleChangeDashboard}
                variant="fullWidth"
              >
                <Tab
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    fontStyle: "none",
                    color: customerDashboard ? "orange" : "#6A707C",
                    textTransform: "none",
                  }}
                  label={`Dashboard`}
                  {...a11yProps(0)}
                  onClick={() => {
                    setCustomerDashboard(true);
                    setAdminDashboard(false);
                    setAllDashboard(false);
                    dispatch(clearSelectedDate()); // Dispatch action to update slice state
                  }}
                />
                <Tab
                  className=""
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    color: adminDashboard ? "orange" : "#6A707C",
                    textTransform: "none",
                  }}
                  label={`Summary View`}
                  {...a11yProps(1)}
                  onClick={() => {
                    setCustomerDashboard(false);
                    setAllDashboard(false);
                    setAdminDashboard(true);
                    dispatch(clearSelectedDate()); // Dispatch action to update slice state
                  }}
                />
                <Tab
                  className=""
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    color: allDashboard ? "orange" : "#6A707C",
                    textTransform: "none",
                  }}
                  label={`Detailed View`}
                  {...a11yProps(2)}
                  onClick={() => {
                    setCustomerDashboard(false);
                    setAdminDashboard(false);
                    setAllDashboard(true);
                    dispatch(dispatch(clearSelectedDate())); // Dispatch action to update slice state
                  }}
                />
              </Tabs>
            </div>
          </div>
          {allDashboard && (
            <div>
              <div className="row mt-3 mb-3">
                <div className="col-12">
                  <Paper
                    className="mt-1"
                    elevation={2}
                    style={{ borderRadius: "15px" }}
                  >
                    <div className="p-2">
                      <AllCustHistoryDataTable />
                    </div>
                  </Paper>
                </div>
              </div>
            </div>
          )}
          {customerDashboard && (
            <div>
              <div className="mt-3 mb-3 chart-container-reward">
                <div className="total-summary-claim  d-flex align-items-center justft-content-space-between ">
                  <div>
                    <div style={{ fontWeight: "600", fontSize: "14px" }}>
                      Total Customers
                    </div>
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        color: "orange",
                      }}
                    >
                      {ParticipationTechCountData?.total_user_count}
                    </div>
                  </div>
                  <div>
                    <div style={{ fontWeight: "600", fontSize: "14px" }}>
                      Total Winners
                    </div>
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        color: "orange",
                      }}
                    >
                      {ParticipationTechCountData?.total_winner_count}
                    </div>
                  </div>
                  <div>
                    <div style={{ fontWeight: "600", fontSize: "14px" }}>
                      Total Participants
                    </div>
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        color: "orange",
                      }}
                    >
                      {ParticipationTechCountData?.total_participant_count}
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-charts w-100 d-flex align-items-center gap-3 mt-3 mb-3">
                <div className="chart-container w-40">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      minHeight: "115px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div style={{ fontSize: "20px" }}>
                        <b>Logged In Customers</b>
                      </div>
                      <div
                        style={{
                          fontSize: "25px",
                          fontWeight: "300",
                          color: "#0B1354",
                        }}
                      >
                        {totalZoneWiseTechCount}
                      </div>
                      <div style={{ fontSize: "13px", color: "#4F4F4F" }}>
                        Zone-Wise Logins
                      </div>
                    </div>
                  </div>
                  <div className="separator-line" />
                  <div>
                    <ZoneChart />
                  </div>
                </div>
                <div className="chart-container w-75 ">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      minHeight: "115px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div style={{ fontSize: "20px" }}>
                        <b>Logged In Customers</b>
                      </div>
                      <div
                        style={{
                          fontSize: "25px",
                          fontWeight: "300",
                          color: "#0B1354",
                        }}
                      >
                        {totalDistrictWiseTechCount}
                      </div>
                      <div style={{ fontSize: "13px", color: "#4F4F4F" }}>
                        Top 7 Districts
                      </div>
                    </div>
                  </div>
                  <div className="separator-line" />

                  <div>
                    <DistrictChart />
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-12">
                  <Paper
                    className="mt-1"
                    elevation={2}
                    style={{ borderRadius: "15px" }}
                  >
                    <div className="p-2">
                      <LoginCustDataTable />
                    </div>
                  </Paper>
                </div>
              </div>
            </div>
          )}
          {adminDashboard && (
            <div>
              <div className="row mt-3 mb-3">
                <div className="col-12">
                  <Paper
                    className="mt-1"
                    elevation={2}
                    style={{ borderRadius: "15px" }}
                  >
                    <div className="p-2">
                      <RewardWinnigCustDataTable />
                    </div>
                  </Paper>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminCustomerClaimDashboard;
