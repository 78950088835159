import React, { useEffect, useState, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const DistrictChart = () => {
  const { RewardDistrictListData } = useSelector(
    (state) => state.RewardDistrictListState
  );

  const [districtData, setDistrictData] = useState({
    districtNames: [],
    claimCounts: [],
  });

  useEffect(() => {
    setDistrictDataFromAPI(RewardDistrictListData);
  }, [RewardDistrictListData]);

  const setDistrictDataFromAPI = (data) => {
    // Sort the data by count in descending order, then by city_name in ascending order
    const sortedDistricts = [...data].sort((a, b) => {
      if (b.count !== a.count) {
        return b.count - a.count; // Sort by count descending
      } else {
        return a.city_name.localeCompare(b.city_name); // Sort by city_name ascending
      }
    });

    // Get the top 7 districts
    const topDistricts = sortedDistricts.slice(0, 7);

    const districtNames = topDistricts.map((item) => item.city_name);
    const claimCounts = topDistricts.map((item) => item.count);

    setDistrictData({ districtNames, claimCounts });
  };

  const state = useMemo(
    () => ({
      series: [
        {
          name: "District-count",
          data: districtData.claimCounts,
        },
      ],
      options: {
        xaxis: {
          categories: districtData.districtNames.map(String),
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#F9A825"],
      },
    }),
    [districtData]
  );

  return (
    <div id="chart" style={{ overflowY: "auto" }}>
      {RewardDistrictListData.length > 0 &&
      districtData.claimCounts.length &&
      districtData.districtNames.length ? (
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={310}
          width={620}
        />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default DistrictChart;
