import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import { WinningCustRequest, WinningCustFail, WinningCustSuccess } from "../slices/winningCustSlice";
export const getAllWinningCust = (startDate, endDate) => async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
        dispatch(WinningCustRequest())
        const response = await axios.get(`${BASE_URL}/customer/reward/winning`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        dispatch(WinningCustSuccess(response?.data))
    } catch (error) {
        console.log(error);
        dispatch(WinningCustFail(error?.response?.data))
    }
}
