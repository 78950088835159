import React, { useEffect, useState } from "react";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import {
  Stack,
  Button,
  Typography,
  Card,
  Paper,
  IconButton,
  InputBase,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";

import "../../../layout.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { FaCalendarDays } from "react-icons/fa6";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import Api from "../../../service/services";
import moment from "moment";
import { Modal } from "react-bootstrap";
import ProgressBar from "../../Customers/progress";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { KeyboardArrowUp } from "@mui/icons-material";
const OrderManagement = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(theme.breakpoints.down("sm"));

  const [SrDetailArray] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [userid, setUserId] = useState("");
  const [orderListById, setOrderListById] = useState({});
  const [filterModal, setFilterModal] = useState(false);
  const [filterOrderTracking, setfilterOrderTracking] = useState("");
  const [updateStatus, setUpdateStatus] = useState(false);
  const [itemStatus, setItemStatus] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [clickTrue, setClickTrue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDelivery, setIsDelivery] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isOrderStatusOpen, setIsOrderStatusOpen] = useState(false);

  const handleUpdateOrder = async () => {
    let updatedOrderStatus;

    if (itemStatus === "submitted") {
      updatedOrderStatus = "dispatched";
    } else if (itemStatus === "dispatched") {
      updatedOrderStatus = "delivered";
    }

    try {
      await Api.editOrderStatus(orderId, updatedOrderStatus);
      const updatedOrdersList = await Api.myOrdersList();
      setOrdersList(updatedOrdersList);

      getOrderById(orderId, updatedOrdersList);
    } catch (err) {}
  };

  const searchFilter = async (event) => {
    const searchTerm = event.target.value.toLowerCase().trim();

    if (searchTerm === "") {
      const res = await Api.myOrdersList();
      setOrdersList(res);
      if (res?.length > 0) {
        getOrderById(res[0]?.id, res);
      }
    } else {
      try {
        const res = await Api.myOrdersList();
        const filteredOrders = res.filter((order) => {
          const productName = order?.id;
          return productName.includes(searchTerm);
        });
        setOrdersList(filteredOrders);
        if (filteredOrders?.length > 0) {
          getOrderById(filteredOrders[0]?.id, filteredOrders);
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  const handleApplyFilter = () => {
    let payload = null;
    if (clickTrue) {
      payload = {
        startDate: clearFilter
          ? ""
          : moment(dateRange[0]?.startDate).format("YYYY-MM-DD"),
        endDate: clearFilter
          ? ""
          : moment(dateRange[0]?.endDate).format("YYYY-MM-DD"),
      };
    }
    setFilterModal(false);
    setFilterApplied(true);
    if (clearFilter) {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
    }

    Api.myOrdersList(payload, filterOrderTracking)
      .then((res) => {
        setOrdersList(res);

        if (res?.length > 0) {
          getOrderById(res[0]?.id, res);
        }
      })
      .catch((err) => console.log(err));
  };
  const clearData = () => {
    setClickTrue(false);
    setClearFilter(true);
    setfilterOrderTracking("");
    setFilterApplied(false);
    setFilterModal(true);
    Api.myOrdersList()
      .then((res) => {
        setOrdersList(res);

        if (res?.length > 0) {
          getOrderById(res[0]?.id, res);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleFilterCancel = () => {
    setFilterModal(false);
  };
  const currentDate = new Date();

  const defaultDate = [
    {
      startDate: currentDate,
      endDate: currentDate,
      key: "selection",
    },
  ];
  const [dateRange, setDateRange] = useState(defaultDate);
  const [viewDatePickerPopover, setViewDatePickerPopover] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);

  const DatePicker = () => {
    const [dateRangeSelected, setDateRangeSelected] = useState(defaultDate);
    const handleSelectDate = (ranges) => {
      setDateRangeSelected([ranges.selection]);
    };
    const handleCancelDate = () => {
      setClickTrue(false);
      setViewDatePickerPopover(false);
      setDateRangeSelected(defaultDate);
    };
    const handleSetSelectedDate = () => {
      setClickTrue(true);
      setDateRange(dateRangeSelected);
      setViewDatePickerPopover(false);
      setDateRangeSelected(defaultDate);
      setClearFilter(false);
    };
    return (
      <div>
        <div
          onClick={() => {
            setViewDatePickerPopover(!viewDatePickerPopover);
            setDateRangeSelected(dateRange);
          }}
          className="selected-date-filter "
        >
          {clickTrue ? (
            <div className="d-flex align-items-center gap-3">
              <div>{moment(dateRange[0].startDate).format("DD-MM-YYYY")}</div>
              {moment(dateRange[0].startDate).format("DD-MM-YYYY") !==
                moment(dateRange[0].endDate).format("DD-MM-YYYY") && (
                <div>-</div>
              )}
              {moment(dateRange[0].startDate).format("DD-MM-YYYY") !==
                moment(dateRange[0].endDate).format("DD-MM-YYYY") && (
                <div>{moment(dateRange[0].endDate).format("DD-MM-YYYY")}</div>
              )}
            </div>
          ) : (
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: "gray" }}
            >
              DD-MM-YYYY
            </div>
          )}
          <FaCalendarDays style={{ color: "rgb(235, 129, 40)" }} />
        </div>
        <Modal
          show={viewDatePickerPopover}
          onHide={() => setViewDatePickerPopover(false)}
          className="date-picker-modal"
        >
          <div
            className="d-flex align-items-center set-cancel-container"
            style={{
              justifyContent: "center",
            }}
          >
            <div>
              <DateRangePicker
                ranges={dateRangeSelected}
                onChange={handleSelectDate}
                maxDate={currentDate}
                rangeColors={"rgb(235, 129, 40)"}
              />
            </div>
            <div>
              <button
                className="filter-apply-btn "
                onClick={() => handleSetSelectedDate()}
              >
                Set
              </button>
              <button
                className="filter-cancel-btn mt-2"
                onClick={() => handleCancelDate()}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  const handleFilterChange = (event) => {
    setfilterOrderTracking(event.target.value);
  };

  useEffect(() => {
    Api.myOrdersList()
      .then((res) => {
        setOrdersList(res);
        if (res?.length > 0) {
          getOrderById(res[0]?.id, res);
        }
      })
      .catch((err) => console.log("err", err));
    // eslint-disable-next-line
  }, []);
  const getOrderById = (id, list) => {
    setUserId(id);
    const order = list.find((item) => item?.id === id.toString());
    setOrderListById(order);
    if (mobileMatches) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  };

  return (
    <>
      <div className="" style={{ overflow: "hidden" }}>
        <Topbar />
        <div
          className="row container-fluid"
          style={{ overflow: "hidden", paddingRight: "0px" }}
        >
          <div className="col-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <div
            className="col-12 user_management"
            style={{ paddingRight: "0px", marginTop: "80px" }}
          >
            <div className="mt-lg-2 ">
              <i
                onClick={() => navigate(-1)}
                className="bi bi-chevron-left "
                style={{
                  fontWeight: "600",
                  fontSize: "21px",
                  maxHeight: "20px",
                  minHeight: "20px",
                  maxWidth: "20px",
                  minWidth: "20px",
                  cursor: "pointer",
                }}
              ></i>
              <text
                className="p-0"
                style={{
                  fontWeight: "600",
                  color: "#1E232C",
                  fontSize: "21px",
                  maxWidth: "208px",
                  maxHeight: "32px",
                }}
              >
                Order Management - Total Orders ({ordersList?.length})
              </text>
            </div>
            <ul
              className="p-0 mt-3 mb-0"
              style={{
                display: "inline-flex",
                width: "33.2%",
              }}
            >
              <li className="ms-2" style={{ listStyle: "none", width: "100%" }}>
                <Paper
                  elevation={0}
                  className="w-100  border rounded-0"
                  component="form"
                  sx={{ display: "flex", alignItems: "center" }}
                  style={{ maxHeight: "42px" }}
                >
                  <IconButton type="button" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ flex: 1 }}
                    style={{
                      height: "40px",
                      fontFamily: "Arial",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      width: "100%",
                    }}
                    onChange={searchFilter}
                    placeholder="Search by Order ID"
                    inputProps={{ "aria-label": "search google maps" }}
                  />
                </Paper>
              </li>
              <li className="ms-2" style={{ listStyle: "none" }}>
                <IconButton
                  style={{ width: "40px", height: "40px" }}
                  className="border rounded-0"
                  aria-label="delete"
                  onClick={() => setFilterModal(true)}
                >
                  {filterApplied && (
                    <Tooltip title="Filter applied" arrow>
                      <div className="filter-indicator">
                        <div className="ic" />
                      </div>
                    </Tooltip>
                  )}
                  <FilterListIcon style={{ color: "rgb(235, 129, 40)" }} />
                </IconButton>
              </li>
            </ul>
            <Modal
              show={filterModal}
              onHide={() => setFilterModal(false)}
              centered
              size="md"
              className="filter-modal"
            >
              <div className="p-3">
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "25px",
                  }}
                >
                  Filter
                </div>
                <div className="row">
                  <div className="col-12 col-lg-12">
                    <DatePicker />
                  </div>
                  <div className="col-12 col-lg-12 mt-3">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Order Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filterOrderTracking}
                        label=" Order Status"
                        onChange={handleFilterChange}
                      >
                        <MenuItem value={"submitted"}>Order Placed</MenuItem>
                        <MenuItem value={"dispatched"}>Dispatched</MenuItem>
                        <MenuItem value={"delivered"}>Delivered</MenuItem>
                      </Select>
                    </FormControl>{" "}
                  </div>

                  <div
                    className="d-flex align-items-center mt-4 gap-2"
                    style={{
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <button
                      className="filter-clear-btn"
                      onClick={() => clearData()}
                    >
                      Clear
                    </button>
                    <button
                      className="filter-cancel-btn"
                      onClick={() => handleFilterCancel()}
                    >
                      Cancel
                    </button>
                    <button
                      className="filter-apply-btn"
                      onClick={() => handleApplyFilter()}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
            <div className="container-fluid" style={{ overflow: "hidden" }}>
              <div className="row">
                <div
                  className="col-lg-4 p-0 Main_menu"
                  style={{ paddingTop: "15px" }}
                >
                  <div className="container-fluid" style={{ padding: "1px" }}>
                    {ordersList?.length > 0 ? (
                      <div
                        className="mt-lg-3 custom-scrollbar-order order-management-card"
                        style={{ height: "110vh" }}
                      >
                        {ordersList?.map((item, index) => {
                          const progressPercentage = () => {
                            if (item?.status === "submitted") {
                              return "50";
                            } else if (item?.status === "failed") {
                              return "0";
                            } else if (item?.status === "pending") {
                              return "0";
                            } else if (item?.status === "dispatched") {
                              return "80";
                            } else if (item?.status === "delivered") {
                              return "100";
                            } else if (item?.status === "cancelled") {
                              return "0";
                            } else {
                              return "0";
                            }
                          };
                          return (
                            <Card
                              className=""
                              key={index}
                              onClick={() => getOrderById(item?.id, ordersList)}
                              style={{
                                marginBottom: "10px",
                                background:
                                  item?.id === userid
                                    ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                    : "white",
                                border:
                                  item?.id === userid
                                    ? "rgb(235, 129, 40) solid 1px"
                                    : "",
                                margin: "2px",
                                padding: "0px",
                                cursor: "pointer",
                                borderRadius: "10px",
                                marginTop: "0.5rem",
                              }}
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                className="mt-1 mb-2 p-2 me-1 "
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div style={{ fontSize: "13px" }}>
                                    <text style={{ fontWeight: "bold" }}>
                                      Order Id #:{" "}
                                    </text>
                                    <text style={{ color: "#1E232C" }}>
                                      {item?.id?.slice(-6)}
                                    </text>

                                    <div
                                      style={{
                                        fontSize: "13px",
                                        marginTop: "6px",
                                      }}
                                    >
                                      <text style={{ fontWeight: "bold" }}>
                                        Amount:{" "}
                                      </text>
                                      <text style={{ color: "#1E232C" }}>
                                        ₹ {item?.net_payable_amount}
                                      </text>

                                      <div
                                        style={{
                                          fontSize: "13px",
                                          marginTop: "6px",
                                        }}
                                      >
                                        <b>Deliver by: </b>
                                        {moment(item?.delivery_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <Tooltip title={item?.status}>
                                    <div style={{}}>
                                      <ProgressBar
                                        percentage={progressPercentage()}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </Stack>
                            </Card>
                          );
                        })}
                      </div>
                    ) : (
                      <div
                        style={{
                          color: "rgb(235, 129, 40)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        No Orders Found!
                      </div>
                    )}
                  </div>
                </div>
                {ordersList?.length > 0 && (
                  <div className="col-lg-8 col-12 order-management-space ">
                    <div className="order-details">
                      <p
                        className="ms-lg-1 mb-lg-1 "
                        style={{ fontSize: "13px" }}
                      >
                        <b>Order ID #: </b>
                        {orderListById?.id?.slice(-6)}
                      </p>
                      <p
                        className="ms-lg-1 mb-lg-1"
                        style={{ fontSize: "13px" }}
                      >
                        <b>Created at :</b>{" "}
                        {moment(orderListById?.created_at).format(
                          "DD-MM-YYYY, hh:mm a"
                        )}
                      </p>
                    </div>
                    {/* Ordered Items */}
                    <ul
                      className="p-0 mt-3"
                      style={{
                        display: "inline-flex",
                        width: "-webkit-fill-available",
                      }}
                    >
                      <li
                        className="mt-2"
                        style={{
                          listStyle: "none",
                        }}
                      >
                        <div
                          className="w-20 rounded-circle"
                          style={{
                            backgroundColor: "#36A93F",
                            height: "20px",
                            width: "20px",
                          }}
                        ></div>
                      </li>
                      <li
                        className="ms-2"
                        style={{
                          listStyle: "none",
                          width: "-webkit-fill-available",
                        }}
                      >
                        <Accordion>
                          <AccordionSummary
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            <Typography>
                              {" "}
                              {orderListById?.carts?.length > 1
                                ? "Ordered Items"
                                : "Ordered Item"}{" "}
                              ({orderListById?.carts?.length})
                            </Typography>
                          </AccordionSummary>

                          <AccordionDetails
                            className="accordian_details"
                            style={{ width: "520px" }}
                          >
                            <div
                              className="similar-products-container"
                              style={{
                                overflowX: "auto",
                                gap: "6px",
                                paddingBottom: "5px",
                              }}
                            >
                              {orderListById?.carts?.map((item, index) => (
                                <Card
                                  key={index}
                                  sx={{ flexShrink: 0 }}
                                  className="mt-2 mb-2"
                                  style={{
                                    width: "auto",
                                    height: "80px",
                                    marginLeft: "4px",
                                    marginRight: "4px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <img
                                      alt={item?.name}
                                      src={item?.product_img}
                                      style={{
                                        width: "90px",
                                        padding: "6px",
                                        height: "80px",
                                      }}
                                    />
                                    <div className="p-3">
                                      <div
                                        style={{
                                          fontSize: "12px",
                                        }}
                                      >
                                        <b>Product Name :</b> {item?.name}{" "}
                                      </div>
                                      <div style={{ fontSize: "12px" }}>
                                        <b> Quantity :</b> {item?.quantity}
                                      </div>
                                      <div style={{ fontSize: "12px" }}>
                                        <b> Amount :</b> {item?.amount}
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              ))}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    </ul>

                    {/* Delivery Details */}
                    <ul
                      className="p-0 mt-3"
                      style={{
                        display: "inline-flex",
                        width: "-webkit-fill-available",
                      }}
                    >
                      <li
                        className="mt-2"
                        style={{
                          listStyle: "none",
                        }}
                      >
                        <div
                          className="w-20 rounded-circle"
                          style={{
                            backgroundColor: "#36A93F",
                            height: "20px",
                            width: "20px",
                          }}
                        ></div>
                      </li>
                      <li
                        className="ms-2"
                        style={{
                          listStyle: "none",

                          width: "-webkit-fill-available",
                        }}
                      >
                        <Accordion>
                          <AccordionSummary
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={() => setIsDelivery(!isDelivery)}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              width="100%"
                            >
                              <Typography> Delivery Details </Typography>
                              {isDelivery ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails
                            className="accordian_details"
                            style={{ width: "580px" }}
                          >
                            <div className="row p-2">
                              <div className="text-center p-0 with-vertical-line"></div>
                              <div className="col-lg pl-2 text-start">
                                <table className="w-100 border-0">
                                  {SrDetailArray.length > 0 ? (
                                    SrDetailArray.map((srDetail, index) => (
                                      <tbody></tbody>
                                    ))
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        paddingLeft: "16px",
                                      }}
                                    >
                                      <div style={{ flex: 1 }}>
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <b>Deliver to</b>
                                        </p>
                                      </div>

                                      <div
                                        style={{
                                          flexBasis: "50px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <span>:</span>
                                      </div>

                                      <div style={{ flex: 3 }}>
                                        <p
                                          className="m-0 pe-2"
                                          style={{
                                            color: "black",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {orderListById?.address?.name || ""}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </table>
                                <table className="w-100 border-0">
                                  {SrDetailArray.length > 0 ? (
                                    SrDetailArray.map((srDetail, index) => (
                                      <tbody></tbody>
                                    ))
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        paddingLeft: "16px",
                                      }}
                                    >
                                      <div style={{ flex: 1 }}>
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <b>Mobile</b>
                                        </p>
                                      </div>

                                      <div
                                        style={{
                                          flexBasis: "50px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <span>:</span>
                                      </div>

                                      <div style={{ flex: 3 }}>
                                        <p
                                          className="m-0 pe-2"
                                          style={{
                                            color: "black",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {orderListById?.address?.mobile_no ||
                                            ""}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </table>
                                <table className="w-100 border-0">
                                  {SrDetailArray.length > 0 ? (
                                    SrDetailArray.map((srDetail, index) => (
                                      <tbody></tbody>
                                    ))
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        paddingLeft: "16px",
                                        paddingBottom: "15px",
                                      }}
                                    >
                                      <div style={{ flex: 1 }}>
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <b>Address:</b>
                                        </p>
                                      </div>

                                      <div
                                        style={{
                                          flexBasis: "50px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <span>:</span>
                                      </div>

                                      <div style={{ flex: 3 }}>
                                        <p
                                          className="m-0 pe-2"
                                          style={{
                                            color: "black",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {orderListById?.address?.address},{" "}
                                          {orderListById?.address?.city_name},{" "}
                                          {orderListById?.address?.postalcode ||
                                            ""}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </table>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    </ul>

                    {/* Payment Details */}
                    <ul
                      className="p-0 mt-3"
                      style={{
                        display: "inline-flex",
                        width: "-webkit-fill-available",
                      }}
                    >
                      <li
                        className="mt-2"
                        style={{
                          listStyle: "none",
                        }}
                      >
                        <div
                          className="w-20 rounded-circle"
                          style={{
                            backgroundColor: "#36A93F",
                            height: "20px",
                            width: "20px",
                          }}
                        ></div>
                      </li>
                      <li
                        className="ms-2"
                        style={{
                          listStyle: "none",
                          width: "-webkit-fill-available",
                        }}
                      >
                        <Accordion>
                          <AccordionSummary
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={() => setIsPaymentOpen(!isPaymentOpen)} // Toggle the open state
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              width="100%"
                            >
                              <Typography> Payment Details </Typography>
                              {isPaymentOpen ? (
                                <KeyboardArrowUp />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails
                            className="accordian_details"
                            style={{ width: "520px" }}
                          >
                            <div className="row p-2 ">
                              <div className="text-center p-0 with-vertical-line"></div>
                              <div className="col-lg pl-2 text-start">
                                <div
                                  style={{
                                    display: "flex",
                                    // alignItems: "center",
                                    paddingLeft: "16px",
                                  }}
                                >
                                  <div style={{ flex: 1 }}>
                                    <p
                                      className="m-0"
                                      style={{
                                        color: "black",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <b>Order Items</b>
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      flexBasis: "50px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <span>:</span>
                                  </div>

                                  <div style={{ flex: 2.5 }}>
                                    <p
                                      className="m-0"
                                      style={{
                                        color: "black",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      &nbsp; {orderListById?.carts?.length}
                                    </p>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "16px",
                                  }}
                                >
                                  <div style={{ flex: 1 }}>
                                    <p
                                      className="m-0"
                                      style={{
                                        color: "black",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <b>Order Value (₹)</b>
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      flexBasis: "50px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <span>:</span>
                                  </div>

                                  <div style={{ flex: 2.5 }}>
                                    <p
                                      className="m-0"
                                      style={{
                                        color: "black",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      &nbsp;
                                      {orderListById?.net_payable_amount || ""}
                                    </p>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "16px",
                                    paddingBottom: "15px",
                                  }}
                                >
                                  <div style={{ flex: 1 }}>
                                    <p
                                      className="m-0"
                                      style={{
                                        color: "black",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <b>Payment Mode</b>
                                    </p>
                                  </div>

                                  <div
                                    style={{
                                      flexBasis: "50px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <span>:</span>
                                  </div>
                                  <div style={{ flex: 2.5 }}>
                                    <p
                                      className="m-0"
                                      style={{
                                        color: "black",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      &nbsp;
                                      {orderListById?.transaction
                                        ?.payment_method || ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    </ul>
                    <ul
                      className="p-0 mt-3"
                      style={{
                        display: "inline-flex",
                        width: "-webkit-fill-available",
                      }}
                    >
                      <li
                        className="mt-2"
                        style={{
                          listStyle: "none",
                        }}
                      >
                        <div
                          className="w-20 rounded-circle"
                          style={{
                            backgroundColor:
                              orderListById?.status !== "delivered"
                                ? "#FCB61A"
                                : "#36A93F",
                            height: "20px",
                            width: "20px",
                          }}
                        ></div>
                      </li>
                      <li
                        className="ms-2"
                        style={{
                          listStyle: "none",
                          width: "-webkit-fill-available",
                        }}
                      >
                        <Accordion>
                          <AccordionSummary
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={() =>
                              setIsOrderStatusOpen(!isOrderStatusOpen)
                            } // Toggle the open state
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              width="100%"
                            >
                              <Typography> Order Status </Typography>
                              {isOrderStatusOpen ? (
                                <KeyboardArrowUp />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails
                            className="accordian_details"
                            style={{ width: "520px" }}
                          >
                            <div className="row p-2">
                              <div className=" text-center p-0 with-vertical-line"></div>
                              <div className="col-lg p-lg-0 pl-2 text-start">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "25px",
                                    fontSize: "13px",
                                    textTransform: "capitalize",
                                    paddingBottom: "15px",
                                  }}
                                >
                                  <div style={{ flex: 1 }}>
                                    <p
                                      className="m-0"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <b>Current Status</b>
                                    </p>
                                  </div>

                                  <div
                                    style={{
                                      flexBasis: "50px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <span>:</span>
                                  </div>

                                  <div style={{ flex: 2.5 }}>
                                    <p
                                      className="m-0"
                                      style={{
                                        color: "black",
                                        fontWeight: "400",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      &nbsp;{orderListById?.status || ""}
                                    </p>
                                  </div>
                                </div>

                                {orderListById?.status === "submitted" && (
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: "white",
                                      marginTop: "20px",
                                      border: "1px solid rgb(235, 129, 40)",
                                      backgroundColor: "rgb(235, 129, 40)",
                                    }}
                                    onClick={() => {
                                      setOrderId(orderListById?.id);
                                      setItemStatus(orderListById?.status);
                                      setUpdateStatus(true);
                                    }}
                                  >
                                    Update Status
                                  </Button>
                                )}
                                {orderListById?.status === "dispatched" && (
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: "white",
                                      marginTop: "20px",
                                      border: "1px solid rgb(235, 129, 40)",
                                      backgroundColor: "rgb(235, 129, 40)",
                                    }}
                                    onClick={() => {
                                      setOrderId(orderListById?.id);
                                      setItemStatus(orderListById?.status);
                                      setUpdateStatus(true);
                                    }}
                                  >
                                    Update Status
                                  </Button>
                                )}
                                <Modal
                                  className="filter-modal"
                                  show={updateStatus}
                                  onHide={() => setUpdateStatus(false)}
                                  centered
                                >
                                  {orderListById?.status === "submitted" && (
                                    <div
                                      className="pt-3 pb-4"
                                      style={{ textAlign: "center" }}
                                    >
                                      Are you sure you want to update the status
                                      from Submitted to Dispatched?
                                    </div>
                                  )}
                                  {orderListById?.status === "dispatched" && (
                                    <div
                                      className="pt-3 pb-4"
                                      style={{ textAlign: "center" }}
                                    >
                                      Are you sure you want to update the status
                                      from Dispatched to Delivered?
                                    </div>
                                  )}

                                  <div
                                    className="d-flex align-items-center pb-2"
                                    style={{
                                      justifyContent: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      style={{
                                        border: "1px solid rgb(235, 129, 40)",
                                        color: "rgb(235, 129, 40)",
                                      }}
                                      onClick={() => setUpdateStatus(false)}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        setUpdateStatus(false);
                                        handleUpdateOrder();
                                        setItemStatus(null);
                                      }}
                                      style={{
                                        backgroundColor: "rgb(235, 129, 40)",
                                        color: "white",
                                        border: "1px solid rgb(235, 129, 40)",
                                      }}
                                    >
                                      Yes, Update
                                    </Button>
                                  </div>
                                </Modal>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderManagement;
