import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./common/Navbar";

export default function PrivacyPolicy() {
  const [showButton, setShowButton] = useState(false);
  const currentRef = useRef(null);
  const location = useLocation();
  const buttonStyle = {
    position: "fixed",
    bottom: "20px",
    right: "14px",
    fontSize: "14px",
    padding: "20px 0px",
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
    animation: "moveUpDown 2s infinite",
    height: "60px",
  };
  const handleClick = (index) => {
    const section = document.getElementById(`section-${index}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.error(`Section with ID 'section-${index}' not found.`);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const isMobileView = window.innerWidth <= 768;
      const offsetThreshold = isMobileView ? 700 : 300;
      const offset = documentHeight - windowHeight - scrollTop;

      if (scrollTop > 0 && offset > offsetThreshold) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);
  return (
    <div ref={currentRef}>
      {showButton && (
        <div style={buttonStyle} onClick={() => handleClick(2)}>
          ToC
        </div>
      )}
      <div className="sticky-top">
        <Navbar />
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#F8F8F8",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "30px",
          paddingBottom: "30px",
          marginTop: "60px",
        }}
      >
        <div
          className="res-576px-p-20px"
          style={{
            padding: 50,
            width: "90%",
            backgroundColor: "white",
            boxShadow: "0 0 10px 1px #00000036",
            fontSize: "14px",
          }}
        >
          <h5 style={{ marginTop: 0, textAlign: "center", marginBottom: 20 }}>
            <b>PRIVACY POLICY</b>
          </h5>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div style={{ fontSize: 11, display: "flex", gap: "3px" }}>
              <span>Last Updated: </span>
              <span className="date">
                12<span className="superscript">th</span> June, 2024
              </span>
            </div>
          </div>
          <div style={{ fontSize: 17, marginTop: 40 }}>
            <b>Introduction</b>
          </div>

          <div style={{ fontSize: 14, marginTop: "30px" }}>
            <div>
              This privacy notice for Secure Sales Service (S3) App (doing
              business as S3-app) ('we', 'us', or 'our'), describes how and why
              we might collect, store, use, and/or share ('process') your
              information when you use our services ('Services'), such as when
              you:
            </div>
            <ul>
              <li className="mt-3">
                Visit our website at{" "}
                <span style={{ color: "blue" }}>
                  <a
                    href="https://s3-app.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://s3-app.com
                  </a>
                </span>
                , or any website of ours that links to this privacy notice
              </li>
              <li className="mt-3">
                Download and use our mobile application (S3-app), or any other
                application of ours that links to this privacy notice{" "}
              </li>
              <li className="mt-3">
                Engage with us in other related ways, including any sales, or
                events{" "}
              </li>
            </ul>
            <div>
              <b>Questions or concerns?</b> Reading this privacy notice will
              help you understand your privacy rights and choices. If you do not
              agree with our policies and practices, please do not use our
              Services. If you still have any questions or concerns, please
              contact us at support@s3-app.com.{" "}
            </div>
          </div>

          <h6 style={{ marginTop: "30px" }}>
            <b>SUMMARY OF KEY POINTS </b>
          </h6>
          <p style={{ fontSize: 14, marginTop: "30px" }}>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => handleClick(2)}
            >
              table of contents{" "}
            </span>
            below to find the section you are looking for.
          </p>
          <p style={{ fontSize: 14, marginTop: "30px" }}>
            <b>What personal information do we process?</b> When you visit, use,
            or navigate our Services, we may process personal information
            depending on how you interact with us and the Services, the choices
            you make, and the products and features you use. Learn more about
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                handleClick(0);
              }}
            >
              {" "}
              personal information you disclose to us.
            </span>
          </p>
          <p style={{ fontSize: 14, marginTop: "30px" }}>
            <b>Do we process any sensitive personal information?</b> We may
            process sensitive personal information when necessary with your
            consent or as otherwise permitted by applicable law. Learn more
            about
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                handleClick(1);
              }}
            >
              {" "}
              sensitive information we process.
            </span>
          </p>
          <p style={{ fontSize: 14, marginTop: "30px" }}>
            <b>Do we collect any information from third parties? </b> We do not
            collect any information from third parties.
          </p>
          <p style={{ fontSize: 14, marginTop: "30px" }}>
            <b>How do we process your information?</b>We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with the law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so. Learn more about
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                handleClick(3);
              }}
            >
              {" "}
              how we process your information.
            </span>
          </p>
          <p style={{ fontSize: 14, marginTop: "30px" }}>
            <b>
              In what situations and with which parties do we share personal
              information?
            </b>
            We may share information in specific situations and with specific
            third parties. Learn more about
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                handleClick(4);
              }}
            >
              {" "}
              when and with whom we share your personal information.{" "}
            </span>
          </p>
          <p style={{ fontSize: 14, marginTop: "30px" }}>
            <b>How do we keep your information safe?</b>
            We have organizational and technical processes and procedures in
            place to protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorised third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information. Learn more about
            <span
              onClick={() => handleClick(5)}
              style={{ color: "blue", cursor: "pointer" }}
            >
              {" "}
              how we keep your information safe.{" "}
            </span>
          </p>
          <p style={{ fontSize: 14, marginTop: "30px" }}>
            <b>What are your rights?</b>
            Depending on where you are located geographically, the applicable
            privacy law may mean you have certain rights regarding your personal
            information. Learn more about
            <span
              onClick={() => handleClick(6)}
              style={{ color: "blue", cursor: "pointer" }}
            >
              {" "}
              your privacy rights.{" "}
            </span>
          </p>
          <p
            id={`section-${2}`}
            data-index={2}
            style={{ fontSize: 14, marginTop: "30px" }}
          >
            <b>How do you exercise your rights?</b>
            The easiest way to exercise your rights is by visiting{" "}
            <a
              style={{ color: "blue" }}
              href=" https://s3-app.com/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://s3-app.com/login
            </a>
            , or by contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </p>

          <p style={{ fontSize: 14, marginTop: "30px" }}>
            Want to learn more about what we do with any information we collect?
            Review the privacy notice in full.
          </p>
          <div>
            <div>
              <h6 style={{ marginTop: "30px" }}>
                <b>TABLE OF CONTENTS (ToC) </b>
              </h6>
              <div style={{ color: "blue" }}>
                <div
                  className="mt-4"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  onClick={() => handleClick(0)}
                >
                  <em>1. WHAT INFORMATION DO WE COLLECT?</em>
                </div>
                <div
                  className="mt-2"
                  onClick={() => {
                    handleClick(3);
                  }}
                  style={{ fontSize: "14px", cursor: "pointer" }}
                >
                  <em>2. HOW DO WE PROCESS YOUR INFORMATION? </em>
                </div>
                <div
                  className="mt-2"
                  onClick={() => {
                    handleClick(4);
                  }}
                  style={{ fontSize: "14px", cursor: "pointer" }}
                >
                  <em>
                    {" "}
                    3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?{" "}
                  </em>
                </div>
                <div
                  onClick={() => handleClick(7)}
                  className="mt-2"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                >
                  <em>
                    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?{" "}
                  </em>
                </div>
                <div
                  className="mt-2"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  onClick={() => handleClick(8)}
                >
                  <em> 5. HOW LONG DO WE KEEP YOUR INFORMATION? </em>
                </div>
                <div
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  className="mt-2"
                  onClick={() => handleClick(5)}
                >
                  <em>6. HOW DO WE KEEP YOUR INFORMATION SAFE? </em>
                </div>
                <div
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  className="mt-2"
                  onClick={() => handleClick(9)}
                >
                  <em>7. DO WE COLLECT INFORMATION FROM MINORS? </em>
                </div>
                <div
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  className="mt-2"
                  onClick={() => handleClick(6)}
                >
                  <em>8. WHAT ARE YOUR PRIVACY RIGHTS? </em>
                </div>
                <div
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  className="mt-2"
                  onClick={() => handleClick(10)}
                >
                  <em>9. CONTROLS FOR DO-NOT-TRACK FEATURES </em>
                </div>
                <div
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  className="mt-2"
                  onClick={() => handleClick(11)}
                >
                  <em>
                    10. COMPLIANCE UNDER INFORMATION TECHNOLOGY ACT, 2000{" "}
                  </em>
                </div>
                <div
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  className="mt-2"
                  onClick={() => handleClick(12)}
                >
                  <em>11. INFORMATION ON COOKIES </em>
                </div>
                <div
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  className="mt-2"
                  onClick={() => handleClick(13)}
                >
                  <em>12. DO WE MAKE UPDATES TO THIS NOTICE? </em>
                </div>
                <div
                  id={`section-${0}`}
                  data-index={0}
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  className="mt-2"
                  onClick={() => handleClick(14)}
                >
                  <em>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </em>
                </div>
                <div
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  className="mt-2"
                  onClick={() => handleClick(15)}
                >
                  <em>
                    14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                    COLLECT
                  </em>
                  FROM YOU?{" "}
                </div>
              </div>
            </div>
          </div>

          <div>
            <h6 style={{ marginTop: "30px" }}>
              <b>1. WHAT INFORMATION DO WE COLLECT? </b>
            </h6>
            <p style={{ fontSize: 14, marginTop: "30px", fontWeight: 600 }}>
              Personal information you disclose to us{" "}
            </p>
            <div style={{ fontSize: 14, marginTop: "30px" }}>
              <b>In Short:</b> We collect personal information that you provide
              to us.
            </div>
            <div>
              <div style={{ fontSize: 14, marginTop: "20px" }}>
                We collect personal information that you voluntarily provide to
                us when you register on the Services, express an interest in
                obtaining information about us or our products and Services,
                when you participate in activities on the Services, or otherwise
                when you contact us.
              </div>
              <div style={{ fontSize: 14, marginTop: "20px" }}>
                <b>Personal Information Provided by You.</b> The personal
                information that we collect depends on the context of your
                interactions with us and the Services, the choices you make, and
                the products and features you use. The personal information we
                collect may include the following:
                <ul>
                  <li className="mt-2">Names</li>
                  <li className="mt-2">Phone numbers </li>
                  <li className="mt-2">Email addresses </li>
                  <li className="mt-2">Profile picture </li>
                  <li className="mt-2">
                    Electronic appliance information: appliance type, service
                    description, photos of the appliance{" "}
                  </li>
                </ul>
                <ul>
                  <li className="mt-2">Contact preferences</li>
                  <li className="mt-2">Passwords </li>
                  <li className="mt-2">
                    Qualification and skill information of technicians{" "}
                  </li>
                  <li className="mt-2">Date of birth of technicians </li>
                  <li className="mt-2">Mailing addresses </li>
                  <li className="mt-2">Billing addresses </li>
                  <li id={`section-${1}`} data-index={1} className="mt-2">
                    Financial information{" "}
                  </li>
                  <li className="mt-2">
                    Service address collected from customers is converted to
                    geolocation using Google API and used only for the sole
                    purpose of service assignment{" "}
                  </li>
                  <li className="mt-2">
                    Shop address collected from technicians is converted to
                    geolocation using Google API and used only for the sole
                    purpose of service assignment{" "}
                  </li>
                </ul>
              </div>
              <div>
                <b>Sensitive Information.</b> When necessary, with your consent
                or as otherwise permitted by applicable law, we process the
                following categories of sensitive information:
                <ul>
                  <li>financial data </li>
                  <li>gender </li>
                </ul>
              </div>
              <div style={{ fontSize: 14, marginTop: "20px" }}>
                <b>Payment Data.</b> We may collect data necessary to process
                your payment if you choose to make purchases, such as your
                payment instrument number, and the security code associated with
                your payment instrument. All payment data is handled and stored
                by PhonePe payment gateway. You may find their privacy notice
                link(s) here:{" "}
                <a
                  style={{ color: "blue" }}
                  href="https://www.phonepe.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.phonepe.com/privacy-policy/.
                </a>
              </div>
              <div style={{ fontSize: 14, marginTop: "20px" }}>
                <b>Application Data.</b> If you use our application(s), we also
                may collect the following information if you choose to provide
                us with access or permission:
              </div>
            </div>
            <div
              style={{ fontSize: 15, marginTop: "30px", paddingLeft: "40px" }}
            >
              <i>Geolocation Information.</i> Based on the service address
              shared voluntarily by you (as a customer) to request service or
              the shop address shared by you (as the technician), we may convert
              the address to Geolocation using Google APIs to ensure service
              requests and service providers can be connected optimally.
            </div>
            <div style={{ paddingLeft: "40px" }}>
              <div style={{ fontSize: 14, marginTop: "20px" }}>
                <i>Mobile Device Access.</i> We may request access or permission
                to certain features from your mobile device, including your
                mobile device's camera, storage, and other features. If you wish
                to change our access or permissions, you may do so in your
                device's settings.
              </div>
              <div
                id={`section-${3}`}
                data-index={3}
                style={{ fontSize: 14, marginTop: "20px" }}
              >
                This information is primarily needed to maintain the security
                and operation of our application(s), for troubleshooting, and
                for our internal analytics and reporting purposes.
              </div>
              <div style={{ fontSize: 14, marginTop: "20px" }}>
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </div>
            </div>
            <h6 style={{ marginTop: "20px", fontWeight: 600 }}>
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </h6>
            <div style={{ fontSize: 14, marginTop: "30px" }}>
              <b>In Short:</b> We process your information to provide, improve,
              and administer our Services, communicate with you, for security
              and fraud prevention, and to comply with law. We may also process
              your information for other purposes with your consent.
            </div>
            <div style={{ fontSize: 14, marginTop: "20px" }}>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </div>
            <div style={{ marginTop: "30px" }}>
              <b>
                To facilitate account creation and authentication and otherwise
                manage user accounts.
              </b>
              We may process your information so you can create and log in to
              your account, as well as keep your account in working order.
            </div>
            <div style={{ marginTop: "30px" }}>
              <b>To deliver and facilitate delivery of services to the user.</b>
              We may process your information to provide you with the requested
              service.
            </div>
            <div style={{ marginTop: "30px" }}>
              <b>To respond to user inquiries/offer support to users.</b>
              We may process your information to respond to your inquiries and
              solve any potential issues you might have with the requested
              service.
            </div>
            <div style={{ marginTop: "30px" }}>
              <b>To send administrative information to you.</b>
              We may process your information to send you details about our
              products and services, changes to our terms and policies, and
              other similar information.
            </div>
            <div style={{ marginTop: "30px" }}>
              <b>To request feedback.</b>
              We may process your information when necessary to request feedback
              and to contact you about your use of our Services.
            </div>
            <div style={{ marginTop: "30px" }}>
              <b>To administer prize draws and competitions.</b>
              We may process your information to administer prize draws and
              competitions.
            </div>
            <div
              style={{ marginTop: "30px" }}
              id={`section-${4}`}
              data-index={4}
            >
              <b>
                To evaluate and improve our Services, appliances covered, and
                your experience.
              </b>
              We may process your information when we believe it is necessary to
              identify usage trends, determine the effectiveness of our
              services, and evaluate and improve your experience.
            </div>
            <h6 style={{ marginTop: "30px", fontSize: "16px" }}>
              <b>
                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?{" "}
              </b>
            </h6>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              <b>In Short:</b> We may share information in specific situations
              described in this section and/or with the following third parties.{" "}
            </p>
            <div style={{ fontSize: 14, marginTop: "20px" }}>
              <b>To provide the Service: </b>  We use your information to
              connect you with qualified technicians, process service requests,
              send notifications, and provide customer support.{" "}
            </div>
            <div style={{ fontSize: 14, marginTop: "20px" }}>
              We may need to share your personal information in the following
              situations:
            </div>
            <div style={{ marginTop: "30px" }}>
              <b>Business Transfers.</b>
              We may share or transfer your information in connection with, or
              during negotiations of, any merger, sale of company assets,
              financing, or acquisition of all or a portion of our business to
              another company.
            </div>
            <div style={{ marginTop: "30px" }}>
              <b>When we use Google Maps Platform APIs. </b>
              We may share your information with certain Google Maps Platform
              APIs (e.g. Google Maps API, Places API).
            </div>
            <div style={{ marginTop: "30px" }}>
              <b>Affiliates. </b>
              We may share your information with our affiliates, in which case
              we will require those affiliates to honour this privacy notice.
              Affiliates include our parent company and any subsidiaries, joint
              venture partners, or other companies that we control or that are
              under common control with us.
            </div>
            <div
              id={`section-${7}`}
              data-index={7}
              style={{ fontSize: 14, marginTop: "20px" }}
            >
              <b>Business Partners.  </b>  We may share your information with
              our business partners to offer you certain services .Partners
              include the technicians who visit the customer’s home or site to
              render service.
            </div>
            <h6 style={{ marginTop: "30px" }}>
              <b>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</b>
            </h6>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              <b>In Short:</b> We may use cookies and other tracking
              technologies to collect and store your information
            </p>
            <p
              id={`section-${8}`}
              data-index={8}
              style={{ fontSize: 14, marginTop: "30px" }}
            >
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to gather information when you interact with
              our Services. Some online tracking technologies help us maintain
              the security of our Services and your account, prevent crashes,
              fix bugs, save your preferences, and assist with basic site
              functions.
            </p>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              Specific information about how we use such technologies and how
              you can refuse certain cookies is set out in our{" "}
              <span>
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => handleClick(12)}
                >
                  INFORMATION ON COOKIES
                </span>
              </span>{" "}
              section
            </p>
            <h6 style={{ marginTop: "30px" }}>
              <b>5. HOW LONG DO WE KEEP YOUR INFORMATION? </b>
            </h6>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              <b>In Short:</b> We keep your information for as long as necessary
              to fulfil the purposes outlined in this privacy notice unless
              otherwise required by law.
            </p>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </p>
            <p
              id={`section-${5}`}
              data-index={5}
              style={{ fontSize: 14, marginTop: "30px" }}
            >
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymise such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
            <h6 style={{ marginTop: "30px" }}>
              <b>6. HOW DO WE KEEP YOUR INFORMATION SAFE? </b>
            </h6>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              <b>In Short:</b> We aim to protect your personal information
              through a system of organisational and technical security
              measures.
            </p>
            <div
              id={`section-${9}`}
              data-index={9}
              style={{ fontSize: 14, marginTop: "20px" }}
            >
              We have implemented appropriate and reasonable technical and
              organisational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorised
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </div>
            <h6 style={{ marginTop: "30px" }}>
              <b>7. DO WE COLLECT INFORMATION FROM MINORS? </b>
            </h6>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              <b>In Short:</b> We do not knowingly collect data from or market
              to children under 18 years of age.
            </p>
            <p
              id={`section-${6}`}
              data-index={6}
              style={{ fontSize: 14, marginTop: "30px" }}
            >
              We do not knowingly collect, solicit data from, or market to
              children under 18 years of age, nor do we knowingly sell such
              personal information. By using the Services, you represent that
              you are at least 18 or that you are the parent or guardian of such
              a minor and consent to such minor dependent’s use of the Services.
              If we learn that personal information from users less than 18
              years of age has been collected, we will deactivate the account
              and take reasonable measures to promptly delete such data from our
              records. If you become aware of any data we may have collected
              from children under age 18, please contact us at
              <b>support@s3-app.com.</b>
            </p>
            <h6 style={{ marginTop: "30px" }}>
              <b>8. WHAT ARE YOUR PRIVACY RIGHTS? </b>
            </h6>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              <b>In Short:</b> You may review, change, or terminate your account
              at any time, depending on your country, province, or state of
              residence.
            </p>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              <b style={{ textDecoration: "underline" }}>
                Withdrawing your consent:
              </b>{" "}
              If we are relying on your consent to process your personal
              information, which may be express consent (where you give us
              specific permission) and/or implied consent (where your permission
              can be inferred) depending on the applicable law, you have the
              right to withdraw your consent at any time. You can withdraw your
              consent at any time by contacting us by using the contact details
              provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS
              NOTICE?' below.
            </p>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </p>
            <div style={{ marginTop: "30px", fontWeight: 600 }}>
              Account Information
            </div>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </p>
            <ul>
              <li className="mt-3">
                Log in to your account settings and update your user account.
              </li>
              <li className="mt-3">
                Use{" "}
                <span>
                  <a href="/delete-account">'Delete Account'</a>
                </span>{" "}
                link on the website to terminate/delete your account.
              </li>
            </ul>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              We may retain some information in our files to prevent fraud,
              troubleshoot problems, assist with any investigations, enforce our
              legal terms and/or comply with applicable legal requirements.
            </p>
            <p
              id={`section-${10}`}
              data-index={10}
              style={{ fontSize: 14, marginTop: "30px" }}
            >
              <b style={{ textDecoration: "underline" }}>
                Cookies and similar technologies:
              </b>{" "}
              Most Web browsers are set to accept cookies by default. If you
              prefer, you can usually choose to set your browser to remove
              cookies and to reject cookies. If you choose to remove cookies or
              reject cookies, this could affect certain features or services of
              our Services.
            </p>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              If you have questions or comments about your privacy rights, you
              may email us at{" "}
              <span>
                <a href="mailto:support@s3-app.com">support@s3-app.com.</a>
              </span>
            </p>
            <h6 style={{ marginTop: "30px" }}>
              <b>9. CONTROLS FOR DO-NOT-TRACK FEATURES</b>
            </h6>
            <p
              style={{ fontSize: 14, marginTop: "30px" }}
              id={`section-${11}`}
              data-index={11}
            >
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ('DNT') feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage, no uniform technology standard for recognising and
              implementing DNT signals has been finalised. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </p>
            <h6 style={{ marginTop: "30px" }}>
              <b>10. COMPLIANCE UNDER INFORMATION TECHNOLOGY ACT, 2000 </b>
            </h6>
            In accordance with the Information Technology Act, 2000 and the
            rules made thereunder, the Grievance Officer for complaints and
            grievances can be contacted at:
            <p style={{ fontSize: 14, marginTop: "20px" }}>
              Grievance-cum-Data Protection Officer{" "}
            </p>
            <p style={{ fontSize: 14, marginTop: "20px" }}>
              S3 – Secure Sales Service,
            </p>
            <p style={{ fontSize: 14, marginTop: "20px" }}>
              Vinayaka Electronics and GPS,{" "}
            </p>
            <p style={{ fontSize: 14, marginTop: "20px" }}>
              20/2, VAIYAPURI NAGAR,{" "}
            </p>
            <p style={{ fontSize: 14, marginTop: "20px" }}>
              SECOND CROSS, KARUR,{" "}
            </p>
            <p style={{ fontSize: 14, marginTop: "20px" }}>
              Tamil Nadu, 639002{" "}
            </p>
            <p
              id={`section-${12}`}
              data-index={12}
              style={{ fontSize: 14, marginTop: "20px" }}
            >
              <b>Ph:</b> <a href="tel:+919600300459">+91 9600300459</a>
            </p>{" "}
            <p style={{ fontSize: 14 }}>
              <b>Email:</b>{" "}
              <a href="mailto:support@s3-app.com">support@s3-app.com</a>
            </p>
            <h6 style={{ marginTop: "30px" }}>
              <b>11. INFORMATION ON COOKIES </b>
            </h6>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              We use cookies on this Website, whose principal objective is to
              improve your experience in the Website or mobile application. A
              cookie refers to a small file placed on your device by our website
              or mobile application when you either visit or use certain
              features of our website or mobile application.
            </p>
            <p
              id={`section-${13}`}
              data-index={13}
              style={{ fontSize: 14, marginTop: "30px" }}
            >
              "Cookies" are used to store user preferences and to track user
              trends, so as to enhance the user’s interactive experience and
              generally improve the Website’s services to the user. The user can
              set the browser to notify the user when the user is sent a
              "cookie", giving the user the chance to decide whether or not to
              accept it. If the user accepts a "cookie", the user agrees to the
              use of any Personal Data collected by the Website using that
              cookie. The user may update the profile information of the user at
              any time on the Website. The user may restrict, block or delete
              the Cookies from this website at any time by changing the
              configuration of the user's browser.
            </p>
            <h6 style={{ marginTop: "30px" }}>
              <b>12. DO WE MAKE UPDATES TO THIS NOTICE? </b>
            </h6>
            <p
              id={`section-${14}`}
              data-index={14}
              style={{ fontSize: 14, marginTop: "30px" }}
            >
              <b>In Short:</b> Yes, we will update this notice as necessary to
              stay compliant with relevant laws.
            </p>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              We may update this privacy notice from time to time. The updated
              version will be indicated by a revised ‘Last updated’ date at the
              top of this privacy notice. If we make material changes to this
              privacy notice, we may notify you either by prominently posting a
              notice of such changes or by directly sending you a notification.
              We encourage you to review this privacy notice frequently to be
              informed of how we are protecting your information.
            </p>
            <h6 style={{ marginTop: "30px" }}>
              <b>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </b>
            </h6>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              If you have questions or comments about this notice, you may email
              us at support@s3-app.com or contact us by post at:
            </p>
            <p style={{ fontSize: 14, marginTop: "20px" }}>
              Secure Sales Service (S3) App
            </p>
            <p style={{ fontSize: 14, marginTop: "10px" }}>
              Vinayaka Electronics and GPS,
            </p>
            <p
              id={`section-${15}`}
              data-index={15}
              style={{ fontSize: 14, marginTop: "10px" }}
            >
              20/2, Vaiyapuri Nagar, Second Cross,
            </p>
            <p style={{ fontSize: 14, marginTop: "10px" }}>
              Karur, Tamil Nadu 639002
            </p>
            <p style={{ fontSize: 14, marginTop: "10px" }}>India</p>
            <h6 style={{ marginTop: "30px" }}>
              <b>
                14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </b>
            </h6>
            <p style={{ fontSize: 14, marginTop: "30px" }}>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, details about how we have processed it, correct
              inaccuracies, or delete your personal information. You may also
              have the right to withdraw your consent to our processing of your
              personal information. These rights may be limited in some
              circumstances by applicable law. To request to review, update, or
              delete your personal information, please visit:{" "}
              <a
                href="https://s3-app.com/login"
                style={{ color: "blue" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                https://s3- app.com/login
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      {!localStorage.getItem("token") && <Footer />}
    </div>
  );
}
