import React, { useCallback, useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Card, Avatar, TextField, Popover } from "@mui/material/";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import PhoneIcon from "@mui/icons-material/Phone";
import Checkbox from "@mui/material/Checkbox";
import femaleclor from "../../../../src/Assets/profile/female-clicked.svg";
import maleclor from "../../../../src/Assets/profile/male-clicked.svg";
import female from "../../../../src/Assets/profile/female-normal.svg";
import male from "../../../../src/Assets/profile/male-normal.svg";
import Api from "../../../service/services";
import { Modal } from "react-bootstrap";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { TiCancel } from "react-icons/ti";
import "../../../styles/techverify.css";
import Swal from "sweetalert2";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import {
  MdClose,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import moment from "moment";
import {
  CardMedia,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import "../../../../src/components/Technicians/SRmanagement/CategoryTechnician/techcard.css";
import NoShopImage from "../../../Images/NoShopImage.png";
import FilterModule from "../../../plugins/FilterModule";
import CountContext from "../../countContext/CountContext";
const PendingTechniciansList = () => {
  const { setSearch } = useContext(CountContext);
  const [pendingTechList, setPendingTechList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState("");
  const [isAlertRejectModal, setIsAlertRejectModal] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [technecianId, setTechnicianId] = useState("");
  const [fullname, setFullname] = useState("");
  const [email, setMail] = useState("");
  const [mobile_no, setMobilenumber] = useState("");
  const [dob, setdob] = useState("");
  const [address, setAddress] = useState("");
  const [country] = useState("India");
  const [state] = useState("Tamil Nadu");
  const [city, setCity] = useState("");
  const [postal_code, setPostalcode] = useState("");

  const [selectedApplianceDataInput, setSelectedApplianceDataInput] = useState(
    []
  );
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [IsFullNameEmpty, setIsFullEmpty] = useState(false);
  const [IsDobEmpty, setIsDobEmpty] = useState(false);
  const [IsCityEmpty, setIsCityEmpty] = useState(false);
  const [IsPostalEmpty, setIsPostalEmpty] = useState(false);

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [Ismale, setIsMale] = useState(false);
  const [IsFemale, setIsFemale] = useState(false);
  const [availableAppliances, setAvailableAppliances] = useState([]);
  const [labelUserSelectedAppliances, setLabelUserSelectedAppliances] =
    useState([]);
  const [selectedApplianceData, setSelectedApplianceData] = useState([]);
  const [labelUserSelectedModels, setLabelUserSelectedModels] = useState([]);
  const [imageModal, setImageModal] = useState(false);
  const [storeImage, setStoreImage] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState({});
  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };

  const showPreviousImage = () => {
    const previousIndex =
      (currentIndex - 1 + storeImage?.length) % storeImage?.length;
    setCurrentIndex(previousIndex);
  };

  const showNextImage = () => {
    const nextIndex = (currentIndex + 1) % storeImage?.length;
    setCurrentIndex(nextIndex);
  };
  // }

  useEffect(() => {
    Api.getPendingTechnicians("").then((res) => {
      setPendingTechList(res.data);
    });
  }, []);

  const handleViewModal = (id) => {
    setTechnicianId(id);
    setIsModalOpen(true);
    Api.getTechnicianDetails(id).then((res) => {
      const payload = {
        user_id: id,
      };
      Api.getApplianceRelatedList(payload).then((res) => {
        if (res) {
          setAvailableAppliances(res?.availableAppliances);
          setLabelUserSelectedAppliances(
            res?.userSelectedAppliances?.selectedAppliance
          );
          setLabelUserSelectedModels(
            res?.userSelectedAppliances?.selectedModels
          );
          getUniqueAppliance(
            res?.userSelectedAppliances?.selectedAppliance,
            res?.userSelectedAppliances?.selectedModels
          );
        }
      });
      setFullname(res?.data[0]?.fullname);
      setMail(res?.data[0]?.emailid);
      setMobilenumber(res?.data[0]?.mobile_no);
      setAddress(res?.data[0]?.users_details[0]?.address);
      if (res?.data[0]?.users_details[0]?.dob) {
        const dobMoment = moment(
          res.data[0]?.users_details[0].dob,
          ["YYYY-MM-DD", "DD-MM-YYYY"],
          true
        );
        if (dobMoment.isValid()) {
          setdob(dobMoment.format("YYYY-MM-DD"));
        } else {
          setdob(""); // Or any other appropriate action
        }
      } else {
        setdob("");
      }
      setCity(res?.data[0]?.users_details[0]?.district);
      setPostalcode(res?.data[0]?.users_details[0]?.postalcode);
      setStoreImage(res?.data[0]?.shop_images);
      console.log("stroeImage", storeImage);
      const genderdata = res?.data[0]?.users_details[0]?.gender;
      const isMale = genderdata === "MALE";
      const isFemale = genderdata === "FEMALE";
      setIsMale(isMale);
      setIsFemale(isFemale);
    });
  };

  const handleReject = () => {
    setSearch("");
    Api.rejectTechnician(technecianId).then((res) => {
      setIsModalOpen(false);
      Api.getPendingTechnicians("").then((res) => {
        setPendingTechList(res.data);
        Swal.fire({
          text: `You rejected the technician`,
          confirmButtonText: "OK",
        });
      });
    });
  };

  const handleApprove = () => {
    setSearch("");
    const formData = new FormData();
    formData.append("role", 2);
    formData.append("fullname", fullname);
    formData.append("username", email);
    formData.append("mobile_no", mobile_no);
    formData.append("address", address);
    formData.append("dob", dob);
    formData.append("gender", Ismale ? "MALE" : IsFemale && "FEMALE");
    formData.append("country", country);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("pincode", postal_code);
    formData.append("applianceMap", JSON.stringify(selectedApplianceData));
    Api.approveTechnician(technecianId, formData).then((res) => {
      // eslint-disable-next-line
      if (res.message == "User Approved Successfully") {
        Swal.fire({
          text: `Great, You onboarded a technician! 🎉`,
          confirmButtonText: "OK",
        });
      }
      setIsModalOpen(false);
      Api.getPendingTechnicians("").then((res) => {
        setPendingTechList(res?.data);
      });
    });
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleCloseRejectModal = () => {
    setIsAlertRejectModal(false);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
    setAnchorEl(null);
  };

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setPopoverOpen(true);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }
  useEffect(() => {
    const formattedLabelUserSelectedAppliances =
      labelUserSelectedAppliances?.map((appliance) => {
        return {
          appliance_id: appliance?.id,
        };
      });
    const formattedLabelUserSelectedModels = labelUserSelectedModels?.map(
      (selectedModels) => {
        return {
          appliance_id: selectedModels?.appliance_id,
          model_id: selectedModels?.id,
        };
      }
    );
    const formattedLabelUserSelectedModelsView = labelUserSelectedModels?.map(
      (selectedModels) => {
        if (
          labelUserSelectedAppliances?.some(
            (appliance) => appliance?.id === selectedModels?.appliance_id
          )
        ) {
          return null;
        } else {
          return {
            appliance_id: selectedModels?.appliance_id,
            model_id: selectedModels?.id,
          };
        }
      }
    );
    const formattedPayload =
      formattedLabelUserSelectedAppliances && formattedLabelUserSelectedModels
        ? [
            ...formattedLabelUserSelectedAppliances,
            ...formattedLabelUserSelectedModels,
          ]
        : [];
    const uniqueIds = new Set();
    const uniqueAppliances = [];
    formattedLabelUserSelectedModelsView.forEach((obj) => {
      if (!uniqueIds.has(obj?.appliance_id)) {
        uniqueIds.add(obj?.appliance_id);
        uniqueAppliances.push(obj);
      }
    });
    const formattedPayloadView =
      formattedLabelUserSelectedAppliances &&
      formattedLabelUserSelectedModelsView
        ? [...formattedLabelUserSelectedAppliances, ...uniqueAppliances]
        : [];
    setSelectedApplianceData(formattedPayload);
    setSelectedApplianceDataInput(formattedPayloadView);
  }, [labelUserSelectedAppliances, labelUserSelectedModels]);
  const getUniqueAppliance = async (
    selected_appliance_data,
    model_data,
    uncheck = false
  ) => {
    let updated_appliance;

    // Mapping through model_data to find matching appliances from selected_appliance_data
    updated_appliance = model_data.map((selectedModel) => {
      const data = selected_appliance_data.find(
        (selectedAppliance) =>
          selectedAppliance.id === selectedModel.appliance_id
      );
      return data;
    });

    // Filter out undefined values (appliances without models)
    let filtered_appliance = updated_appliance.filter(Boolean);

    // Concatenate the remaining selected appliances that are not already in updated_appliance
    let concatenated_appliance = [
      ...filtered_appliance,
      ...selected_appliance_data.filter(
        (appliance) =>
          !filtered_appliance.some((model) => model?.id === appliance.id)
      ),
    ];

    // Ensure uniqueness by using a Set
    const uniqueIds = new Set();
    let uniqueObjects = [];
    concatenated_appliance.forEach((obj) => {
      if (obj && !uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        uniqueObjects.push(obj);
      }
    });

    // If uncheck is true, filter out the unchecked appliance or model
    if (uncheck) {
      const { applianceId, modelId } = uncheck;
      if (applianceId) {
        uniqueObjects = uniqueObjects.filter((obj) => obj.id !== applianceId);
      } else if (modelId) {
        const model = model_data.find((m) => m.id === modelId);
        if (model) {
          uniqueObjects = uniqueObjects.filter(
            (obj) => obj.id !== model.appliance_id
          );
        }
      }
    }
    setLabelUserSelectedAppliances(uniqueObjects);
  };

  const handleApplianceChange = (applianceValue, isChecked) => {
    if (!isChecked) {
      // Uncheck appliance and all its models
      const updatedModels = labelUserSelectedModels.filter(
        (model) => model.appliance_id !== applianceValue.id
      );
      setLabelUserSelectedModels(updatedModels);
      getUniqueAppliance(
        labelUserSelectedAppliances.filter(
          (appliance) => appliance.id !== applianceValue.id
        ),
        updatedModels,
        { applianceId: applianceValue.id }
      );
    } else {
      // Check appliance
      const updatedAppliance = availableAppliances.appliances.find(
        (item) => item.id === applianceValue.id
      );
      setLabelUserSelectedAppliances([
        ...labelUserSelectedAppliances,
        updatedAppliance,
      ]);
    }
  };

  const handleModelChange = (modelValue, isChecked) => {
    if (isChecked) {
      // Check model and corresponding appliance
      const updatedUserSelectedModel = availableAppliances.models.find(
        (item) => item.id === modelValue.id
      );
      const updatedUserSelectedModels = [
        ...labelUserSelectedModels,
        updatedUserSelectedModel,
      ];
      setLabelUserSelectedModels(updatedUserSelectedModels);

      const correspondingAppliance = availableAppliances.appliances.find(
        (appliance) => appliance.id === modelValue.appliance_id
      );
      if (
        !labelUserSelectedAppliances.some(
          (appliance) => appliance.id === correspondingAppliance.id
        )
      ) {
        setLabelUserSelectedAppliances([
          ...labelUserSelectedAppliances,
          correspondingAppliance,
        ]);
      }
    } else {
      // Uncheck model
      const updatedModels = labelUserSelectedModels.filter(
        (model) => model.id !== modelValue.id
      );
      setLabelUserSelectedModels(updatedModels);

      // Uncheck corresponding appliance if no models remain checked
      const remainingModels = updatedModels.filter(
        (model) => model.appliance_id === modelValue.appliance_id
      );
      if (remainingModels.length === 0) {
        getUniqueAppliance(labelUserSelectedAppliances, updatedModels, {
          applianceId: modelValue.appliance_id,
        });
      }
    }
  };

  const handleFilterData = (e) => {
    setFilterData(e);
    Api.getPendingTechnicians(searchInput, e).then((res) => {
      setPendingTechList(res.data);
    });
  };
  const handleSearchInput = useCallback(
    (e) => {
      setSearchInput(e);
      Api.getPendingTechnicians(e, filterData).then((res) => {
        setPendingTechList(res.data);
      });
    },
    [setSearchInput, setPendingTechList, filterData]
  );

  const mobileView = useMediaQuery("(min-width: 200px) and (max-width: 799px)");
  return (
    <>
      <div className="row" style={{ overflow: "hidden", width: "100%" }}>
        <div className="col-lg-4" style={{ margin: "10px 0px 10px 15px" }}>
          <FilterModule
            inputPlaceholder={"Search by Name"}
            onSearchInputChange={handleSearchInput}
            onChangeFilterData={handleFilterData}
          />
        </div>
        <div className="col-lg-12 ps-0 pe-0">
          <div
            className="d-flex flex-wrap mt-4"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "2rem",
              overflowY: "auto",
              overflowX: "hidden",
              padding: "6px 0px 20px 0px",
            }}
          >
            {pendingTechList?.length > 0 ? (
              pendingTechList.map((technician, index) => (
                <Card
                  className="custom-card"
                  onClick={() => handleViewModal(technician?.id)}
                  style={{
                    margin: mobileView && "auto",
                    width: mobileView ? "17rem" : "20rem",
                  }}
                  id="card-hover"
                >
                  <Avatar
                    className="centered-text p-0 ms-2 mt-2"
                    sx={{ width: mobileView ? "1rem" : "2rem", height: 40 }}
                    src={technician.avatar !== null ? technician.avatar : null}
                    {...(technician.avatar == null && {
                      ...stringAvatar(technician.fullname),
                    })}
                  />

                  <CardHeader
                    subheader={
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        {technician?.fullname}
                      </Typography>
                    }
                  />

                  {technician?.shop_images.length > 0 ? (
                    <>
                      <CardMedia
                        component="img"
                        height="194"
                        image={technician?.shop_images[0]}
                        alt="Shop_image"
                      />
                    </>
                  ) : (
                    <>
                      <CardMedia
                        component="img"
                        height="194"
                        image={NoShopImage}
                        src="No Shop Image Uploaded"
                        style={{ objectFit: "fit" }}
                      />
                    </>
                  )}

                  {technician?.users_details?.map((data) => (
                    <CardContent
                      key={data.address}
                      style={{ overflow: "hidden", wordWrap: "break-word" }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {data.address.length > 50 ? (
                          <Tooltip
                            title={data.address}
                            arrow
                            placement="top"
                            enterDelay={200}
                            leaveDelay={200}
                            sx={{
                              fontSize: "14px",
                              backgroundColor: "#333",
                              color: "#fff",
                              padding: "10px",
                              borderRadius: "8px",
                            }}
                          >
                            <div>
                              <span>{data.address.substring(0, 50)}...</span>
                            </div>
                          </Tooltip>
                        ) : (
                          <div>
                            <span>{data.address},</span>
                          </div>
                        )}

                        <div>
                          <span>
                            {data.district} - {data.postalcode}.
                          </span>
                        </div>

                        <div>
                          <span>
                            {"+91 "}
                            {technician.mobile_no}
                          </span>
                        </div>
                      </Typography>
                    </CardContent>
                  ))}
                </Card>
              ))
            ) : (
              <p
                className="text-center mt-5 w-100"
                style={{
                  fontSize: "2rem",
                  fontWeight: "600",
                  color: "#ffa5005c",
                }}
              >
                No Data found
              </p>
            )}
          </div>
        </div>

        <Modal show={isModalOpen} onHide={handleModalClose} centered size="lg">
          <div className="p-3">
            <div className="mt-2 me-2 font-size-2 font-weight-2">
              Technician's Details
            </div>

            <div className="row  mt-lg-4">
              <div className="col-lg-6 mt-lg-0 mt-3">
                <div>
                  <TextField
                    fullWidth
                    label="Full Name"
                    id="full-name"
                    value={fullname}
                    onChange={(e) => {
                      let inputValue = e.target.value;

                      // Remove extra spaces and allow only alphabets (no numbers or special characters)
                      var onlyText = inputValue
                        .replace(/[^A-Za-z ]/g, "")
                        .replace(/  +/g, " ");

                      // Remove leading space if present
                      if (onlyText.startsWith(" ")) {
                        onlyText = onlyText.slice(1);
                      }

                      // Limit the input length to 20 characters
                      const truncatedText = onlyText.slice(0, 20);
                      setFullname(truncatedText);
                      setIsFullEmpty(false);
                    }}
                    type="text"
                    placeholder="Technician Name"
                    error={IsFullNameEmpty ? true : false}
                    helperText={IsFullNameEmpty ? "please fill fullname" : ""}
                    FormHelperTextProps={{}}
                    disabled
                  />
                </div>

                <div className="mt-lg-4 mt-3">
                  <TextField
                    fullWidth
                    style={{ height: "56px" }}
                    id="email"
                    value={email}
                    onChange={(e) => setMail(e.target.value)}
                    label="Email"
                    type="email"
                    placeholder="technician_name@mail.com"
                    error={!!emailError}
                    helperText={emailError}
                    FormHelperTextProps={{
                      style: {
                        position: "absolute",
                        bottom: "-20px",
                        whiteSpace: "nowrap",
                      },
                    }}
                    onKeyUp={() => {
                      const trimmedEmail = email.trim();
                      setMail(trimmedEmail);

                      if (!trimmedEmail) {
                        setEmailError("Please enter your email address.");
                      } else if (!emailPattern.test(trimmedEmail)) {
                        setEmailError("Please enter a valid email address.");
                      } else {
                        setEmailError("");
                      }
                    }}
                    disabled
                  />
                </div>

                <div>
                  <TextField
                    className="w-100 mt-3 mt-lg-3 phone_number_input"
                    style={{ height: "47px" }}
                    id="mobile-number"
                    value={mobile_no}
                    label="Phone number"
                    type="number"
                    placeholder=" 90000 00009"
                    error={!!mobileError}
                    helperText={mobileError}
                    FormHelperTextProps={{
                      style: {
                        position: "absolute",
                        bottom: "-20px",
                        whiteSpace: "nowrap",
                      },
                    }}
                    onKeyUp={() => {
                      if (!mobile_no) {
                        setMobileError("Please enter your phone number.");
                      } else if (mobile_no.length !== 10) {
                        setMobileError("please enter valid phone number");
                      } else {
                        setMobileError("");
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Remove any non-digit characters
                      const numericValue = value.replace(/\D/g, "");
                      // Enforce length of 6
                      const formattedValue = numericValue.slice(0, 10);
                      setMobilenumber(formattedValue);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">+91 |</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ color: "rgb(235, 129, 40)" }}
                        >
                          <PhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled
                  />
                </div>
                <div>
                  <div className="mt-lg-4 mt-3">
                    <TextField
                      fullWidth
                      id="datePicker"
                      label="Date Of Birth"
                      type="date"
                      onChange={(e) => {
                        setdob(e.target.value);
                        setIsDobEmpty(false);
                      }}
                      value={dob}
                      error={IsDobEmpty ? true : false}
                      helperText={IsDobEmpty ? "please select Dob" : ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end"></InputAdornment>
                        ),
                      }}
                      style={{ height: "47px" }}
                      disabled
                    />
                  </div>
                </div>

                <div
                  className="row m-0 p-0 border border rounded-3 mt-3 mt-lg-3"
                  style={{ height: "54px" }}
                >
                  <div
                    className="col border-right p-lg-2"
                    style={{
                      borderRight: "1px solid grey ",
                      height: "52px",
                      color: Ismale ? "rgb(235, 129, 40)" : "black",
                      cursor: "not-allowed",
                    }}
                  >
                    <label
                      htmlFor="maleRadio"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "not-allowed",
                        position: "relative",
                        bottom: "4px",
                      }}
                    >
                      <IconButton
                        color="primary"
                        component="label"
                        style={{ cursor: "not-allowed" }}
                      >
                        <img
                          disabled
                          alt="demo"
                          src={Ismale ? maleclor : male}
                          style={{ cursor: "not-allowed" }}
                        />
                      </IconButton>
                      <input
                        id="maleRadio"
                        checked={Ismale}
                        onChange={() => {
                          setIsMale(true);
                          setIsFemale(false);
                        }}
                        type="radio"
                        style={{ display: "none", cursor: "not-allowed" }}
                        disabled
                      />
                      <span>Male</span>
                    </label>
                  </div>
                  <div
                    className="col p-lg-2"
                    style={{ color: IsFemale ? "rgb(235, 129, 40)" : "black" }}
                  >
                    <label
                      htmlFor="femaleRadio"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "not-allowed",
                        position: "relative",
                        bottom: "4px",
                      }}
                    >
                      <IconButton
                        color="primary"
                        component="label"
                        style={{ cursor: "not-allowed" }}
                      >
                        <img alt="demo" src={IsFemale ? femaleclor : female} />
                      </IconButton>
                      <input
                        id="femaleRadio"
                        checked={IsFemale}
                        onChange={() => {
                          setIsFemale(true);
                          setIsMale(false);
                        }}
                        disabled
                        type="radio"
                        style={{ display: "none", cursor: "not-allowed" }}
                      />
                      <span>Female</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-12 mt-lg-0 mt-3">
                <div className="mt-lg-0 mt-3">
                  <TextField
                    fullWidth
                    label={"Country"}
                    value={country}
                    aria-readonly={true}
                    disabled
                  />
                </div>

                <div className="mt-lg-4 mt-3">
                  <TextField
                    fullWidth
                    label="State"
                    value={state}
                    aria-readonly={true}
                    disabled
                  />
                </div>

                <div className="mt-lg-4 mt-3">
                  <TextField
                    fullWidth
                    label="Address"
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Type your door no, street name"
                    disabled
                  />
                </div>

                <div className="row mt-lg-4 mt-3">
                  <div className="col-6">
                    <TextField
                      fullWidth
                      label="District"
                      select
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                        setIsCityEmpty(false);
                      }}
                      error={IsCityEmpty ? true : false}
                      helperText={IsCityEmpty ? "please select city" : ""}
                      disabled
                    >
                      <MenuItem value={city}>{city}</MenuItem>
                    </TextField>
                  </div>

                  <div className="col-6">
                    <div>
                      <TextField
                        fullWidth
                        label="Postal Code"
                        value={postal_code}
                        onChange={(e) => {
                          setPostalcode(e.target.value);
                          setIsPostalEmpty(false);
                        }}
                        type="text"
                        error={IsPostalEmpty ? true : false}
                        helperText={IsPostalEmpty ? "please enter postal" : ""}
                        disabled
                        FormHelperTextProps={{}}
                      />
                    </div>
                  </div>

                  <div className="mt-lg-4 mt-3">
                    <div>
                      <div className="label-popover">
                        <div className="content">
                          Appliance(s) the technician can repair
                        </div>
                      </div>
                      <div
                        onClick={(e) => handlePopoverOpen(e)}
                        className="d-flex align-items-center gap-2 popover-input w-full ps-2"
                      >
                        {selectedApplianceData?.length === 0 && (
                          <div className="text-3">
                            {console.log(
                              "selectedApplianceDataInput",
                              selectedApplianceDataInput,
                              selectedApplianceData
                            )}
                            Validate the appliance(s)
                          </div>
                        )}
                        {selectedApplianceDataInput.map((applianceData) => {
                          const returnBadge =
                            availableAppliances?.appliances?.find(
                              (appliance) =>
                                appliance?.id === applianceData?.appliance_id
                            );
                          return (
                            <div className="badge">
                              {applianceData?.model_id
                                ? ""
                                : returnBadge?.appliance_name}
                              {applianceData?.model_id &&
                                returnBadge?.appliance_name}
                            </div>
                          );
                        })}
                      </div>
                      <Popover
                        open={popoverOpen}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        className="appliances-popover"
                      >
                        {availableAppliances?.appliances?.length > 0 &&
                          availableAppliances?.appliances?.map(
                            (applianceValue) => {
                              const findModels =
                                availableAppliances?.models.length > 0
                                  ? availableAppliances?.models?.some(
                                      (modalValue) =>
                                        modalValue.appliance_id ===
                                        applianceValue.id
                                    )
                                  : false;
                              const getCheckAppliance = () => {
                                if (
                                  labelUserSelectedAppliances?.some(
                                    (selectedAppliancesValue) =>
                                      selectedAppliancesValue?.id ===
                                      applianceValue?.id
                                  )
                                ) {
                                  return true;
                                } else if (
                                  labelUserSelectedModels?.some(
                                    (selectedModelsValue) =>
                                      selectedModelsValue?.appliance_id ===
                                      applianceValue?.id
                                  )
                                ) {
                                  return true;
                                } else {
                                  return false;
                                }
                              };
                              return (
                                <div>
                                  <div className="d-flex align-items-center gap-1">
                                    <Checkbox
                                      checked={getCheckAppliance()}
                                      onChange={(event) =>
                                        handleApplianceChange(
                                          applianceValue,
                                          event.target.checked
                                        )
                                      }
                                      disabled={findModels ? true : false}
                                    />

                                    <div>{applianceValue.appliance_name}</div>
                                  </div>
                                  <div className="d-flex align-items-center gap-2 ps-4 pe-4">
                                    {availableAppliances?.models?.length > 0 &&
                                      availableAppliances?.models?.map(
                                        // eslint-disable-next-line
                                        (modelValue) => {
                                          const getCheckModel = () => {
                                            const isUserSelected =
                                              labelUserSelectedModels.some(
                                                (userSelectedModel) =>
                                                  userSelectedModel?.id ===
                                                  modelValue.id
                                              );
                                            return isUserSelected;
                                          };
                                          if (
                                            modelValue.appliance_id ===
                                            applianceValue.id
                                          ) {
                                            return (
                                              <div className="d-flex align-items-center gap-1">
                                                <Checkbox
                                                  checked={getCheckModel()}
                                                  onChange={(event) =>
                                                    handleModelChange(
                                                      modelValue,
                                                      event.target.checked
                                                    )
                                                  }
                                                />
                                                <div>
                                                  {modelValue?.appliance_model}
                                                </div>
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
            <div className="border border-bottom mt-3"></div>
            <div className="row mt-lg-4 mt-3">
              <div
                className="col-lg col-12 text-end"
                style={{ display: "inline-flex", justifyContent: "center" }}
              >
                <ul
                  className="p-0"
                  style={{ display: "inline-flex", justifyContent: "center" }}
                >
                  <li style={{ listStyle: "none" }}></li>
                  <li className="me-2" style={{ listStyle: "none" }}>
                    <Button
                      style={{
                        height: "45px",
                        color: "rgb(235, 129, 40)",
                        border: "2px soid rgb(235, 129, 40)",
                        textTransform: "none",
                        fontSize: mobileView && "0.7rem",
                        width: mobileView ? "2.5rem" : "7rem",
                        position: mobileView && "relative",
                        right: mobileView && "36px",
                      }}
                      className="cancel-btn"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancel
                    </Button>
                  </li>
                  <li
                    className="me-2"
                    style={{
                      listStyle: "none",
                      width: mobileView && "2.5rem",
                      fontSize: mobileView && "0.7rem",
                    }}
                  >
                    <Button
                      style={{
                        height: "45px",
                        color: "white",
                        backgroundColor:
                          storeImage == null || storeImage?.length === 0
                            ? "lightgray"
                            : "#4BB544",
                        textTransform: "none",
                        position: mobileView && "relative",
                        right: mobileView && "35px",
                        width: mobileView ? "2.5rem" : "7rem",
                        fontSize: mobileView && "0.7rem",
                      }}
                      onClick={() => setImageModal(true)}
                      disabled={storeImage == null || storeImage?.length === 0}
                    >
                      Shop Images
                    </Button>
                    {imageModal && (
                      <Modal
                        className="imageModal"
                        show={imageModal}
                        onHide={() => setImageModal(false)}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            width: "100%",
                            maxWidth: "600px",
                            overflow: "hidden",
                            margin: "auto",
                            borderRadius: "15px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            padding: "20px",
                          }}
                        >
                          <div className="p-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <div style={{ fontSize: "20px" }}></div>
                              <div onClick={() => setImageModal(false)}>
                                <MdClose
                                  style={{
                                    cursor: "pointer",
                                    color: "rgb(235, 129, 40)",
                                    position: "relative",
                                    left: "20px",
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="image-container"
                              style={{
                                overflowX: "hidden",
                                maxWidth: "80vw",
                                margin: "auto",
                                whiteSpace: "nowrap",
                                position: "relative",
                              }}
                            >
                              {storeImage &&
                                storeImage.map((imageSrc, index) => (
                                  <img
                                    key={index}
                                    src={imageSrc}
                                    alt="shopImage"
                                    style={{
                                      cursor: "pointer",
                                      height: "300px",
                                      width: "100%",
                                      objectFit: "contain",
                                      display:
                                        currentIndex === index
                                          ? "block"
                                          : "none",
                                    }}
                                    onClick={() => handleImageClick(index)}
                                  />
                                ))}

                              {/* Conditionally show the left arrow only if the current image is not the first one */}
                              {storeImage?.length > 1 && currentIndex > 0 && (
                                <Button
                                  variant="link"
                                  className="arrow-button left"
                                  onClick={showPreviousImage}
                                  style={{
                                    position: "absolute",
                                    top: "40%",
                                    left: "10px",
                                    color: "rgb(235, 129, 40)",
                                    fontSize: "30px",
                                  }}
                                >
                                  <MdKeyboardArrowLeft />
                                </Button>
                              )}

                              {/* Conditionally show the right arrow only if the current image is not the last one */}
                              {storeImage?.length > 1 &&
                                currentIndex < storeImage.length - 1 && (
                                  <Button
                                    variant="link"
                                    className="arrow-button right"
                                    onClick={showNextImage}
                                    style={{
                                      position: "absolute",
                                      top: "40%",
                                      right: "10px",
                                      color: "rgb(235, 129, 40)",
                                      fontSize: "30px",
                                    }}
                                  >
                                    <MdKeyboardArrowRight />
                                  </Button>
                                )}
                            </div>
                          </div>
                        </div>
                      </Modal>
                    )}
                  </li>
                  <li
                    className="me-2"
                    style={{
                      listStyle: "none",
                      width: mobileView && "2.5rem",
                      fontSize: mobileView && "0.7rem",
                    }}
                  >
                    <Button
                      // variant="contained"
                      type="submit"
                      style={{
                        height: "45px",
                        color: "white",
                        backgroundColor: "#DC3545",
                        textTransform: "none",
                        width: mobileView ? "2.5rem" : "7rem",
                        fontSize: mobileView && "0.7rem",
                        position: mobileView && "relative",
                        right: mobileView && "9px",
                      }}
                      onClick={() => {
                        setIsAlertRejectModal(true);
                        /*  setIsModalOpen(true); */
                      }}
                      className="reject"
                    >
                      <div className="d-flex align-items-center font-size-2">
                        <TiCancel />
                      </div>
                      Reject
                    </Button>
                  </li>
                  <li
                    className="me-2"
                    style={{
                      listStyle: "none",
                      width: mobileView && "2.5rem",
                      fontSize: mobileView && "0.7rem",
                    }}
                  >
                    <Button
                      // variant="contained"
                      type="submit"
                      style={{
                        height: "45px",
                        color: "white",
                        backgroundColor: "#4BB543",
                        textTransform: "none",
                        width: mobileView ? "4rem" : "7rem",
                        fontSize: mobileView && "0.7rem",
                        position: mobileView && "relative",
                        left: mobileView && "15px",
                      }}
                      onClick={handleApprove}
                    >
                      <div className="d-flex align-items-center font-size-2">
                        <RiVerifiedBadgeFill />
                      </div>
                      Approve
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          show={isAlertRejectModal}
          onHide={handleCloseRejectModal}
          centered
          className="p-1"
        >
          <p className="text-center mt-5">
            Are you sure you want to reject this technician?
          </p>
          <p className="text-center m-0">This action cannot be undone.</p>
          <div className="button-class">
            <button
              onClick={() => {
                setIsAlertRejectModal(false);
                setIsModalOpen(true);
              }}
              className="cancel"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleReject();
                setIsAlertRejectModal(false);
                setIsModalOpen(false);
              }}
              className="reject"
            >
              Yes, Reject
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default PendingTechniciansList;
