import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import { TextField, Button, Popover } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneIcon from "@mui/icons-material/Phone";
import Topbar from "../topbar";
import Sidebar from "../Sidebar";
import Api from "../../service/services";
import Swal from "sweetalert2";
import femaleclor from "../../Assets/profile/female-clicked.svg";
import maleclor from "../../Assets/profile/male-clicked.svg";
import female from "../../Assets/profile/female-normal.svg";
import male from "../../Assets/profile/male-normal.svg";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { FaRegCopy } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
  MdClose,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { Modal } from "react-bootstrap";
import HalfRatings from "../../plugins/HalfRatings";
import { Avatar } from "@mui/material";
import InfoPopover from "./profilePopup";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

const Profile = () => {
  const userId = localStorage.getItem("id");

  const minimumAllowedDate = () => {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 15);
    return currentDate.toISOString().split("T")[0];
  };
  const minimumAllowedDateTech = () => {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 18);
    return currentDate.toISOString().split("T")[0];
  };
  // Function to get the minimum date (January 1, 1920)
  const getMinDate = () => {
    const minYear = new Date(1920, 0, 1); // Month is 0-based (0 = January)
    return minYear.toISOString().split("T")[0]; // Format the date as YYYY-MM-DD
  };
  const [fullname, setFullname] = useState("");
  const [profilename, setProfilename] = useState("");
  const [email, setMail] = useState("");
  const [mobile_no, setMobilenumber] = useState("");
  const [dob, setdob] = useState("");
  const [address, setAddress] = useState("");
  const [address1] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("Tamil Nadu");
  const [city, setCity] = useState("");
  const [postal_code, setPostalcode] = useState("");

  const [removeAvatar, setRemoveAvatar] = useState(false);
  const [images, setImages] = useState("");
  const [resImage, setResImage] = useState(null);
  const [selectedGender, setSelectedGender] = useState("");

  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  const [genderError, setGenderError] = useState("");
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [IsFullNameEmpty, setIsFullEmpty] = useState(false);
  const [IsDobEmpty, setIsDobEmpty] = useState(false);
  const [IsPostalEmpty, setIsPostalEmpty] = useState(false);
  const [availableAppliances, setAvailableAppliances] = useState([]);
  const [labelUserSelectedAppliances, setLabelUserSelectedAppliances] =
    useState([]);
  const [selectedApplianceData, setSelectedApplianceData] = useState([]);
  const [labelUserSelectedModels, setLabelUserSelectedModels] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [Ismale, setIsMale] = useState(false);
  const [IsFemale, setIsFemale] = useState(false);
  const [isAddressEmpty, setIsAddressEmpty] = useState(false);
  const [isProfileUpdate, setIsProfileUpdate] = useState(false);
  const [storeImage, setStoreImage] = useState([]);
  const [imageModal, setImageModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [shopImage, setShopImage] = useState([]);
  const [shopImages, setshopImages] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [removeshop] = useState(false);
  const [shopremove] = useState([]);
  const [, updateState] = useState(0);
  const [userRatings, setUserRatings] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const [selectedApplianceDataInput, setSelectedApplianceDataInput] = useState(
    []
  );
  const [imageChangeed, setImageChangeed] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentValues, setCurrentValues] = useState({});
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };
  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };
  const fileInputRef = useRef(null); // Create a ref for the file input
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(userId);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000); // Reset the color after 1.5 seconds
  };

  const showPreviousImage = () => {
    const previousIndex =
      (currentIndex - 1 + storeImage?.length) % storeImage?.length;
    setCurrentIndex(previousIndex);
  };

  const showNextImage = () => {
    const nextIndex = (currentIndex + 1) % storeImage?.length;
    setCurrentIndex(nextIndex);
  };

  const navigate = useNavigate();
  const Role = localStorage.getItem("role");

  useEffect(() => {
    getProfileDetails();
    const user_id = localStorage.getItem("id");

    Api.reviewsAndRatingsList(user_id)
      .then((res) => {
        setUserRatings(res);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  const getNavigationRoute = () => {
    if (localStorage.getItem("role") === "Consumer") {
      navigate("/customerDashboard");
    } else if (localStorage.getItem("role") === "Technician") {
      navigate("/technicianDashboard");
    } else if (localStorage.getItem("role") === "DistrictAdmin") {
      navigate("/district-admin-dashboard");
    } else if (localStorage.getItem("role") === "SuperAdmin") {
      navigate("/super-admin-dashboard");
    } else if (localStorage.getItem("role") === "ZonalAdmin") {
      navigate("/zonal-admin-dashboard");
    }
  };

  const onShopImageChange = (event) => {
    const maxSizeBytes = 2 * 1024 * 1024; // 2MB
    const allowedMimetypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxAllowedImages = 2;

    const files = Array.from(event.target.files || []);
    const imageFiles = files.filter((file) =>
      allowedMimetypes.includes(file.type)
    );

    // Validate image size
    const validImageFiles = imageFiles.filter(
      (file) => file.size <= maxSizeBytes
    );

    if (
      imageFiles.length > maxAllowedImages ||
      validImageFiles.length !== imageFiles.length
    ) {
      const message =
        imageFiles.length > maxAllowedImages
          ? `You have selected ${imageFiles.length} images, but only ${maxAllowedImages} images (each under 2MB) can be uploaded. Only the first ${maxAllowedImages} images will be processed.`
          : "One or more selected files are larger than 2 MB. Please select files that are each under 2 MB.";

      Swal.fire({
        text: message,
        confirmButtonText: "OK",
      });
    }
    setshopImages(validImageFiles.slice(0, maxAllowedImages));

    if (validImageFiles.length > 0) {
      setShopImage(validImageFiles.slice(0, maxAllowedImages));
    }
  };
  const getProfileDetails = () => {
    Api.getProfile().then((res) => {
      setCurrentValues(res?.data);
      if (res.data) {
        handleCityData(res?.data?.postalcode);
        const payload = {
          user_id: localStorage.getItem("id"),
        };
        Api.getApplianceRelatedList(payload).then((applianceRes) => {
          if (applianceRes) {
            setAvailableAppliances(applianceRes?.availableAppliances);
            setLabelUserSelectedAppliances(
              applianceRes?.userSelectedAppliances?.selectedAppliance
            );
            setLabelUserSelectedModels(
              applianceRes?.userSelectedAppliances?.selectedModels
            );
          }
        });

        setFullname(res.data.fullname);
        setProfilename(res.data.fullname);
        setMail(res.data.emailid);
        setMobilenumber(res.data.mobile_no);
        if (res?.data?.dob) {
          const dobMoment = moment(
            res.data.dob,
            ["YYYY-MM-DD", "DD-MM-YYYY"],
            true
          );
          if (dobMoment.isValid()) {
            setdob(dobMoment.format("YYYY-MM-DD"));
          } else {
            setdob("");
          }
        } else {
          setdob("");
        }
        // setCity(res.data.city);
        forceUpdate((prev) => prev + 1);
        setImage(res.data.avatar);
        if (res.data.avatar) {
          setResImage(res.data.avatar);
        } else {
          setResImage(null);
        }
        setStoreImage(res?.data?.shop_images);
        // localStorage.setItem("userImage", res.data.avatar);
        localStorage.setItem("name", res.data.fullname);
        setSelectedGender(res.data.gender);
        forceUpdate();

        // setSelectedGender("MALE");
        if (res.data.gender === "MALE") {
          setIsMale(true);
          setIsFemale(false);
        } else if (res.data.gender === "FEMALE") {
          setIsMale(false);
          setIsFemale(true);
        }
        setAddress(res.data.address);
        setPostalcode(res.data.postalcode);
      }
    });
  };
  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setPopoverOpen(true);
  };
  const handlePopoverClose = () => {
    setPopoverOpen(false);
    setAnchorEl(null);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    let hasError = false;

    if (fullname === "") {
      setIsFullEmpty("please enter the name");
      hasError = true;
    } else {
      setIsFullEmpty(""); // Reset the error if no issue
    }

    if (mobile_no === "") {
      setMobileError("Please enter the mobile number");
      hasError = true;
    } else if (mobile_no.length !== 10) {
      setMobileError("Please enter a valid mobile Number");
      hasError = true;
    } else {
      setMobileError(""); // Reset the error if no issue
    }
    if (dob === "") {
      setIsDobEmpty(true);
      hasError = true;
    } else {
      setIsDobEmpty(false); // Reset the error if no issue
    }

    if (selectedGender === "" || selectedGender === null) {
      setGenderError("Gender is required");
      hasError = true;
    } else {
      setGenderError("");
    }

    if (country === "") {
      hasError = true;
    }
    if (state === "") {
      hasError = true;
    }
    if (postal_code === "") {
      setIsPostalEmpty(true);
      hasError = true;
    } else {
      setIsPostalEmpty(false);
    }
    if (address === "" || address === null) {
      setIsAddressEmpty(true);
      hasError = true;
    } else {
      setIsAddressEmpty(false);
    }
    if (!hasError) {
      if (
        currentValues?.fullname === fullname &&
        currentValues?.mobile_no === mobile_no &&
        currentValues?.gender === selectedGender &&
        currentValues?.dob === dob &&
        currentValues?.postalcode === postal_code &&
        currentValues?.address === address &&
        !imageModal &&
        !imageChangeed
      ) {
        return;
      }
      if (Role === "Consumer") {
        // If role is Consumer, skip date of birth validation
        const consumerData = new FormData();
        consumerData.append("fullname", fullname);
        consumerData.append("username", email);
        consumerData.append("mobile_no", mobile_no);
        consumerData.append("address", address);
        consumerData.append("address1", address1);
        consumerData.append("dob", dob);
        consumerData.append("gender", selectedGender);
        consumerData.append("country", country);
        consumerData.append("state", state);
        consumerData.append("city", city);
        consumerData.append("postalcode", postal_code);
        // const blob = new Blob([images]);
        // const url = URL.createObjectURL(blob);
        consumerData.append("files", images);

        Api.Profile(consumerData, removeAvatar, removeshop, shopremove).then(
          (response) => {
            Api.getProfile().then((res) => {
              if (res.data) {
                handleCityData(res?.data?.postalcode);
                const payload = {
                  user_id: localStorage.getItem("id"),
                };
                Api.getApplianceRelatedList(payload).then((applianceRes) => {
                  if (applianceRes) {
                    setAvailableAppliances(applianceRes?.availableAppliances);
                    setLabelUserSelectedAppliances(
                      applianceRes?.userSelectedAppliances?.selectedAppliance
                    );
                    setLabelUserSelectedModels(
                      applianceRes?.userSelectedAppliances?.selectedModels
                    );
                  }
                });
                setImageChangeed(false);
                setFullname(res.data.fullname);
                setMail(res.data.emailid);
                setMobilenumber(res.data.mobile_no);
                if (res?.data?.dob) {
                  const dobMoment = moment(
                    res.data.dob,
                    ["YYYY-MM-DD", "DD-MM-YYYY"],
                    true
                  );
                  if (dobMoment.isValid()) {
                    setdob(dobMoment.format("YYYY-MM-DD"));
                  } else {
                    // Handle invalid date here
                    setdob(""); // Or any other appropriate action
                  }
                } else {
                  setdob("");
                }
                setImage(res.data.avatar);
                if (res.data.avatar) {
                  setResImage(res.data.avatar);
                } else {
                  setResImage(null);
                }
                // localStorage.setItem("userImage", res.data.avatar);
                localStorage.setItem("name", res.data.fullname);
                setSelectedGender(res.data.gender);
                setSelectedGender("MALE");
                // eslint-disable-next-line
                if (res.data.gender == "MALE") {
                  setIsMale(true);
                  setIsFemale(false);
                  // eslint-disable-next-line
                } else if (res.data.gender == "FEMALE") {
                  setIsMale(false);
                  setIsFemale(true);
                }
                setAddress(res.data.address);
                setPostalcode(res.data.postalcode);
              }
            });
            setIsProfileUpdate(true);
            if (response?.status === 200) {
              Swal.fire({
                text: response.message,
                // confirmButtonText: "OK",
                timer: 1500,
                timerProgressBar: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.replace("/customerDashboard");
                }
              });
            } else {
              Swal.fire({
                text: response.message,
                // confirmButtonText: "OK",
                timer: 1500,
                timerProgressBar: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
              });
            }
          }
        );
      }
      const formData = new FormData();
      const dataToAppend = {
        fullname,
        username: email,
        mobile_no,
        address,
        address1,
        dob,
        gender: selectedGender,
        country,
        state,
        city,
        postalcode: postal_code,
        files: images,
        shopimage: shopImage,
        applianceMap: JSON.stringify(selectedApplianceData),
      };
      Object.entries(dataToAppend).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            formData.append(key, item);
          });
        } else {
          formData.append(key, value);
        }
      });
      // eslint-disable-next-line
      if (dob && Role != "Consumer") {
        const currentDate = moment().format("YYYY-MM-DD");
        const eighteenYearsAgo = moment(currentDate).subtract(18, "years");
        const isEighteenOrOlder = moment(dob, "YYYY-MM-DD").isBefore(
          eighteenYearsAgo
        );
        if (isEighteenOrOlder) {
          Api.Profile(formData, removeAvatar).then((response) => {
            Api.getProfile().then((res) => {
              if (res.data) {
                handleCityData(res?.data?.postalcode);
                const payload = {
                  user_id: localStorage.getItem("id"),
                };
                Api.getApplianceRelatedList(payload).then((applianceRes) => {
                  if (applianceRes) {
                    setAvailableAppliances(applianceRes?.availableAppliances);
                    setLabelUserSelectedAppliances(
                      applianceRes?.userSelectedAppliances?.selectedAppliance
                    );
                    setLabelUserSelectedModels(
                      applianceRes?.userSelectedAppliances?.selectedModels
                    );
                  }
                });
                setFullname(res.data.fullname);
                setMail(res.data.emailid);
                setMobilenumber(res.data.mobile_no);
                if (res?.data?.dob) {
                  const dobMoment = moment(
                    res.data.dob,
                    ["YYYY-MM-DD", "DD-MM-YYYY"],
                    true
                  );
                  if (dobMoment.isValid()) {
                    setdob(dobMoment.format("YYYY-MM-DD"));
                  } else {
                    // Handle invalid date here
                    setdob(""); // Or any other appropriate action
                  }
                } else {
                  setdob("");
                }
                setImage(res.data.avatar);
                if (res.data.avatar) {
                  setResImage(res.data.avatar);
                } else {
                  setResImage(null);
                }
                // localStorage.setItem("userImage", res.data.avatar);
                localStorage.setItem("name", res.data.fullname);
                setSelectedGender(res.data.gender);
                setSelectedGender("MALE");
                // eslint-disable-next-line
                if (res.data.gender == "MALE") {
                  setIsMale(true);
                  setIsFemale(false);
                  // eslint-disable-next-line
                } else if (res.data.gender == "FEMALE") {
                  setIsMale(false);
                  setIsFemale(true);
                }
                setAddress(res.data.address);
                setPostalcode(res.data.postalcode);
              }
            });
            setIsProfileUpdate(true);
            if (response?.status === 200) {
              Swal.fire({
                text: response.message,
                // confirmButtonText: "OK",
                timer: 1500,
                timerProgressBar: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.replace("/customerDashboard");
                }
              });
            } else {
              Swal.fire({
                text: response.message,
                // confirmButtonText: "OK",
                timer: 1500,
                timerProgressBar: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
              });
              setImageModal(false);
            }
          });
        } else {
          Swal.fire({
            text: "You must be 18 years or older.",
            confirmButtonText: "OK",
          });
        }
      }
    }
  };
  useEffect(() => {
    if (postal_code?.length === 6) {
      handleCityData(postal_code);
    } else {
      setState("");
      setCountry("");
    }
  }, [postal_code]);
  const [districts, setDistricts] = useState([]);
  const [response, setResponse] = useState([]);
  const [district, setDistrict] = useState("");
  const handleCityData = async (postalCode) => {
    try {
      const response = await Api.getLocationByPincode(postalCode);
      setResponse(response);
      if (response?.error) {
        setIsPostalEmpty(response?.error);
        setState("");
        setCountry("");
      }
      let district = "",
        city_id = "";
      response &&
        response?.map((item) => {
          district = item?.district_name;
          city_id = item?.city_id;
          return item.location_name;
        });
      setDistricts([...new Set(response.map((item) => item.district_name))]);
      setDistrict(district);

      setState("Tamil Nadu");
      setCountry("India");
      setCity(city_id);
    } catch (error) {
      setIsPostalEmpty("Invalid Postal Code");
    }
  };
  const handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    setDistrict(selectedDistrict);

    // Find the first city_id associated with the selected district
    const selectedCityId =
      response.find((item) => item.district_name === selectedDistrict)
        ?.city_id || "";

    // Set the city to the found city_id
    setCity(selectedCityId);
  };
  useEffect(() => {
    const formattedLabelUserSelectedAppliances =
      labelUserSelectedAppliances?.map((appliance) => {
        return {
          appliance_id: appliance.id,
        };
      });
    const formattedLabelUserSelectedModels = labelUserSelectedModels?.map(
      (selectedModels) => {
        return {
          appliance_id: selectedModels.appliance_id,
          model_id: selectedModels.id,
        };
      }
    );
    const formattedLabelUserSelectedModelsView = labelUserSelectedModels?.map(
      (selectedModels) => {
        if (
          labelUserSelectedAppliances.some(
            (appliance) => appliance.id === selectedModels.appliance_id
          )
        ) {
          return null;
        } else {
          return {
            appliance_id: selectedModels.appliance_id,
            model_id: selectedModels.id,
          };
        }
      }
    );
    const formattedPayload =
      formattedLabelUserSelectedAppliances && formattedLabelUserSelectedModels
        ? [
          ...formattedLabelUserSelectedAppliances,
          ...formattedLabelUserSelectedModels,
        ]
        : [];
    const formattedPayloadView =
      formattedLabelUserSelectedAppliances &&
        formattedLabelUserSelectedModelsView
        ? [
          ...formattedLabelUserSelectedAppliances,
          ...formattedLabelUserSelectedModelsView,
        ]
        : [];
    setSelectedApplianceData(formattedPayload);
    setSelectedApplianceDataInput(formattedPayloadView);
  }, [labelUserSelectedAppliances, labelUserSelectedModels]);

  useEffect(() => {
    if (isProfileUpdate === true) {
      setTimeout(() => {
        setIsProfileUpdate(false);
        setshopImages([]);
        setEditMode(false);
        getProfileDetails();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [isProfileUpdate]);

  const [image, setImage] = useState(null);
  const [profileImageModal, setProfileImageModal] = useState(false);
  const onImageChange = (event) => {
    event.stopPropagation();
    setRemoveAvatar(false);
    setOpen(!open);

    if (event.target.files && event.target.files.length > 0) {
      const fileList = Array.from(event.target.files);
      const validTypes = ["image/png", "image/jpeg", "image/jpg"];
      const filteredFiles = fileList.filter((file) =>
        validTypes.includes(file.type)
      );
      if (filteredFiles.length > 0) {
        const imageUrls = filteredFiles.map((file) =>
          URL.createObjectURL(file)
        );
        setImage(imageUrls);
        setImageChangeed(true);
        setImages(filteredFiles[0]);
      } else {
        Swal.fire({
          text: "Only JPG, JPEG, and PNG files are allowed.",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };

  const handleDelete = () => {
    setImages("");
    setImage(null);
    setRemoveAvatar(true);
    localStorage.removeItem("userImage");
    setImageChangeed(true);
    setOpen(!open);
  };

  const getUsernameInitials = () => {
    if (!profilename) return "";

    const nameParts = profilename.split(" ");
    if (nameParts.length === 1) {
      return nameParts[0].slice(0, 2).toUpperCase();
    } else {
      const initials = nameParts.map((part) => part.charAt(0));
      return initials.join("").toUpperCase();
    }
  };

  const mobileView = useMediaQuery("(min-width: 200px) and (max-width: 799px)");
  return (
    <div style={{ overflow: "hidden" }}>
      <Topbar isProfileUpdate={isProfileUpdate} profileImage={resImage} />
      <div
        className="row container-fluid"
        style={{ paddingRight: "0px", overflow: "hidden" }}
      >
        <div className="col-2 col-12 p-0 border border-right">
          <Sidebar open={true} />
        </div>
        <div className="col-12 user_management" style={{ marginTop: "80px" }}>
          <div className="row" style={{ paddingRight: "0px" }}>
            <div className="mt-2">
              <i
                className="bi bi-chevron-left "
                style={{
                  fontWeight: "600",
                  fontSize: "21px",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              ></i>
              <text
                className="p-0 mt-5"
                style={{
                  fontWeight: "600",
                  color: "#1E232C",
                  fontSize: "21px",
                  width: "208px",
                  height: "32px",
                }}
              >
                Profile
              </text>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row mt-lg-4">
                <div className="res-width col-lg-2 mb-3">
                  <div>
                    <Box
                      sx={{
                        position: "relative",
                        textAlign: mobileView ? "center" : "left",
                        display: "inline-block",
                      }}
                    >
                      <Avatar
                        src={
                          image
                            ? image
                            : `https://dummyimage.com/200x200/ffedcc/000&text=${getUsernameInitials()}`
                        }
                        sx={{
                          width: 170,
                          height: 170,
                          // cursor: "pointer",
                          margin: mobileView ? "auto" : 0,
                          position: "relative",
                          opacity: image ? 1 : 0.3,
                        }}
                        onClick={() => image && setProfileImageModal(true)}
                      />

                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 10,
                          right: 13,
                          display: "flex",
                          gap: 1,
                        }}
                      >
                        <InfoPopover
                          placement="right"
                          size={40}
                          isOpen={open}
                          onToggle={() => setOpen(!open)}
                          ondelete={handleDelete}
                          image={image}
                          onImageChange={onImageChange}
                          fileInputRef={fileInputRef}
                        />
                      </Box>
                      <Modal
                        open={profileImageModal}
                        onClose={() => setProfileImageModal(false)}
                        aria-labelledby="profile-image-modal"
                        aria-describedby="profile-image-modal-description"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <img
                            alt="profile_image"
                            src={image}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                      </Modal>
                    </Box>
                    <div style={{ fontSize: "9px" }}>
                      <b>Note:</b> Supported file formats are PNG, SVG, JPEG,
                      with a maximum size of 2 MB.
                    </div>
                    <div
                      style={{
                        fontSize: "15px",
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span>
                        <lable style={{ color: "#EB8128" }}>My ID </lable>:
                        {" " + userId}
                      </span>
                      <FaRegCopy
                        onClick={handleCopy}
                        style={{
                          marginLeft: "8px",
                          marginTop: "3px",
                          color: isCopied ? "#ccc" : "inherit",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <HalfRatings
                        rating={userRatings?.average_rating}
                        fontSize={"20px"}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 mt-3">
                  <div>
                    <TextField
                      fullWidth
                      label="Full Name"
                      id="full-name"
                      value={fullname}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        var onlyText = inputValue
                          .replace(/[^A-Za-z ]/g, "")
                          .replace(/  +/g, " ");
                        if (onlyText.startsWith(" ")) {
                          onlyText = onlyText.slice(1);
                        }
                        const truncatedText = onlyText.slice(0, 20);
                        setFullname(truncatedText);
                        setIsFullEmpty(false);
                      }}
                      type="text"
                      placeholder="Enter your full name"
                      error={IsFullNameEmpty ? true : false}
                      helperText={
                        IsFullNameEmpty ? "Please Enter the Full Name" : ""
                      }
                      FormHelperTextProps={{
                        style: {
                          position: "absolute",
                          bottom: "-20px",
                          whiteSpace: "nowrap",
                        },
                      }}
                    />
                  </div>

                  <div className="mt-2">
                    <TextField
                      className="w-100 mt-3 mt-lg-4"
                      disabled
                      fullWidth
                      style={{ height: "56px" }}
                      id="email"
                      value={email}
                      /* onChange={(e) => setMail(e.target.value)} */
                      label="Email"
                      type="email"
                      placeholder="Enter your Email Address"
                      error={!!emailError}
                      helperText={emailError}
                      FormHelperTextProps={{
                        style: {
                          position: "absolute",
                          bottom: "-20px",
                          whiteSpace: "nowrap",
                        },
                      }}
                      onKeyUp={() => {
                        const trimmedEmail = email.trim();
                        setMail(trimmedEmail);

                        if (!trimmedEmail) {
                          setEmailError("Please enter your Email Address.");
                        } else if (!emailPattern.test(trimmedEmail)) {
                          setEmailError("Please enter a valid Email Address.");
                        } else {
                          setEmailError("");
                        }
                      }}
                      readonly
                    />
                  </div>

                  <div className="mt-1">
                    <TextField
                      className="w-100 mt-3 mt-lg-3"
                      style={{ height: "47px" }}
                      id="mobile-number"
                      value={mobile_no}
                      label="Phone number"
                      type="number"
                      placeholder=" 90000 00009"
                      error={!!mobileError}
                      helperText={mobileError}
                      FormHelperTextProps={{
                        style: {
                          position: "absolute",
                          bottom: "-20px",
                          whiteSpace: "wrap",
                        },
                      }}
                      onKeyUp={() => {
                        if (!mobile_no) {
                          setMobileError("Please enter your mobile number.");
                        } else if (mobile_no.length !== 10) {
                          setMobileError("Please enter a valid mobile number");
                        } else {
                          setMobileError("");
                        }
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        const numericValue = value.replace(/\D/g, "");
                        const formattedValue = numericValue.slice(0, 10);
                        setMobilenumber(formattedValue);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            +91 | &nbsp;
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ color: "rgb(235, 129, 40)" }}
                          >
                            <PhoneIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {Role === "Consumer" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                      className="mt-4"
                    >
                      <div className="w-100">
                        <TextField
                          className="w-100 mt-4"
                          id="datePicker"
                          label="Date of Birth"
                          type="date"
                          onChange={(e) => {
                            e.preventDefault();
                            setdob(e.target.value);
                            setIsDobEmpty(false);
                          }}
                          value={dob}
                          error={IsDobEmpty}
                          helperText={
                            IsDobEmpty && "Please select date of Birth"
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end"></InputAdornment>
                            ),
                            inputProps: {
                              max: minimumAllowedDate(),
                              min: getMinDate(),  // Min date (January 1, 1920)
                            },
                          }}
                          style={{ height: "47px" }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="row mt-lg-4 mt-3"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {/* <div className="row mt-lg-4 mt-3"></div> */}
                      <div className="col-lg-6 col-12" onMouseW>
                        <TextField
                          onWheel={(e) => e.preventDefault()}
                          fullWidth
                          className="w-100 mt-2"
                          id="datePicker"
                          label="Date of Birth"
                          type="date"
                          onChange={(e) => {
                            setdob(e.target.value);
                            setIsDobEmpty(false);
                          }}
                          onScroll={(e) => e.preventDefault()}
                          value={dob}
                          error={IsDobEmpty ? true : false}
                          helperText={
                            IsDobEmpty ? "Please select date of Birth" : ""
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end"></InputAdornment>
                            ),
                            inputProps: {
                              max: minimumAllowedDateTech(),
                              min: getMinDate(),  // Min date (January 1, 1920)
                            },
                          }}
                          style={{ height: "47px" }}
                        />

                        {/* <DatePicker
                          selected={dob}
                          onChange={(e) => {
                            setdob(e.target.value);
                            setIsDobEmpty(false);
                          }}
                        /> */}
                      </div>
                      {storeImage == null || storeImage.length === 0 ? (
                        <div class="col-12 col-md-6 mt-2">
                          <Button
                            // variant="outlined"
                            style={{
                              height: "47px",
                              color: "black",
                              // backgroundColor: storeImage == null || storeImage.length === 0 ? "lightgray" : "wnite",
                              textTransform: "none",
                              width: "100%", // Use percentage for better responsiveness
                              border: "1px solid gray",
                              marginLeft: "3px",
                            }}
                            // className="cancel-btn"
                            onClick={() => setImageModal(true)}
                          // disabled={storeImage == null || storeImage?.length === 0}
                          >
                            {/* <div className="d-flex align-items-center gap-1"> */}
                            {/* <div className="d-flex align-items-center font-size-2"><ImCancelCircle /></div> */}
                            {/* <div className="d-flex align-items-center font-weight-2"> */}
                            Add Shop Image
                            {/* </div> */}
                            {/* </div> */}
                          </Button>
                          <Modal
                            className="imageModal"
                            show={imageModal}
                            onHide={() => setImageModal(false)}
                          >
                            <div
                              style={{
                                backgroundColor: "white",
                                width: "100%",
                                maxWidth: "600px",
                                overflow: "hidden",
                                margin: "auto",
                                borderRadius: "15px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                padding: "20px",
                              }}
                            >
                              {/* {editMode ? ( */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  minHeight: "258px",
                                  flexDirection: "column",
                                }}
                              >
                                <div>
                                  <label
                                    className="w-100 "
                                    htmlFor="file-input"
                                    style={{
                                      textAlign: "center",
                                      minHeight: "56px",
                                      padding: "10px",
                                      border: "2px solid #ddd",
                                      borderRadius: "10px",
                                      backgroundColor: "#f9f9f9",
                                      cursor: "pointer",
                                      display: "inline-block",
                                      marginRight: "5px",
                                    }}
                                  >
                                    Upload Shop Images
                                    <p
                                      style={{
                                        margin: "5px 0",
                                        fontSize: "8px",
                                        color: "#666",
                                      }}
                                    >
                                      Up to 2 images, max 2 MB, JPG/JPEG/PNG
                                      only{" "}
                                    </p>
                                  </label>
                                  <input
                                    type="file"
                                    id="file-input"
                                    accept="image/*"
                                    onChange={onShopImageChange}
                                    style={{ display: "none" }}
                                    multiple
                                  />
                                  {/* Display selected image */}
                                  {shopImages.length > 0 && (
                                    <div>
                                      {shopImages.map((image, index) => (
                                        <img
                                          key={index}
                                          src={URL.createObjectURL(image)}
                                          alt={`Selected ${index + 1}`}
                                          style={{
                                            width: "60px",
                                            height: "60px",
                                            marginRight: "5px",
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      ))}
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    onClick={handleSubmit}
                                    style={{
                                      flex: "1", // Take half of the available space
                                      height: "45px",
                                      color: "white",
                                      backgroundColor: !shopImages?.length
                                        ? "#FFC55C"
                                        : "#EB8128",
                                      textTransform: "none",
                                    }}
                                    disabled={!shopImages?.length}
                                  >
                                    Save Image
                                  </Button>
                                </div>
                              </div>
                            </div>
                            ;
                          </Modal>
                        </div>
                      ) : (
                        <div className="col-lg-6 col-12">
                          <Button
                            // variant="outlined"
                            className="w-100 mt-2 "
                            style={{
                              height: "45px",
                              color: "black",
                              // backgroundColor: storeImage == null || storeImage.length === 0 ? "lightgray" : "wnite",
                              textTransform: "none",
                              width: "100%", // Use percentage for better responsiveness
                              border: "1px solid gray",
                              // fontSize: "inherit", // Use the default font size
                              fontWeight: "normal",
                              marginLeft: "3px",
                            }}
                            // className="cancel-btn"
                            onClick={() => setImageModal(true)}
                          // disabled={storeImage == null || storeImage?.length === 0}
                          >
                            {/* <div className="d-flex align-items-center gap-1"> */}
                            {/* <div className="d-flex align-items-center font-size-2"><ImCancelCircle /></div> */}
                            {/* <div className="d-flex align-items-center font-weight-2"> */}
                            View Shop Images
                            {/* </div> */}
                            {/* </div> */}
                          </Button>
                          {imageModal && (
                            <Modal
                              className="imageModal"
                              show={imageModal}
                              onHide={() => setImageModal(false)}
                            >
                              <div
                                style={{
                                  backgroundColor: "white",
                                  width: "100%",
                                  maxWidth: "600px",
                                  overflow: "hidden",
                                  margin: "auto",
                                  borderRadius: "15px",
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                  padding: "20px",
                                }}
                              >
                                {editMode ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      minHeight: "358px",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      htmlFor="file-input"
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        minHeight: "56px",
                                        padding: "10px",
                                        border: "2px solid #ddd",
                                        borderRadius: "10px",
                                        backgroundColor: "#f9f9f9",
                                        cursor: "pointer",
                                        display: "inline-block",
                                        marginRight: "5px",
                                      }}
                                    >
                                      Upload Shop Images
                                      <p
                                        style={{
                                          margin: "5px 0",
                                          fontSize: "8px",
                                          color: "#666",
                                        }}
                                      >
                                        Up to 2 images, max 2 MB, JPG/JPEG/PNG
                                        only
                                      </p>
                                    </label>
                                    <input
                                      type="file"
                                      id="file-input"
                                      accept="image/*"
                                      onChange={onShopImageChange}
                                      style={{ display: "none" }}
                                      multiple
                                    />
                                    {shopImages.length > 0 && (
                                      <div style={{ display: "flex" }}>
                                        {shopImages.map((image, index) => (
                                          <img
                                            key={index}
                                            src={URL.createObjectURL(image)}
                                            alt={`Selected ${index + 1}`}
                                            style={{
                                              marginTop: "15px",
                                              width: "60px",
                                              height: "60px",
                                              marginRight: "5px",
                                              objectFit: "cover",
                                              borderRadius: "50%",
                                            }}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className="p-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div style={{ fontSize: "20px" }}></div>
                                      <div onClick={() => setImageModal(false)}>
                                        <MdClose
                                          style={{
                                            cursor: "pointer",
                                            color: "rgb(235, 129, 40)",
                                            position: "relative",
                                            left: "20px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="image-container"
                                      style={{
                                        overflowX: "hidden",
                                        maxWidth: "80vw",
                                        margin: "auto",
                                        whiteSpace: "nowrap",
                                        position: "relative",
                                      }}
                                    >
                                      {storeImage &&
                                        storeImage.map((imageSrc, index) => (
                                          <img
                                            key={index}
                                            src={imageSrc}
                                            alt="https://images.unsplash.com/photo-1704165873660-884ac0494138?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0fHx8ZW58MHx8fHx8"
                                            style={{
                                              cursor: "pointer",
                                              height: "300px",
                                              width: "100%",
                                              objectFit: "contain",
                                              // marginLeft: '67px',
                                              display:
                                                currentIndex === index
                                                  ? "block"
                                                  : "none",
                                            }}
                                            onClick={() =>
                                              handleImageClick(index)
                                            }
                                          />
                                        ))}
                                      {currentIndex !== 0 ||
                                        (storeImage?.length !== 1 && (
                                          <Button
                                            variant="link"
                                            className="arrow-button left"
                                            onClick={showPreviousImage}
                                            style={{
                                              position: "absolute",
                                              top: "40%",
                                              left: "10px",
                                              color: "rgb(235, 129, 40)",
                                              fontSize: "30px",
                                            }}
                                          >
                                            <MdKeyboardArrowLeft />
                                          </Button>
                                        ))}
                                      {currentIndex !==
                                        storeImage?.length - 1 ||
                                        (storeImage.length !== 1 && (
                                          <Button
                                            variant="link"
                                            className="arrow-button right"
                                            onClick={showNextImage}
                                            style={{
                                              position: "absolute",
                                              top: "40%",
                                              right: "10px",
                                              color: "rgb(235, 129, 40)",
                                              fontSize: "30px",
                                            }}
                                          >
                                            <MdKeyboardArrowRight />
                                          </Button>
                                        ))}
                                    </div>
                                  </div>
                                )}
                                <div
                                  className="w-100"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div style={{ display: "flex", gap: "10px" }}>
                                    <button
                                      onClick={toggleEditMode}
                                      style={{
                                        flex: "1", // Take half of the available space
                                        padding: "8px",
                                        backgroundColor: "rgb(235, 129, 40)",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {editMode ? "Back" : "Change Image"}
                                    </button>
                                    {editMode && (
                                      <Button
                                        variant="contained"
                                        type="submit"
                                        onClick={handleSubmit}
                                        style={{
                                          flex: "1", // Take half of the available space
                                          height: "45px",
                                          color: "white",
                                          backgroundColor: !shopImages?.length
                                            ? "#FFC55C"
                                            : "#EB8128",
                                          textTransform: "none",
                                        }}
                                        disabled={!shopImages?.length}
                                      >
                                        Save Image
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {/* <div className='mt-lg-4 mt-3'>
                                    <text className='mt-3' style={{ fontSize: '11px', fontWeight: '300', color: '#6A707C' }}>Gender</text><br />
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={selectedGender}
                                            name="radio-buttons-group"
                                            onChange={handleChange}
                                        >
                                            <span>
                                                {GenderData.map((gen) =>
                                                    <FormControlLabel
                                                        className=""
                                                        control={<Radio />}
                                                        label={gen.label}
                                                        value={gen.id}
                                                    />
                                                )}

                                            </span>
                                            {IsGenderEmpty ?
                                                <p className='m-0' style={{ color: 'red', fontSize: '12px' }}>please choose gender</p>
                                                : ''}

                                        </RadioGroup>
                                    </FormControl>

                                </div> */}
                  <div
                    className="row m-0 p-0 border rounded-3 mt-3 mt-lg-4"
                    style={{ height: "54px" }}
                  >
                    <div
                      className="col border-right p-lg-2"
                      style={{
                        height: "52px",
                        color: Ismale ? "rgb(235, 129, 40)" : "black",
                        border: genderError && "1px solid #d32f2f",
                        borderRadius: genderError && "3px 0 0 3px",
                        borderRight: !genderError && "1px solid grey",
                      }}
                    >
                      <label
                        htmlFor="maleRadio"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          style={{ display: "none" }}
                          id="maleRadio"
                          checked={Ismale}
                          onChange={() => { }}
                          onClick={() => {
                            setSelectedGender("male");
                            setGenderError("");
                            setIsMale(true);
                            setIsFemale(false);
                          }}
                          type="radio"
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            position: "relative",
                            bottom: "4px",
                          }}
                        >
                          <IconButton color="primary" component="span">
                            <img src={Ismale ? maleclor : male} alt="Male" />
                          </IconButton>
                          <span className="">Male</span>
                        </div>
                      </label>
                    </div>
                    <div
                      className="col p-lg-2"
                      style={{
                        height: "52px",
                        color: IsFemale ? "rgb(235, 129, 40)" : "black",
                        border: genderError && "1px solid #d32f2f",
                        borderRadius: genderError && "0 3px 3px 0",
                      }}
                    >
                      <label
                        htmlFor="femaleRadio"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          position: "relative",
                          bottom: "4px",
                        }}
                      >
                        <input
                          style={{ display: "none" }}
                          id="femaleRadio"
                          checked={IsFemale}
                          onChange={() => { }}
                          onClick={() => {
                            setSelectedGender("female");
                            setGenderError("");
                            setIsFemale(true);
                            setIsMale(false);
                          }}
                          type="radio"
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <IconButton color="primary" component="span">
                            <img
                              src={IsFemale ? femaleclor : female}
                              alt="Female"
                            />
                          </IconButton>
                          <span className="">Female</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  {genderError && (
                    <p
                      className="mt-0"
                      style={{ color: "#d32f2f", fontSize: "12px" }}
                    >
                      {genderError}
                    </p>
                  )}
                </div>

                <div className="col-lg-4 col-12 mt-3">
                  <div className="mt-lg-0 mt-3">
                    <TextField
                      fullWidth
                      label="Country"
                      value={country}
                      aria-readonly={true}
                      disabled
                    />
                  </div>

                  <div className="mt-lg-4 mt-3">
                    <TextField
                      className="mt-lg-1"
                      fullWidth
                      label="State"
                      value={state}
                      aria-readonly={true}
                      disabled
                    />
                  </div>

                  <div className="mt-lg-4 mt-3">
                    <TextField
                      fullWidth
                      label="Address "
                      type="text"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                        setIsAddressEmpty(false);
                      }}
                      placeholder="Type your door no, street name"
                      error={isAddressEmpty ? true : false}
                      helperText={
                        isAddressEmpty ? "Please Enter the Address" : ""
                      }
                    />
                  </div>
                  {/* 
                                <div className='mt-lg-4 mt-3'>
                                    <TextField
                                        fullWidth
                                        label="Address Line 2"
                                        value={address1}
                                        onChange={(e) => setAddress1(e.target.value)}
                                        type='text'
                                        placeholder='Nearest Landmark'
                                    />
                                </div> */}

                  <div className="row mt-lg-4 mt-3">
                    <div className="col-6">
                      <TextField
                        className="w-100"
                        label="District"
                        select
                        value={district}
                        onChange={handleDistrictChange}
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {districts.map((district, index) => (
                          <MenuItem key={index} value={district}>
                            {district}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>

                    <div className="col-6">
                      <TextField
                        fullWidth
                        className="mt-1"
                        label="Postal Code"
                        value={postal_code}
                        onChange={(e) => {
                          const inputPostalCode = e.target.value;
                          let truncatedPostalCode = inputPostalCode.slice(0, 6); // Truncate to 6 characters
                          setPostalcode(truncatedPostalCode);

                          // Check if the input is empty
                          if (truncatedPostalCode.trim() === "") {
                            setIsPostalEmpty(true);
                          } else {
                            setIsPostalEmpty(false);
                          }

                          // Check if the length reaches 6 to disable typing
                          if (truncatedPostalCode.length === 6) {
                            e.preventDefault(); // Prevent further typing
                          }
                        }}
                        type="text"
                        error={IsPostalEmpty ? true : false}
                        helperText={
                          IsPostalEmpty ? "Please Enter Valid Postal Code" : ""
                        }
                      />
                    </div>
                    {localStorage.getItem("role") !== "Consumer" && (
                      <div className="mt-lg-4 mt-4">
                        <div>
                          <div className="label-popover">
                            <div className="content mt-2">
                              Appliance(s) the technician can repair
                            </div>
                          </div>
                          <div
                            onClick={(e) => handlePopoverOpen(e)}
                            className="d-flex align-items-center gap-2 popover-input w-full ps-2"
                          >
                            {selectedApplianceData?.length === 0 && (
                              <div className="text-3">
                                Validate the appliance(s)
                              </div>
                            )}
                            {selectedApplianceDataInput.map((applianceData) => {
                              const returnBadge =
                                availableAppliances?.appliances?.find(
                                  (appliance) =>
                                    appliance?.id ===
                                    applianceData?.appliance_id
                                );
                              return (
                                <div className="badge">
                                  {applianceData?.model_id
                                    ? ""
                                    : returnBadge?.appliance_name}
                                  {applianceData?.model_id &&
                                    returnBadge?.appliance_name}
                                </div>
                              );
                            })}
                          </div>
                          <Popover
                            open={popoverOpen}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            className="appliances-popover"
                          >
                            {availableAppliances?.appliances?.length > 0 &&
                              availableAppliances?.appliances?.map(
                                (applianceValue) => {
                                  const getCheckAppliance = () => {
                                    if (
                                      labelUserSelectedAppliances?.some(
                                        (selectedAppliancesValue) =>
                                          selectedAppliancesValue?.id ===
                                          applianceValue?.id
                                      )
                                    ) {
                                      return true;
                                    } else if (
                                      labelUserSelectedModels?.some(
                                        (selectedModelsValue) =>
                                          selectedModelsValue?.appliance_id ===
                                          applianceValue?.id
                                      )
                                    ) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  };
                                  return (
                                    <div>
                                      <div className="d-flex align-items-center gap-1">
                                        <Checkbox
                                          checked={getCheckAppliance()}
                                          onChange={() => {
                                            if (
                                              labelUserSelectedAppliances?.some(
                                                (appliance) =>
                                                  appliance?.id ===
                                                  applianceValue?.id
                                              )
                                            ) {
                                              const updatedUserSelectedAppliances =
                                                labelUserSelectedAppliances.filter(
                                                  (item) =>
                                                    item?.id !==
                                                    applianceValue?.id
                                                );
                                              setLabelUserSelectedAppliances(
                                                updatedUserSelectedAppliances
                                              );
                                            } else if (
                                              labelUserSelectedAppliances?.some(
                                                (appliance) =>
                                                  appliance?.id !==
                                                  applianceValue?.id
                                              )
                                            ) {
                                              const updatedUserSelectedAppliance =
                                                availableAppliances.appliances.find(
                                                  (item) =>
                                                    item?.id ===
                                                    applianceValue?.id
                                                );
                                              setLabelUserSelectedAppliances([
                                                ...labelUserSelectedAppliances,
                                                updatedUserSelectedAppliance,
                                              ]);
                                            } else {
                                              const updatedUserSelectedAppliance =
                                                availableAppliances.appliances.find(
                                                  (item) =>
                                                    item?.id ===
                                                    applianceValue?.id
                                                );
                                              setLabelUserSelectedAppliances([
                                                updatedUserSelectedAppliance,
                                              ]);
                                            }
                                          }}
                                          disabled
                                        // ={
                                        //   findModels ||
                                        //     localStorage.getItem("role") ===
                                        //     "Technician" && "SuperAdmin"&&"districtAdmin"
                                        //     ? true
                                        //     : false
                                        // }
                                        />
                                        <div>
                                          {applianceValue.appliance_name}
                                        </div>
                                      </div>
                                      <div className="d-flex align-items-center gap-2 ps-4 pe-4">
                                        {availableAppliances?.models?.length >
                                          0 &&
                                          availableAppliances?.models?.map(
                                            // eslint-disable-next-line
                                            (modelValue) => {
                                              const getCheckModel = () => {
                                                const isUserSelected =
                                                  labelUserSelectedModels.some(
                                                    (userSelectedModel) =>
                                                      userSelectedModel?.id ===
                                                      modelValue.id
                                                  );
                                                return isUserSelected;
                                              };
                                              if (
                                                modelValue.appliance_id ===
                                                applianceValue.id
                                              ) {
                                                return (
                                                  <div className="d-flex align-items-center gap-1">
                                                    <Checkbox
                                                      disabled
                                                      checked={getCheckModel()}
                                                      onChange={() => {
                                                        if (
                                                          labelUserSelectedModels?.some(
                                                            (model) =>
                                                              model?.id ===
                                                              modelValue?.id
                                                          )
                                                        ) {
                                                          const updatedUserSelectedModels =
                                                            labelUserSelectedModels.filter(
                                                              (item) =>
                                                                item?.id !==
                                                                modelValue?.id
                                                            );
                                                          setLabelUserSelectedModels(
                                                            updatedUserSelectedModels
                                                          );
                                                        } else if (
                                                          labelUserSelectedModels?.some(
                                                            (model) =>
                                                              model?.id !==
                                                              modelValue?.id
                                                          )
                                                        ) {
                                                          const updatedUserSelectedModel =
                                                            availableAppliances.models.find(
                                                              (item) =>
                                                                item?.id ===
                                                                modelValue?.id
                                                            );
                                                          setLabelUserSelectedModels(
                                                            [
                                                              ...labelUserSelectedModels,
                                                              updatedUserSelectedModel,
                                                            ]
                                                          );
                                                        } else {
                                                          const updatedUserSelectedModel =
                                                            availableAppliances.models.find(
                                                              (item) =>
                                                                item?.id ===
                                                                modelValue?.id
                                                            );
                                                          setLabelUserSelectedModels(
                                                            [
                                                              updatedUserSelectedModel,
                                                            ]
                                                          );
                                                        }
                                                      }}
                                                    />
                                                    <div>
                                                      {
                                                        modelValue.appliance_model
                                                      }
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            }
                                          )}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </Popover>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
              <div className="border border-bottom mt-3"></div>
              <div className="row mt-lg-4 mt-3">
                <div className="col-lg"></div>
                <div className="col-lg col-12 text-end">
                  <ul className="p-0" style={{ display: "inline-flex" }}>
                    <li style={{ listStyle: "none" }}></li>
                    <li className="me-2" style={{ listStyle: "none" }}>
                      <Button
                        onClick={() => getNavigationRoute()}
                        variant="outlined"
                        style={{
                          height: "45px",
                          width: "120px",
                          color: "rgb(235, 129, 40)",
                          border: "1px solid rgb(235, 129, 40)",
                          textTransform: "none",
                        }}
                        className="sr-edit"
                      >
                        Cancel
                      </Button>
                    </li>
                    <li className="me-2" style={{ listStyle: "none" }}>
                      <Button
                        variant="contained"
                        type="submit"
                        style={{
                          height: "45px",
                          color: "white",
                          backgroundColor: "#EB8128",
                          textTransform: "none",
                        }}
                      >
                        Save Profile
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
