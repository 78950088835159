import React, { useEffect, useState } from "react";
import Logo from "../Assets/Cust/login.svg";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Api from "../service/services";
import { Link, useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Swal from "sweetalert2";
import { getNotificationsCount } from "../redux/actions/notificationsAction";
import { useDispatch } from "react-redux";
import S3NewLogo from "../Assets/s3_new_logo/S3NewLogo";
import Footer from "../../src/components/Footer";
const Login = () => {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [mobileError, setMobileError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const user_token = localStorage.getItem("token");
    const user_role = localStorage.getItem("role");
    if (user_token) {
      if (user_role === "Technician") {
        navigate("/technicianDashboard");
      } else if (user_role === "SuperAdmin") {
        navigate("/super-admin-dashboard");
      } else if (user_role === "ZonalAdmin") {
        navigate("/zonal-admin-dashboard");
      } else if (user_role === "DistrictAdmin") {
        navigate("/district-admin-dashboard");
      } else {
        navigate("/customerDashboard");
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleMobileChange = (e) => {
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    const value = e.target.value;
    setMobile(value);

    if (!value) {
      setMobileError(true);
    } else if (!emailRegex.test(value)) {
      setMobileError(true);
    } else {
      setMobileError("");
    }
  };
  const isButtonDisabled = () => {
    if (!password || !mobile) {
      return true;
    } else if (mobileError || passwordError) {
      return true;
    } else {
      return false;
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    if (!value) {
      setPasswordError(true);
    } else {
      setPasswordError("");
    }
  };

  const login = async () => {
    setMobileError("");
    setPasswordError("");

    let isValid = true;
    if (!mobile) {
      setMobileError("Please Enter the Email Id.");
      isValid = false;
    }
    if (!password) {
      setPasswordError("Please Enter the Password.");
      isValid = false;
    }

    if (isValid) {
      const data = new FormData();
      data.append("username", mobile);
      data.append("password", password);
      data.append("skip_otp", true);
      const deviceInfo = {
        userAgent: navigator.userAgent,
        deviceType: /Mobi/.test(navigator.userAgent) ? "Mobile" : "Desktop",
      };
      data.append("device_info", JSON.stringify(deviceInfo));
      localStorage.clear();

      try {
        const response = await Api.Login(data);

        if (response.status === 200) {
          localStorage.setItem("token", response.data[0].token);
          localStorage.setItem("zone_id", response?.data[0]?.zone_id);
          response.data[0].district_id &&
            localStorage.setItem("district_id", response.data[0].district_id);
          localStorage.setItem("email_id", response.data[0].emailid);
          localStorage.setItem("id", response.data[0].id);
          localStorage.setItem("role", response.data[0].role_name);
          localStorage.setItem("name", response.data[0].fullname);
          localStorage.setItem("userImage", response.data[0].avatar);
          dispatch(getNotificationsCount(response.data[0].token));
          Api.getProfileImage(response.data[0].token).then((res) => {
            if (response.data[0].role_name === "Technician") {
              navigate("/technicianDashboard");
            } else if (response.data[0].role_name === "DistrictAdmin") {
              navigate("/district-admin-dashboard");
            } else if (response.data[0].role_name === "ZonalAdmin") {
              navigate("/zonal-admin-dashboard");
            } else if (response.data[0].role_name === "SuperAdmin") {
              navigate("/super-admin-dashboard");
            } else {
              navigate("/customerDashboard");
            }
            localStorage.setItem("userImage", res?.data?.avatar);
          });
        } else {
          Swal.fire({
            text: response?.response?.data?.message || response?.message,
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="">
      <div className="container-fluid">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
          className="res-576px-d-unset mt--40px"
        >
          <div
            className="p-0 res-576px-d-none"
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              width: "43.5%",
              padding: "60px",
              justifyContent: "center",
            }}
          >
            <div
              className="logo_container"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
                marginTop: "100px",
              }}
            >
              <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                <S3NewLogo color={"rgba(235, 129, 40, 1)"} />
              </div>
              <img alt="demo" src={Logo} className="logo" />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "center",
                  width: "76%",
                }}
              >
                Are your electronics appliances acting up?
              </div>
              <div
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  width: "76%",
                  color: "rgba(0, 0, 0, 1)",
                }}
                className="mt--10px"
              >
                Log in now to easily book a Service Request with our skilled
                technicians.
              </div>
            </div>
          </div>
          <div className="col-lg-1 border-register-right res-576px-d-none"></div>
          <div className="res-577px-d-none" onClick={() => navigate("/")}>
            <S3NewLogo color={"rgba(235, 129, 40, 1)"} />
          </div>
          <div
            style={{
              width: "56.5%",
            }}
            className="res-576px-w-fill"
          >
            <div
              className="p-lg-5 p-md-3 p-2 res-576px-mt-0"
              style={{ marginTop: "100px" }}
            >
              <div className="text-start">
                <p
                  className="font-weight-bold text-dark"
                  style={{ fontWeight: 600, fontSize: "22px" }}
                >
                  Welcome to Secure Sales Service
                </p>
              </div>

              <div className="d-flex align-items-center">
                <div></div>
              </div>
              <form onSubmit={login}>
                <TextField
                  className="w-100 mt-3 textfield-mui"
                  style={{ height: "56px" }}
                  id="username"
                  value={mobile}
                  onChange={handleMobileChange}
                  label="Enter your registered email id"
                  type="text"
                  placeholder="Registered email address"
                  error={!!mobileError}
                  helperText={mobileError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className="w-100 mt-4"
                  style={{ height: "56px" }}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  label="Enter your password"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  error={!!passwordError}
                  helperText={passwordError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          tabIndex={-1}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "72%" }}
                    className="res-576px-w-90per"
                  ></div>
                  <div className="text-end" style={{ width: "30%" }}>
                    <Link
                      to="/forgotPassword"
                      className="text-muted text-decoration-none"
                      style={{ fontSize: "12px" }}
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>

                <Button
                  className="w-100 mt-4 rounded-3"
                  onClick={login}
                  style={{
                    height: "56px",
                    backgroundColor: isButtonDisabled()
                      ? "rgb(235 129 40 / 55%)"
                      : "rgba(235, 129, 40, 1)",
                    color: "white",
                    fontWeight: "600",
                    fontSize: "15px",
                    textTransform: "capitalize",
                  }}
                  variant="contained"
                  disabled={isButtonDisabled()}
                  Submit
                >
                  Login
                </Button>
                <div className="text-center mt-4" style={{ fontSize: "14px" }}>
                  <p className="m-0">
                    Don't have an account?
                    <Link
                      to="/commonRegister"
                      className="text-decoration-none font-weight-bold"
                      style={{ color: "#EB8128" }}
                    >
                      {" "}
                      Register Now
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "100px" }} className="res-576px-mt-30px">
        <Footer topscroller />
      </div>
    </div>
  );
};
export default Login;
