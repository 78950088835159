import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Popover } from "react-tiny-popover";
import { FaCalendarDays } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { getAllRewardZoneList } from "../../../redux/actions/rewardZoneListAction";
import { getAllRewardDistrictList } from "../../../redux/actions/rewardDistrictListAction";
import { getAllrewardLoginList } from "../../../redux/actions/rewardLoginTechnician";
import { getAllrewardWinningList } from "../../../redux/actions/rewardWinningTechnicianAction";
import { setSelectedDate as setSelectedDateAction } from "../../../redux/slices/todayParticipantsSlice ";

const DatePickerClaimDashboard = () => {
  const dispatch = useDispatch();
  const [viewDatePickerPopover, setViewDatePickerPopover] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    dispatch(getAllRewardZoneList(formattedDate, formattedDate, "technician"));
    dispatch(
      getAllRewardDistrictList(formattedDate, formattedDate, "technician")
    );
    dispatch(getAllrewardLoginList(formattedDate, formattedDate, "technician"));
    dispatch(
      getAllrewardWinningList("technician", formattedDate, formattedDate)
    );
  }, [dispatch, selectedDate]);

  const handleSelect = (date) => {
    setSelectedDate(date);
    dispatch(setSelectedDateAction(date)); // Dispatch action to update slice state
  };

  const clearData = () => {
    setSelectedDate(new Date());
  };

  return (
    <div>
      <Popover
        isOpen={viewDatePickerPopover}
        positions={["top", "bottom", "left", "right"]}
        content={
          <div style={{ boxShadow: "0 0 10px 1px #80808091" }}>
            <Calendar
              date={selectedDate}
              onChange={handleSelect}
              maxDate={new Date()}
            />
            <div className="date-range-quick-access">
              <button className="clear-button-dashboard" onClick={clearData}>
                Clear
              </button>
            </div>
          </div>
        }
        onClickOutside={() => setViewDatePickerPopover(false)}
      >
        <div
          onClick={() => setViewDatePickerPopover(!viewDatePickerPopover)}
          className="selected-date"
        >
          <div>{moment(selectedDate).format("DD-MM-YYYY")}</div>
          <FaCalendarDays style={{ color: "rgb(235, 129, 40)" }} />
        </div>
      </Popover>
    </div>
  );
};

export default DatePickerClaimDashboard;
